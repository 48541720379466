import React,{useState,useContext} from 'react'
import { FIELDS } from '../Firebase/Constants';
import {AuthContext} from '../Firebase/AuthProvider'

export default function MessageWrite(props) {
    const { state } = useContext(AuthContext);

    const [message, setMessage] = useState("")
    const [errorText, setErrorText] = useState(false)
    const [emails, setemails] = useState([])
    const [showTagBox, setshowTagBox] = useState(false)
    const [top, setTop] = useState(false)
    const [left, setLeft] = useState(false)

    const onChangeHandler = (e) =>{
        setMessage(e.target.value)
        let element = e.target
        let cordi = getCaretCoordinates(element,element.selectionEnd)
        
        element.style.height = "5px";
        element.style.height = (element.scrollHeight)+"px";



        setTop(cordi.top)
        setLeft(cordi.left)

        props.setTagUserEmpty()
        // search User if character length is greater than equal to 3
        let breakWord = e.target.value.split(" ")
        if(breakWord[breakWord.length -1]?.includes("@")){
            for (let counter = breakWord.length - 1; counter >= breakWord.length - 2; counter--) {
                if(breakWord[counter]?.includes("@") && breakWord[counter].length >= 4){
                    props.autoCompleteChat(breakWord[counter])
                    if(props.tagUsers?.length > 0){
                        setshowTagBox(true)
                    }
                }
            }
        }

    }

    const getCaretCoordinates = (element, position) => {
        var properties = [
            'boxSizing',
            'width',  // on Chrome and IE, exclude the scrollbar, so the mirror div wraps exactly as the textarea does
            'height',
            'overflowX',
            'overflowY',  // copy the scrollbar for IE
            
            'borderTopWidth',
            'borderRightWidth',
            'borderBottomWidth',
            'borderLeftWidth',
            
            'paddingTop',
            'paddingRight',
            'paddingBottom',
            'paddingLeft',
            
            // https://developer.mozilla.org/en-US/docs/Web/CSS/font
            'fontStyle',
            'fontVariant',
            'fontWeight',
            'fontStretch',
            'fontSize',
            'lineHeight',
            'fontFamily',
            
            'textAlign',
            'textTransform',
            'textIndent',
            'textDecoration',  // might not make a difference, but better be safe
            
            'letterSpacing',
            'wordSpacing'
        ];
        
        var isFirefox = !(window.mozInnerScreenX == null);
        var mirrorDiv, computed, style;

        console.log("this")

        // mirrored div
        mirrorDiv = document.getElementById(element.nodeName + '--mirror-div');
        if (!mirrorDiv) {
          mirrorDiv = document.createElement('div');
          mirrorDiv.id = element.nodeName + '--mirror-div';
          document.body.appendChild(mirrorDiv);
        }
      
        style = mirrorDiv.style;
        computed = getComputedStyle(element);
      
        // default textarea styles
        style.whiteSpace = 'pre-wrap';
        if (element.nodeName !== 'INPUT')
          style.wordWrap = 'break-word';  // only for textarea-s
      
        // position off-screen
        style.position = 'absolute';  // required to return coordinates properly
        style.top = element.offsetTop + parseInt(computed.borderTopWidth) + 'px';
        style.left = "-400px";
        style.color = "transparent";
        style.cursor = "none"
        // style.visibility = mirrorDivDisplayCheckbox.checked ? 'visible' : 'hidden';  // not 'display: none' because we want rendering
      
        // transfer the element's properties to the div
        properties.forEach(function (prop) {
          style[prop] = computed[prop];
        });
      
        if (isFirefox) {
          style.width = parseInt(computed.width) - 2 + 'px'  // Firefox adds 2 pixels to the padding - https://bugzilla.mozilla.org/show_bug.cgi?id=753662
          // Firefox lies about the overflow property for textareas: https://bugzilla.mozilla.org/show_bug.cgi?id=984275
          if (element.scrollHeight > parseInt(computed.height))
            style.overflowY = 'scroll';
        } else {
          style.overflow = 'hidden';  // for Chrome to not render a scrollbar; IE keeps overflowY = 'scroll'
        }  
      
        mirrorDiv.textContent = element.value.substring(0, position);
        // the second special handling for input type="text" vs textarea: spaces need to be replaced with non-breaking spaces - http://stackoverflow.com/a/13402035/1269037
        if (element.nodeName === 'INPUT')
          mirrorDiv.textContent = mirrorDiv.textContent.replace(/\s/g, "\u00a0");
      
        var span = document.createElement('span');
        // Wrapping must be replicated *exactly*, including when a long word gets
        // onto the next line, with whitespace at the end of the line before (#7).
        // The  *only* reliable way to do that is to copy the *entire* rest of the
        // textarea's content into the <span> created at the caret position.
        // for inputs, just '.' would be enough, but why bother?
        span.textContent = element.value.substring(position) || '.';  // || because a completely empty faux span doesn't render at all
        span.style.backgroundColor = "lightgrey";
        mirrorDiv.appendChild(span);
      
        var coordinates = {
          top: span.offsetTop + parseInt(computed['borderTopWidth']),
          left: span.offsetLeft + parseInt(computed['borderLeftWidth'])
        };
      
        return coordinates;
    }



    const onTagClick = (e)=>{
        setshowTagBox(false)
        let tagName = "@"+e.target.dataset.value
        let email = e.target.dataset.email
        
        let mess = message.split(" ").slice(0, -1).join(" ");
        setMessage(mess+" "+tagName)

        let id = [...emails]
        id.push(email)

        setemails(id)
        setLeft(false)
        setTop(false)
        props.setTagUserEmpty()
    }

    const sendMessage = (e) =>{
        if(!state.currentUser){
            props.onLoginClick()
        }else{
            if(message === "" || message.trim() === ""){
                setErrorText("Please type something!")
                e.preventDefault();
                return
            }else if(!state.currentUser){
                setErrorText("please Login first")
                setMessage("")
            }
            else{
                setErrorText(false)
                let data = {
                    [FIELDS.CHAT.CHAT_MESSAGE] : message,
                    [FIELDS.CHAT.CHAT_USER_ID] : state?.currentUser,
                    [FIELDS.CHAT.CHAT_USER_NAME] : state?.userDetail?.[FIELDS.USER.USER_NAME],
                    [FIELDS.CHAT.CHAT_USER_IMAGE] : state?.userDetail?.[FIELDS.USER.USER_IMAGE],
                    [FIELDS.CHAT.CHAT_TO_USER_ID] : props.selectedUser.id,
                }
                if(e) {
                    let elem = e.target
                    e.preventDefault()
                    elem.style.height = "34px"
                }
                document.querySelector("textarea").value = "";
    
                props.onSendClick(data,emails)
                setMessage("")
                setemails([])
            }
        }
    }

    return (
        <React.Fragment>
        <div className="write-message-component">
            <div className="textarea-container">
                <textarea placeholder="Type a message" value={message} onKeyDown={e=>{(e.keyCode === 13 && !e.shiftKey) && sendMessage(e)}} onChange={e=>{onChangeHandler(e)}}></textarea>
                <ul style={{"top":top,"left":left+3}} id="suggestedList" className={`suggestion-box ${showTagBox && "display-suggestion-box"}`}>
                    {props.tagUsers && props.tagUsers.map((el,key)=>{
                        return(
                            <li tabIndex="-1" onClick={(e)=>{onTagClick(e)}} data-value={el.name} data-email={el.email} key={key}>{el.name}</li>
                        )
                    })}
                </ul>
            </div>
            <button onClick={()=>{sendMessage()}}>
                <img src={require("../Assets/Images/Assembly.png")} alt=""/>
            </button>

        </div>
        {errorText && (
            <span className="text-red">{errorText}</span>
        )}
        </React.Fragment>
    )
}
