import React, { Component, Fragment } from "react";
import { AuthContext } from "../Firebase/AuthProvider";
import DatePicker from "react-datepicker";
import { updateUserExperience } from "../Firebase/Users";
import "react-datepicker/dist/react-datepicker.css";
import { getUserDetail } from "../Firebase/Users";

class ProfileModal extends Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    let currentDate = new Date()

    this.state = {
      jobTitle: this.props.selectedExp.length !== 0 ? this.props.selectedExp.jobTitle  : "",
      companyName: this.props.selectedExp.length !== 0 ? this.props.selectedExp.companyName  : "",
      startDate: this.props.selectedExp.length !== 0 ? new Date(this.props.selectedExp.startDate)  : currentDate,
      endDate: this.props.selectedExp.length !== 0 ? new Date(this.props.selectedExp.endDate)  : currentDate,
      description: this.props.selectedExp.length !== 0 ? this.props.selectedExp.description  : "",
      present: this.props.selectedExp.length !== 0 ? this.props.selectedExp.present  : false,
    };
  }
  
  onChangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  validate = () => {
    let error = "";
    if (this.state.jobTitle === "") {
      error = "Role cannot be blank";
      return error;
    }
    if (this.state.companyName === "") {
      error = "Company name cannot be blank";
      return error;
    }
    if (!this.state.startDate) {
      error = "Start date cannot be blank";
      return error;
    }
    if (!this.state.endDate) {
      error = "End date cannot be blank";
      return error;
    }
    return null;
  };
  

  onSubmitForm = async (e) => {
    e.preventDefault();
    const validate = this.validate();
    if (validate != null) {
      this.setState({ errorText: validate });
      return;
    } else {
      this.setState({ errorText: "" });
    }
    this.context.showLoader(true);
    let userUID = localStorage.getItem("userID")
    let userDetail = this.context.state.userDetail
    let userExperience = userDetail.user_experience ? userDetail.user_experience : []
    
    let endDate = this.state.endDate
    if(this.state.present) {
      endDate = new Date()
    } 
    let data = {
      'jobTitle': this.state.jobTitle,
      'companyName': this.state.companyName,
      'startDate': (this.state.startDate.getMonth()+1)+'/'+(this.state.startDate.getDate())+'/'+this.state.startDate.getFullYear(),
      'endDate': (endDate.getMonth()+1)+'/'+(endDate.getDate())+'/'+endDate.getFullYear(),
      'description': this.state.description,
      'present': this.state.present
    }
    if(this.props.selectedIndex !== "") {
      userExperience[this.props.selectedIndex] = data
    }
    else {
      userExperience.push(data)
    }
    let resp = await updateUserExperience(userUID, userExperience)
    if(resp){
      let userData = await getUserDetail(userUID)
      if(userData?.id === userUID){
        this.context.setCurrentUser(userUID, userData);
        this.context.getData();
        this.props.updateCurrentUser(userData)
      }
    }
    this.context.showLoader(false);
  };

  handleStartDateChange = (date) => {
    this.setState({ startDate: date })
  }

  handleEndDateChange = (date) => {
    this.setState({ endDate: date })
  }

  render() {
    return (
      <div className="user-modal" onClick={() => this.props.closeModal()}>
        <div className="user-modal-inner" onClick={(e) => e.stopPropagation()}>
          {this.props.show === "showProfileModal" && (
            <div className="change-pswrd-div gnrl-styling">
              {/* <h3 className="login-header">
                Update Profile
              </h3> */}
              <form>
                <label>Role</label>
                <input
                  type="text"
                  required
                  name="jobTitle"
                  value={this.state.jobTitle}
                  onChange={(e) => this.onChangeHandler(e)}
                  placeholder="e.g. Founder"
                  className="email-input"
                />
                <label>Company</label>
                <input
                  type="text"
                  required
                  name="companyName"
                  value={this.state.companyName}
                  onChange={(e) => this.onChangeHandler(e)}
                  placeholder="e.g. Appletree"
                  className="email-input"
                />
                <label>Start Date</label>
                <DatePicker
                  selected={this.state.startDate}
                  onChange={this.handleStartDateChange}
                  showYearDropdown={true}
                  className="email-input"
                />
                
                {
                  !this.state.present &&
                  <Fragment>
                    <label>End Date</label>
                    <DatePicker
                      selected={this.state.endDate}
                      onChange={this.handleEndDateChange}
                      showYearDropdown={true}
                      className="email-input"
                    />
                  </Fragment>
                }
                <div className="stillworking">
                  <input 
                    name="present"
                    type="checkbox"
                    value={this.state.present}
                    checked={this.state.present}
                    onChange={() => this.setState({ present: !this.state.present})}
                  />&nbsp;
                  <label>Currently working here</label>
                </div>
                <label>Description</label>
                <textarea
                  cols="30"
                  rows="2"
                  value={this.state.description}
                  onChange={(e) => this.setState({ description: e.target.value })}
                  placeholder="Your description"
                  name="description"
                  className="email-input"
                ></textarea>
                {this.state.errorText && <span className="text-red">{this.state.errorText}</span>}
                <button
                  className="login-button-form"
                  onClick={(e) => {
                    this.onSubmitForm(e);
                  }}
                >
                  Update
                </button>
              </form>
            </div>
          )}
          

        </div>
      </div>
    );
  }
}

export default ProfileModal;
