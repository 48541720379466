import React, { Component } from "react";
import { AuthContext } from "../../Firebase/AuthProvider";
import speakerIcon from '../../Assets/Images/userPlaceholder.jpeg'
import Helper from "../../Helper/helper";
import SearchDropdown from "../SearchDropdown";

class AddAdmin extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      speakerList: this.props.adminList
    };
  }

  onAddSpeakerClick = (data) => {
    let oldSpeaker = [...this.state.speakerList];
    oldSpeaker.push(data);
    this.setState({ speakerList: oldSpeaker });
    this.props.updateChannelInfo({ adminList: oldSpeaker });
  }

  closeDropdown = (e) => {
    let dropDownMenu = e.target.parentNode.parentNode;
    dropDownMenu.classList.remove("show-post-menu-dropdown");
  };

  removeSpeaker = (name) => {
    let newSpeakers = [...this.state.speakerList]
    newSpeakers = newSpeakers.filter(el => {
      return el.name !== name;
    })
    this.setState({ speakerList: newSpeakers });
    this.props.updateChannelInfo({ adminList: newSpeakers });
  }

  openPostMenu = (e) => {
    let menu = e.target.childNodes[0];
    if (menu.classList.contains("show-post-menu-dropdown")) {
      menu.classList.remove("show-post-menu-dropdown");
    } else {
      menu.classList.add("show-post-menu-dropdown");
    }
  };

  render() {
    return (
      <div className="create-live-event-component">
        <div className="cle-inner">
          <form>
          <div className="cle-input-group" style={{marginBottom:`25px`}}>
              <SearchDropdown title="Add Admin" width="100" onAddSpeakerClick={this.onAddSpeakerClick} />
            </div>
            <div className={`ourSpeakers ${this.state.speakerList?.length === 0 && "remove-grid"}`}>
                {this.state.speakerList?.map((item, index) => {
                  return (
                    <div className="speaker" key={index}>
                      <div className="image-div">
                        <img
                          src={item.image?? speakerIcon}
                          alt="speaker"
                          className="img-fluid"
                        />
                      </div>

                      <div
                        className="pbm-post-menu float-right"
                        onClick={(e) => {
                          this.openPostMenu(e);
                        }}
                      >
                        <div
                          className="post-menu-dropdown"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <ul>
                            <li
                              className="delete"
                              onClick={(e) => {
                                this.removeSpeaker(item.name)
                                this.closeDropdown(e);
                              }}
                            >
                              Delete
                            </li>
                          </ul>
                        </div>
                      </div>
                      <p>{Helper.transformText(item.name)}</p>
                      <span>{item.title}</span>
                    </div>
                  );
                })}
          </div>
          </form>
        </div>
      </div>
    );
  }
}

export default AddAdmin;
