import React, { useState, useContext, useEffect } from 'react'
import Select from 'react-select';
import { searchSpecificUser, getUserDetail } from '../Firebase/Users';
import userIcon from '../Assets/Images/profile.png'
import {AuthContext} from '../Firebase/AuthProvider'
import MessageChat from './messageChat';
import { app } from "../Firebase/firebase-config";
import { withChat } from "../Firebase/Schemas";
const db = app.firestore();

function Messenger() {
    const [selectedOption] = useState(null)
    const [searchResults, setSearchResults] = useState([])
    const [searchValue, setSearchValue] = useState("")
    const [selectedUser, setSelectedUser] = useState("")
    const { state } = useContext(AuthContext);
    const [chats, setChatsList] = useState([]);
    const [inboxUsers, setInboxUsers] = useState([]);
    const [inboxGroups, setInboxGroups] = useState([]);
    const [hostId, setHostId] = useState(state?.currentUser);
    const [isChannel, setIsChannel] = useState(false);
    

    const handleChange = async selectedOption => {
        setSearchValue(selectedOption);
        if(selectedOption){
          const result = await searchSpecificUser(selectedOption.toLowerCase());
          let users = result.map(el=>{
            el.id = el.uid
            return el;
          })
          let newResult = users.map((item) => {
            return { value: item.name, label:  <div className="userImg dropdownWithImage"><a href={() => false} onClick={()=>getUserInfo(item)}><img className="img-fluid" src={item.image ? item.image : userIcon} alt="noImage" />&nbsp;&nbsp;{item.name}</a></div> }
          })
          setSearchResults(newResult)
        }
        else {
          setSearchResults([])
        }
      };

      const getUserInfo = (user) => {
        setIsChannel(false);
        setSelectedUser(user);
        setHostId(state?.currentUser);
        setChatsList([])
      }

      const getInboxUsersList = async () => {
        let InboxList = [];
        await db.collection("users").doc(localStorage.getItem("userID") ).collection("group_chat").orderBy("chat_created_at","asc").onSnapshot((snapshot) => {
            snapshot.docChanges().forEach(async (change) => {
              let chatMessage = withChat(change.doc.data());
                InboxList = InboxList.filter(el => {
                  return el.chat_to_user_id !== chatMessage.chat_to_user_id
              })
              InboxList.push(chatMessage);
              InboxList.sort(
                (a, b) => b.chat_created_at - a.chat_created_at
              );
              await InboxList.map(async el => {
                    let userToInfo = await getUserDetail(el.chat_to_user_id);
                    el.toUser = userToInfo;
                    
                })
            });
            setTimeout(() => {
                setInboxUsers(InboxList);
            }, 1000)
          });
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      const getChannelMessages = async () => {
          let myChannels = state?.userDetail.user_channels;
          let groupMessages = [];

          if(myChannels) {
            // eslint-disable-next-line array-callback-return
            await myChannels.map(el => {
                db.collection("channels").doc(el).collection("group_chat").orderBy("chat_created_at","desc").limit(1).onSnapshot((snapshot) => {
                    snapshot.docChanges().forEach((change) => {
                      let chatData = change.doc.data();
                      groupMessages.push(chatData)
                      groupMessages.sort(
                        (a, b) => b.chat_created_at - a.chat_created_at
                      );
                    });
                  });
            })
            setTimeout(() => {
               setInboxGroups(groupMessages);
            }, 1500)
          }
      }

      useEffect(() => {
        getInboxUsersList();
        getChannelMessages();    
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [state?.userDetail])

      const getChannelChat = (item) => {
        setIsChannel(true);
        setHostId(item.chat_event_id);
        setSelectedUser({ id: item.chat_event_id, name: item.chat_channel_name, image: item.chat_channel_image  })
      }

      

    return (
        <div id="messenger_comp">
            <div className="messenger-list">
                <div className="header">
                    <h3>Inbox</h3>
                </div>
                    <Select
                    className="search-bar-header"
                    value={selectedOption}
                    onInputChange={event => handleChange(event)}
                    inputValue={searchValue}
                    options={searchResults}
                    placeholder="Search People"
                    noOptionsMessage={() => 'No people found.'}
                    />
                <div className="body">
                {
                    inboxUsers.map(el => {
                        return (
                            <div className="list">
                                <div className={`messeger-item list`} onClick={() => getUserInfo(el.toUser)}>
                                    <div className={`image list`}>
                                        {el.toUser ? <img src={el.toUser.image} alt="noImage" /> : ""}
                                    </div>
                                    <div className={`detail list`}>
                                        <div className="name">{el.toUser ? el.toUser.name : ""}  </div>
                                        <span>{el.chat_message}</span>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                {
                    inboxGroups.map(el => {
                        return (
                            <div className="list"  onClick={() => getChannelChat(el)}>
                                <div className={`messeger-item list`}>
                                    <div className={`image list`}>
                                        <img src={el.chat_channel_image} alt="noImage" />
                                    </div>
                                    <div className={`detail list`}>
                                        <div className="name">{el.chat_channel_name}  </div>
                                        <span>{el.chat_message}</span>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                </div>
            </div>

            <div className="messenger-view">
                <div className="header">
                {
                    selectedUser &&
                    <div className={`messeger-item header}`}>
                        <div className={`image header`}>
                            <img src={selectedUser.image} alt="noImage" />
                        </div>
                        <div className={`detail header`}>
                            <div className="name">{selectedUser.name}  </div>
                        </div>
                    </div>
                }
                </div>
                {
                    selectedUser &&
                    <MessageChat isChannel={isChannel} chats={chats} setChatsList={setChatsList} userDetail={state?.userDetail} userId={hostId} selectedUser={selectedUser} getInboxUsersList={getInboxUsersList} getChannelMessages={getChannelMessages} />
                }
            </div>

        </div>
    )
}

export default Messenger
