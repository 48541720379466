export const signUpError = {
    fullnameError:'Please enter your full name',
    organisationError:'Please provide Organisation/ Institution',
    emailError:'Please enter your email',
    passwordError:'Please enter password',
    confirmPasswordError:'Password does not match'
}

export const signUpFormText = {
    name:'Your full name',
    organisation:'Organisation/ Institution',
    email:'Your email',
    password:'Password',
    confirmPassword:'Confirm Password',
    image:'Upload your image (Optional)',
    submitBtnText:'SUBMIT',
    registerBtnText:'REGISTER'
}

export const loginError = {
    resetPasswordError :'Password link sent to your email address.',
    accountNotApprovedError:'Your account is not approved yet!',
    userNotExist:"This user is not exist"
}

export const loginFormText = {
    email: 'Your email',
    password: 'Password',
    sendLinkBtnText: 'Send Link',
    loginBtnText: 'Login',
    forgotPassText: 'Forgot password?'
}

export const defaultImageUrl = {
    userDefaultImage :'https://firebasestorage.googleapis.com/v0/b/assembly-dev-3c297.appspot.com/o/images%2Fusers%2Fdefault-user-image.png?alt=media&token=49e1855a-e4e2-428e-b5ed-9603b7d5e115'
}


export const TestimonialErrors = {
    testimonial : "Please write something",
    rating : "Please give rating ",
    charLen: "Maximum 300 character allow"
}

export const adminUserPass = {
    id : 'admin@assemblyf.com',
    pass : '12345678'
}


export const postBoxConstants = {
    placeholderText : "What did you learn today, ",
    iconsLabel:{
        link:"Links",
        photo:"Photo/Video",
        emoticons:"Feeling/Reaction"
    },
    postMessageLengthError: "text will not allow more than 500 characters.",
    postImageUnknowError : "invalid image file type"
}

export const wallCommentsConstats = {
    cmntsPlaceholder : "Add a comment"
}

export const modalStaticContent = {
    deletePostHeading : "Delete post?",
    deletePostMessage : "Are you sure you want to permanently remove this post from Assembly F?",
    deleteBtnText : "Delete",
    cancelBtnText : "Cancel",
}

export const channelError = {
    nameError:'Please enter tribe name',
    linkError:'Please provide link',
    codeError:'Please provide code',
}

export const channelFormText = {
    name:'Tribe Name',
    description:'Description',
    link:'link',
    imageFile:'Cover Image',
    channelCode:'Tribe Code',
    submitBtnText:'Create Tribe'
}

export const liveShowError = {
    nameError:'Please enter event name',
}

export const signUpSteps = [
    // {value:1,label:'Login'},
    {value:2,label:'Invite'},
    {value:3,label:'Basic'},
    {value:4,label:'Finish'}
]

export const role = [
    {role:'Founder',label:"Founder"},
    {role:'Product',label:"Product"},
    {role:'Engineer',label:"Engineer"},
    {role:'Growth',label:"Growth"},
    {role:'UX',label:"UX"},
    {role:'Investor',label:"Investor"},
    {role:'Other',label:"Other"}
]

export const adminEmail = 'shah@assemblyf.com'