import React, { Component } from "react";
import { AuthContext } from "../Firebase/AuthProvider";
import "../Assets/Style/newLayout.scss";
import Header from "../components/header";
import Sidebar from "../components/Sidebar";
import UnSubscribeTribes from "../components/UnSubscribeTribes";

class Unsubscribe extends Component {
  static contextType = AuthContext;

  constructor() {
    super();
    this.state = {
      posts: []
    };
  }

  render() {
    return (
      <React.Fragment>
            <Header props={this.props} />
            <div className="DashboardMainContent channel-grid unsub-Page">
              <Sidebar currentPage="home" />
              {
                this.context.state.currentUser && this.context.state.userDetail &&
                <UnSubscribeTribes userId={this.context.state.currentUser} userChannels={this.context.state.userDetail.user_channels} />
              }
            </div>
          </React.Fragment>
    );
  }
}

export default Unsubscribe;
