import React, { Component } from 'react';
import '../Assets/Style/admin-style.css';
import { FIELDS } from '../Firebase/Constants';
import { userEventGoingUser } from '../Firebase/Events';
import {AuthContext} from '../Firebase/AuthProvider'
import { defaultImageUrl } from '../Helper/StaticContent';

class EventGoingUsers extends Component {
  static contextType = AuthContext
  constructor(props){
    super(props)
    this.state={
      userGoingDetails:[]
    }
  }

  componentDidMount() {
    this.users(this.props.event && this.props.event)
  }
  

  users = async (data) =>{
    this.context.showLoader(true)
    const userDetail = await userEventGoingUser(data)
    this.setState({
      userGoingDetails:userDetail
    },()=>{
      this.context.showLoader(false)
    })
  }
  


  render() {
    const event = this.props.event && this.props.event
    const hostname = window.location.hostname === "localhost" ? "assembly-dev-3c297.web.app" : window.location.hostname
    return (
        <div className="event-going-user-list">
            <p className="event-name"><a rel="noopener noreferrer" target="_blank" href={`https://${hostname}/event/${event.uid}`}>{event[FIELDS.EVENT.EVENT_NAME]}</a></p>
            <p className="event-host">Hosted by : {event[FIELDS.EVENT.EVENT_HOST_DETAIL][FIELDS.USER.USER_NAME]}</p>
            <div className="users-going">
              {this.state.userGoingDetails && this.state?.userGoingDetails?.event_going_user_details?.map((el,key)=>{
                return(
                  <div className="user-detail-box" key={key}>
                    <div className="user-image">
                      <img src={el[FIELDS.USER.USER_IMAGE] || defaultImageUrl.userDefaultImage} alt=""/>
                    </div>
                    <div className="user-detail">
                      <p className="user-going-name"><a target="_blank" rel="noopener noreferrer" href={`https://${hostname}/profile/${el[FIELDS.USER.USER_ID]}`}>{el[FIELDS.USER.USER_NAME]}</a></p>
                      <p className="user-going-email">{el[FIELDS.USER.USER_EMAIL]}</p>
                    </div>
                  </div>
                )
              })}
            </div>
        </div>
    )
  }
}

export default EventGoingUsers
