import React, { Component } from 'react'
import "../Assets/Style/admin-style.css"
import { createEvent,updateCoverFeaturedImagesLink, getCoverFeaturedImagesLink, getSpecificEventData, updateEvent, userEventGoingUser } from "../Firebase/Events";
import { FIELDS } from '../Firebase/Constants';
import { uploadEventImage, uploadCoverImage, uploadFeaturedImage } from '../Firebase/StorageBucket';
import {approveUser as getApprovedUser } from '../Firebase/Users';
import { AuthContext } from '../Firebase/AuthProvider';
import Header from '../components/header';
import Loader from '../components/Loader';
import { app } from '../Firebase/firebase-config';
import UserModal from '../components/UserModal';
import { sendEditEventEmail } from '../Firebase/Mailer';

class CreateEvent extends Component {
    static contextType = AuthContext
    constructor(props) {
        super(props)
        this.state = {
            formData: {
                event_name: "",
                event_host: "",
                event_host_id: "",
                event_zoom_link: "",
                event_zoom_id: "",
                event_zoom_password: "",
                event_start_date_time: '',
                event_start_time: '',
                event_end_time: "",
                event_image: "",
                event_capacity: 0,
                event_detail_long: "",
                event_host_partner_image:"",
                event_date_timestamp:"",
                event_is_approved:true
            },
            imageFile:'',
            users: [],
            selectedUser: 'Select User',
            date: '',
            time: '',
            endtime: '',
            coverImageUrl:'',
            featuredimageUrl:'',
            featuredLink:'',
            editEvent:false,
            showModal:false
        }
    }

    componentDidMount() {
        // get event detail if event id is available in url
        let queryParameter = new URL(window.location.href);
        let searchParams = queryParameter.searchParams;
        let eventID = searchParams.get("id");
        if(eventID){
            this.context.showLoader(true)
            this.getEventDetail(eventID).then(detail=>{
                if(detail){
                    this.setState({editEvent:true,eventID})
                    detail = detail[0]
                    const eventDate = new Date(detail[FIELDS.EVENT.EVENT_START_DATE]);
                    
                    const day = ("0" + eventDate.getDate()).slice(-2);
                    const month = ("0" + (eventDate.getMonth() + 1)).slice(-2);
                    const year = eventDate.getFullYear()
                    let date = (year) + "-" + (month) + '-' + (day)

                    const eventEndTime = new Date(detail[FIELDS.EVENT.EVENT_END_TIME])
                    
                    const endTime = ("0" + eventEndTime.getHours()).slice(-2) + ":" + ("0" + eventEndTime.getMinutes()).slice(-2);

                    this.setState({
                        formData:{
                            ...this.state.formData,
                            event_name:detail[FIELDS.EVENT.EVENT_NAME],
                            event_capacity:detail[FIELDS.EVENT.EVENT_CAPACITY],
                            event_detail_long:detail[FIELDS.EVENT.EVENT_DETAIL_LONG],
                            event_host_id:detail[FIELDS.EVENT.EVENT_HOST_DETAIL][FIELDS.USER.USER_ID],
                            event_zoom_link:detail[FIELDS.EVENT.EVENT_ZOOM_LINK],
                            event_zoom_id:detail[FIELDS.EVENT.EVENT_ZOOM_ID],
                            event_zoom_password:detail[FIELDS.EVENT.EVENT_ZOOM_PASSWORD],
                            event_image:detail[FIELDS.EVENT.EVENT_IMAGE],
                            event_host_partner_image:detail[FIELDS.EVENT.EVENT_HOST_PARTNER_IMAGE],
                            event_date_timestamp:new Date(detail[FIELDS.EVENT.EVENT_START_DATE]).getTime(),
                            event_start_date_time:detail[FIELDS.EVENT.EVENT_START_DATE],
                            event_end_time:detail[FIELDS.EVENT.EVENT_END_TIME],
                            event_start_time:detail[FIELDS.EVENT.EVENT_START_TIME],
                            event_ics:detail[FIELDS.EVENT.EVENT_ICS],
                            event_going:detail[FIELDS.EVENT.EVENT_GOING],
                            event_testimonials: detail[FIELDS.EVENT.EVENT_TESTIMONIAL] ? detail[FIELDS.EVENT.EVENT_TESTIMONIAL] : ""
                        },
                        date:date,
                        time:detail[FIELDS.EVENT.EVENT_START_TIME],
                        endtime:endTime
                    },()=>{
                        this.context.showLoader(false)
                    })
                }
            }).catch(err=>{
                if(err)
                this.context.showLoader(false)
            })
        }

        getCoverFeaturedImagesLink("featuredEvent").then(data=>{
            this.setState({
                featuredimageUrl:data.featuredImageUrl
            })
        }).catch(err=>{
            if(err){
                return
            }
        })

        let hostname = window.location.hostname === "www.assemblyf.com" ? "assembly-f-live" : "assembly-dev-3c297"
        this.setState({
            coverImageUrl:`https://firebasestorage.googleapis.com/v0/b/${hostname}.appspot.com/o/images%2Fcover%2Fcover.png?alt=media&token=cad64cdb-90dd-49d7-8867-1c5d1c21d0e9`,
        })

        getApprovedUser("host").then(users => {
            this.setState({ users })
        })

        this.setCurrentDateTime();
    }
    // get specific event detail
    getEventDetail = async(id)=>{
        let eventDetail = await getSpecificEventData("events",id)
        return eventDetail
    }


    setCurrentDateTime = () => {
        const now = new Date();

        const day = ("0" + now.getDate()).slice(-2);
        const month = ("0" + (now.getMonth() + 1)).slice(-2);

        const today = now.getFullYear() + "-" + (month) + "-" + (day);
        const time = ("0" + now.getHours()).slice(-2) + ":" + ("0" + now.getMinutes()).slice(-2);


        var date = new Date(today + " " + time);
        const utcTime = date.toUTCString();
        this.setState({
            formData: {
                ...this.state.formData,
                [FIELDS.EVENT.EVENT_START_DATE]: utcTime,
                [FIELDS.EVENT.EVENT_START_TIME]: time,
                [FIELDS.EVENT.EVENT_DATE_TIMESTAMP] : date.getTime()
            },
            date: today,
            time: time,
            endtime: time
        })
        // this.setState({
        //     formData: {
        //         ...this.state.formData,
        //         [FIELDS.EVENT.EVENT_START_DATE]: utcTime,
        //         [FIELDS.EVENT.EVENT_START_TIME]: time,
        //     },
        //     [e.target.name]: e.target.value,
        // })
    }

    onSubmit = async () => {
        const { formData } = this.state;
        const validate = this.validate();

        // if error exit then show error and return
        if (validate != null) {
            this.setState({ errorText: validate })
            return;
        }

        // if there is no error then set error to false and show loader 
        this.setState({ errorText: false })
        this.context.showLoader(true)

        // Event Image 
        const eventImageFile = this.state.imageFile;
        let eventImageNewURL = '';
        // if eventImage exist then get the file path(store) in firebase storage and replace that image otherwise upload new image
        if(eventImageFile && formData.event_image){
            // get the current event cover Image URL
            let eventImageURL = formData.event_image;
            // get the current firestore storage PATH of event cover image 
            let eventImagePath = await this.getImageUrl(eventImageURL)
            // upload the new event cover image to the same PATH and get the UPDATED URL
            eventImageNewURL = await uploadEventImage(eventImageFile,eventImagePath)
            // change the previous image URL to the NEW ONE
            formData.event_image = eventImageNewURL
        }else if(eventImageFile){
            let now = new Date();
            let uniqueId = now.getTime();
            // IF event image is not exist then upload the new event IMAGE with unique ID 
            eventImageNewURL = await uploadEventImage(eventImageFile,`images/events/${uniqueId}${eventImageFile.name}`)
            // ADD new event image URL to the formDATA 
            formData.event_image = eventImageNewURL
        }


        // event partner image
        const partnerImageFile = this.state.event_host_partner_image;
        let partnerImageNewUrl ='';
        if(partnerImageFile && formData.event_host_partner_image){
            // get the current event partner image URL from event Detail 
            let eventPartnerImageURL = formData.event_host_partner_image;
            // get the current firestore storage PATH of event partner image 
            let eventPartnerImagePath = await this.getImageUrl(eventPartnerImageURL)
            // get the new URL of the uploaded event partner image
            partnerImageNewUrl = await uploadEventImage(partnerImageFile,eventPartnerImagePath)
            // Update the formData 
            formData.event_host_partner_image = partnerImageNewUrl 
        }else if(partnerImageFile){
            let now = new Date();
            let uniqueId = now.getTime();
            // IF event partner image is not exist then upload the new event partner IMAGE with unique ID 
            partnerImageNewUrl = await uploadEventImage(partnerImageFile,`images/events/${uniqueId}${partnerImageFile.name}`)
            formData.event_host_partner_image = partnerImageNewUrl
        }
        

        if (validate == null) {
            if(this.state.editEvent){
                updateEvent("events",this.state.eventID,formData).then(res=>{
                    this.setState({
                        showModal:true,
                        message:"Event detail updated"
                    },async()=>{
                        this.context.showLoader(false)

                        const userDetail = await userEventGoingUser(this.state.formData)
                        let userEmails = userDetail[FIELDS.EVENT.EVENT_GOING_USER_DETAILS].map(user=>user[FIELDS.USER.USER_EMAIL])
                        for(const email of userEmails ){
                            sendEditEventEmail(email,this.state.formData)
                        }

                    })
                }).catch(err=>{
                    if(err){
                        this.setState({
                            showModal:true,
                            message:"Try sometime later"
                        },()=>{
                            this.context.showLoader(false)
                            this.props.history.push('/admin/events')
                        })
                    }
                })
            }else{
                createEvent("events",formData).then(res => {
                    this.setState({
                        showModal:true,
                        message:"Event Created successfully"
                    },()=>{
                        this.context.showLoader(false)
                    })
                }).catch(err => {
                    if(err){
                        this.context.showLoader(false);
                        this.setState({ errorText: "Something went wrong" })
                    }
                });
            }
        }
    }

    // get the image url of change 
    getImageUrl = async(url)=>{
        let storage = app.storage();
        let eventImageName = storage.refFromURL(url).getMetadata()
        const eventImagePath = await eventImageName.then(metadata=>metadata.fullPath)
        return eventImagePath
    }

    onchangeHandler = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            },
            [e.target.name]:e.target.value
        })
    }

    onChangeDateTime = (e) => {
        let date = '';
        let startTime = '';
        let endTime = '';

        if(e.target.name === "date"){
            date = e.target.value
            startTime = this.state.time
            endTime = this.state.endtime
        }


        if(e.target.name === "time"){
            date = this.state.date
            startTime = e.target.value
            endTime = this.state.endtime
        }
        

        if(e.target.name === "endtime"){
            date = this.state.date
            startTime = this.state.time
            endTime = e.target.value

           
        }

        let eventStartDateTime = new Date(date + " " + startTime);
        // if event end time is after 12AM so the end date would be the next day date 
        // like if event start time is fri-11PM and end time is 3AM mean the day is sat 3AM   
        var startEventTime = startTime.split(':')[0]
        var timeSplit = endTime.split(':'),
        hours;
        hours = timeSplit[0];
        if(startEventTime > 12 &&  hours < 12 ){
            let prevDate = new Date(date)
            prevDate.setDate(prevDate.getDate()+1)
            const day = ("0" + prevDate.getDate()).slice(-2);
            const month = ("0" + (prevDate.getMonth() + 1)).slice(-2);
            date = prevDate.getFullYear() + "-" + (month) + "-" + (day);
        }
        let eventEndTime = new Date(date + " " + endTime).toUTCString();

        this.setState({
            formData: {
                ...this.state.formData,
                [FIELDS.EVENT.EVENT_START_DATE]: eventStartDateTime.toUTCString(),
                [FIELDS.EVENT.EVENT_END_TIME] : eventEndTime, 
                [FIELDS.EVENT.EVENT_START_TIME]: startTime,
                [FIELDS.EVENT.EVENT_DATE_TIMESTAMP] : eventStartDateTime.getTime()
            },
            [e.target.name]: e.target.value,
        })
        // var date = '';
        // var time = '';
        // if (e.target.name === 'date') {
        //     date = e.target.value;
        //     time = this.state.time;
        // } else if (e.target.name === 'time') {
        //     time = e.target.value;
        //     date = this.state.date;
        // }

        
        // var date = new Date(date + " " + time);
        // const utcTime = date.toUTCString();
        // this.setState({
        //     formData: {
        //         ...this.state.formData,
        //         [FIELDS.EVENT.EVENT_START_DATE]: utcTime,
        //         [FIELDS.EVENT.EVENT_START_TIME]: time,
        //         [FIELDS.EVENT.EVENT_DATE_TIMESTAMP] : date.getTime()
        //     },
        //     [e.target.name]: e.target.value,
        // })
    }

    onChangeEndTime = (e) => {
        var date = new Date(this.state.date + " " + e.target.value);
        const utcTime = date.toUTCString();
        this.setState({
            formData: {
                ...this.state.formData,
                [FIELDS.EVENT.EVENT_END_TIME]: utcTime,
            },
            endtime:e.target.value
        })
    }

    onChangeHostHandler = (e) => {
        const data = e.target.value;

        this.setState({
            formData: {
                ...this.state.formData,
                [FIELDS.EVENT.EVENT_HOST]: data.name,
                [FIELDS.EVENT.EVENT_HOST_ID]: data.id,
                'email': data.email,
            },
            selectedUser: data

        })
    }

    validate = () => {
        const formData  = this.state.formData;
        let error = '';
        if (formData.event_name === '') {
            error = 'Please enter event name'
            return error;
        }
        if (formData.event_host_id === '') {
            error = 'Please select host'
            return error;
        }
        if (formData.event_zoom_link === '') {
            error = 'Please provide zoom link';
            return error;
        }
        if (formData.event_zoom_id === '') {
            error = 'Please provide zoom ID';
            return error;
        }
        if (formData.event_zoom_password === '') {
            error = 'Please provide zoom password';
            return error;
        }
        if (this.state.imageFile === '' && this.state.formData.event_image === '') {
            error = 'Please choose event image'
            return error;
        }
        if (formData.event_start_date_time === '') {
            error = 'Please enter event start date';
            return error;
        }
        if (formData.event_end_time === '') {
            error = 'Please enter event end time'
            return error;
        }
        if (formData.event_capacity === '' || formData.event_capacity === '0') {
            error = 'Please enter event capacity'
            return error;
        }
        if (formData.event_detail_long === '') {
            error = 'Please enter event description'
            return error;
        }
        return null;
    }

    handleImageAsFile = (e) => {
        const image = e.target.files[0];
        if(image){
            const imageName = e.target.files[0].name
            this.setState({
                [e.target.name]: image,
                [e.target.name+1]: imageName,
                [e.target.name+2]: URL.createObjectURL(image),
                imageError: false
            });
        }
    }

    handleImageAsFile1 =(e)=>{
        const image = e.target.files[0];

        this.setState({
            imageFile:image
        })
    }


    uploadCoverImage = async (e) =>{
        e.preventDefault(); 
        const imageFile = this.state.heroImage;
        if(imageFile){
            if(imageFile.type.split('/')[1] === "png"){
                this.setState({imageError: ""})
                this.context.showLoader(true)
                const imageUrl = await uploadCoverImage(imageFile);
                if(imageUrl){
                    this.setState({heroImage1:'',heroImage:'',heroImage2:"",coverImageUrl:imageUrl})
                    this.context.showLoader(false)
                }
            }else{
                this.setState({
                    imageError: "Unknown file type! (Png only)"
                })
            }
        }else{
            this.setState({
                imageError:"please select Image"
            })
        }
    }   

    // Upload Featured Images
    uploadFeaturedImage  = async (e) =>{
        e.preventDefault(); 
        const imageFile = this.state.featuredImage;
        const eventLink = this.state.featuredLink
        if(eventLink && !eventLink.includes("https")){
            this.setState({featureImageError:"https:// is missing in URL"})
            return 
        }
        if(imageFile){
            if(imageFile.type.split('/')[1] === "png"){
                this.setState({featureImageError: ""})
                this.context.showLoader(true)
                const imageUrl = await uploadFeaturedImage(imageFile);
                if(imageUrl){
                    updateCoverFeaturedImagesLink("featuredEvent",eventLink,imageUrl).then(()=>{
                        this.setState({featuredImage1:'',featuredImage:'',featuredImage2:"",featuredimageUrl:imageUrl},()=>{
                            this.context.showLoader(false)
                        })
                    }).catch(err=>{
                        if(err)
                        this.context.showLoader(false)
                    })
                }
            }else{
                this.setState({
                    featureImageError: "Unknown file type! (Png only)"
                })
            }
        }else{
            this.setState({
                featureImageError:"please select Image"
            })
        }
    }   

    removeUserImage = (e)=>{
        e.preventDefault();
        e.stopPropagation();
        let id = e.target.id
        if(id === "cover"){
            this.setState({
                heroImage1:'',
                heroImage:'',
                heroImage2:"",
            })
        }else{
            this.setState({
                featuredImage1:'',
                featuredImage:'',
                featuredImage2:""
            })
        }
        
    }

    showModal = ()=>{
        this.setState({
            showModal:!this.state.showModal
        })
    }
    
    render() {
        return (
            <React.Fragment>
            <Loader />
            <Header admin="true" history={this.props.history} />
            
            <div className="upload-cover-image">
                <h2 className="create-event-heading">Upload cover image</h2>
                <div className="form-flex">
                    <form>
                        <div className={`input-upload-image ${this.state.heroImage2 && "image-preview"}`}>
                            <label className={this.state.heroImage2 && "image-preview"} htmlFor="image-upload">{this.state.heroImage2 ? (
                                <React.Fragment>
                                <img src={this.state.heroImage2} alt=""/>
                                <a id="cover" href="# " onClick={e=>{this.removeUserImage(e)}}>&#10010;</a>
                                </React.Fragment>
                                ) : 'Upload cover Image (Png only)'}</label>
                            <input className="input-image-file"
                                accept="image/x-png" 
                                type="file"
                                id="image-upload"
                                name="heroImage"
                                value=""
                                onChange={this.handleImageAsFile}
                            />
                        </div>
                        {this.state.imageError && ( <span className="text-red">{this.state.imageError}</span> )}
                        <button className="btn-upload" onClick={e=>{this.uploadCoverImage(e)}}>Upload Image</button>
                    </form>
                    <img src={this.state.coverImageUrl} alt=""/>
                </div>
            </div>

            <div className="upload-cover-image">
                <h2 className="create-event-heading">Upload featured image for home</h2>
                <div className="form-flex">
                    <form>
                        <div className="input-group">
                            <label className="create-event-label">Featured Link</label>
                            <input type="text" placeholder="Featured Link (https://www.example.com)" onChange={this.onchangeHandler} name="featuredLink" value={this.state.featuredLink} className="create-event-input-field" />
                        </div>
                        <div className={`input-upload-image ${this.state.featuredImage2 && "image-preview"}`}>
                            <label className={this.state.featuredImage2 && "image-preview"} htmlFor="featured-image-upload">{this.state.featuredImage2 ? (
                                <React.Fragment>
                                <img src={this.state.featuredImage2} alt=""/>
                                <a id="featured" href="# " onClick={e=>{this.removeUserImage(e)}}>&#10010;</a>
                                </React.Fragment>
                                ) : 'Upload featured image here (Png only)'}</label>
                            <input className="input-image-file"
                                accept="image/x-png" 
                                type="file"
                                id="featured-image-upload"
                                name="featuredImage"
                                value=""
                                onChange={this.handleImageAsFile}
                            />
                        </div>
                        {this.state.featureImageError && ( <span className="text-red">{this.state.featureImageError}</span> )}
                        <button className="btn-upload" onClick={e=>{this.uploadFeaturedImage(e)}}>Update Image & Link</button>
                        
                    </form>
                    {this.state.featuredimageUrl &&(
                        <img src={this.state.featuredimageUrl} alt=""/>
                    )}
                </div>
            </div>
            
            <div className="create-event-component">
                <h1 className="create-event-heading">Admin Panel</h1>
                <div className="create-event-form-box">
                    <h3>Schedule Session</h3>

                    <form className="create-event-form">

                        <div className="event-general-group">
                            <div className="input-group">
                                <label htmlFor="event-name" className="create-event-label">Event Name</label>
                                <input type="text" placeholder="Event Name" onChange={this.onchangeHandler} name={FIELDS.EVENT.EVENT_NAME} value={this.state.formData.event_name} className="create-event-input-field" />
                            </div>

                            <div className="input-group">
                                <label htmlFor="event-host" className="create-event-label">Event Hosted By</label>
                                <select name={FIELDS.EVENT.EVENT_HOST_ID} value={this.state.formData[FIELDS.EVENT.EVENT_HOST_ID]}  onChange={this.onchangeHandler}>
                                    <option value="">Select User</option>
                                    {this.state.users && this.state.users.length > 0 ? this.state.users.map((user, key) => {
                                        return (
                                            <option key={key} value={user.uid}>{user.name} - {user.email}</option>
                                        )
                                    }) : null}
                                </select>
                            </div>
                        </div> 


                        <div className="event-general-group">
                            <div className="input-group">
                                <label htmlFor="event-zoom-link" className="create-event-label">Zoom Link</label>
                                <input type="text" placeholder="Zoom Link" onChange={this.onchangeHandler} name={FIELDS.EVENT.EVENT_ZOOM_LINK} value={this.state.formData.event_zoom_link} className="create-event-input-field" />
                            </div>

                            <div className="input-group">
                                <label htmlFor="event-zoom-id" className="create-event-label">Zoom ID</label>
                                <input type="text" placeholder="Zoom ID" onChange={this.onchangeHandler} name={FIELDS.EVENT.EVENT_ZOOM_ID} value={this.state.formData.event_zoom_id} className="create-event-input-field" />
                            </div>

                            <div className="input-group">
                                <label htmlFor="event-zoom-password" className="create-event-label">Zoom Password</label>
                                <input type="text" placeholder="Zoom Password" onChange={this.onchangeHandler} name={FIELDS.EVENT.EVENT_ZOOM_PASSWORD} value={this.state.formData.event_zoom_password} className="create-event-input-field" />
                            </div>

                            <div className="input-group">
                                <label htmlFor="event-image" className="create-event-label">Upload Event Image</label>
                                <input accept="image/x-png,image/gif,image/jpeg"  type="file" id="" onChange={this.handleImageAsFile1} name={FIELDS.EVENT.EVENT_IMAGE} className="create-event-input-field image-file" />
                            </div>

                            <div className="input-group">
                                <label htmlFor="event-image" className="create-event-label">Partner image (optional)</label>
                                <input accept="image/x-png,image/gif,image/jpeg"  type="file"  onChange={this.handleImageAsFile} name={FIELDS.EVENT.EVENT_HOST_PARTNER_IMAGE} className="create-event-input-field image-file" />
                            </div>
                        </div>


                        <div className="event-general-group">
                            <div className="input-group">
                                <label htmlFor="event-date" className="create-event-label">Event Date</label>
                                <input type="date" placeholder="Event Date" onChange={this.onChangeDateTime} name="date" value={this.state.date} className="create-event-input-field" />
                            </div>
                            <div className="input-group">
                                <label htmlFor="event-start-time" className="create-event-label">Event Start Time</label>
                                <input type="time" placeholder="Event start time" onChange={this.onChangeDateTime} name="time" value={this.state.time} className="create-event-input-field" />
                            </div>

                            <div className="input-group">
                                <label htmlFor="event-end-time" className="create-event-label">Event End Time</label>
                                <input type="time" placeholder="Event end time" onChange={this.onChangeDateTime} name="endtime" value={this.state.endtime} className="create-event-input-field" />
                            </div>
                        </div>

                        <div className="input-group">
                            <label htmlFor="event-seats-capacity" className="create-event-label">Maximum Seats</label>
                            <input type="number" placeholder="Maximum Seats" onChange={this.onchangeHandler} name={FIELDS.EVENT.EVENT_CAPACITY} value={this.state.formData.event_capacity} className="create-event-input-field" />
                        </div>

                        <div className="input-group short-detail">
                            <label htmlFor="event-long-detail" className="create-event-label">Event Long Detail</label>
                            <textarea  placeholder="Enter Long Detail" onChange={this.onchangeHandler} name={FIELDS.EVENT.EVENT_DETAIL_LONG} value={this.state.formData.event_detail_long} className="create-event-input-field short-detail" cols="30" rows="10"></textarea>
                        </div>
                        {this.state.errorText && (
                            <span className="text-red">{this.state.errorText}</span>
                        )} 
                    </form>
                    <button className="create-event-button" onClick={()=>this.onSubmit()}>{this.state.editEvent ?"Update":"Submit"}</button>
                </div>
            </div>
            {this.state.showModal && (
                <UserModal show="showEditEventModal" message={this.state.message} closeModal={this.showModal}  />
            )}
            </React.Fragment>
        )
    }
}

export default CreateEvent
