import React,{ useContext } from 'react'
import {AuthContext} from '../Firebase/AuthProvider'


export default function ShowSnackbar(props) {
    const context = useContext(AuthContext)

    return (
        <div className={`snackbar-comp ${context.state.showSnackbar && "show-snackbar"}`}>
            {props.show === "LinkCopied" && (
                <div className="inner-snackbar">
                    <h1>Link copied</h1>
                </div>
            )}
        </div>
    )
}
