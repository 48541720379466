export const SidebarData = [
  {
    title: "Agenda",
    path: "/agenda",
    image: "note.svg",
  },
  {
    title: "Community",
    path: "/community",
    image: "community.svg",
  },
  {
    title: "Speakers",
    path: "/speakers",
    image: "profile.png",
  },
  {
    title: "Partners",
    path: "/partners",
    image: "partners.svg",
  },
];

export const RightSidebarData = [
  {
    title: "Chat",
    path: "/",
    image: "chat.png",
  },
  {
    title: "People",
    path: "/",
    image: "people.png",
  }
];

export const UserDropDownOption = [
  {
    title: "Upload Photo",
    path: "/",
  },
  {
    title: "Change Password",
    path: "/",
  },
  {
    title: "Logout",
    path: "/",
  },
];

export const FollowersData = [
  { image: "picture-1.png" },
  { image: "picture-2.png" },
];

export const SpeakerData = [
  {
    image: "speaker.jpg",
    name: "Jerome Ternynck",
    title: "Master Speaker Makerbot",
  },
  {
    image: "speaker.jpg",
    name: "Jerome Ternynck",
    title: "Master Speaker Makerbot",
  },
  {
    image: "speaker.jpg",
    name: "Jerome Ternynck",
    title: "Master Speaker Makerbot",
  },
  {
    image: "speaker.jpg",
    name: "Jerome Ternynck",
    title: "Master Speaker Makerbot",
  },
  {
    image: "speaker.jpg",
    name: "Jerome Ternynck",
    title: "Master Speaker Makerbot",
  },
  {
    image: "speaker.jpg",
    name: "Jerome Ternynck",
    title: "Master Speaker Makerbot",
  },
];

export const OurPartners = [
  { image: "jazz.png" },
  { image: "hbl.png" },
  { image: "zong.svg" },
  { image: "google.png" },
];

export const UpComingSessions = [
  { session: "How to come up with ideas", time: "8th Oct - 8:00pm (PST)" },
  { session: "How to select your confounders", time: "8th Oct - 8:00pm (PST)" },
  { session: "How to build marketplaces", time: "8th Oct - 8:00pm (PST)" },
  {
    session: "How to build products as a startup",
    time: "8th Oct - 8:00pm (PST)",
  },
  { session: "How to build marketplaces", time: "8th Oct - 8:00pm (PST)" },
];

export const ReplayAndClips = [
  { image: "thumbnail.jpg" },
  { image: "thumbnail.jpg" },
  { image: "thumbnail.jpg" },
  { image: "thumbnail.jpg" },
  { image: "thumbnail.jpg" },
  { image: "thumbnail.jpg" },
];

export const ChannelList = [
  { title: "All stations" },
  { title: "HTML/CSS Academy" },
  { title: "Backend Academy" },
];

export const LearningJourneyList = [
  {
    title: "How to come up with ideas?",
  },
  {
    title: "How to select your co founders?",
  },
  {
    title: "How to build marketplace?",
  },
  {
    title: "how to build product as a startup?",
  },
];

export const ChannelTabList = [
  {
    tab: "Schedule Session",
    slug: "event"
  },
  {
    tab: "Add Partner",
    slug: "partner"
  },
  {
    tab: "Edit Tribe",
    slug: "editChannel"
  },
  {
    tab: "Add Admin",
    slug: "addAdmin"
  },
  {
    tab: "Enter Live Producer",
    slug: "live"
  },
];

export const monthNames = ["Jan", "Feb", "March", "April", "May", "June",
  "July", "Aug", "Sept", "Oct", "Nov", "Dec"
];
