import moment from "moment";
// import { messaging } from "../Firebase/firebase-config";

export default class Helper{
    static onLoginClick(){
        document.querySelectorAll(".show-modal").forEach((el)=> {
            el.classList.remove('show-modal')
        })
        let loginModal = document.querySelector("#LoginModal");
        if(!loginModal.classList.contains('show-modal')){
            loginModal.classList.add("show-modal")
        }
    }
    
    static onSignupClick(){
        document.querySelectorAll(".show-modal").forEach((el)=> {
            el.classList.remove('show-modal')
        })
        let signUpModal = document.querySelector("#SignupModal");
        if(!signUpModal.classList.contains('show-modal')){
            signUpModal.classList.add("show-modal")
        }
    }

    static getDate(date){
        let timezone = new Date(date);
        let months = ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
        let year = timezone.getFullYear();
        let month = months[timezone.getMonth()]
        let day = timezone.getUTCDate();
        if(day === "1" || day === "21" || day === "31"){
            day = day+"st"
        }else if(day === "2" || day === "22"){
            day = day+"nd"
        }else if(day === "3" || day === "23"){
            day = day+"rd"
        }
        else{
            day = day+"th"
        }
        return {day:day,month:month,year:year}
    }

    static getLocalTimeFromUTC(dateString){
        const date = moment(dateString);
        return this.tConvert(date.hour()+":"+("0"+date.minute()).slice(-2));
    }

    static getLocalTimeFromUTC_24hr(dateString){
        const date = moment(dateString);
        let time = date.hour()+":"+("0"+date.minute()).slice(-2);
        time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
        
        if (time.length > 1) { // If time format correct
            time = time.slice (1);  // Remove full string match value
        }
        return (time.join (''))+":00"; // return adjusted time or original string
    }


    static tConvert(time) {
        // Check correct time format and split into components
        time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
        
        if (time.length > 1) { // If time format correct
            time = time.slice (1);  // Remove full string match value
            time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
            time[0] = +time[0] % 12 || 12; // Adjust hours
        }
        return time.join (''); // return adjusted time or original string
    }

    static getHours(time) {
        // Check correct time format and split into components
        time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
        
        if (time.length > 1) { // If time format correct
            time = time.slice (1);
        }
        return time[0] // return adjusted time or original string
    }

    static getEventDate(startDate,endDate){
        const local_start_date = new Date(startDate)
        const local_end_date = new Date(endDate)


        let months = ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
        let month = months[local_start_date.getMonth()]

        let day = local_start_date.getDate();;
        if(day === "1" || day === "21" || day === "31"){
            day = day+"st"
        }else if(day === "2" || day === "22"){
            day = day+"nd"
        }else if(day === "3" || day === "23"){
            day = day+"rd"
        }
        else{
            day = day+"th"
        }

        // Event start time
        let local_start_hour = parseInt(local_start_date.getHours());
        let local_start_AMPM = local_start_hour >=12 ?'PM':'AM'  
        local_start_hour =  local_start_hour % 12 || 12;
        let local_start_minute = (local_start_date.getMinutes()<10?'0':'') + local_start_date.getMinutes()
        // format event start
        const local_event_start_time = local_start_hour +":"+local_start_minute + local_start_AMPM


        // Event End time 
        let local_end_hour = parseInt(local_end_date.getHours());
        let local_end_AMPM = local_end_hour >=12 ?'PM':'AM'  
        local_end_hour =  local_end_hour % 12 || 12;
        let local_end_minute = (local_end_date.getMinutes()<10?'0':'') + local_end_date.getMinutes()
        // format event end time 
        const local_event_end_time = local_end_hour +":"+ local_end_minute + local_end_AMPM

        
        return day + " " + month + "  " + local_event_start_time + " - " + local_event_end_time
    }
      
    static getEventDateV2(startDate,endDate){
        let start = new Date(startDate)
        let end = new Date(endDate)

        let months = ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
        let month = months[start.getMonth()]

        let day = start.getDate();;
        if(day === "1" || day === "21" || day === "31"){
            day = day+"st"
        }else if(day === "2" || day === "22"){
            day = day+"nd"
        }else if(day === "3" || day === "23"){
            day = day+"rd"
        }
        else{
            day = day+"th"
        }

        // Event start time
        let local_start_hour = parseInt(start.getHours());
        let local_start_AMPM = local_start_hour >=12 ?'PM':'AM'  
        local_start_hour =  local_start_hour % 12 || 12;
        let local_start_minute = (start.getMinutes()<10?'0':'') + start.getMinutes()
        // format event start
        const local_event_start_time = local_start_hour +":"+local_start_minute + local_start_AMPM


        let startTime = start.getTime();
        let endTime = end.getTime()

        let diff = parseInt(endTime - startTime)

        let hours = Math.floor((diff / 3600 ) / 1000)

        let minutes = Math.floor((diff / (1000 * 60)) % 60);

        let duration = `${hours} hour ${minutes <= 0 ? '' : minutes +" minutes" }`

        return {date:day +" " +month,time:local_event_start_time, duration }
    }

    static getPostDate(date){
        let postDate = new Date(date).getTime()
        let currentTime = new Date().getTime()

        let months = ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
        let month = months[new Date(date).getMonth()]

        let day = new Date(date).getDate();;
        if(day === "1" || day === "21" || day === "31"){
            day = day+"st"
        }else if(day === "2" || day === "22"){
            day = day+"nd"
        }else if(day === "3" || day === "23"){
            day = day+"rd"
        }
        else{
            day = day+"th"
        }


        // Event start time
        let postHours = parseInt(new Date(date).getHours());
        let postHours_AMPM = postHours >=12 ?'PM':'AM'  
        postHours =  postHours % 12 || 12;
        let post_minute = (new Date(date).getMinutes()<10?'0':'') + new Date(date).getMinutes()
        // format event start
        const postTime = postHours +":"+post_minute + postHours_AMPM


        let timeDiff = parseInt(currentTime - postDate)
        
        let secondDIff = Math.floor(timeDiff / 1000)

        let hoursDiff = Math.floor((timeDiff / 3600 ) / 1000)

        let minutesDIff = Math.floor((timeDiff / (1000 * 60)) % 60);

        if(hoursDiff > 44){
            // console.log(month,day,"at",postTime)
            return `${month} ${day} at ${postTime}`
        }

        if(hoursDiff > 24 && hoursDiff <= 44){
            // console.log("Yesterday at ",postTime)
            return `Yesterday at ${postTime}`
        }

        
        if(hoursDiff > 0 && hoursDiff <= 24){
            // console.log(hoursDiff,"h")
            return `${hoursDiff}h`
        }

        if(secondDIff > 60 && hoursDiff === 0 && minutesDIff < 60){
            // console.log(minutesDIff,"m")
            return `${minutesDIff}m`
        }

        if(secondDIff < 10){
            return `Just now`
        }

        if(secondDIff > 10 && secondDIff < 60){
            // console.log(secondDIff,"s")
            return `${secondDIff}s`
        }
    }


    // static getMessageToken = async (setToken) => {
    //     if(messaging){
    //         try {
    //             const currentToken = await messaging.getToken({ vapidKey: 'BEdcAXx6BIJ5k-5JlH30XihEZ9smMrNIceU_f2sr6jXSjGU_pV7fXi-r4kLZuX4iYRudKabizooJPSQGVnwB780' });
    //             if (currentToken) {
    //                 console.log('current token for client: ', currentToken);
    //                 setToken(true);
    //                 // Track the token -> client mapping, by sending to backend server
    //                 // show on the UI that permission is secured
    //             } else {
    //                 console.log('No registration token available. Request permission to generate one.');
    //                 setToken(false);
    //                 // shows on the UI that permission is required 
    //             }
    //         } catch (err) {
    //             console.log('An error occurred while retrieving token. ', err);
    //         }
    //     }
    // }


    static getCaretCoordinates = (element, position) => {
        var properties = [
            'boxSizing',
            'width',  // on Chrome and IE, exclude the scrollbar, so the mirror div wraps exactly as the textarea does
            'height',
            'overflowX',
            'overflowY',  // copy the scrollbar for IE
            
            'borderTopWidth',
            'borderRightWidth',
            'borderBottomWidth',
            'borderLeftWidth',
            
            'paddingTop',
            'paddingRight',
            'paddingBottom',
            'paddingLeft',
            
            // https://developer.mozilla.org/en-US/docs/Web/CSS/font
            'fontStyle',
            'fontVariant',
            'fontWeight',
            'fontStretch',
            'fontSize',
            'lineHeight',
            'fontFamily',
            
            'textAlign',
            'textTransform',
            'textIndent',
            'textDecoration',  // might not make a difference, but better be safe
            
            'letterSpacing',
            'wordSpacing'
        ];
        
        var isFirefox = !(window.mozInnerScreenX === null);
        var mirrorDiv, computed, style;

        // console.log("this")

        // mirrored div
        mirrorDiv = document.getElementById(element.nodeName + '--mirror-div');
        if (!mirrorDiv) {
          mirrorDiv = document.createElement('div');
          mirrorDiv.id = element.nodeName + '--mirror-div';
          document.body.appendChild(mirrorDiv);
        }
      
        style = mirrorDiv.style;
        computed = getComputedStyle(element);
      
        // default textarea styles
        style.whiteSpace = 'pre-wrap';
        if (element.nodeName !== 'INPUT')
          style.wordWrap = 'break-word';  // only for textarea-s
      
        // position off-screen
        style.position = 'absolute';  // required to return coordinates properly
        style.top = element.offsetTop + parseInt(computed.borderTopWidth) + 'px';
        style.left = "-400px";
        style.color = "transparent";
        style.cursor = "none"
        // style.visibility = mirrorDivDisplayCheckbox.checked ? 'visible' : 'hidden';  // not 'display: none' because we want rendering
      
        // transfer the element's properties to the div
        properties.forEach(function (prop) {
          style[prop] = computed[prop];
        });
      
        if (isFirefox) {
          style.width = parseInt(computed.width) - 2 + 'px'  // Firefox adds 2 pixels to the padding - https://bugzilla.mozilla.org/show_bug.cgi?id=753662
          // Firefox lies about the overflow property for textareas: https://bugzilla.mozilla.org/show_bug.cgi?id=984275
          if (element.scrollHeight > parseInt(computed.height))
            style.overflowY = 'scroll';
        } else {
          style.overflow = 'hidden';  // for Chrome to not render a scrollbar; IE keeps overflowY = 'scroll'
        }  
      
        mirrorDiv.textContent = element.value.substring(0, position);
        // the second special handling for input type="text" vs textarea: spaces need to be replaced with non-breaking spaces - http://stackoverflow.com/a/13402035/1269037
        if (element.nodeName === 'INPUT')
          mirrorDiv.textContent = mirrorDiv.textContent.replace(/\s/g, "\u00a0");
      
        var span = document.createElement('span');
        // Wrapping must be replicated *exactly*, including when a long word gets
        // onto the next line, with whitespace at the end of the line before (#7).
        // The  *only* reliable way to do that is to copy the *entire* rest of the
        // textarea's content into the <span> created at the caret position.
        // for inputs, just '.' would be enough, but why bother?
        span.textContent = element.value.substring(position) || '.';  // || because a completely empty faux span doesn't render at all
        span.style.backgroundColor = "lightgrey";
        mirrorDiv.appendChild(span);
      
        var coordinates = {
          top: span.offsetTop + parseInt(computed['borderTopWidth']),
          left: span.offsetLeft + parseInt(computed['borderLeftWidth'])
        };
      
        return coordinates;
    }


    static transformText =(str) =>{
        str = str.toLowerCase()
        str = str.split(" ");

        for (var i = 0, x = str.length; i < x; i++) {
            if(str[i] !== ""){
                str[i] = str[i][0]?.toUpperCase() + str[i].substr(1);
            }
        }
    
        return str.join(" ");
    }

    static randomItem = (array) =>{
        return array[Math.floor((Math.random() * array.length))];
    }

    static urlify(text) {
        var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
        //var urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.replace(urlRegex, function(url,b,c) {
            var url2 = (c === 'www.') ?  'http://' +url : url;
            return '<a href="' +url2+ '" target="_blank">' + url + '</a>';
        }) 
    }
    

}

