import React, { useEffect, useState } from 'react'
import Loader from './Loader'
import { getChannelByUserId } from "../Firebase/Channels";
import { app } from "../Firebase/firebase-config";
import { withUnsubscribe } from "../Firebase/Schemas";
const db = app.firestore();

function UnSubscribeTribes({ userId, userChannels }) {
    const [channelsList, setChannelsList] = useState([])
    const [unSubscribeList, setUnsubscribeUser] = useState([])
    const [message, setMessage] = useState("")

    useEffect(() => {
        (async function(){
           await getChannels()
        }())
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const addUnsubscribe = (tribe) => {
        let oldList = [...unSubscribeList];
        let isFound = isCheckBoxSet(tribe.id);
        if(isFound) {
            oldList = oldList.filter(el => {
                return el.id !== tribe.id;
            })
        }
        else {
            oldList.push(tribe);
        }
        
        setUnsubscribeUser(oldList);
    }

    const UnsubscribeUser = async (type) => {
        let tribeList = unSubscribeList;
        if(type === 'all') {
            tribeList = channelsList.map(el => {
                return el.id;
            })
        }
        let data =  {
            userId,
            tribeList
        }
        var getData = await db.collection("unsubscribe_tribes").where("userId", "==", userId).get()
        if(getData.docs.length === 0) {
            await db.collection("unsubscribe_tribes").add(withUnsubscribe(data));
        }
        else {
            let docId = getData.docs[0].id;
            await db
                .collection("unsubscribe_tribes")
                .doc(docId)
                .update(data);
        }
        setMessage("Unsubscribe Successful");
    }

    const isCheckBoxSet = (id) => {
        let oldList = [...unSubscribeList];
        let isFound = oldList.find(el => {
            return el.id === id
        })
        if(isFound) {
            return true;
        }
        return false;
    }

    const getChannels = async()=>{
        const result = await getChannelByUserId(userId);
        let myChannels = [];
        // eslint-disable-next-line array-callback-return
        result.map(el => {
          let members = el.data().members;
          if(members.length > 0) {
            let found = members.find(elem => {
              return elem.id === userId;
            })
            let isExists = userChannels.find(elem => {
                return elem === el.id;
            })
            if(isExists || found || el.data().channel_owner === userId) {
              myChannels.push(el)
            }
          }
          else if(el.data().channel_owner === userId) {
            myChannels.push(el)
          }
        })
        setChannelsList([...channelsList,...myChannels])
    }
    return (
        <React.Fragment>
        {channelsList.length === 0 ? <Loader /> :
        <div id="channels_container" className="channels_container main-page">
            <h5><span>Unsubscribe</span> from my tribes.</h5>
            <div className="channel-grid">
                <div className="channels_inner">
                    {channelsList.map((channelInfo,index)=>{
                        return(
                            <div className="channel-card-new" key={index}>
                                <div className="channel-list channelNewList">
                                    <div>
                                        <input type='checkbox' value={isCheckBoxSet(channelInfo.id)} onChange={() => addUnsubscribe({id: channelInfo.id, channelName: channelInfo.data().name})} />
                                    </div>
                                    <div className="channel-description">
                                    
                                        <h4 className="channel-name descriptionh4">{channelInfo.data()?.name}</h4>
                                        <p className="channel-desc">{channelInfo.data()?.description}</p>
                                        <ul className="channle-authors">
                                            <li>{channelInfo.data().members.length} members</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    {
                        message ? 
                        <h4 className="successMessage">{message}</h4>
                        :
                        (
                            <div className="loadMoreBtn unsubscribeBtn"><button onClick={async()=>{UnsubscribeUser('all')}}>Unsubscribe All Tribes</button>
                            <button onClick={async()=>{UnsubscribeUser()}}>Unsubscribe Selected Tribes</button></div>
                        )
                    }
            </div>
</div>
        </div>
        }
        </React.Fragment>
    )
}

export default React.memo(UnSubscribeTribes)
