import React, { Fragment } from "react";

// import styles
import '../../Assets/Style/agenda.css'

// import constants
import { useState } from "react";
import Helper from "../../Helper/helper";

const UpComingSession = ({ eventsList, removeEvents, channelInfo, currentUser, isNotifyAll, notifyAllMembers }) => {
  const [colors] = useState([
    "#3d5a80",
    "#98c1d9",
    "#e0fbfc",
    "#ee6c4d",
    "#293241"
  ])
  const deleteEvent = (name) => {
    let eventFound = eventsList.filter(el => {
      return el.event_name !== name;
    });
    removeEvents(eventFound);
  }

  return (
    <Fragment>
      <div className="AgendaContentWrapper">
        <div className="title">
          <h2>Upcoming Sessions</h2>
        </div>
        <div className="AgendaContainer">
          {
            eventsList?.length > 0 && eventsList.map((event,index)=>{
              return(
                <div className="SessionContent" key={index}>
                  {channelInfo?.channel_owner === currentUser && (
                    <a href={() => false} onClick={(e) => {
                      deleteEvent(event?.event_name)
                    }}
                    className="float-right">
                      <img src={require('../../Assets/svg/close.svg')} alt="noImage" />
                    </a>
                  ) }

                  <div className="session-image">
                    {event.image ?
                      <img src="" alt="" />
                     :
                      <div className="cover-image" style={{backgroundColor: `${Helper.randomItem(colors)}`}} ></div>
                    }
                  </div>
                  
                  <div className="agenda-view">
                    <h3>{event?.event_name}</h3>
                    <div className="inner">
                      <div className="speakers">
                        {
                          event?.speakerList?.map(el => {
                            return (
                              <div className="img">
                                <div className="host-image">
                                  <img src={el.image} alt=""/>
                                </div>
                                <div className="overflow-modal">
                                  <h4>{el.name}</h4>
                                  <span>{el.title}</span>
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>

                      <div className="detail">
                        <p>{event?.event_detail_long}</p>
                      </div>

                      <div className="date">
                        <p>{Helper.getEventDate(event?.event_start_date_time,event?.event_end_time)}</p>
                      </div>
                    </div>
                    {channelInfo?.channel_owner === currentUser && !isNotifyAll && !event?.event_is_notify_all &&
                      <button onClick={()=>notifyAllMembers(event.eventId)}>Notify All Members</button>
                    }
                    {
                      isNotifyAll &&
                      <p>Email has been delivered to all members.</p>
                    }
                  </div>
                  
              </div>
              )
            })
            
          }
          {
            eventsList?.length === 0 &&
            <h5>No Upcoming session found.</h5>
          }
        </div>
      </div>
    </Fragment>
  );
};

export default UpComingSession;
