import React, { Component } from "react";
import { CreateChannelChat } from "../../Firebase/Events";
import { app } from "../../Firebase/firebase-config";
import ChatMessage from "./../ChatMessage";
import WriteMessage from "../WriteMessage";
import { FIELDS } from "../../Firebase/Constants";
import ImageComponent from "../ImageComponent";
import { withChat } from "../../Firebase/Schemas";
const db = app.firestore();

class GroupChat extends Component {
  constructor(props) {
    super();
    this.state = {
      host_id: "",
      chats: [],
    };
  }

  componentDidMount(){
    if(!this.state.host_id && this.props?.ownerId){
      this.setState({
        host_id: this.props.ownerId,
      },()=>{
        this.realTimeChat(this.state.host_id)
      })
    }
  }

  componentDidUpdate = (prevProps,prevState) => { 
    if(!this.state.host_id && this.props?.ownerId){
      this.setState({
        host_id: this.props.ownerId,
      },()=>{
        this.realTimeChat(this.state.host_id)
      })
    }

    if(this.state.host_id && (this.state.host_id !== (this.props?.ownerId))){
      this.setState({
        host_id: this.props?.ownerId,
        chats:[]
      },()=>{
        this.realTimeChat(this.state.host_id)
      })
    }

  };

  realTimeChat = async (host_id) => {
    db.collection("channels").doc(this.props.channelInfoId).collection("group_chat").orderBy("chat_created_at","asc").onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
        let chatData = change.doc.data();
        if(chatData.chat_event_id === this.props.channelInfoId) {
          this.setState({
            chats: [...this.state.chats, withChat(change.doc.data())],
          });
        }
      });
    });
  };

  onSendClick = async (data,emails) => {
    data[FIELDS.CHAT.CHAT_EVENT_ID] = this.props.channelInfoId;
    data[FIELDS.CHAT.CHAT_CHANNEL_NAME] = this.props.channelInfo.name;
    data[FIELDS.CHAT.CHAT_CHANNEL_IMAGE] = this.props.channelInfo.cover_image;
    await CreateChannelChat(this.props.channelInfoId,data)
    //send notification to tagged users
    //await sendNotification(emails,data)
  };

  
  redirectProfile = (id)=>{
    this.props.history.push(`/profile/${id}`)
  }

  autoCompleteChat = async(search) =>{
    
    let tagUsers = [];
    let searchName = search.toLowerCase();
    searchName = searchName.split("@")[1]
    
    const name = await db.collection("users")
    .where(FIELDS.USER.USER_SEARCH_NAME, "array-contains",searchName)
    .limit(4)
    .get()
    
    for(const i of name.docs){
      let user = i.data()
      user["id"] = i.id
      tagUsers.push(user)
    }

    this.setState({
      tagUsers
    })
  }

  setTagUserEmpty = () =>{
    this.setState({
      tagUsers:[]
    })
  }
  

  render() {
    return (
      <div className="group-chat-component">
        {/* Header */}
        <div className="gc-header">
          <div className="text-div">
            <h5 className="h5-generic">Group Chat <span className="host-name">{this.props?.eventDetail?.[FIELDS.EVENT.EVENT_HOST_DETAIL]?.[FIELDS.USER.USER_NAME] || this.props?.eventDetail?.[FIELDS.USER.USER_NAME]}</span> </h5>
          </div>
        </div>


        {/* Chat Main content */}
        <div className="gc-main-content">
          <div className="gc-message-section">
            <ChatMessage chats={this.state.chats} history={this.props.history} redirectProfile={this.redirectProfile} />
            <WriteMessage onLoginClick={this.props.onLoginClick} setTagUserEmpty={this.setTagUserEmpty} tagUsers={this.state.tagUsers} autoCompleteChat={this.autoCompleteChat} onSendClick={this.onSendClick} />
          </div>


          <div className="gc-info-section">
            <div className="host-image" onClick={()=>{this.redirectProfile(this.props?.eventDetail?.[FIELDS.EVENT.EVENT_HOST_ID])}}>
              <ImageComponent image={this.props?.eventDetail?.[FIELDS.EVENT.EVENT_HOST_DETAIL]?.[FIELDS.USER.USER_IMAGE] || this.props?.eventDetail?.[FIELDS.USER.USER_IMAGE]} />
            </div>
            <h4 className="h4-generic host-info-generic" onClick={()=>{this.redirectProfile(this.props?.eventDetail?.[FIELDS.EVENT.EVENT_HOST_ID] || this.props?.eventDetail?.id )}}>{this.props?.eventDetail?.[FIELDS.EVENT.EVENT_HOST_DETAIL]?.[FIELDS.USER.USER_NAME] || this.props?.eventDetail?.[FIELDS.USER.USER_NAME]}</h4>
            <p className={`host-info-generic ${(this.props?.eventDetail?.[FIELDS.EVENT.EVENT_HOST_DETAIL]?.[FIELDS.USER.USER_BIO]?.length > 0 || this.props?.eventDetail?.[FIELDS.USER.USER_BIO]?.length > 0 )  && "border-bottom"}`}>{this.props?.eventDetail?.[FIELDS.EVENT.EVENT_HOST_DETAIL]?.[FIELDS.USER.USER_BIO] || this.props?.eventDetail?.[FIELDS.USER.USER_BIO] }</p>
          </div>

        </div>

      </div>
    );
  }
}

export default GroupChat;
