import { app } from "../Firebase/firebase-config";

const uploadImage = async(imageFile,path) =>{
  const storage = app.storage();
  return new Promise(function (resolve, reject) {
    const storageRef = storage.ref(`${path}${imageFile.name}`)
    const uploadTask = storageRef.put(imageFile)
    uploadTask.on('state_changed',
      function (snapshot) {
        //var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        // console.log('Upload is ' + progress + '% done')
      },
      function error(err) {
        reject()
      },
      function complete() {
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          resolve(downloadURL)
        })
      }
    )
  })
}

// upload cover Image on same path and overwrite old one
const uploadCover = async(imageFile,path) =>{
  const storage = app.storage();
  return new Promise(function (resolve, reject) {
    const storageRef = storage.ref(`${path}cover.${imageFile.type.split('/')[1]}`)
    const uploadTask = storageRef.put(imageFile)
    uploadTask.on('state_changed',
      function (snapshot) {
        //var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        // console.log('Upload is ' + progress + '% done')
      },
      function error(err) {
        reject()
      },
      function complete() {
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          resolve(downloadURL)
        })
      }
    )
  })
}

// Replace user Image on same path and overwrite old one
const replaceImage = async(imageFile,path) =>{
  const storage = app.storage();
  return new Promise(function (resolve, reject) {
    const storageRef = storage.ref(`/${path}`)
    const uploadTask = storageRef.put(imageFile)
    uploadTask.on('state_changed',
      function (snapshot) {
        //var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        // console.log('Upload is ' + progress + '% done')
      },
      function error(err) {
        reject()
      },
      function complete() {
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          resolve(downloadURL)
        })
      }
    )
  })
}

export const uploadUserImage = async (imageFile) => {

  return uploadImage(imageFile,"/images/users/")
}

export const uploadEventImage = async (imageFile,path) => {
  return replaceImage(imageFile,path)
}

export const uploadCoverImage = async (imageFile) => {
  return uploadCover(imageFile,"/images/cover/")
}

export const changeUserImage = async (imageFile,path) => {
  return replaceImage(imageFile,path)
}

export const uploadFeaturedImage = async (imageFile) => {
  return uploadCover(imageFile,"/images/events/FeaturedEvents/")
}

export const uploadWallImage = async (imageFile,path) =>{
  return replaceImage(imageFile,path)
}
