import React from 'react'
import { defaultUserImages } from '../Firebase/Constants'
import { Link } from "react-router-dom";

const MembersList = ({list}) => {
    return (
        <div className="member-list" id="member_list">
            <div className="inner">
                <h3 className="member-heading">Speakers</h3>
                <ul>
                    {list && list.map((speaker,index)=>{
                        return(
                            <li key={index} >
                                <div className="image-box">
                                    <Link to={"/profile/" + speaker.link}><img src={speaker?.image || defaultUserImages} alt="noImg" /></Link>
                                </div>
                                <span className="overflow">{speaker.name}</span>
                            </li>
                        )
                    })}
                </ul>
                {/* <span>{list.length} members</span> */}
                
            </div>
        </div>
    )
}

export default MembersList
