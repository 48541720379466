import React, { useEffect , useState, useContext} from 'react';
import {AuthContext} from '../Firebase/AuthProvider';
import { getUserDetail } from '../Firebase/Users';
import { withPost } from '../Firebase/Schemas';
import {app} from '../Firebase/firebase-config'
import Header from '../components/header';
import ChatBox from "../components/chatBox";
import Sidebar from "../components/Sidebar";
import RightSidebar from "../components/RightSideBar";
import { addReaction } from "../Firebase/Events";
import PostComponentUI from '../components/channel/PostComponentUI';
import FacebookLoader from "../components/FacebookLoader";
import { getChannelById } from "../Firebase/Channels";
const db = app.firestore();

export default function PostDetail(props) {
    const context = useContext(AuthContext)
    const [posts, setposts] = useState([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getPostById = async (id) =>{
        console.time()
        let post  = await db.collection("wall_posts").doc(id).get()
        if(!post.data().post_is_deleted){
                let data = post.data();
                data.id = id;
                let userReactName = []
                data["user_react_name"] = userReactName
                setposts([data])
                console.timeEnd()
                context.showLoader(false)
        }else{
            context.showLoader(false)
            props.history.push("/")
        }
        
    }

    const getChannelInfo = async (channelID) => {
      const result = await getChannelById(channelID);
      return result;
    };

    useEffect(() => {
      if(posts.length === 0) {
        context.showLoader(true)
        getPostById(props.match.params.ID)
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const updateReaction = async (postId) => {
        let allPosts = [...posts];
    
        const userID = context.state.currentUser;
        let index = allPosts.findIndex((e) => e.id === postId);
    
        if (allPosts[index]["post_react"].includes(userID)) {
          allPosts[index]["post_react"].splice(
            allPosts[index]["post_react"]?.indexOf(userID),
            1
          );
        } else {
          allPosts[index]["post_react"].push(userID);
        }
        await addReaction(postId, userID);
        setposts(allPosts)
      };

  return (
    <React.Fragment>
        <Header props={props} />
        <div className="DashboardMainContent ProfileTopContainer">
        <Sidebar currentPage="home" />
        <div className="profile-wrapper">
           <div className="ProfileContainer post-detail">
              <div className="ContentLeftWrap">
                {
                  posts.length === 0 ?
                  <FacebookLoader numberOfLoader={1} /> :
                  <PostComponentUI
                      isEvenPosts={true}
                      updateReaction={updateReaction}
                      history={props.history}
                      posts={posts}
                /> 
                }
	      </div>
           </div>
        </div>
        <ChatBox props={props} userInfo={context.state.userDetail} />
        <RightSidebar />
        </div>
    </React.Fragment>
  );
}
