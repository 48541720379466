import React, { Component } from 'react'
import { FIELDS } from '../Firebase/Constants'
import ImageComponent from '../components/ImageComponent'

class UserList extends Component {
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }
    
    componentWillUnmount(){
        window.removeEventListener('scroll', this.handleScroll);
    }
    
    handleScroll =() => {
        if (window.innerHeight + document.documentElement.scrollTop === document.scrollingElement.scrollHeight) {
            if(this.props.onLoadMoreClick){
                if(this.props.approved){
                    this.props.onLoadMoreClick('approvedUsers')
                }else{
                    this.props.onLoadMoreClick('disapprovedUsers')
                }
            }
        }
    }

  render() {
    const hostname = window.location.hostname === "localhost" ? "assembly-dev-3c297.web.app" : window.location.hostname
    return (
        <div className="user-list">
            {this.props.users && Array.isArray(this.props.users) ?
                <React.Fragment>
                {this.props.users && this.props.users.map((user,key)=>{
                    return(
                        <div className="user-box" key={key}>
                            <div className="user-image">
                                <ImageComponent image={user[FIELDS.USER.USER_IMAGE]} className=""/>
                            </div>
                            <div className="user-detail">
                                <p className="user-name"><b>Name : </b>{user[FIELDS.USER.USER_NAME]}</p>
                                <p className="user-email"><b>Email : </b>{user[FIELDS.USER.USER_EMAIL]}</p>
                                <p className="user-linkedin-profile"><b>LinkedIn : </b><a rel="noopener noreferrer" target="_blank" href={user[FIELDS.USER.USER_LINKEDIN_PROFILE]}>{user[FIELDS.USER.USER_LINKEDIN_PROFILE]}</a></p>
                                {this.props.ask &&(
                                    <p className="user-event-ask-link"><b>Event : </b><a rel="noopener noreferrer" target="_blank" href={`https://${hostname}/event/${user[FIELDS.USER.USER_ASK_EVENT]}`}>{user[FIELDS.USER.USER_ASK_EVENT]}</a></p>
                                )}
                                <button disabled={user[FIELDS.USER.USER_APPROVE]} className={`user-approve ${user[FIELDS.USER.USER_APPROVE] && "approved" } `} onClick={()=>{this.props.onApproveUser(user.uid,user)}}>{user[FIELDS.USER.USER_APPROVE] ? "Approved" : "Approve" }</button>
                                {!user[FIELDS.USER.USER_DISAPPROVE] && !user[FIELDS.USER.USER_APPROVE] &&(
                                    <button className="user-approve user-disapprove" onClick={()=>{this.props.onDisapproveUser(user.uid)}}>Disapprove</button>
                                )}
                                {this.props.approved && (
                                    <button className="user-approve btn-disable-permnt" onClick={()=>{this.props.toggleModal();this.props.getUserId(user.uid)}} >Disable</button>
                                )}

                                {this.props.approved &&(
                                    <div className="tgl">
                                        <p className="tgl-name">Host</p>
                                        <div className="admin-toggle-btn">
                                            <input className="tgl tgl-flat" onChange={(e)=>{this.props.onHostChange(user.uid,user[FIELDS.USER.USER_IS_HOST],this.props.searchUser,user[FIELDS.USER.USER_NAME])}} checked={user[FIELDS.USER.USER_IS_HOST]} value={user[FIELDS.USER.USER_IS_HOST]}  id={user.uid} type="checkbox" />
                                            <label className="tgl-btn" htmlFor={user.uid}></label>
                                        </div>
                                    </div>

                                )}
                            </div>
                        </div>
                    )
                })}
                </React.Fragment>
            : ("") }
            
        </div>
    )
  }
}

export default UserList
