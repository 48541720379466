import React, { Fragment, useState } from "react";

// import style sheet
import "./tabs.scss";
import CreateShowModal from "../CreateShowModal";

const Tabs = ({ TabList, MainTitle, showHideTabs, selectedTab, channelID }) => {
  const [liveShowModal, setLiveShowModal] = useState(false);
  return (
    <Fragment>
      <div className="tabs-container">
        <div className="tabs-content">
          <div className="main-title">
            <p>{MainTitle}</p>
          </div>
          <div className="tabs">
            <ul>
              {TabList.map((item, key) => {
                return (
                  <Fragment>
                    {
                      item.slug === 'live' ?
                      <li
                        index={key}
                        onClick={() => {
                            showHideTabs(item.slug)
                            setLiveShowModal(true)}
                        }
                        className={item.slug === selectedTab ? "live-tab" : ""}
                      >
                        {item.tab}
                        {
                          selectedTab === item.slug &&
                          <div className={`arrow-down-gnrl arrow-down-${item.slug}`}></div>
                        } 
                      </li>
                      :
                      <li
                        index={key}
                        onClick={() => showHideTabs(item.slug)}
                        className={item.slug === selectedTab ? "live-tab" : ""}
                      >
                        {item.tab}
                        {
                          selectedTab === item.slug &&
                          <div className={`arrow-down-gnrl arrow-down-${item.slug}`}></div>
                        } 
                      </li>
                    }
                    
                  
                  </Fragment>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
      {liveShowModal && (
        <CreateShowModal
          setLiveShowModal={setLiveShowModal}
          channelID={channelID}
        />
      )}
    </Fragment>
  );
};

export default Tabs;
