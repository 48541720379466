import React, { Component } from 'react'
import {app} from '../Firebase/firebase-config'

let auth = app.auth();

class ResetPassword extends Component {
    constructor(props){
        super(props)
        this.state={
            password:'',
            errorText:false
        }
    }

    onChangeHandler = e =>{
        let stateName = e.target.name;
        let stateValue = e.target.value
        this.setState({
            [stateName]: stateValue
        })
    }

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        const mode = urlParams.get('mode');
        const oobCode = urlParams.get('oobCode')
        const apiKey = urlParams.get('apiKey')
        const continueUrl = urlParams.get('continueUrl')
        const lang = urlParams.get('lang')
        this.setState({
            mode,
            oobCode,
            apiKey,
            continueUrl,
            lang
        })

        if(!(urlParams.get('oobCode')) && (urlParams.get('oobCode')) !== "resetPassword"){
            window.location.replace("/")
        }else{
            auth.verifyPasswordResetCode(oobCode).then(email=>{
                this.setState({email},()=>{
                })
            }).catch(err=>{
                if(err){
                    window.location.replace("/")
                }
            })
        }
    }

    onResetPasswordClick = (e) =>{
        e.preventDefault();
        let validate = this.validate()
        if(validate !== null){
            this.setState({
                errorText:validate
            })
            return
        }else{
            auth.confirmPasswordReset(this.state.oobCode, this.state.password).then((resp)=> {
                this.setState({
                    errorText:"",
                    resetPass:"You can now sign in with your new password"
                })
            }).catch(err =>{
                if(err.code === "auth/weak-password"){
                    this.setState({
                        errorText:"The password must be 8 characters long or more."
                    })
                }
            });
        }
    }

    validate = () =>{
        let error = '';
        if(this.state.password.length === 0){
            error = "The password cannot be blank"
            return error
        }
        if(this.state.password.length < 8){
            error = "The password must be 8 characters long or more."
            return error
        }
        return null
    }

    render() {
        return (
            <div className="reset-password-container">
                <div className="change-pswrd-div">
                    <h3 className="login-header">Hello from <span className="medium">Assembly <span className="full">F</span></span></h3>
                    {!this.state.resetPass && (
                        <p className="hello-text">for {this.state.email}</p>
                    )}
                    <form>
                        {!this.state.resetPass ?(
                            <React.Fragment>
                                <input className="email-input" placeholder="New password" type="password" name="password" value={this.state.password} onChange={e=>{this.onChangeHandler(e)}} />
                                {this.state.errorText && (
                                    <span className="text-red">{this.state.errorText}</span>
                                )}
                                <button className="login-button-form" onClick={(e)=>{this.onResetPasswordClick(e)}}>Resest Password</button>
                            </React.Fragment>
                        ):(
                            <React.Fragment>
                                <h3 className="">{this.state.resetPass}</h3>
                                <a className="login-button-form reset-pass-btn" href={this.state.continueUrl}>Continue</a>
                            </React.Fragment>
                        )}
                    </form>
                </div>
            </div>
        )
    }
}

export default ResetPassword
