import { app } from "./firebase-config";
import { withChannel } from "./Schemas";
import firebase from 'firebase';
const db = app.firestore();

export const createChannel = async (data) => {
  const result = await db.collection("channels").add(withChannel(data));
  return result;
}

export const getChannelById = async (id) =>{
  var getData = await db.collection("channels").doc(id).get()
  return getData.data();
}

export const updateChannel = async (uid, data) => {
  var channel = await db
    .collection("channels")
    .doc(uid)
    .update(data);
  return channel;
};

export const getAllChannels = async () =>{
  var getData = await db.collection("channels").where("channel_status", "==", "active").get()
  return getData.docs;
}

export const getChannelByUserId = async (userId) =>{
  //var getData = await db.collection("channels").where("channel_owner", "==", userId).get()
  var getData = await db.collection("channels").where("channel_status", "==", "active").get()
  return getData.docs;
}

export const getMyChannels = async (userId) =>{
  //var getData = await db.collection("channels").where("channel_owner", "==", userId).get()
  var getData = await db.collection("channels").where("channel_owner", "==", userId).get()
  return getData.docs;
}

export const getChannelByChannelName = async (search) =>{
  let channels = await db.collection("channels")
  .where("name","==", search).get();
  return channels.docs();
}

export const getChannelBySlug = async (slug) =>{
  var getData = await db.collection("channels").where("slug", "==", slug).get();
  return getData.docs.map(doc => Object.assign({ uid: doc.id }, doc.data()));
}

export const getAllChannelsListWithPagination = async (userChannels, isAdmin) =>{
  const channelList = [];
  let query;

  if(isAdmin) {
    query = db.collection("channels").orderBy("name", "asc");
  } else {
    query = db.collection("channels").where("channel_status", "==", "active").orderBy("name", "asc");
  }

  const result = await query.get()

  result.forEach(channel =>{
    let data = channel.data()
    if(userChannels.indexOf(channel.id) !== -1) {
      data['isJoin'] = 1;
    }
    else {
      data['isJoin'] = 0;
    }
    data['id'] = channel.id
    channelList.push(data)
  })
  return channelList
}

export const getChannelPostCount = async (id) =>{
  const result = await db.collection('wall_posts')
      .where("post_is_deleted", "==", false)
      .where("post_channel_id", "==", id)
      .get()
      return result.docs.length;
}

export const getChannelPosts = async (id, startAfter, limit, orderBy) =>{
  let result =  "";
  if(startAfter) {
    result = await db
    .collection("wall_posts")
    .where("post_is_deleted", "==", false)
    .where("post_channel_id", "==", id)
    .orderBy("post_created_at", orderBy)
    .startAfter(startAfter)
    .limit(limit);
  }
  else {
    result = await db
    .collection("wall_posts")
    .where("post_is_deleted", "==", false)
    .where("post_channel_id", "==", id)
    .orderBy("post_created_at", "desc")
    .limit(limit);
  }
  return result;
}

export const getUserChannels = async (userId) =>{
  return db.collection("channels").where("membersIds", "array-contains", userId).get();
}
