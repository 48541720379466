import React, { Fragment } from "react";

// import styles
import '../../Assets/Style/channelHeader.css'

const ChannelHeader = ({ setChannelModal }) => {
  return (
    <Fragment>
      <button onClick={() => setChannelModal()} className="channelBtnName"> Create Tribe </button>
    </Fragment>
  );
};

export default ChannelHeader;
