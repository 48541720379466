import React, { Fragment, useEffect } from "react";

// import styles
import '../../Assets/Style/channelHeader.css'
import "../../Assets/Style/newLayout.scss";
import SkeletonLoader from "../Loaders/SkeletonLoader";

const ChannelHeader = ({ channelInfo, currentUser, openJoinChannelModal, toggleModal, selectedTab, channelMembersLength, updateStep }) => {

  const isJoin = (() => {
    if (channelInfo) {
      return channelInfo.members.find((el) => {
        return el.id === currentUser
      })
    }
  })

  useEffect(() => {
    document.title = channelInfo.name ? channelInfo.name + " | Assembly F" : "Assembly F";
  })

  return (
    <Fragment>
      {!channelInfo.cover_image ?
      null
        // <div className="channels_header">
        //   <div className="channels_inner">
        //     <div className="channel_card">
        //       <div className="description">
        //         <h1>{channelInfo.name}</h1>
        //         {/* <p>{channelInfo && channelInfo.members.length + 1} Members</p> */}
        //       </div>
        //       <div className="channels_buttons">
        //         {/* {
        //       !isJoin() && currentUser !== channelInfo.channel_owner &&
        //       <a href="javascript:void(null);" onClick={(e)=>openJoinChannelModal()} className="channel_join">
        //         Join
        //       </a>
        //     } */}
        //       </div>
        //     </div>
        //   </div>
        // </div> :
        :
        null
        // <div className={`${channelInfo?.cover_image && "no-bg-color"} channelHeaderContainer`}>
        //   {
        //     channelInfo.cover_image &&
        //     <img src={channelInfo.cover_image} alt="channel cover" className="coverImage" />
        //   }
        //   {
        //     channelInfo.cover_image === "" &&
        //     <div className="channelName">
        //       <h1>{channelInfo.name}</h1>
        //     </div>
        //   }
        // </div>
      }
      <div className="join-channel-btn-div">
        {
          // (selectedTab === 'community' || selectedTab === 'people' || selectedTab === 'groupChat') &&
          // (!isJoin() && currentUser !== channelInfo.channel_owner) && (
            <div className="channel-div" style={{display: !currentUser && 'block'}}>
              {currentUser && (
                <div className="left">
                <p>
                  {/* This week's topic:
                  <a href="javascript:void(null);" onClick={(e) => openJoinChannelModal()} className="channel_join">
                    Join
                  </a>
                  150 <span className="ozi">Ozi</span> per year */}
                  {channelInfo?.name}
                </p>
              </div>
              )}
              {!channelInfo ?
                <SkeletonLoader mb="0" textAlign="right" button={true} />
                : 
                <div className="right" style={{justifyContent: !currentUser && 'flex-end'}}>
                  <p> {isJoin() ? "You + " + parseInt(channelMembersLength - 1) : channelMembersLength && channelMembersLength} Members</p>
                  <button onClick={()=>{(!isJoin() && currentUser !== channelInfo.channel_owner) ? openJoinChannelModal() : toggleModal("inviteChannel")}} >{(!isJoin() && currentUser !== channelInfo.channel_owner) ? "Join Now" : "+ Invite new members"}</button>
                </div>
              }
              
            </div>
            
          // )

        }
      </div>


    </Fragment>
  );
};

export default ChannelHeader;
