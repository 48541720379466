import React, { Fragment, useState } from "react";

// Import styles
import '../../../Assets/Style/profile.scss'
import MyStoryModal from './MyStoryModal';

const MyStory = ({ userDetail, userID, updateCurrentUser }) => {

  const [showModal, setShowModal] = useState(false)
  const [selectedExp, setSelectedExp] = useState([])
  const [selectedIndex, setSelectedIndex] = useState("")

  const toggleModal = (item, index)=>{
    if(item) {
      setSelectedExp(item)
      setSelectedIndex(index)
    }
    else {
      setSelectedIndex("")
      setSelectedExp([])
    }
    setShowModal(!showModal)
  }

  return (
    <Fragment>
      <div className="ExperienceContainer">
        <div className="header">
          <p>My Story</p>
          {
            userID === userDetail.id &&
            <img
              src={require('../../../Assets/svg/plus.svg')}
              alt="edit-img"
              className="img-fluid"
              onClick={() => toggleModal()}
            />
          }
        </div>
        {
            !userDetail?.user_story ? <p className="notFound">Not found.</p> : ""
        }
        {
          userDetail?.user_story &&
          <Fragment>
            <div className="Content">
              <div className="text">
                <p>
                  {userDetail?.user_story}
                </p>
              </div>
            </div>
          </Fragment>
        }
      </div>
      {showModal && (
            <MyStoryModal 
              show="showProfileModal"
              updateCurrentUser={updateCurrentUser} 
              openModal={toggleModal} 
              closeModal={toggleModal}  
              selectedExp={selectedExp}
              selectedIndex={selectedIndex}
              userDetail={userDetail}
            />
        )}
    </Fragment>
  );
};

export default MyStory;
