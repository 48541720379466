import React, { Component } from "react";
import { CreateChat, CreateChannelChat} from "../Firebase/Events";
import { app } from "../Firebase/firebase-config";
import ChatMessage from "./ChatMessage";
import MessageWrite from "./MessageWrite";
import { FIELDS } from "../Firebase/Constants";
import { withChat } from "../Firebase/Schemas";
const db = app.firestore();

class MessageChat extends Component {
  constructor(props) {
    super();
    this.state = {
      host_id: props.userId,
    };
  }

  componentDidMount() {
    this.realTimeChat(this.state.host_id, true);
  }

  componentDidUpdate = (prevProps,prevState) => { 
    if(this.props.chats.length === 0 && this.props.selectedUser) {
      this.realTimeChat(this.state.host_id, true);
    }
    if(!this.state.host_id && this.props.userId){
      this.setState({
        host_id: this.props.userId,
      },()=>{
        this.realTimeChat(this.state.host_id)
      })
    }

    if(this.state.host_id && (this.state.host_id !== (this.props.userId))){
      this.props.setChatsList([]);
      this.setState({
        host_id: this.props.userId
      },()=>{
        this.realTimeChat(this.state.host_id)
      })
    }

  };

  realTimeChat = async (host_id, isOtherUser) => {
    if(host_id && !this.props.isChannel) {
      db.collection("users").doc(host_id).collection("group_chat").where("chat_to_user_id", "==", this.props.selectedUser.id).orderBy("chat_created_at","asc").onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
          let chatMessage = withChat(change.doc.data());
          let oldMessages = [...this.props.chats]
          oldMessages.push(chatMessage);
          oldMessages = this.removeDuplicates(oldMessages, "chat_message");
          oldMessages.sort(
            (a, b) => a.chat_created_at - b.chat_created_at
          );
          this.props.setChatsList(oldMessages);
        });
        if(isOtherUser) {
          this.getUserMessages(this.props.selectedUser.id)
        }
      });
    }
    else {
      db.collection("channels").doc(host_id).collection("group_chat").orderBy("chat_created_at","asc").onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
          let chatMessage = withChat(change.doc.data());
          let oldMessages = [...this.props.chats]
          oldMessages.push(chatMessage);
          oldMessages = this.removeDuplicates(oldMessages, "chat_message");
          oldMessages.sort(
            (a, b) => a.chat_created_at - b.chat_created_at
          );
          this.props.setChatsList(oldMessages);
        });
      });
    }
  };

  getUserMessages = (host_id) => {
    db.collection("users").doc(host_id).collection("group_chat").where("chat_to_user_id", "==", this.props.userId).orderBy("chat_created_at","asc").onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
        let chatMessage = withChat(change.doc.data());
        let oldMessages = [...this.props.chats]
        oldMessages.push(chatMessage);
        oldMessages = this.removeDuplicates(oldMessages, "chat_message");
        oldMessages.sort(
          (a, b) => a.chat_created_at - b.chat_created_at
        );
        this.props.setChatsList(oldMessages);
      });
    });
  }

  removeDuplicates = (originalArray, prop) => {
      var newArray = [];
      var lookupObject  = {};

      for(var i in originalArray) {
        lookupObject[originalArray[i][prop]] = originalArray[i];
      }

      for(i in lookupObject) {
          newArray.push(lookupObject[i]);
      }
      return newArray;
  }

  onSendClick = async (data,emails) => {
    const host_id = this.props.userId
    if(this.props.isChannel) {
      data[FIELDS.CHAT.CHAT_EVENT_ID] =  data.chat_to_user_id;
      data.chat_to_user_id = "";
      data[FIELDS.CHAT.CHAT_CHANNEL_ID] = data.chat_to_user_id;
      data[FIELDS.CHAT.CHAT_CHANNEL_NAME] = this.props.selectedUser.name;
      data[FIELDS.CHAT.CHAT_CHANNEL_IMAGE] = this.props.selectedUser.image;
      await CreateChannelChat(host_id,data)
      this.props.getChannelMessages();
    }
    else {
      data[FIELDS.CHAT.CHAT_EVENT_ID] =  this.props.userId;
      data[FIELDS.CHAT.CHAT_TO_USER_ID] = this.props.selectedUser.id;
      await CreateChat(host_id,data)
      this.props.getInboxUsersList();
    }
   
  };
  

  
  redirectProfile = (id)=>{
    this.props.history.push(`/profile/${id}`)
  }

  autoCompleteChat = async(search) =>{
    
    let tagUsers = [];
    let searchName = search.toLowerCase();
    searchName = searchName.split("@")[1]
    
    const name = await db.collection("users")
    .where(FIELDS.USER.USER_SEARCH_NAME, "array-contains",searchName)
    .limit(4)
    .get()
    
    for(const i of name.docs){
      let user = i.data()
      user["id"] = i.id
      tagUsers.push(user)
    }

    this.setState({
      tagUsers
    })
  }

  setTagUserEmpty = () =>{
    this.setState({
      tagUsers:[]
    })
  }
  

  render() {
    return (
      <div>
        {/* Chat Main content */}
        <div className="gc-main-content">
          <div className="gc-message-section">
            <ChatMessage chats={this.props.chats} history={this.props.history} redirectProfile={this.redirectProfile} />
            <MessageWrite onLoginClick={this.props.onLoginClick} selectedUser={this.props.selectedUser} setTagUserEmpty={this.setTagUserEmpty} tagUsers={this.state.tagUsers} autoCompleteChat={this.autoCompleteChat} onSendClick={this.onSendClick} />
          </div>
        </div>

      </div>
    );
  }
}

export default MessageChat;
