import React from 'react';
import Header from '../components/header'
import ChannelListComp from '../components/ChannelListComp';
import LoginSignUpComp from '../components/LoginSignUpComp';

const LoginSignUpPage = (props) => {
    return (
        <div className="new-design">
            <Header isLoginPage={true} props={props} />
            
            <div className="two-column-layout">
                <div className="single-col">
                    <div className="image-container">
                        <img src={require("../Assets/Images/abstract.jpeg")} alt="noImage" />
                    </div>
                
                </div>
                <LoginSignUpComp />
            </div>

            <div className="bottom">
                <div className="inner">
                    <div className="combine">
                        <h3 className="full-heading-2"><span>Teach for one hour</span> every week.</h3>
                        <p className="second-tagline">Join our mission to make learning free.</p>
                    </div>
                    <button className="apply-btn" onClick={() => window.open("https://forms.gle/VZDA48XMyE9tkwwu9", "_blank")}>Apply Now</button>
                </div>
            </div>
     
            <ChannelListComp />
        </div>
        
    )
}

export default LoginSignUpPage
