import React, { Fragment } from "react";

// import styles
import '../../Assets/Style/partners.css'
import userIcon from '../../Assets/Images/userPlaceholder.jpeg'
import Helper from "../../Helper/helper";

const Partners = ({ partners, editPartner, removePartner, publicProfile }) => {

  const openPostMenu = (e) => {
    let menu = e.target.childNodes[0];
    if (menu.classList.contains("show-post-menu-dropdown")) {
      menu.classList.remove("show-post-menu-dropdown");
    } else {
      menu.classList.add("show-post-menu-dropdown");
    }
  };

  const closeDropdown = (e) => {
    let dropDownMenu = e.target.parentNode.parentNode;
    dropDownMenu.classList.remove("show-post-menu-dropdown");
  };

  return (
    <Fragment>
      <div className="PartnersWrapper">
        <div className="partnerContainer">
          <div className="title">
          {
            !publicProfile ?
            <h2>Partners & Sponsors</h2>
            :
            <h2 className="font-changed">Partners</h2>
          }
            
          </div>
          <div className={`ourPartners ${partners?.length <= 0 && "remove-grid"}`}>
                {partners?.map((item, index) => {
                  return (
                    <div className="speaker" key={index}>
                      <div className="image-div">
                        <img
                          src={item?.image?? userIcon}
                          alt="speaker"
                          className="img-fluid partner-img"
                        />
                      </div>
                      {
                          !publicProfile &&
                      <div
                        className="pbm-post-menu float-right"
                        onClick={(e) => {
                          openPostMenu(e);
                        }}
                      >
                          <div
                            className="post-menu-dropdown"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <ul>
                            <li
                                className="link"
                                onClick={(e) => {
                                  editPartner(item, index);
                                  closeDropdown(e);
                                }}
                              >
                                edit
                              </li>
                              <li
                                className="delete"
                                onClick={(e) => {
                                  removePartner(item.name)
                                  closeDropdown(e);
                                }}
                              >
                                Delete
                              </li>
                            </ul>
                          </div>
                        
                      </div>
                }
                      <p>{Helper.transformText(item.name)}</p>
                    </div>
                  );
                })}
                {
                  partners?.length === 0 &&
                  <h5>no partner found.</h5>
                }
                
          </div>
          {
            !publicProfile &&
            <div className="add-btn-gnrl-channel">
                  <button>Apply as partner</button>
            </div>
          }
        </div>
      </div>
    </Fragment>
  );
};

export default Partners;
