import React, { Fragment, Component } from "react";

// import style sheet
import "./channelform.scss";

import { AuthContext } from "../../Firebase/AuthProvider";
import { uploadUserImage } from "../../Firebase/StorageBucket";
import { countryList } from '../../Firebase/Constants';

class EditChannel extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      name: props.channelInfo.name,
      description: props.channelInfo.description,
      link: props.channelInfo.link,
      imageFile: "",
      imagePreview: props.channelInfo.cover_image,
      errorText: "",
      //code: props.channelInfo.channel_code_number,
      bullets: props.channelInfo.whyJoin ? props.channelInfo.whyJoin : [
        {value:'Bullet point no 1'}
      ],
      country: props.channelInfo.country ? props.channelInfo.country : "",
      language: props.channelInfo.language ? props.channelInfo.language : "",
      priceType: props.channelInfo.priceType ? props.channelInfo.priceType : "free",
      amount: props.channelInfo.amount ? props.channelInfo.amount : "",
      caption: props.channelInfo.caption ? props.channelInfo.caption : `Join the ${props.channelInfo.name} learning community.`,
      whyJoin: props.channelInfo.whyJoin ? props.channelInfo.whyJoin : [
        {value:'Bullet point no 1'}
      ],
      showPeople: [{
        name: "Speakers",
        value: "speakers",
        isChecked: false
      },
      {
        name: "Partners",
        value: "partners",
        isChecked: false
      },
      {
        name: "Sessions",
        value: "sessions",
        isChecked: false
      }],
      subscription: props.channelInfo.subscription ? props.channelInfo.subscription : "",
      slug: props.channelInfo.slug ? props.channelInfo.slug  : ""
    };
  }

  componentDidMount = () => {
    if(this.props.channelInfo.showPeople) {
      let peopleList = [...this.state.showPeople];
      peopleList = peopleList.map(el => {
        let found = this.props.channelInfo.showPeople.find(elem => {
          return elem.name === el.name;
        })
        if(found) {
          el.isChecked = true;
        }
        return el;
      })
      this.setState({ showPeople: peopleList })
    }
  }

  validate = () => {
    let error = "";
    if (this.state.name === "") {
      error = "Tribe name is required.";
      return error;
    }
    return null;
  };

  onAddBulletClick=()=>{
    let _bullets = [...this.state.bullets]
    _bullets.push({
      value: ''
    })
    this.setState({bullets:_bullets})
  }

  onRemoveBulletClick=(e, index)=>{
    e.preventDefault();
    let _bullets = [...this.state.bullets]
    _bullets.splice(index, 1)
    this.setState({bullets:_bullets})
  }

  handleImageAsFile = (e) => {
    const image = e.target.files[0];
    if (image) {
      this.setState({
        imageFile: image,
        imagePreview: URL.createObjectURL(image),
      });
    }
  };

  onchangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChannelClick = async (e) => {
    e.preventDefault();
    
    this.context.showLoader(true);
    const validate = this.validate();
    if (validate != null) {
      this.setState({ errorText: validate });
      this.context.showLoader(false);
      return;
    } else {
      this.setState({ errorText: "" });
    }
    let channnelSlug = this.createChannelSlug(this.state.slug);
    const imageFile = this.state.imageFile;
    let imageUrl = this.props.channelInfo.cover_image;
    if(this.state.imageFile === false) {
      imageUrl = "";
    }
    else if (imageFile) {
      imageUrl = await uploadUserImage(imageFile);
    }
    let data = {
      name: this.state.name,
      description: this.state.description,
      link: this.state.link,
      cover_image: imageUrl,
      country: this.state.country,
      language: this.state.language,
      priceType: this.state.priceType,
      amount: this.state.amount,
      caption: this.state.caption,
      whyJoin: this.state.bullets,
      subscription: this.state.subscription,
      showPeople: this.state.showPeople.filter(el => { return el.isChecked === true}),
      slug: this.createChannelSlug(this.state.slug)
      //channel_code_number: this.state.code
    };
    this.props.updateChannelInfo(data);
    this.setState({ slug: channnelSlug });
  };

  createChannelSlug( str ) {
	
    //replace all special characters | symbols with a space
    // eslint-disable-next-line no-useless-escape
    str = str.replace(/[`~!@#$%^&*()_\-+=\[\]{};:'"\\|\/,.<>?\s]/g, ' ').toLowerCase();
    
    // trim spaces at start and end of string
    str = str.replace(/^\s+|\s+$/gm,'');
    
    // replace space with dash/hyphen
    str = str.replace(/\s+/g, '');	
    return str;
  }

  removeUserImage = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      imageFile: false,
      imagePreview: "",
    });
  };

  handleAllChecked = (event, index) => {
    let people = this.state.showPeople
    people[index].isChecked = event.target.checked;
    this.setState({showPeople: people})
  }

  changeValue = (event, index) => {
    let bullets = this.state.bullets
    bullets[index].value = event.target.value;
    this.setState({bullets: bullets})
  }

  render() {
    return (
      <Fragment>
        <form>
          <div className="channel-placeholder">
            <h4>Channel Basics </h4>
          </div>

          <div className="channel-container">

            <div className="channel-input-group">
              <label>Tribe Name</label>
              <input type="text" name="name"
                  onChange={this.onchangeHandler}
                  value={this.state.name} placeholder="Enter Tribe Name" />
            </div>

            <div className="channel-input-group">
              <label>Tribe Url Slug</label>
              <input type="text" name="slug"
                  onChange={this.onchangeHandler}
                  value={this.state.slug} placeholder="Enter Tribe Url Slug" />
            </div>

            <div className="cle-input-group">
          <label htmlFor="cle_desc">
            Tribe description - why does this tribe exist
          </label>
          <textarea
            placeholder="Channel detail"
            className="cle-textarea"
            id="cle_desc"
            cols="30"
            rows="10"
            name="description"
              onChange={this.onchangeHandler}
              value={this.state.description}
          >{this.state.description}</textarea>
        </div>

            <div className="channel-input-group">
              <label>Country</label>
              <select
                  className={`${this.state.errorText.includes("Country") && "error-border"}`}
                  name="country"
                  id="country"
                  value={this.state.country}
                  onChange={(e) => {
                      this.setState({ country: e.target.value })
                  }}
                  >
                      <option value="">
                          Choose Country
                      </option>
                      {countryList.map((country) => {
                      return (
                          <option value={country.name} key={country.code}>
                          {country.name}
                          </option>
                      );
                      })}
              </select>
            </div>

            <div className="channel-input-group">
              <label>Language</label>
              <select id="languages" name="languages" onChange={(e) => {
                      this.setState({ language: e.target.value })
                  }}
                  value={this.state.language}>
                <option value="">
                      Choose Language
                  </option>
                <option value="af">Afrikaans</option>
                <option value="sq">Albanian - shqip</option>
                <option value="am">Amharic - አማርኛ</option>
                <option value="ar">Arabic - العربية</option>
                <option value="an">Aragonese - aragonés</option>
                <option value="hy">Armenian - հայերեն</option>
                <option value="ast">Asturian - asturianu</option>
                <option value="az">Azerbaijani - azərbaycan dili</option>
                <option value="eu">Basque - euskara</option>
                <option value="be">Belarusian - беларуская</option>
                <option value="bn">Bengali - বাংলা</option>
                <option value="bs">Bosnian - bosanski</option>
                <option value="br">Breton - brezhoneg</option>
                <option value="bg">Bulgarian - български</option>
                <option value="ca">Catalan - català</option>
                <option value="ckb">Central Kurdish - کوردی (دەستنوسی عەرەبی)</option>
                <option value="zh">Chinese - 中文</option>
                <option value="zh-HK">Chinese (Hong Kong) - 中文（香港）</option>
                <option value="zh-CN">Chinese (Simplified) - 中文（简体）</option>
                <option value="zh-TW">Chinese (Traditional) - 中文（繁體）</option>
                <option value="co">Corsican</option>
                <option value="hr">Croatian - hrvatski</option>
                <option value="cs">Czech - čeština</option>
                <option value="da">Danish - dansk</option>
                <option value="nl">Dutch - Nederlands</option>
                <option value="en">English</option>
                <option value="en-AU">English (Australia)</option>
                <option value="en-CA">English (Canada)</option>
                <option value="en-IN">English (India)</option>
                <option value="en-NZ">English (New Zealand)</option>
                <option value="en-ZA">English (South Africa)</option>
                <option value="en-GB">English (United Kingdom)</option>
                <option value="en-US">English (United States)</option>
                <option value="eo">Esperanto - esperanto</option>
                <option value="et">Estonian - eesti</option>
                <option value="fo">Faroese - føroyskt</option>
                <option value="fil">Filipino</option>
                <option value="fi">Finnish - suomi</option>
                <option value="fr">French - français</option>
                <option value="fr-CA">French (Canada) - français (Canada)</option>
                <option value="fr-FR">French (France) - français (France)</option>
                <option value="fr-CH">French (Switzerland) - français (Suisse)</option>
                <option value="gl">Galician - galego</option>
                <option value="ka">Georgian - ქართული</option>
                <option value="de">German - Deutsch</option>
                <option value="de-AT">German (Austria) - Deutsch (Österreich)</option>
                <option value="de-DE">German (Germany) - Deutsch (Deutschland)</option>
                <option value="de-LI">German (Liechtenstein) - Deutsch (Liechtenstein)</option>
                <option value="de-CH">German (Switzerland) - Deutsch (Schweiz)</option>
                <option value="el">Greek - Ελληνικά</option>
                <option value="gn">Guarani</option>
                <option value="gu">Gujarati - ગુજરાતી</option>
                <option value="ha">Hausa</option>
                <option value="haw">Hawaiian - ʻŌlelo Hawaiʻi</option>
                <option value="he">Hebrew - עברית</option>
                <option value="hi">Hindi - हिन्दी</option>
                <option value="hu">Hungarian - magyar</option>
                <option value="is">Icelandic - íslenska</option>
                <option value="id">Indonesian - Indonesia</option>
                <option value="ia">Interlingua</option>
                <option value="ga">Irish - Gaeilge</option>
                <option value="it">Italian - italiano</option>
                <option value="it-IT">Italian (Italy) - italiano (Italia)</option>
                <option value="it-CH">Italian (Switzerland) - italiano (Svizzera)</option>
                <option value="ja">Japanese - 日本語</option>
                <option value="kn">Kannada - ಕನ್ನಡ</option>
                <option value="kk">Kazakh - қазақ тілі</option>
                <option value="km">Khmer - ខ្មែរ</option>
                <option value="ko">Korean - 한국어</option>
                <option value="ku">Kurdish - Kurdî</option>
                <option value="ky">Kyrgyz - кыргызча</option>
                <option value="lo">Lao - ລາວ</option>
                <option value="la">Latin</option>
                <option value="lv">Latvian - latviešu</option>
                <option value="ln">Lingala - lingála</option>
                <option value="lt">Lithuanian - lietuvių</option>
                <option value="mk">Macedonian - македонски</option>
                <option value="ms">Malay - Bahasa Melayu</option>
                <option value="ml">Malayalam - മലയാളം</option>
                <option value="mt">Maltese - Malti</option>
                <option value="mr">Marathi - मराठी</option>
                <option value="mn">Mongolian - монгол</option>
                <option value="ne">Nepali - नेपाली</option>
                <option value="no">Norwegian - norsk</option>
                <option value="nb">Norwegian Bokmål - norsk bokmål</option>
                <option value="nn">Norwegian Nynorsk - nynorsk</option>
                <option value="oc">Occitan</option>
                <option value="or">Oriya - ଓଡ଼ିଆ</option>
                <option value="om">Oromo - Oromoo</option>
                <option value="ps">Pashto - پښتو</option>
                <option value="fa">Persian - فارسی</option>
                <option value="pl">Polish - polski</option>
                <option value="pt">Portuguese - português</option>
                <option value="pt-BR">Portuguese (Brazil) - português (Brasil)</option>
                <option value="pt-PT">Portuguese (Portugal) - português (Portugal)</option>
                <option value="pa">Punjabi - ਪੰਜਾਬੀ</option>
                <option value="qu">Quechua</option>
                <option value="ro">Romanian - română</option>
                <option value="mo">Romanian (Moldova) - română (Moldova)</option>
                <option value="rm">Romansh - rumantsch</option>
                <option value="ru">Russian - русский</option>
                <option value="gd">Scottish Gaelic</option>
                <option value="sr">Serbian - српски</option>
                <option value="sh">Serbo-Croatian - Srpskohrvatski</option>
                <option value="sn">Shona - chiShona</option>
                <option value="sd">Sindhi</option>
                <option value="si">Sinhala - සිංහල</option>
                <option value="sk">Slovak - slovenčina</option>
                <option value="sl">Slovenian - slovenščina</option>
                <option value="so">Somali - Soomaali</option>
                <option value="st">Southern Sotho</option>
                <option value="es">Spanish - español</option>
                <option value="es-AR">Spanish (Argentina) - español (Argentina)</option>
                <option value="es-419">Spanish (Latin America) - español (Latinoamérica)</option>
                <option value="es-MX">Spanish (Mexico) - español (México)</option>
                <option value="es-ES">Spanish (Spain) - español (España)</option>
                <option value="es-US">Spanish (United States) - español (Estados Unidos)</option>
                <option value="su">Sundanese</option>
                <option value="sw">Swahili - Kiswahili</option>
                <option value="sv">Swedish - svenska</option>
                <option value="tg">Tajik - тоҷикӣ</option>
                <option value="ta">Tamil - தமிழ்</option>
                <option value="tt">Tatar</option>
                <option value="te">Telugu - తెలుగు</option>
                <option value="th">Thai - ไทย</option>
                <option value="ti">Tigrinya - ትግርኛ</option>
                <option value="to">Tongan - lea fakatonga</option>
                <option value="tr">Turkish - Türkçe</option>
                <option value="tk">Turkmen</option>
                <option value="tw">Twi</option>
                <option value="uk">Ukrainian - українська</option>
                <option value="ur">Urdu - اردو</option>
                <option value="ug">Uyghur</option>
                <option value="uz">Uzbek - o‘zbek</option>
                <option value="vi">Vietnamese - Tiếng Việt</option>
                <option value="wa">Walloon - wa</option>
                <option value="cy">Welsh - Cymraeg</option>
                <option value="fy">Western Frisian</option>
                <option value="xh">Xhosa</option>
                <option value="yi">Yiddish</option>
                <option value="yo">Yoruba - Èdè Yorùbá</option>
                <option value="zu">Zulu - isiZulu</option>
            </select>
            </div>

          </div>


          <div className="channel-placeholder">
            <h4>Pricing</h4>
          </div>
          
          <div className="channel-container">

            <div className="channel-input-group">
              <div className="radio-group">
                <input type="radio" id="free" name="price" checked={this.state.priceType === "free"} onChange={() => {
                      this.setState({ priceType: "free" })
                    }} />
                <label htmlFor="free">Free</label>
              </div>

              <div className="radio-group">
                <input type="radio" id="paid" name="price" checked={this.state.priceType !== "free"} onChange={() => {
                      this.setState({ priceType: "paid" })
                    }} />
                <label htmlFor="paid">Paid</label>
              </div>
            </div>
            {
              this.state.priceType !== "free" &&
              <Fragment>
                <p>If paid, choose one:</p>
                <div className="channel-input-group">
                  <div className="radio-group">
                    <input type="radio" id="Monthly" name="subscription" checked={this.state.subscription === "monthly"} onChange={() => {
                      this.setState({ subscription: "monthly" })
                    }}  />
                    <label htmlFor="Monthly">Monthly</label>
                  </div>

                  <div className="radio-group">
                    <input type="radio" id="Quarterly" name="subscription" checked={this.state.subscription === "quarterly"} onChange={() => {
                      this.setState({ subscription: "quarterly" })
                    }}  />
                    <label htmlFor="Quarterly">Quarterly</label>
                  </div>

                  <div className="radio-group">
                    <input type="radio" id="Half-yearly" name="subscription" checked={this.state.subscription === "halfyear"} onChange={() => {
                      this.setState({ subscription: "halfyear" })
                    }}  />
                    <label htmlFor="Half-yearly">Half-yearly</label>
                  </div>


                  <div className="radio-group">
                    <input type="radio" id="Annual" name="subscription" checked={this.state.subscription === "annual"} onChange={() => {
                      this.setState({ subscription: "annual" })
                    }}  />
                    <label htmlFor="Annual">Annual</label>
                  </div>

                  <div className="radio-group">
                    <input type="radio" id="One-time" name="subscription" checked={this.state.subscription === "onetime"} onChange={() => {
                      this.setState({ subscription: "onetime" })
                    }}  />
                    <label htmlFor="One-time">One-time</label>
                  </div>
                </div>

                <div className="channel-input-group">
                  <label>Amount</label>
                  <input type="text" name="amount"
                      onChange={this.onchangeHandler}
                      value={this.state.amount}
                      min="10"
                      placeholder="Enter Amount (minimum $10)" />
                </div>
              </Fragment>
            }
            

          </div>


          <div className="channel-placeholder">
            <h4>Your Landing Page</h4>
          </div>

          <div className="channel-container">
            <div className="channel-input-group">
              <label>Caption</label>
              <input type="text" name="link"
              className="caption"
              onChange={(e) => {
                    this.setState({ caption: e.target.value })
                }}
                  value={this.state.caption}
                  placeholder={`Join the ${this.state.name} learning community.`} />
            </div>
            <span className="small">(you can edit the caption)</span>
          
          
            <div className="channel-input-group">
              <label>Why join </label>
              <div className="bullets">
                <ul className="bullet-list">
                  {this.state.bullets.map((v,index)=>{
                    return(
                      <li key={index}>
                        <input type="text" name={"bullet"}
                          className="caption"
                              onChange={(e) => this.changeValue(e, index)}
                              value={v.value}
                               />
                               {
                                 index !== 0 &&
                                 <span onClick={(e)=>this.onRemoveBulletClick(e, index)}>Remove</span>
                               }
                      </li>
                    )
                  })}
                </ul>
                <span className="small">(you can edit the default bullets and add your own)</span>
                <button onClick={(e)=>{e.preventDefault();this.onAddBulletClick()}} className="add-bullet">Add</button>
              </div>
            </div>
          
            <div className="channel-input-group">
              <label className="check">What else do you want to show on the landing page?</label>
              {
                this.state.showPeople.map((people, index) => {
                  return (<div className="radio-group">
                  <input type="checkbox" name="select" checked={people.isChecked} onClick={(e) => this.handleAllChecked(e, index)}  />
                  <label htmlFor="Speakers">{people.name}</label>
                </div>)
                })
              }
            </div>
          
          
          </div>

          <div className="channel-placeholder">
            <h4>Tribe cover</h4>
          </div>
          


          <div className="channel-container">

        
        {/* <div className="channel-input-group">
          <label>Channel Code</label>
          <input type="text" name="code"
              onChange={this.onchangeHandler}
              value={this.state.code} placeholder="Enter Channel Code" />
        </div> */}
        <div className="channel-upload-group">
            <label>Upload cover logo</label>
            <label className="image-label">
              Select Image
              <input
                type="file"
                accept="image/*"
                id="image-upload"
                name="upload-image"
                value=""
                onChange={this.handleImageAsFile}
              />
            </label>
            <label
                className={this.state.imagePreview && "image-preview"}
                htmlFor="image-upload"
              >
                {this.state.imagePreview ? (
                  <React.Fragment>
                    <img src={this.state.imagePreview} alt="noImage" />
                    <a
                      href="# "
                      onClick={(e) => {
                        this.removeUserImage(e);
                      }}
                    >
                      <img src={require('../../Assets/svg/close.svg')} alt="noImage" />
                    </a>
                  </React.Fragment>
                ) : (
                  ``
                )}
              </label>
            {this.state.errorText && (
              <span className="error-display">{this.state.errorText}</span>
            )}
          </div>

          </div>


          <button
            className="form-submit"
            onClick={(e) => {
              this.onChannelClick(e);
            }}
          >
            Update
          </button>{"  "}{"  "}
          <button
            className="form-submit"
            onClick={(e) => {
              e.preventDefault();
              window.open("/channel/"+this.props.channelId+"?isPreview=1", "_blank")
            }}
          >
            Preview Changes
          </button>
       </form>
      </Fragment>
    );
  }
}

export default EditChannel;
