import React,{useContext, useEffect} from 'react'
import ImageComponent from './ImageComponent'
import {AuthContext} from '../Firebase/AuthProvider'
import { FIELDS } from '../Firebase/Constants';
import Helper from '../Helper/helper';

export default function ChatMessage(props) {
    const { state } = useContext(AuthContext);
    
    useEffect(() => {
        setHeight()
    });


    const setHeight = () =>{
        var chatHeight = document.querySelector("#chat_height");
        var xH = chatHeight.scrollHeight; 
        chatHeight.scrollTo(0, xH);
    }
    
    return (
       <div className="message-component" id="chat_height">
            {props?.chats?.length > 0 ?
                <ul>
                    {props?.chats && props?.chats.map((el,key)=>{
                        let date = new Date(el[FIELDS.CHAT.CHAT_CREATED_AT])
                        let hours = date.getHours();
                        let AMPM = hours >=12 ?'PM':'AM'  
                        hours = hours % 12 || 12;
                        let minutes = (date.getMinutes()<10?'0':'') + date.getMinutes()
                        let time = hours+":"+minutes+" "+AMPM
                    
                        return(
                            <li className={`message-box ${state.currentUser === el[FIELDS.CHAT.CHAT_USER_ID] && ""}`} key={key}>
                                <div className="user-image" onClick={()=>{props.redirectProfile(el[FIELDS.CHAT.CHAT_USER_ID])}}>
                                    <ImageComponent image={el[FIELDS.CHAT.CHAT_USER_IMAGE]} />
                                </div>
                                <div className="message">
                                    <span className="user-name">{Helper.transformText(el[FIELDS.CHAT.CHAT_USER_NAME])}<span className="message-date">{time}</span>  </span>
                                    {/* <span className="message-date">{date}</span> */}
                                    <div className="combine-message">
                                        <p className="chat">{el[FIELDS.CHAT.CHAT_MESSAGE]}</p>
                                    </div>
                                </div>
                            </li>
                        )
                    })}
                </ul>
            :
            <>
            {props?.chats?.length === 0 ? 
                <span className="loading-text">Start chatting now.</span>
                :
                <span className="loading-text">Loading...</span>
            }
            </>
            }
       </div>
    )
}
