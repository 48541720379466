import React, { Component } from "react";
import { AuthContext } from "../../Firebase/AuthProvider";
import "../../Assets/Style/newLayout.scss";
import Header from "../../components/header";
import Sidebar from "../../components/Sidebar";
import Community from "../../components/channelPosts";
import ChatBox from "../../components/channel/chatBox";
import RightSidebar from "../../components/channel/RightSideBar";
import ChannelHeader from "../../components/channelHeader";
import CreateLiveEvent from "../../components/channel/CreateEvent";
import AddAdmin from "../../components/channel/AddAdmin";
import { getChannelById } from "../../Firebase/Channels";
import { userExists, updateUser, getUserByEmail, getUserDetail } from "../../Firebase/Users";
import { updateChannel } from "../../Firebase/Channels";
import PartnersComp from '../../components/partners';
// import tabs
import Tabs from "../../components/tabs";
import { showSuccessMessage } from "../../utils/helperfunctions";
// import tab list
import { ChannelTabList } from "../../utils/constant";

// import forms
import AddPartner from "../../components/channel/AddPartner";
import AddSpeakers from "../../components/channel/addSpeakers";
import EditChannel from "../../components/channel/editChannel";
import EditChannelCode from "../../components/channel/editChannelCode";
import Speakers from "../../components/speakers";
import AgendaComp from '../../components/agenda'
import JoinChannelModal from '../../components/channel/joinChannelModal';
import { signUpError } from "../../Helper/StaticContent";
import { CHANNEL_COVER_IMAGE } from "../../Firebase/Constants";
import firebase from 'firebase';
import { loadStripe } from "@stripe/stripe-js";
import UserModal from "../../components/UserModal";
import BannerTribe from "../../Assets/Images/banner-placeholder.jpeg";

class Channel extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      channelInfo: "",
      initialCall: false,
      selectedTab: "people",
      showChannelModal: false,
      editChannelInfo: "",
      selectedIndex: "",
      channelMembersList: [],
      modalName: '',
      showModal:false,
      userType: "Learner",
      channelTopic: "",
      showForm:false,
      stepId:1,
      invitedEmail: "",
      invitedUserName: "",
      invitedUserId: "",
      firstName: "",
      lastName: "",
      fullName: "",
      country: "",
      linkedInProfile: "",
      imageFile: "",
      confirmPassword: "",
      errorText: "",
      sectionId:0,
      isPaying: false,
      IsUserAdmin: false,
      isPageLoading: false, 
      channelId: this.props.channelID
    };
  }
  
  componentDidMount = async () => {
    this.setState({channelMembersList: this.props.channelInfo.members, channelInfo: this.props.channelInfo, isPageLoading: true})
  }

  componentDidUpdate = (prevState) => {
    if(prevState.channelInfo.name !== this.props.channelInfo.name) {
        this.setState({channelMembersList: this.props.channelInfo.members, channelInfo: this.props.channelInfo, isPageLoading: true, channelId: this.props.channelID})
    }
  }

  closeModal = ()=>{
    this.setState({showForm:!this.state.showForm})
  }

  updateStep = (step)=>{
    this.setState({sectionId:step})
  }

  getChannelInfo = async (channelID, notRefreshUsers) => {
    const result = await getChannelById(channelID);
    let channelOwner = await userExists(result?.channel_owner,'yes')
    if(channelOwner) {
      channelOwner.id = result?.channel_owner;
    }
    // var finalMembers = await Promise.all(result.members.map(async (el) => {
    //   if(el.id) {
    //     const res = await userExists(el.id, 'yes');
    //     if(res) {
    //       res.id = el.id;
    //       return res;
    //     }
    //   }
    // }));
    this.setState({channelMembersList: result.members, channelInfo: result})
  };

  showHideTabs = (slug) => {
    if(slug !== "live") {
      this.setState({ selectedTab: slug })
      this.setState({
        editChannelInfo: "",
        selectedIndex: ""
      })
    }
  };

  updateChannelInfo =  (data, msg) => {
    this.context.showLoader(true);
    updateChannel(this.state.channelId, data)
      .then(async(res) => {
        if(msg !== "noMsg" && msg !== "Congratulations! You have successfully joined the channel.") {
          this.setState({showChannelModal: false,showModal:true,modalName:"update-channel"})
        }
        await this.getChannelInfo(this.state.channelId, true)
        this.context.showLoader(false);
      })
      .catch((err) => {
        if (err.message) {
          this.setState({ errorText: err.message }, () => {
            this.context.showLoader(false);
          });
        }
      });
  };

  addChannelInfoInUser = (channelId, type) => {
    let channelArr = [];
    if(this.context.state.userDetail.user_channels) {
      channelArr = this.context.state.userDetail.user_channels;
      if(type === "leave") {
        channelArr = channelArr.filter(el => {
          return el !== channelId;
        })
      }
      else {
        channelArr.push(channelId)
      }
    }
    else {
      channelArr.push(channelId)
    }
    updateUser(this.context.state.currentUser, "user_channels", channelArr);
  }

  removeSpeaker = (name) => {
    let channelSpeakers = [...this.state.channelInfo.speakers];
    channelSpeakers = channelSpeakers.filter(el => {
      return el.name !== name;
    })
    this.setState({ channelInfo: {
      ...this.state.channelInfo,
      speakers: channelSpeakers
    } });
    this.updateChannelInfo({ speakers: channelSpeakers });
  }

  editSpeaker = (item, index) => {
    this.setState({
      selectedTab: "speaker",
      editChannelInfo: item,
      selectedIndex: index
    })
  }

  setUserType = (type) => {
    this.setState({ userType: type })
  }

  removePartner = (name) => {
    let channelPartners = [...this.state.channelInfo.partners];
    channelPartners = channelPartners.filter(el => {
      return el.name !== name;
    })
    this.setState({ channelInfo: {
      ...this.state.channelInfo,
      partners: channelPartners
    } });
    this.updateChannelInfo({ partners: channelPartners });
  }

  editPartner = (item, index) => {
    this.setState({
      selectedTab: "partner",
      editChannelInfo: item,
      selectedIndex: index
    })
  }

  toggleModal = (name) =>{
    this.setState({
      modalName: name,
      showModal: !this.state.showModal
    })
  }

  closeModal = () =>{
    this.setState({showModal:!this.state.showModal})
  }

  addChannelTopic = (topic) => {
    if(topic) {
      let channelTopic = this.state.channelInfo.channel_topics ? [...this.state.channelInfo.channel_topics] : [];
      channelTopic.push(topic);
      this.closeModal();
      this.updateChannelInfo({ channel_topics: channelTopic });
      this.setState({ channelTopic: "" })
    }
    else {
      this.setState({ errorText: "Please add any topic" });
    }
    
  }

  onChangeHandlerInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  removeEvents = (newEvents) => {
    this.setState({ channelInfo: {
      ...this.state.channelInfo,
      events: newEvents
    } });
    this.updateChannelInfo({ events: newEvents });
  }
  
  checkIsAdmin = (adminList) => {
    let IsAdmin = false;
    if(adminList &&  this.context.state.userDetail) {
      let foundAdmin = adminList.find(el => {
        return el.email ===  this.context.state.userDetail.email
      })
      if(foundAdmin) {
        IsAdmin = true
      }
    }
    return IsAdmin;
  }

  scrollToForm = ()=>{
    let elem = document.getElementById("signup")
    elem.scrollIntoView({behavior: "smooth", block: "end", inline: "start"});
  }

validate = async () => {
  let error = '';
  if (this.state.firstName === '') {
      error = "First name is required."
      return error;
  }
  if (this.state.lastName === '') {
      error = "Last name is required."
      return error;
  }
  if (this.state.country === '') {
      error = "Country is required."
      return error;
  }
  if (this.state.linkedInProfile === '') {
      error = signUpError.organisationError
      return error;
  }
  if (this.state.email === '') {
      error = signUpError.emailError
      return error;
  }
  if (this.state.password === '') {
      error = signUpError.passwordError
      return error;
  }
  if (this.state.password !== this.state.confirmPassword) {
      error = signUpError.confirmPasswordError
      return error;
  }
  let isUserExist = await getUserByEmail(this.state.email)
  if(isUserExist){
      for (const user of isUserExist) {
          let data = user.data()
          if(data.email){
              return "Email address is already in use";
          }             
      }
  }
  if(this.state.password.length < 6) {
      this.setState({ errorText: "Password should be at least 6 characters." })
  }
  else if(this.state.password !== this.state.confirmPassword) {
    error = signUpError.confirmPasswordError
    return error;
  }
  return null;
}

onChangeHandler = (e) =>{
  this.setState({
      [e.target.name]:e.target.value
  })
}


getObjectValue = (element) => {
  let valueObj =  "" 
  Object.keys(element)
    .forEach(function eachKey(key) { 
      valueObj = element[key];
    });
    return valueObj;
}

isShowPeople = (type) => {
  let isFound = false;
  let peopleList =  this.state.channelInfo?.showPeople;
  if(peopleList) {
    // eslint-disable-next-line array-callback-return
    peopleList.map(el => {
      if(el.value === type) {
        isFound = true;
      }
    })
  }
  return isFound;
}

checkoutToStripe = async (price) => {
  const stripePromise = loadStripe('pk_test_51IR2DFKBf8tXl3nrO0mGk0Xs8w1GPMJnPNATZ1xGv1ti54u4GLzkio4yQjgMDEM3aUqeO4zHwlBmOrk074z9oKkQ00BgWWOp1s'); 
  const stripe = await stripePromise;
  this.setState({ isPaying: true });
  //app.functions().useFunctionsEmulator('http://localhost:5001');
  const createStripeCheckout =  firebase.functions().httpsCallable('createStripeCheckout?price='+price+'&channelId='+this.state.channelId);
  createStripeCheckout().then(response => {
    const sessionId = response.data.id;
    stripe.redirectToCheckout({ sessionId: sessionId })
  })
}

openJoinChannelModal =()=>{
    if(true)
    {
      let newMembersArr = [...this.state.channelInfo.members]
      let userInfo = this.context.state.userDetail;
      userInfo.userChannelType = this.state.userType;
      this.addChannelInfoInUser(this.state.channelId, "join");
      newMembersArr.push(userInfo)
      this.updateChannelInfo({ members: newMembersArr }, "Congratulations! You have successfully joined the channel.");
      this.setState({ isPreview: { isPreview: false } })
    }
    else {
      this.setState({showChannelModal: !this.state.showChannelModal})
    }
  }

removeMember = (id) => {
  // get user channel and remove it
  getUserDetail(id).then(data=>{
      let userChannels = data.user_channels; 
      userChannels = userChannels.filter(el => {
        return el !== this.state.channelId
      });
      updateUser(id, "user_channels", userChannels);
  })
  let membersIds = [...this.state.channelInfo.membersIds];
  membersIds = membersIds.filter(el => {
    return el !== id;
  })
  let channelMemberrs = [...this.state.channelInfo.members];
    channelMemberrs = channelMemberrs.filter(el => {
      return el.id !== id;
    })
    this.setState({ channelInfo: {
      ...this.state.channelInfo,
      MembersList: channelMemberrs
    } });
    this.updateChannelInfo({ members: channelMemberrs, membersIds });
}

  render() {
    return (
      <div className={(!this.context.state.currentUser ) ? "logged-out-channle-page new-design" : ""}>
        {this.state.showModal && (
          <UserModal 
            channelInfo={this.state.channelInfo} 
            openJoinChannelModal={this.openJoinChannelModal} 
            show={(!this.context.state.currentUser) ? "login-form" : this.state.modalName} 
            closeModal={this.closeModal} 
            userType={this.state.userType} 
            setUserType={this.setUserType}
            addChannelTopic={this.addChannelTopic}
            channelTopic={this.state.channelTopic}
            onChangeHandlerInput={this.onChangeHandlerInput}
            channelId={this.state.channelId}
            checkoutToStripe={this.checkoutToStripe}
            openPaymentModal={this.openPaymentModal}
            isPaying={this.state.isPaying}
            userInfo={this.context.state.userDetail}
          />
        )}
        <Header 
          props={this.props}
          channelInfo={this.state.channelInfo}
          showForm={this.closeModal}
          isLoggedOutChannel={!!this.context.state.currentUser}
          emptyChannelList={this.props.emptyChannelList}
        />
        <div className="main-cover home-main-cover">
          <div className={`inside-cover ${this.state.channelInfo.cover_image && "loader-cover" }`}>
            {this.state?.channelInfo?.cover_image ? 
              <img src={this.state?.channelInfo?.cover_image || CHANNEL_COVER_IMAGE} alt="noImage" />
            :
              <img src={BannerTribe} alt="noImage" />
            }
          </div>
        </div>
        {
          this.state.isPageLoading === true ?
          <div className={`${(!this?.context?.state?.currentUser) && "single-grid"} DashboardMainContent channel`}>
          
            <Sidebar currentUser={this.context.state.currentUser} currentPage="channels" selectedTab={this.state.selectedTab} showHideTabs={this.showHideTabs} />
            <div className="channel-inner-main">
              {this.context.state.currentUser && (

              <ChannelHeader channelInfo={this.state.channelInfo}
                currentUser={this.context.state.currentUser}
                toggleModal ={this.toggleModal}
                selectedTab={this.state.selectedTab}
                channelMembersLength= {this.state.channelMembersList.length}
                updateStep={this.updateStep}
                sectionId={this.state.sectionId}
                openJoinChannelModal={this.openJoinChannelModal}
              />
              )}

              {this.context?.state?.currentUser && (
                <Sidebar currentPage="channels"  showHideTabs={this.showHideTabs} />
              )}

              {
                ((this.checkIsAdmin(this.state.channelInfo?.adminList) || (this.context.state.userDetail && this.context.state.userDetail.email === 'shah@assemblyf.com') || (this.context.state.currentUser === this.state.channelInfo.channel_owner))) ?
                <Tabs
                  TabList={ChannelTabList}
                  showHideTabs={this.showHideTabs}
                  MainTitle="Tribe Admin"
                  selectedTab= {this.state.selectedTab}
                  channelID={this.state.channelId}
                /> : ""
              }
              {this.context.state.currentUser && (
                <>
              {
                this.state.channelInfo && ((this.state.selectedTab === "people" || this.state.selectedTab === "community") || this.state.selectedTab === "groupChat") &&
                <Community
                    history={this.props.history}
                    isChannelPage={true}
                    updateChannelInfo={this.updateChannelInfo}
                    channelID={this.state.channelId}
                    channelInfo={this.state.channelInfo}
                    checkIsAdmin={this.checkIsAdmin}
                />
              }
              </>
              )}

              {(!this.context.state.currentUser) &&
              <>
                {this.state.channelInfo && (
                  <div className="logged-out-div">
                    <h1 className="logged-out-heading">{this.state.channelInfo?.caption} </h1>
                    <div className="button-div">
                        {!this.context.state.currentUser ? 
                          <button className="register" onClick={()=> this.closeModal()}>Register</button> 
                          : 
                          <button className="register" onClick={() => this.setState({ showChannelModal: true })}>Register</button>} 
                        {
                          this.state.channelInfo?.priceType === "paid" &&
                          <span>${this.state.channelInfo.amount}/{this.state.channelInfo.subscription}</span>
                        }
                    </div>
                    {
                        this.state.channelInfo?.whyJoin &&
                        <div className="list">
                          <h3 className="font-changed">Why join?</h3>
                          <ul>
                            {
                              this.state.channelInfo?.whyJoin.map((el) =>{
                              return <li><span></span>{this.getObjectValue(el)}</li>
                              })
                            }
                          </ul>
                        </div>
                      }

                  </div>
                  
                  )}
                  {
                    this.isShowPeople("speakers") &&
                    <Speakers selectedIndex={this.state.selectedIndex} editChannelInfo={this.state.editChannelInfo} updateChannelInfo={this.updateChannelInfo} channelInfo={this.state.channelInfo} speakers={this.state.channelInfo.speakers} removeSpeaker={this.removeSpeaker} editSpeaker={this.editSpeaker} stepId={this.state.stepId} />
                  }
                  
                
                </>
              }
              
              {
                this.state.selectedTab === "speakers" &&
                <Speakers selectedIndex={this.state.selectedIndex} editChannelInfo={this.state.editChannelInfo} updateChannelInfo={this.updateChannelInfo} channelInfo={this.state.channelInfo} speakers={this.state.channelInfo.speakers} removeSpeaker={this.removeSpeaker} editSpeaker={this.editSpeaker} />
              }
              {
                this.state.selectedTab === "partners" &&
                <PartnersComp partners={this.state.channelInfo.partners} removePartner={this.removePartner} editPartner={this.editPartner} />
              }
              {
                this.state.selectedTab === "agenda" &&
                <AgendaComp eventsList={this.state.channelInfo.events.filter(el=> { return new Date() < new Date(el.event_start_date_time) })} removeEvents={this.removeEvents} channelInfo={this.state.channelInfo} currentUser={this.context.state.currentUser}  />
              }
              {
                (this.context.state.userDetail && this.context.state.userDetail.email === 'shah@assemblyf.com') || (this.context.state.currentUser === this.state.channelInfo.channel_owner) ?
                <div className="channel-forms">
                  {this.state.selectedTab === "partner" && (
                    <AddPartner selectedIndex={this.state.selectedIndex} editChannelInfo={this.state.editChannelInfo} updateChannelInfo={this.updateChannelInfo} partnersList={this.state.channelInfo.partners} />
                  )}
                  {this.state.selectedTab === "event" && (
                    <CreateLiveEvent showSuccessMessage={showSuccessMessage} close="" channelId={this.state.channelId} channelInfo={this.state.channelInfo} eventsInfo={this.state.channelInfo.events} updateChannelInfo={this.updateChannelInfo}/>
                  )}
                  {this.state.selectedTab === "speaker" && (
                    <AddSpeakers selectedIndex={this.state.selectedIndex} editChannelInfo={this.state.editChannelInfo} updateChannelInfo={this.updateChannelInfo} speakersList={this.state.channelInfo.speakers} />
                  )}
                  {this.state.selectedTab === "editChannel" && (
                    <EditChannel channelId={this.state.channelId} channelInfo={this.state.channelInfo} updateChannelInfo={this.updateChannelInfo} />
                  )}
                  {this.state.selectedTab === "addAdmin" && (
                    <AddAdmin adminList={this.state.channelInfo?.adminList ? this.state.channelInfo?.adminList : []} showSuccessMessage={showSuccessMessage} updateChannelInfo={this.updateChannelInfo}/>
                  )}
                  {this.state.selectedTab === "editChannelCode" && (
                    <EditChannelCode channel_code_number={this.state.channelInfo.channel_code_number} updateChannelInfo={this.updateChannelInfo} />
                  )}
                </div>
                : ""
              }
              
            </div>
            <ChatBox 
                props={this.props} 
                selectedTab={this.state.selectedTab} 
                membersList={this.state.channelMembersList} 
                channelInfoId={this.state.channelId} 
                ownerId={this.state.channelInfo.channel_owner}
                userInfo={this.context.state.userDetail}
                showHideTabs={this.showHideTabs}
                currentUser={this.context.state.currentUser}
                removeMember={this.removeMember}
                channelInfo={this.state.channelInfo}
            />
            <RightSidebar currentUser={this.context.state.currentUser}  noIcon={true} showHideTabs={this.showHideTabs}  />
            {this.state.showChannelModal && (
            <JoinChannelModal
              historyProps=""
              requiredCode={this.state.channelInfo.channel_code_number}
              updateChannelInfo={this.updateChannelInfo}
              memberList={this.state.channelInfo.members} 
            />
          )}
          </div>
  : ''}
      </div>
    );
  }
}

export default Channel;