import { app } from "./firebase-config";
import firebase from 'firebase';
import { getUserDetail } from "./Users";
const db = app.firestore()

export const REACTNOTIFICATION = "ReactNotifications"
export const COMMENTNOTIFICATION = "CommentsNotifications"
export const TribePostNotifications = "TribePostNotifications"


// export const 

export const setIsSeenTrue = async (userId,postId,type)=>{
  console.log(userId,postId,type)
  let resp
  if(type === REACTNOTIFICATION){
    resp = await db.collection("users").doc(userId).collection(REACTNOTIFICATION).doc(postId).update({
      isSeen: true
    })
  }else if(type === COMMENTNOTIFICATION){
    resp = await db.collection("users").doc(userId).collection(REACTNOTIFICATION).doc(postId).update({
      isSeen: true
    })
  }
  console.log(resp)
  return true
}


export const setAllNotificationSeenTrue = async(userId)=>{
  await db.collection("users").doc(userId).collection(REACTNOTIFICATION).get().then(function(querySnapshot) {
    querySnapshot.forEach(function(doc) {
      doc.ref.update({
        isSeen: true
      });
    });
  });

  await db.collection("users").doc(userId).collection(COMMENTNOTIFICATION).get().then(function(querySnapshot) {
    querySnapshot.forEach(function(doc) {
      doc.ref.update({
        isSeen: true
      });
    });
  });

  await db.collection("users").doc(userId).collection(TribePostNotifications).get().then(function(querySnapshot) {
    querySnapshot.forEach(function(doc) {
      doc.ref.update({
        isSeen: true
      });
    });
  });
}

export const checkReactIsExists = async (postUserId, postId, id)=>{
    let isExists =  await db.collection("users").doc(postUserId).collection(REACTNOTIFICATION).where("reactorId", "==", id).where("postId", "==" , postId ).get()
    return isExists
}

export const addNotification = async (postUserId, data) =>{
    let resp =  await db.collection("users").doc(postUserId).collection(REACTNOTIFICATION).add(data);
    return resp
}

export const getTheNotificationDataByUserId = async (userId, postId, hostId, hostName) =>{
    let userData = await getUserDetail(userId)
    let reactorName = userData.name
    let date = firebase.firestore.Timestamp.now().seconds * 1000

    let data = {
        reactorId : userId,
        reactorName,
        date,
        isSeen:false,
        postId,
        hostId,
        hostName
    }
    return data
}

export const pushReactNotifications = async(reactionList, postUserId, postUserName, postId, userId) =>{
    if(reactionList?.length > 0){
      // Loop through the reaction list 
      for(const id of reactionList){
        
        // remove the host of the post from the array
        if(id !== postUserId){
          let data = await getTheNotificationDataByUserId(id, postId, postUserId, postUserName)

          let isExists = await checkReactIsExists(postUserId, postId, id)
          if(isExists.docs.length === 0){
            await addNotification(postUserId,data);
            console.log("host done")
          }
        }

        // Send notification to all the user who's like the post 
        if(id !== userId){
            // console.log(id,"user finding not equal to ",userId)
          let data = await getTheNotificationDataByUserId(userId, postId, postUserId, postUserName)

          for(const _user of reactionList){
            if(_user !== id){
              let isExists = await checkReactIsExists(id,postId,userId)
              if(isExists.docs.length === 0){
                await addNotification(id, data);
                console.log(_user,"User done",id)
              }
            }
          }

        }
      }
    }
}

