import React, { Component } from 'react'
import '../Assets/Style/postbox.css'
import ImageComponent from './ImageComponent'
import {AuthContext} from '../Firebase/AuthProvider'
import { FIELDS } from '../Firebase/Constants'
import { postBoxConstants } from '../Helper/StaticContent'
import { CreatePost } from '../Firebase/Events'
import { uploadEventImage } from '../Firebase/StorageBucket'
import { searchUserByName } from "../Firebase/Users";
import  axios from 'axios';


class PostBoxModal extends Component {
    static contextType = AuthContext
    constructor(props){
      super(props)
      this.state={
        [FIELDS.WALL_POST.POST_USER_ID] :'',
        [FIELDS.WALL_POST.POST_MESSAGE] :'',
        [FIELDS.WALL_POST.POST_REACT] : [],
        [FIELDS.WALL_POST.POST_IMAGE_URL] :[],
        [FIELDS.WALL_POST.POST_VIDEO_URL] : [],
        post_image: '',
        post_image_url_preview: false,
        disablePostBtn: false,
        errorText:false,
        pagePreview: false,
        pagePreviewLoader: false,
        isLoading: false,
        postMessage: "",
        left:false,
        top: false,
        tagUsers:[],
        tagUsersList: [],
        mentionUsers: [],
        isTagUser: false
      }
    }

    componentDidMount(){
      if(!this.state[FIELDS.WALL_POST.POST_USER_ID]){
        this.setState({
          [FIELDS.WALL_POST.POST_USER_ID]:this.context.state.currentUser
        })
      }
    }

    onChangeHandler = async (e) =>{
      let placeholderDiv = document.querySelector("#placeholderText")
      let textarea = e.target
      let text = e.target.value
      let youtubeLinks = [];
      // eslint-disable-next-line no-mixed-operators
      if(text.length > 0 <= 3000){
        placeholderDiv.style.display = "none"
        textarea.style.position = "relative"
        textarea.style.top = "unset"
        textarea.style.left = "unset"
        textarea.style.paddingTop = "0"
        // extract url from text 
        // eslint-disable-next-line no-useless-escape
        let urls = text.match(/((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9\;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\)\,\_])|(?:\%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi);
        // remove identical/same urls
        if(urls && urls.length > 0){
          var uniqueUrls = urls.filter((item,index)=>urls.indexOf(item) === index)
          uniqueUrls = uniqueUrls.filter(item=>((/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/)).test(item) !== 1)
        }
        // eslint-disable-next-line no-useless-escape
        let youtubeID = text.match(/(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/);
        if(youtubeID){
          let youtubeUrl = `https://www.youtube.com/embed/${youtubeID[1]}`
          youtubeLinks.push(youtubeUrl)
        }
        if(youtubeLinks && youtubeLinks.length > 0){
          var uniqueYoutubeLinks = youtubeLinks.filter(function(item, pos) {
            return youtubeLinks.indexOf(item) === pos;
          })
        }

        if(uniqueUrls && uniqueUrls.length > 0){
          this.getPageMetaTags(uniqueUrls[0])
        }


        text = text.replace(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/,'')
        this.setState({
          [FIELDS.WALL_POST.POST_MESSAGE]:text,
          [FIELDS.WALL_POST.POST_VIDEO_URL]:uniqueYoutubeLinks,
          disablePostBtn:false,
          errorText:false,
          postMessage: text
        })
      }
      
      if(text === "\n"){
        placeholderDiv.style.display = "block"
        textarea.style.position = "absolute"
        textarea.style.top = "0"
        textarea.style.left = "0"
        let span = document.createElement("SPAN");
        span.classList.add("pbm-message-content")
        span.setAttribute("contenteditable","inherit")
        textarea.appendChild(span)
        this.setState({
          [FIELDS.WALL_POST.POST_MESSAGE]:"",
          disablePostBtn: true,
          postMessage: ""
        })
      }

      // search User if character length is greater than equal to 3
    let breakWord = e.target.value.split(" ")
    if(breakWord[breakWord.length -1]?.includes("@")){
      if(breakWord[breakWord.length - 1]?.length >= 4){
        let users = await searchUserByName(breakWord[breakWord.length - 1])
        this.setState({tagUsers:users,[`tagBox`]:true, isTagUser: true})
      }else{
        Object.keys(this.state).forEach(e=>{
          if(e.includes("tagBox")){
            this.setState({[e]:false})
          }
        })
        this.setState({tagUsers:[]})
      }
    }
    }

    getPageMetaTags = async (link)=>{
      link = `https://assemblyfpost.herokuapp.com/Post?link=${link}`
      this.setState({
        pagePreviewLoader:true
      },async()=>{
          await axios.get(link,
        {
          headers: {
            'Content-Type': 'application/json'
          } 
        }).then(response => {
          this.setState({
            pagePreviewLoader:false,
            pagePreview:response.data
          })
        }).catch(err=>{
          console.log(err)
        })
      })
      
    } 

    onTagUserClick = async (e,user) =>{
      let tagBox = "tagBox"
      let tagName = "@"+e.target.dataset.value
      let mess = this.state.postMessage.split(" ").slice(0, -1).join(" ");
      let id = user.id
      let _tagUsersList = [...this.state.tagUsersList]
      if(!_tagUsersList.includes(id)){
        _tagUsersList.push(id)
      }
      let newMentions = [...this.state.mentionUsers];
      newMentions.push(tagName)
      console.log(_tagUsersList)
      this.setState({postMessage:mess+" "+tagName,[tagBox]:false,tagUsersList:_tagUsersList, mentionUsers: newMentions, isTagUser: false})
    }

    stopEditing = (event) =>{
      let settings = {
        maxLen: 500
      };
      
      let keys = {
        backspace: 8,
        shift: 16,
        ctrl: 17,
        alt: 18,
        delete: 46,
        leftArrow: 37,
        upArrow: 38,
        rightArrow: 39,
        downArrow: 40
      };
      
      let utils = {
        special: {},
        navigational: {},
        isSpecial(e) {
          return typeof this.special[e.keyCode] !== "undefined";
        },
        isNavigational(e) {
          return typeof this.navigational[e.keyCode] !== "undefined";
        }
      };
      
      utils.special[keys["backspace"]] = true;
      utils.special[keys["shift"]] = true;
      utils.special[keys["ctrl"]] = true;
      utils.special[keys["alt"]] = true;
      utils.special[keys["delete"]] = true;
      
      utils.navigational[keys["upArrow"]] = true;
      utils.navigational[keys["downArrow"]] = true;
      utils.navigational[keys["leftArrow"]] = true;
      utils.navigational[keys["rightArrow"]] = true;
      
      let len = event.target.innerText.length;
      let hasSelection = false;
      let selection = window.getSelection();
      let isSpecial = utils.isSpecial(event);
      let isNavigational = utils.isNavigational(event);

      if (selection) {
        hasSelection = !!selection.toString();
      }

      if (isSpecial || isNavigational) {
        return true;
      }

      if (len >= settings.maxLen && !hasSelection ) {
        event.preventDefault();
        return false;
      }
    }

    onPasteText = (e) =>{
      // eslint-disable-next-line no-unused-vars
      let parentSpan = e.target
    }

    onPostBtnClick = async () =>{
      this.context.showLoader(true)
      let data = {
        [FIELDS.WALL_POST.POST_USER_ID] : this.state[FIELDS.WALL_POST.POST_USER_ID],
        [FIELDS.WALL_POST.POST_MESSAGE] : this.state[FIELDS.WALL_POST.POST_MESSAGE],
        [FIELDS.WALL_POST.POST_REACT] : this.state[FIELDS.WALL_POST.POST_REACT],
        [FIELDS.WALL_POST.POST_IMAGE_URL] : this.state[FIELDS.WALL_POST.POST_IMAGE_URL],
        [FIELDS.WALL_POST.POST_VIDEO_URL] : this.state[FIELDS.WALL_POST.POST_VIDEO_URL],
        [FIELDS.WALL_POST.POST_USER_DETAIL] : '',
        [FIELDS.WALL_POST.POST_PAGE_METATAGS] : [this.state.pagePreview]
      }

      if(this.state[FIELDS.WALL_POST.POST_MESSAGE]?.length > 500){
        this.setState({
          errorText: postBoxConstants.postMessageLengthError
        },()=>{
          this.context.showLoader(false)
        })
        return
      }

      if(this.state.post_image && !(/(jpe?g|png|gif|bmp|jfif|pjp|pjpeg)$/i.test(this.state.post_image.type.split('/')[1]))){
        this.setState({
          errorText:postBoxConstants.postImageUnknowError
        },()=>{
          this.context.showLoader(false)
        })
        return
      }
      if(this.state.post_image && this.state.post_image_url_preview){
        let now = new Date();
        let uniqueId = now.getTime();

        // upload image to database and get image Url
        let post_image_url = await uploadEventImage(this.state.post_image,`images/wall_post_image/${uniqueId}${this.state.post_image.name}`)
        
        // checking this image url is already in state if not then push it into state
        let image_url = [...this.state.post_image_url]
        if(!image_url.includes(post_image_url)){
          image_url.push(post_image_url)
        }
        this.setState({
          [FIELDS.WALL_POST.POST_IMAGE_URL] : image_url
        },()=>{
          data[FIELDS.WALL_POST.POST_IMAGE_URL] = this.state[FIELDS.WALL_POST.POST_IMAGE_URL]
        })
      }
      if(this.props.channelID) {
        let userIds = [];
        userIds.push(this.state[FIELDS.WALL_POST.POST_USER_ID])
        userIds.push(this.props.channelID)
        data[FIELDS.WALL_POST.POST_USER_PROFILE_ID] = userIds
        data[FIELDS.WALL_POST.POST_CHANNEL_ID] = this.props.channelID
        data[FIELDS.WALL_POST.POST_CHANNEL_NAME] = this.props.channelName
      }
      if(this.props.isProfiePage) {
        let userIds = [];
        userIds.push(this.state[FIELDS.WALL_POST.POST_USER_ID])
        userIds.push(this.props.userDetail.id)
        data[FIELDS.WALL_POST.POST_USER_PROFILE_ID] = userIds
        data[FIELDS.WALL_POST.POST_USER_PROFILE_NAME] = this.props.userDetail.name
        data[FIELDS.WALL_POST.POST_USER_PROFILEID] = this.props.userDetail.id
      }
      else if(!this.props.channelID){
        let userIds = [];
        userIds.push(this.state[FIELDS.WALL_POST.POST_USER_ID])
        data[FIELDS.WALL_POST.POST_USER_PROFILE_ID] = userIds
      }
      this.setState({ isLoading: true })

      // tag users replace with href
      let commentText = this.state.postMessage.trim()
      this.state.mentionUsers.map((el, key) => {
        if(commentText.includes(el)) {
          data[FIELDS.WALL_POST.POST_MESSAGE] = commentText.replace(el, `<a className="userTagged" href="/profile/${this.state.tagUsersList[key]}"><b>${el.replace("@", "")}</b></a>`);
        }
      })

      await CreatePost(data).then(()=>{
        this.setState({
          message:''
        },()=>{
          let span = document.querySelectorAll(".pbm-message-content")
          for(const a of span){
            a.innerText = ""
          }
          this.props.closeModal()
          this.context.showLoader(false)
          if(!this.props.isProfiePage) {
           this.props.realTimePostUpdate('','', 'add');
          }
        })
      })
    }

    onImageHandler = (e) =>{
      const image = e.target.files[0];
      const name = e.target.name

      this.setState({
        [name+"_preview"]:URL.createObjectURL(image),
        post_image: image
      })
    }

    onImageRemove = () =>{
      this.setState({
        post_image_url_preview:false,
        post_image:""
      })
    }


  render() {
    return (
      <div className="post-box-modal-outer">
        <div className="pbm-inner" onClick={e=>e.stopPropagation()}>
            <div className="pbm-header">
                <h3 className="h3-generic">Create Post</h3>
                <span className="pbm-close-btn" onClick={()=>this.props.closeModal()}><span>&#43;</span></span>
            </div>
            <div className="pbm-main-content-section">
                <div className="pbm-profile-image-name">
                    <div className="pbm-profile-image">
                        <ImageComponent image={this.context?.state?.userDetail?.[FIELDS.USER.USER_IMAGE]}  className="pbm-user-image" />
                    </div>
                    <div className="pbm-profile-name">
                        <h4 className="h4-generic">{this.context?.state?.userDetail?.[FIELDS.USER.USER_NAME]}</h4>
                    </div>
                    {this.state[FIELDS.WALL_POST.POST_MESSAGE]?.length >=349 && (
                      <span className="word-counter">{this.state[FIELDS.WALL_POST.POST_MESSAGE]?.length}/500</span>
                    )}
                </div>
                <div className="pbm-textarea">
                  <div id="placeholderText" className="pbm-placeholder-text">
                  
                  </div>
                  <textarea className="pbm-content-editable" placeholder={
                    this.context.state.userDetail[FIELDS.USER.USER_NAME] ? this.props.channelID ? 
                    "Write to the community..." : this.props.userDetail && this.props.userDetail?.id !== this.context.state.currentUser ? "Write to "+ this.props.userProfileName?.split(
                      " "
                    )[0]+" ..." : postBoxConstants.placeholderText + this.props.userProfileName?.split(
                      " "
                    )[0]+ ' ?'
                  : "What did you learn today ?"} value={this.state.postMessage} onPaste={e=>{this.onPasteText(e)}} onKeyDown={e=>this.stopEditing(e)} onInput={e=>this.onChangeHandler(e)} >
                  </textarea>
                </div>
                <div className="pcui-cmnt-sec">
                  <div className="pcui-cmnt-inpt">
                  <ul style={{"top":this.state.top-90,"left":this.state.left+32,display:`${this.state.isTagUser ? 'block' : 'none' }`}} id={`taggingBox`} className={`tag-box`}>
                        {// eslint-disable-next-line array-callback-return
                        this.state.tagUsers.map((el,index)=>{
                            if(el.id !== this.context.state?.currentUser && !this.state?.tagUsersList?.includes(el.id))
                            return(
                                <li id={`dropdown${index}`} onClick={(e)=>{this.onTagUserClick(e,el)}} data-value={el.name} data-email={el.email} key={index}>{el.name}</li>
                            )
                        })}
                    </ul>
                  </div>
                </div>
                
                {this.state.pagePreview &&  (
                    <div className="pbm-live-preview">
                      <span onClick={()=>{this.setState({pagePreview:false})}} className="pbm-close-btn"><span>&#43;</span></span>
                      <a target="_blank" rel="noopener noreferrer" href={this.state.pagePreview.url}>
                        <div className="pbm-lp-image">
                        <ImageComponent image={this.state.pagePreview.image} />
                      </div>
                        <div className="pbm-lp-content">
                        <div className="pbm-lp-title">
                          <h4 className="h4-generic">{this.state.pagePreview.title}</h4>
                        </div>
                        <div className="pbm-lp-desc">
                          <p className="lp-desc">{this.state.pagePreview.description}</p>
                        </div>
                        <div className="pbm-lp-url">
                          <p className="lp-url">{this.state.pagePreview.url}</p>
                        </div>
                      </div>
                      </a>
                    </div>
                )}
                  
                {this.state.pagePreviewLoader && (
                  <div className="show-page-preview-loader">
                  <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                  </div>
                )}
                

                {this.state.post_image_url_preview &&  (
                  <div className="pbm-media-preview">
                    <div className="pbm-preview">
                      <span className="image-delete" onClick={()=>{this.onImageRemove()}}>
                        <span>&#43;</span>
                      </span>
                      <ImageComponent image={this.state.post_image_url_preview} />
                    </div>
                  </div>
                )}
                
                <div className="pbm-attachments">
                  <h4 className="h4-generic">Add to your post</h4>
                  <div className="pbm-attachments-icons">
                    {/* <div className="pbm-icon">
                      <span className="link_icon"></span>
                    </div> */}
  
                    {/* <div className="pbm-icon">
                      <span className="tag_icon"></span>
                    </div> */}
  
                    <div className="pbm-icon">
                      <label htmlFor="post_image"></label>
                      <input id="post_image" name="post_image_url" value="" accept="image/x-png,image/gif,image/jpeg" onChange={(e)=>{this.onImageHandler(e)}} type="file"/>
                      <span className="image_icon"></span>
                    </div>
                    
                    {/* <div className="pbm-icon">
                      <span className="emoticon_icon"></span>
                    </div> */}
                  </div>
                </div>
                {this.state.errorText && (
                  <span className="text-red">{this.state.errorText}</span>
                )}
                {
                  !this.state.isLoading &&
                  <button onClick={()=>{this.onPostBtnClick()}} disabled={this.state.disablePostBtn} className={`pbm-post-btn ${this.state[FIELDS.WALL_POST.POST_MESSAGE].length === 0 && "btn-disabled"}`}>Post</button>
                }
            </div>
        </div>
      </div>
    )
  }
}

export default PostBoxModal
