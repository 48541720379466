import { app } from "./firebase-config";
import { withEvent, withTestimonial, withChat, withPost, withComments } from "./Schemas";
import { FIELDS } from "./Constants";
import { getUserDetail } from "./Users";
import firebase from 'firebase';
import { decamelize } from "humps";
const ical = require("ical-generator");
const db = app.firestore();
const base = "https://us-central1-assembly-f-live.cloudfunctions.net/";


// get all events List
export const getEvents = async (event, arg) => {
  let currentDate = new Date();
  let dateToNum = currentDate.getTime();
  const events = [];

  if (arg) {
    const result = await db
      .collection(event)
      .where(FIELDS.EVENT.EVENT_IS_APPROVED, "==", false)
      .where(FIELDS.EVENT.EVENT_DATE_TIMESTAMP, ">=", dateToNum)
      .where(FIELDS.EVENT.EVENT_IS_DELETE, "==", false)
      .orderBy(FIELDS.EVENT.EVENT_DATE_TIMESTAMP, "asc")
      .get()
      .then((querySnapshot) => {
        return querySnapshot.docs;
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });

    for (const doc of result) {
      const event = await withUser(doc.data());
      event["uid"] = doc.id;
      events.push(event);
    }
    return events;
  } else {
    const result = await db
      .collection(event)
      .where(FIELDS.EVENT.EVENT_DATE_TIMESTAMP, ">=", dateToNum)
      .where(FIELDS.EVENT.EVENT_IS_DELETE, "==", false)
      .orderBy(FIELDS.EVENT.EVENT_DATE_TIMESTAMP, "asc")
      .get()
      .then((querySnapshot) => {
        return querySnapshot.docs;
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
    
    for (const doc of result) {
      const event = await withUser(doc.data());
      event["uid"] = doc.id;
      events.push(event);
    }
    return events;
  }
};

export const updateTimeStampEvent = async () => {
  const result = await db.collection("events").get();
  for (const doc of result.docs) {
    let data = doc.data();
    if (!data[FIELDS.EVENT.EVENT_DATE_TIMESTAMP]) {
      let eventDate = new Date(data[FIELDS.EVENT.EVENT_START_DATE]).getTime();
      await db
        .collection("events")
        .doc(doc.id)
        .set(
          {
            [FIELDS.EVENT.EVENT_DATE_TIMESTAMP]: eventDate,
          },
          { merge: true }
        );
    } else {
      return;
    }
  }
};

export const getUserAttendingEvents = async (collection, uid) => {
  let currentDate = new Date();
  let dateToNum = currentDate.getTime();
  const events = [];
  var result = await db
    .collection(collection)
    .where(FIELDS.EVENT.EVENT_GOING, "array-contains", uid)
    .where(FIELDS.EVENT.EVENT_DATE_TIMESTAMP, ">=", dateToNum)
    .where(FIELDS.EVENT.EVENT_IS_DELETE, "==", false)
    .orderBy(FIELDS.EVENT.EVENT_DATE_TIMESTAMP, "asc")
    .get()
    .then((querySnapshot) => {
      return querySnapshot.docs;
    })
    .catch((err) => {
      console.log(err);
    });

  if (result.length > 0) {
    for (const doc of result) {
      const event = await withUser(doc.data());
      event["uid"] = doc.id;
      events.push(event);
    }
  }

  return events;
};

// get event detail of specic event by event ID
export const getSpecificEventData = async (collection, uid) => {
  const event = [];
  var docRef = await db.collection(collection).doc(uid).get();
  var data = docRef.data();
  data = await withUser(data);
  data["uid"] = docRef.id;
  event.push(data);
  return event;
};

//Update single event going array by event ID and provide new going array
export const updateEventGoingSeat = async (uid, going) => {
  var update = await db.collection("events").doc(uid).update({
    event_going: going,
  });
  return update;
};

//Update single event going array by event ID and provide new going array
export const updateSpecificEvent = async (event, uid, key, value) => {
  var update = await db
    .collection(event)
    .doc(uid)
    .update({
      [key]: value,
    });
  return update;
};

export const getIcsByEventId = async (id) => {
  const doc = await db.collection("invites").doc(id).get();
  return doc.data();
};

export const updateIcsByEventId = async (id, data) => {
  const doc = await db.collection("invites").doc(id).set(data);
  return doc;
};

export const shallowIcs = async (data) => {
  const cal = ical(data);
  return cal;
};

export const uploadIcs = (data, event_id) => {
  const storage = app.storage();
  return new Promise(function (resolve, reject) {
    const storageRef = storage.ref("/ics/" + event_id + ".ics");
    const uploadTask = storageRef.putString(data);
    uploadTask.on(
      "state_changed",
      function (snapshot) {
        //var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // console.log('Upload is ' + progress + '% done')
      },
      function error(err) {
        reject();
      },
      function complete() {
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          resolve(downloadURL);
        });
      }
    );
  });
};

export const addAttendeInIcs = async (user_name, user_email, event_id) => {
  const icsData = await getIcsByEventId(event_id);
  const ics = await shallowIcs(icsData);
  const event = ics.events().pop();
  event.createAttendee({ email: user_email, name: user_name });
  ics.events().push(event);
  await updateIcsByEventId(event_id, ics.toJSON());
  uploadIcs(ics.toString(), event_id);
};

export const createEvent = async (data) => {
  var docRef = await db.collection("events").add(withEvent(data));
  return docRef;
};

export const updateEvent = async (id, data) => {
  return await db.collection("wall_posts").doc(id).update(data);
};

export const withUser = async (data) => {
  const user = await getUserDetail(data[FIELDS.EVENT.EVENT_HOST_ID]);
  data[FIELDS.EVENT.EVENT_HOST_DETAIL] = user;
  return withEvent(data);
};

export const userEventGoingUser = async (data) => {
  const goinUser = await Promise.all(
    data[FIELDS.EVENT.EVENT_GOING].map(async (id) => {
      let detail = await getUserDetail(id);
      return detail;
    })
  );
  data[FIELDS.EVENT.EVENT_GOING_USER_DETAILS] = goinUser;
  return data;
};

export const getEventsByHost = async (collection, id, query) => {
  let currentDate = new Date();
  let dateToNum = currentDate.getTime();
  const eventsByHost = [];

  var user = db.collection(collection);

  if (query === "showEventsHostedByUser") {
    user = user.where(FIELDS.EVENT.EVENT_HOST_ID, "==", id);
    user = user.where(FIELDS.EVENT.EVENT_DATE_TIMESTAMP, ">=", dateToNum);
    user = user.where(FIELDS.EVENT.EVENT_IS_DELETE, "==", false);
    user = user.orderBy(FIELDS.EVENT.EVENT_DATE_TIMESTAMP, "asc");
  }

  if (query === "showUnapprovedEvents") {
    user = user.where(FIELDS.EVENT.EVENT_HOST_ID, "==", id);
    user = user.where(FIELDS.EVENT.EVENT_IS_APPROVED, "==", false);
    user = user.where(FIELDS.EVENT.EVENT_IS_DELETE, "==", false);
    user = user.where(FIELDS.EVENT.EVENT_DATE_TIMESTAMP, ">=", dateToNum);
    user = user.orderBy(FIELDS.EVENT.EVENT_DATE_TIMESTAMP, "asc");
  }

  if (query === "showPastApprovedEvents") {
    user = user.where(FIELDS.EVENT.EVENT_HOST_ID, "==", id);
    user = user.where(FIELDS.EVENT.EVENT_IS_DELETE, "==", false);
    // user = user.where(FIELDS.EVENT.EVENT_IS_APPROVED, "==", true)
    user = user.where(FIELDS.EVENT.EVENT_DATE_TIMESTAMP, "<=", dateToNum);
    user = user.orderBy(FIELDS.EVENT.EVENT_DATE_TIMESTAMP, "asc");
  }

  user = await user
    .get()
    .then((querySnapshot) => {
      return querySnapshot;
    })
    .catch((err) => {
      console.log(err);
    });

  for (const doc of user.docs) {
    const event = await withUser(doc.data());
    event["uid"] = doc.id;
    eventsByHost.push(event);
  }
  return eventsByHost;
};

export const CreateChat = async (host_id,data) => {
  let date = firebase.firestore.Timestamp.now().seconds * 1000
  data[FIELDS.CHAT.CHAT_CREATED_AT] = date
  db.collection("users").doc(host_id).collection("group_chat").add(withChat(data));
};

export const CreateChannelChat = async (host_id,data) => {
  let date = firebase.firestore.Timestamp.now().seconds * 1000
  data[FIELDS.CHAT.CHAT_CREATED_AT] = date
  db.collection("channels").doc(host_id).collection("group_chat").add(withChat(data));
};

export const getCoverFeaturedImagesLink = async (id) =>{
    var getData = await db.collection("images").doc(id).get()
    return getData.data();

}

export const updateCoverFeaturedImagesLink = async (id,link,url) =>{
    var featuredEvent = await db.collection("images").doc(id).set({
        featuredLink: link,
        featuredImageUrl:url
    },{merge:true})
    return featuredEvent
}

export const createTestimonial = async (data) =>{
    const host_id = data.host_id
    // create new Collection and add testimonial to it 
    var docRef = await db.collection("testimonials").add(withTestimonial(data));
    data['id'] = docRef.id
    await db.collection("testimonials").doc(docRef.id).update(withTestimonial(data));


    // get the specific Host testimonials
    let hostDetails = await db.collection("users").doc(host_id).get().then(user=>{
        return user.data()
    }).catch(err=>{
        console.log(err)
    })

    // if event does not have testimonials then add new testimonial 
    if(!hostDetails.host_testimonials){
        let update = await db.collection("users").doc(host_id).set({
            [FIELDS.USER.HOST_TESTIMONIAL]: [withTestimonial(data)]
        },{merge:true})
        return update;
    }else{
        // if event has testimonials then apend new testimonial 
        let testimonials = [...hostDetails.host_testimonials]
        testimonials.push(withTestimonial(data))
        // update event testimonial with the newly append testimonial
        let update = await db.collection("users").doc(host_id).set({
            [FIELDS.USER.HOST_TESTIMONIAL]: testimonials
        },{merge:true})
        return update;
    }
}

export const getTestimonials = async (id) =>{
    let testimonials = []
    let docRef = await db.collection('events').doc(id).get()
    let data = await withUser(docRef.data())

    let testimonial = data[FIELDS.EVENT.EVENT_HOST_DETAIL][FIELDS.USER.HOST_TESTIMONIAL]

    for (const i of testimonial){
        testimonials.push(withTestimonial(i))
    }
    return testimonials
}

export const setApprovedEventTrue = async ()=>{
    const result = await db.collection("events").get();
    for (const doc of result.docs){
        let data = doc.data()
        if(!data[FIELDS.EVENT.EVENT_IS_APPROVED]){
            console.log("unapprove")
            await db.collection("events").doc(doc.id).set({
                [FIELDS.EVENT.EVENT_IS_APPROVED]:true
            },{merge:true})
        }else{
            console.log("approved")
        }
    }
}

export const CreatePost = async (data) => {
  let date = firebase.firestore.Timestamp.now().seconds * 1000
  data[FIELDS.WALL_POST.POST_CREATED_AT] = date
  data[FIELDS.WALL_POST.POST_SCORE] = 0
  let post = await WithUserDetail(data)
  await db.collection("wall_posts").add(post);
};

export const WithUserDetail = async (data)=>{
  const userDetail = await getUserDetail(data[FIELDS.WALL_POST.POST_USER_ID]);
  data[FIELDS.WALL_POST.POST_USER_DETAIL] = userDetail;
  return withPost(data);
}

export const addReaction = async (postId,userID) =>{
  let post = await db.collection("wall_posts").doc(postId).get()
  post = await WithUserDetail(post.data())
  let postReactionList = post?.[FIELDS.WALL_POST.POST_REACT]
  if(!postReactionList.includes(userID)){
    let reactionList = [...postReactionList]
    reactionList.push(userID)
    await db.collection("wall_posts").doc(postId).update({
      "post_react" : reactionList
    })
  }else{
    await db.collection("wall_posts").doc(postId).update({
      "post_react": firebase.firestore.FieldValue.arrayRemove(userID)
    })
  }
}

export const deletePost = async (postId) =>{
  await db.collection("wall_posts").doc(postId).update({
    [FIELDS.WALL_POST.POST_IS_DELETED]: true
  })
}

export const addComment = async (post_id,data) =>{
  let userDetail = await getUserDetail(data.user_id)
  let date = firebase.firestore.Timestamp.now().seconds * 1000
  data[FIELDS.WALL_POST_COMMENTS.POST_COMMENT_USER_DETAIL] = userDetail
  data[FIELDS.WALL_POST_COMMENTS.POST_COMMENT_CREATED_AT] = date
  data[FIELDS.WALL_POST_COMMENTS.POST_COMMENT_ISDELETED] = false
  let comments = db.collection("wall_posts").doc(post_id)
  await comments.update({
    [FIELDS.WALL_POST.POST_COMMENTS]:firebase.firestore.FieldValue.arrayUnion(withComments(data))
  })
}

export const deleteComment = async(post_id,data) =>{
  await db.collection("wall_posts").doc(post_id).update({
    [FIELDS.WALL_POST.POST_COMMENTS]: data
  })
}

export const getPostById = async (id)=>{
  let result = await db.collection("wall_posts").doc(id).get()
  if(result.exists){
    let data = result.data()
    withPost(data)
    data["id"] = result.id
    return data
  }
}

export const getEventById = async (id)=>{
  let result = await db.collection("events").doc(id).get()
  if(result.exists){
    let data = result.data()
    withEvent(data)
    data["id"] = result.id
    return data
  }
}

export const getLatestEvents = async ()=>{
  let currentDate = new Date();
  let dateToNum = currentDate.getTime();
  let events = [];
  const result = await db.collection('events')
  .where(FIELDS.EVENT.EVENT_DATE_TIMESTAMP, ">=", dateToNum)
      .where(FIELDS.EVENT.EVENT_IS_DELETE, "==", false)
      .orderBy(FIELDS.EVENT.EVENT_DATE_TIMESTAMP, "desc")
      .limit(5)
    .get();
    result.docs.map(async result => {
      let event = result.data();
      event.id = result.id;
      await events.push(event);
    }  )
    return events;
}

function jsonToUrlEncode(json) {
  return Object.keys(json)
    .map((k) => decamelize(encodeURIComponent(k)) + "=" + encodeURIComponent(json[k]))
    .join("&");
}

export const notifyAllAboutTribeEvent = async (eventId) => {
  const url = base + "notifyAllAboutTribeEvent?" + jsonToUrlEncode({ event_id: eventId });
  return await fetch(url, { method: "GET" });
};