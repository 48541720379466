import React, { Component } from "react";
import { AuthContext } from "../Firebase/AuthProvider";
import { FIELDS } from "../Firebase/Constants";
import "../Assets/Style/admin-style.css";
import {
  getUnApprovedUsers,
  approveUser,
  updateUser,
  getDisapproveUser,
  getUserDetail,
  updateUserFollower,
  updateUserFollowing,
  searchSpecificUser,
  setDisabledUser,
} from "../Firebase/Users";
import UserList from "./UserList";
import Header from "../components/header";
import { getSpecificEventData, updateEventGoingSeat, updateSpecificEvent } from "../Firebase/Events";
import { inviteUser } from "../Firebase/Mailer";
import Loader from "../components/Loader";
import EventGoingUsers from "./eventGoingUsers";
import SearchComponent from "../components/SearchComponent";
import UserModal from "../components/UserModal";

class Users extends Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = {
      showInviteModal: false,
      selectedEvent: null,
      email: "",
      users: [],
      UnApprovedUsers: [],
      signUpUser: [],
      approveUsers: [],
      showUsers: "inActiveUser",
      disApprovedUser: [],
      searchUser: [],
      searchName:'',
      showPromptModal:false,
      userID :""
    };
  }

  componentDidMount() {
    this.getUser();

    // updateUserKeywords();

    approveUser().then((users) => {
      this.setState({
        approveUsers: users,
      });
    });

    getDisapproveUser().then((data) => {
      this.setState({
        disApprovedUser: data,
      });
    });
  }

  onApproveUser = (uid, user) => {
    this.context.showLoader(true);
    updateUser(uid, [FIELDS.USER.USER_APPROVE], true, user[FIELDS.USER.USER_EMAIL], user[FIELDS.USER.USER_NAME]).then(() => {
      this.getUser();

      approveUser().then((users) => {
        this.setState({
          approveUsers: users,
        });
      });

      getDisapproveUser().then((data) => {
        this.setState({
          disApprovedUser: data,
        });
      });

      if (user[FIELDS.USER.USER_ASK_EVENT] !== false) {
        getSpecificEventData("events", user[FIELDS.USER.USER_ASK_EVENT]).then(async (res) => {
          const event = res[0];
          inviteUser(user[FIELDS.USER.USER_EMAIL], event, user[FIELDS.USER.USER_NAME]);
          const userList = [...event[FIELDS.EVENT.EVENT_GOING]];
          userList.push(uid);
          updateEventGoingSeat(event.uid, userList);

          // auto following host when reserving a seat to event
          let hostFollowersDetail = await getUserDetail(event[FIELDS.EVENT.EVENT_HOST_ID]).then((data) => data[FIELDS.USER.USER_FOLLOWERS]);
          let hostId = event[FIELDS.EVENT.EVENT_HOST_ID];
          if(!hostFollowersDetail.includes(uid) && uid !== hostId ){
            let userFollowingList = [...user[FIELDS.USER.USER_FOLLOWING]]
            userFollowingList.push(hostId)
            // update user Following list 
            await updateUserFollowing(uid,userFollowingList)

            hostFollowersDetail.push(uid);
            // update Host follower list
            await updateUserFollower(hostId, hostFollowersDetail);

            let hostDetailUpdate = await getUserDetail(hostId);
            await updateSpecificEvent("events", event.uid, [FIELDS.EVENT.EVENT_HOST_DETAIL], hostDetailUpdate);
          }
        });
      }
      this.context.showLoader(false);
    });
  };

  onFilterChange = (e) => {
    this.setState({
      showUsers: e.target.id,
    });
  };

  getUser = async () => {
    getUnApprovedUsers().then((users) => {
      let ask = users.filter((el) => {
        return el[FIELDS.USER.USER_ASK_EVENT] !== false && el[FIELDS.USER.USER_DISAPPROVE] === false;
      });
      let normal = users.filter((el) => {
        // eslint-disable-next-line
        return el[FIELDS.USER.USER_ASK_EVENT] == false && el[FIELDS.USER.USER_DISAPPROVE] === false;
      });

      this.setState({
        UnApprovedUsers: normal.reverse(),
        signUpUser: ask.reverse(),
      });
    });
  };

  onDisapproveUser = async (uid) => {
    this.context.showLoader(true);
    updateUser(uid, [FIELDS.USER.USER_DISAPPROVE], true)
      .then(() => {
        this.getUser();

        getDisapproveUser()
          .then((data) => {
            this.setState(
              {
                disApprovedUser: data,
              },
              () => {
                this.context.showLoader(false);
              }
            );
          })
          .catch((err) => {
            if (err) {
              this.context.showLoader(false);
            }
          });
      })
      .catch((err) => {
        if (err) {
          this.context.showLoader(false);
        }
      });
  };

  onHostChange = async (id, value, isSearchUser) => {
    this.context.showLoader(true);
    await updateUser(id, [FIELDS.USER.USER_IS_HOST], !value).then(async() => {
      // if search user change host
      if(isSearchUser){
        await this.onSearchClick(this.state.searchName)
      }else{
        approveUser().then((users) => {
          this.setState({
            approveUsers: users,
          });
          this.context.showLoader(false);
        })
        .catch((err) => {
          if (err) {
            this.context.showLoader(false);
          }
        });
      }
    })
    .catch((err) => {
      if (err) {
        this.context.showLoader(false);
      }
    });
  };

  onLoadMoreClick = (loadUser) => {
    this.context.showLoader(true);
    if (loadUser === "approvedUsers") {
      approveUser(this.state.approveUsers)
        .then((users) => {
          if (users.length === 0) {
            this.context.showLoader(false);
            return;
          }
          let updateUser = [...this.state.approveUsers];
          for (const user of users) {
            updateUser.push(user);
          }
          this.setState(
            {
              approveUsers: updateUser,
            },
            () => {
              this.context.showLoader(false);
            }
          );
        })
        .catch((err) => {
          if (err) this.context.showLoader(false);
        });
    } else if (loadUser === "disapprovedUsers") {
      getDisapproveUser(this.state.disApprovedUser)
        .then((users) => {
          if (users.length === 0) {
            this.context.showLoader(false);
            return;
          }
          let updateUser = [...this.state.disApprovedUser];
          for (const user of users) {
            updateUser.push(user);
          }
          this.setState(
            {
              disApprovedUser: updateUser,
            },
            () => {
              this.context.showLoader(false);
            }
          );
        })
        .catch((err) => {
          if (err) console.log(err);
          this.context.showLoader(false);
        });
    }
  };

  onSearchClick = async (name) => {
    this.context.showLoader(true)
    await searchSpecificUser(name.toLowerCase()).then((users) => {
      if(users.length > 0){
        this.setState({
            searchUser: users,
            searchName:name
          },()=>{
            this.context.showLoader(false)
        });
      }else{
        this.setState({
          searchUser:"No result found!"
        })
        this.context.showLoader(false)
      }
    });
  };

  onCancelSearchClick = async ()=>{
    this.setState({
      searchUser:[],
      searchName:''
    });
  }

  toggleModal = () =>{
    this.setState({
      showPromptModal:!this.state.showPromptModal
    })
  }

  getUserId = (id) =>{
    this.setState({
      userID:id
    })
  }

  onDisableUserClick = async()=>{
    this.context.showLoader(true)
    let users = await setDisabledUser(this.state.userID)

    if(users){
      this.toggleModal()
        if(this.state.searchName.length > 0){
          await this.onSearchClick(this.state.searchName)
        }else{
          approveUser().then((approveUsers) => {
            this.setState({
              approveUsers: approveUsers,
            });
          });
        }
      this.context.showLoader(false)
    }
  }


  render() {
    return (
      <React.Fragment>
        <Loader />
        <Header admin="true" history={this.props.history} />
        <div className="event-list-component users-height">
          <div className="user-list-toggle">
            <span className="user-list-toggle-heading">Filter</span>
            <form>
              <div className="user-toggle-group">
                <label htmlFor="inActiveUser">Waiting for Approval</label>
                <input type="radio" checked={this.state.showUsers === "inActiveUser"} onChange={(e) => this.onFilterChange(e)} id="inActiveUser" name="user-approve" />
              </div>
              <div className="user-toggle-group">
                <label htmlFor="activeUser">Approved Users</label>
                <input type="radio" id="activeUser" onChange={(e) => this.onFilterChange(e)} name="user-approve" />
              </div>
              <div className="user-toggle-group">
                <label htmlFor="eventGoing">Users by events</label>
                <input type="radio" id="eventGoing" onChange={(e) => this.onFilterChange(e)} name="user-approve" />
              </div>
              <div className="user-toggle-group">
                <label htmlFor="eventGoing">Disapproved Users</label>
                <input type="radio" id="disapprovedUser" onChange={(e) => this.onFilterChange(e)} name="user-approve" />
              </div>
            </form>
          </div>
          <div className="user-container">
            {this.state.showUsers === "inActiveUser" && (
              <React.Fragment>
                <div className="event-ask-users">
                  <span>New Sign-ups</span>
                  <UserList onDisapproveUser={this.onDisapproveUser} onApproveUser={this.onApproveUser} users={this.state.UnApprovedUsers} />
                </div>
                <div className="event-ask-users">
                  <span>Event Based Sign-ups</span>
                  <UserList onDisapproveUser={this.onDisapproveUser} ask={[FIELDS.USER.USER_ASK_EVENT]} onApproveUser={this.onApproveUser} users={this.state.signUpUser} />
                </div>
              </React.Fragment>
            )}
            {this.state.showUsers === "activeUser" && (
              <React.Fragment>
                <div className="event-ask-users mb-10">
                  <span>Search user</span>
                  <SearchComponent onSearchClick={this.onSearchClick} onCancelSearchClick={this.onCancelSearchClick} />
                </div>
                <div className="event-ask-users">
                  <span>{Array.isArray(this.state.searchUser) ?"Approved Users":"No result found!"} </span>
                  <UserList
                    onLoadMoreClick={this.onLoadMoreClick}
                    onHostChange={this.onHostChange}
                    users={this.state.searchUser.length > 0 ? this.state.searchUser : this.state.approveUsers}
                    approved={true}
                    toggleModal={this.toggleModal}
                    getUserId={this.getUserId}
                    searchUser={this.state.searchUser.length > 0}
                  />
                </div>
              </React.Fragment>
            )}
            {this.state.showUsers === "eventGoing" && (
              <div className="event-ask-users">
                <span>Users by events</span>
                <div className="users-event-going-container">
                  {this.context.state &&
                    // eslint-disable-next-line array-callback-return
                    this.context.state.events.map((el, key) => {
                      if (!el[FIELDS.EVENT.EVENT_IS_DELETE] && el[FIELDS.EVENT.EVENT_GOING].length > 0) {
                        return <EventGoingUsers event={el} key={key} />;
                      }
                    })}
                </div>
              </div>
            )}
            {this.state.showUsers === "disapprovedUser" && (
              <div className="event-ask-users">
                <span>Disapproved Users</span>
                <UserList onLoadMoreClick={this.onLoadMoreClick} onApproveUser={this.onApproveUser} users={this.state.disApprovedUser} />
              </div>
            )}
          </div>
        </div>
        {
          this.state.showPromptModal && (
            <UserModal onDisableUserClick={this.onDisableUserClick}  show="disableUserPermanently" closeModal={this.toggleModal} />
          )
        }
      </React.Fragment>
    );
  }
}

export default Users;
