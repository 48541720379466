import Ajv from 'ajv';
import {
  EVENT_SCEHMA,
  USER_SCHEMA,
  EVENT,
  USER,
  EVENT_LIST_SCHEMA,
  TESTIMONIAL_SCHEMA,
  TESTIMONIAL,
  CHAT_SCHEMA,
  CHAT,
  WALL_POST_SCHEMA,
  WALL_POST,
  WALL_POST_COMMENTS_SCHEMA,
  WALL_POST_COMMENTS, POST_COMMENTS_LIST_SCHEMA,
  CHANNEL_SCHEMA,
  CHANNEL,
  UNSUBSCRIBE_TRIBES,
  UNSUBSCRIBE_TRIBES_SCHEMA
} from "./Constants";
var ajv = new Ajv({ useDefaults: true,removeAdditional: true });

export const withEvent = (data) => {
    initObjectSchema(USER_SCHEMA,USER);
    var validate = initObjectSchema(EVENT_SCEHMA,EVENT);
    validate(data);
    return data;
}

export const withEventList = (data) => {
    initObjectSchema(USER_SCHEMA,USER);
    initObjectSchema(EVENT_SCEHMA,EVENT);

    var validate = initArraySchema(EVENT_LIST_SCHEMA,EVENT_SCEHMA);
    validate(data);
    // console.log(validate(data)); 
    // console.log(data); 
}

export const withUser = (data) => {
    var validate = initObjectSchema(USER_SCHEMA,USER);
    validate(data);
    // console.log(validate(data)); 
    // console.log(data); 
    return data;
}

export const withTestimonial = (data) =>{
    initObjectSchema(USER_SCHEMA,USER);
    var validate = initObjectSchema(TESTIMONIAL_SCHEMA,TESTIMONIAL)
    validate(data)
    return data
}

export const withChat = (data) =>{
    var validate = initObjectSchema(CHAT_SCHEMA,CHAT)
    validate(data)
    return data
}

export const withPost = (data) =>{
    initObjectSchema(USER_SCHEMA,USER);
    var validate = initObjectSchema(WALL_POST_SCHEMA,WALL_POST)
    validate(data)
    return data
}

export const withComments = (data) =>{
    initObjectSchema(USER_SCHEMA,USER);
    initObjectSchema(WALL_POST_COMMENTS_SCHEMA,WALL_POST_COMMENTS)
    var validate = initArraySchema(POST_COMMENTS_LIST_SCHEMA,WALL_POST_COMMENTS_SCHEMA)
    validate(data)
    return data
}


const initObjectSchema = (id,props) => {
    return ajv.compile({
        "$id" : id,
        type:"object",
        "properties":props,
        "additionalProperties": false,
    })
}

const initArraySchema = (id,ref) => {
    return ajv.compile({
        "$id" : id,
        type:"array",
        items:{"$ref": ref},
        "additionalProperties": false,
    })
}

export const withChannel = (data) => {
    initObjectSchema(CHANNEL_SCHEMA,CHANNEL);
    var validate = initObjectSchema(CHANNEL_SCHEMA,CHANNEL);
    validate(data);
    return data;
}

export const withUnsubscribe = (data) => {
    initObjectSchema(UNSUBSCRIBE_TRIBES_SCHEMA,UNSUBSCRIBE_TRIBES);
    var validate = initObjectSchema(UNSUBSCRIBE_TRIBES_SCHEMA,UNSUBSCRIBE_TRIBES);
    validate(data);
    return data;
}
