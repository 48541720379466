import React, { Component } from "react";
import { AuthContext } from "../Firebase/AuthProvider";
import "../Assets/Style/newLayout.scss";
import Header from "../components/header";
import Sidebar from "../components/Sidebar";
import RightSidebar from "../components/RightSideBar";
import ChannelModal from "../components/channel/ChannelModal";
import { updateChannel } from "../Firebase/Channels";
import { updateUser } from "../Firebase/Users";
import LoginSignUpPage from "../containers/LoginSignUpPage";
import UserModal from '../components/UserModal'
import ChannelListComp from "../components/ChannelListComp";

class Layout extends Component {
  static contextType = AuthContext;

  constructor() {
    super();
    this.state = {
      posts: [],
      initialCall: false,
      showChannelModal: false,
      showSignupModal: false
    };
  }

  setChannelModal = () => {
    this.setState({
      showChannelModal: !this.state.showChannelModal,
    });
  };

  updateChannelInfo = (channelId, data, msg) => {
    updateChannel(channelId, data)
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        if (err.message) {
          this.setState({ errorText: err.message }, () => {
          });
        }
      });
  };

  openJoinChannelModal =(channelId, members, isLeaveChannel)=>{
    if(!isLeaveChannel)
    {
      let newMembersArr = [...members]
      newMembersArr.push(this.context.state.userDetail)
      this.addChannelInfoInUser(channelId, "join");
      this.props.history.push("channel/"+channelId)
      this.updateChannelInfo(channelId, { members: newMembersArr }, "Congratulations! You have successfully joined the channel.");
    }
    else {
      let MembersArr = [...members]
      MembersArr = MembersArr.filter(el => {
        return el.id !== isLeaveChannel
      })
      this.addChannelInfoInUser(channelId, "leave");
      this.updateChannelInfo(channelId, { members: MembersArr }, "You have successfully left the channel.");
    }
  }

  addChannelInfoInUser = (channelId, type) => {
    let channelArr = [];
    if(this.context.state.userDetail.user_channels) {
      channelArr = this.context.state.userDetail.user_channels;
      if(type === "leave") {
        channelArr = channelArr.filter(el => {
          return el !== channelId;
        })
      }
    }
    else {
      channelArr.push(channelId)
    }
    updateUser(this.context.state.currentUser, "user_channels", channelArr);
  }

  moreProfileInfo =()=>{
    this.setState({showSignupModal:true})
  }
  
  closeModal = ()=>{
    this.setState({showSignupModal:false})
  }

  render() {
    return (
      <React.Fragment>
        {!this.context.state.currentUser ? (
          <LoginSignUpPage moreProfileInfo={this.moreProfileInfo} />
        ) : (
          <React.Fragment>
            <Header props={this.props} />
            <div className="DashboardMainContent channel-grid">
              <Sidebar currentPage="home" />
              <ChannelListComp userId={this.context.state.currentUser} userInfo={this.context.state.userDetail}  />
              <RightSidebar />
            </div>
          </React.Fragment>
        )}
        {this.state.showChannelModal && (
          <ChannelModal
            setChannelModal={this.setChannelModal}
            historyProps=""
          />
        )}
        {this.state.showSignupModal && (
          <UserModal show="signup-popup" userInfo={this.context.state.userDetail} closeModal={this.closeModal}  />
        )}
      </React.Fragment>
    );
  }
}

export default Layout;
