import React from 'react'
import './index.css'

const SkeletonLoader = (props) => {
    return (
       <div className={`skeleton-loader height-${props.height} mb-${props.mb} ${props?.textAlign}` }>
           <div className="inner">
                {props?.profile &&(
                    <div className="profile-image">
                        <div className="left">
                            <div className="circle"></div>
                        </div>
                        <div className="right">
                            <div className="row-inner">
                                <div className="para"></div>
                                <div className="para"></div>
                            </div>
                        </div>
                    </div>
                )}

                {props?.general && (
                    <div className="general">
                        <div className="row-inner">
                            <div className="para header"></div>
                            {/* <div className="para content"></div> */}
                            {/* <div className="para small"></div> */}
                            <div className="para full"></div>
                        </div>
                    </div>
                )}

                {props?.button && (
                    <div className="btn-div">
                        <div className="btn"></div>
                    </div>
                )}
           </div>
       </div>
    )
}

export default SkeletonLoader
