import React, { Component } from "react";
import "../Assets/Style/postbox.css";
import ImageComponent from "./ImageComponent";
import { FIELDS } from "../Firebase/Constants";
import { AuthContext } from "../Firebase/AuthProvider";
import { adminUserPass, wallCommentsConstats } from "../Helper/StaticContent";
import Helper from "../Helper/helper";
import Play from '../Assets/Images/Assembly.png'
import { getUserDetail } from "../Firebase/Users";
import {
  addComment,
  addReaction,
  deleteComment,
  deletePost,
} from "../Firebase/Events";
import ShowSnackbar from "./ShowSnackbar";
import UserModal from "./UserModal";

class PostComponentUI extends Component {
  static contextType = AuthContext;
  constructor(props) {
    super();
    // Creating a reference
    this.box = React.createRef();
    this.state = {
      showModal: false,
      isShowMoreComments: false
    };
  }

  componentDidMount() {
    // Adding a click event listener
    document.addEventListener('click', this.handleOutsideClick);
  }

  handleOutsideClick = (event) => {
    if (event.target.id !== 'three-dots-1') {
      let allMenu = document.querySelectorAll(".post-menu-dropdown");
      for (const a of allMenu) {
        a.classList.remove("show-post-menu-dropdown");
      }
    }
  }

  // Add Reaction to the post
  addReactToPost = async (postId) => {
    console.log("this is react")
    let userID = this.context.state.currentUser;
    if (this.props.updateReaction) {
      this.props.updateReaction(postId);
    } else {
      await addReaction(postId, userID);
    }
  };

  // Delete Post
  ondeletPostClick = async (postId) => {
    this.context.showLoader(true);
    deletePost(postId).then(() => {
      this.context.showLoader(false);
      this.setState({ showModal: false });
    });
  };

  // Add Comment on Post
  addCommentToPost = async (postId, data) => {
    console.log("this is comment")
    await addComment(postId, data);
  };

  // Delete Comment
  onDeleteCommentClick = async (post_id, comments, index) => {
    let commentsList = [...comments];
    commentsList.splice(index, 1);
    await deleteComment(post_id, commentsList);
  };

  openPostMenu = (e) => {
    let allMenu = document.querySelectorAll(".post-menu-dropdown");
    let menu = e.target.childNodes[0];
    for (const a of allMenu) {
      if (!(a.getAttribute("id") === menu.getAttribute("id"))) {
        a.classList.remove("show-post-menu-dropdown");
      }
    }
    if (menu.classList.contains("show-post-menu-dropdown")) {
      menu.classList.remove("show-post-menu-dropdown");
    } else {
      menu.classList.add("show-post-menu-dropdown");
    }
  };

  closeDropdown = (e) => {
    let dropDownMenu = e.target.parentNode.parentNode;
    dropDownMenu.classList.remove("show-post-menu-dropdown");
  };

  onChangeHandler = (e) => {
    let element = e.target;
    this.setState({
      [e.target.name]: e.target.value,
    });

    element.style.height = "5px";
    element.style.height = element.scrollHeight + 2 + "px";
  };

  onSendCommentClick = async (e, post_id, key) => {
    if(!this.context.state.currentUser){
      this.props.history.push("/")
      return
    }
    if (
      !this.state["post_comment" + key] ||
      this.state["post_comment" + key]?.trim() === "" ||
      this.state["post_comment" + key]?.length === 0
    ) {
      e.preventDefault();
      return;
    } else {
      let element = e.target;
      e.preventDefault();
      element.style.height = "35px";
      element.style.color = "#ffffff";
      element.value = "";
      let data = {
        user_id: this.context.state.currentUser,
        [FIELDS.WALL_POST_COMMENTS.POST_COMMENT_TEXT]: this.state[
          "post_comment" + key
        ].trim(),
      };
      this.setState({
        ["post_comment" + key]: "",
      });
      this.addCommentToPost(post_id, data).then(() => {
        element.style.color = "unset";
      });
    }
  };

  redirectProfile = (id) => {
    this.props.history.push(`/profile/${id}`);
  };

  redirectPostDetail = (id) => {
    this.props.history && this.props.history.push(`/post/${id}`);
  };

  getUserDetails = async (userList) => {
    let userLikeName = [];
    for (const id of userList) {
      let data = await getUserDetail(id);
      userLikeName.push(data.name);
    }
    return userLikeName;
  };

  copyLink = (id) => {
    let link = `${process.env.REACT_APP_FIREBASE_DOMAIN}/post/${id}`;
    var copyhelper = document.createElement("input");
    copyhelper.className = "copyhelper";
    document.body.appendChild(copyhelper);
    copyhelper.value = link;
    copyhelper.select();
    document.execCommand("copy");
    document.body.removeChild(copyhelper);
    this.context.showSnackbar(true);
    setTimeout(() => {
      this.context.showSnackbar(false);
    }, 2000);
  };

  closeModal = (postId) => {
    this.setState({
      showModal: !this.state.showModal,
      postId,
    });
  };

  moveToComment = (id) => {
    var element = document.getElementById("cmnt_tglr" + id);
    element.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
    setTimeout(() => {
      element.focus();
    }, 300);
  };

  showMoreComments = () => {
    this.setState({
      isShowMoreComments: !this.state.isShowMoreComments
    })
  }

  render() {
    return (
      <React.Fragment>
        {this.props?.posts?.map((post, key) => {
          if(this.props.isProfiePage && post.post_user_profile_name && post.post_user_profile_name !==this.props.userDetail.name) {
            return;
          }
          else if(this.props.isProfiePage && !post.post_user_profile_name){
            if(post.post_user_id !== this.props.userDetail.id) return;
          }
          let postTime = new Date(post[FIELDS.WALL_POST.POST_CREATED_AT]);
          let date = Helper.getPostDate(postTime);
          let sortComments = post?.post_comments.sort(
            (a, b) => b.post_comment_created_at - a.post_comment_created_at
          );
          let commentsName = sortComments?.map(
            (x) => x.post_comment_user_detail.name
          );
          let filterName = commentsName?.filter(
            (v, i) => commentsName.indexOf(v) === i
          );
          return (
              <div
                className="post-component-ui br-10 gnrl-white-bg"
                key={key}
                ref={this.box}
              >
                <div className="pcui-header">
                  <div className="pbm-profile-image-name">
                    <div
                      className="pbm-profile-image"
                      onClick={() => {
                        this.redirectProfile(
                          post[FIELDS.WALL_POST.POST_USER_DETAIL]?.id
                        );
                      }}
                    >
                      <ImageComponent
                        image={
                          post[FIELDS.WALL_POST.POST_USER_DETAIL]?.[
                            FIELDS.USER.USER_IMAGE
                          ]
                        }
                        className="pbm-user-image"
                      />
                    </div>
                    <div className="pbm-profile-name">
                      <h4
                        className="h4-generic pcui-font-size"
                      >
                        <span onClick={() =>
                          this.redirectProfile(
                            post[FIELDS.WALL_POST.POST_USER_DETAIL]?.id
                          )
                        } >{
                          Helper.transformText(post[FIELDS.WALL_POST.POST_USER_DETAIL]?.[
                            FIELDS.USER.USER_NAME
                          ])
                        }
                        </span>
                        {
                          post['channelInfo']?.name &&
                          <a href={"/channel/"+post['post_channel_id']}>{' '}<img
                          src={Play}
                          className="img-fluid ml-1"
                          width="14"
                          alt="usrimage"
                          />{' '}{post['channelInfo']?.name}</a>
                        }
                      </h4>
                      <p className="pcui-time">
                        {date}
                        <span className="globe-logo"></span>{" "}
                      </p>
                    </div>
                    {(this.context.state.currentUser ===
                      post[FIELDS.WALL_POST.POST_USER_ID] ||
                      localStorage.getItem("admin") === adminUserPass.id) && (
                      <div
                        className="pbm-post-menu"
                        onClick={(e) => {
                          this.openPostMenu(e);
                        }}
                        id="three-dots-1"
                      >
                        <div
                          className="post-menu-dropdown"
                          id={`postMenu${key}`}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <ul>
                            <li
                              className="delete"
                              onClick={(e) => {
                                this.closeModal(post.id);
                                this.closeDropdown(e);
                              }}
                            >
                              Delete
                            </li>
                            <li
                              className="link"
                              onClick={(e) => {
                                this.copyLink(post.id);
                                this.closeDropdown(e);
                              }}
                            >
                              Copy link
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className="pcui-main-content"
                  onClick={() => {
                    this.redirectPostDetail(post.id);
                  }}
                >
                  <p>
                    {post?.[FIELDS.WALL_POST.POST_MESSAGE]
                      ?.trim()
                      ?.replace(
                        /(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/,
                        ""
                      )}
                  </p>
                </div>

                {post?.[FIELDS.WALL_POST.POST_PAGE_METATAGS][0]?.title &&
                 post?.[FIELDS.WALL_POST.POST_IMAGE_URL]?.length === 0 && (
                  <div className="pcui-page-preview">
                    <a
                      href={post?.[FIELDS.WALL_POST.POST_PAGE_METATAGS][0].url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="pcui-image">
                        <ImageComponent
                          image={
                            post?.[FIELDS.WALL_POST.POST_PAGE_METATAGS][0].image
                          }
                        />
                      </div>
                      <div className="pcui-content">
                        <div className="pcui-title">
                          <h4>
                            {
                              post?.[FIELDS.WALL_POST.POST_PAGE_METATAGS][0]
                                .title
                            }
                          </h4>
                        </div>
                        <div className="pcui-desc">
                          <p>
                            {
                              post?.[FIELDS.WALL_POST.POST_PAGE_METATAGS][0]
                                .description
                            }
                          </p>
                        </div>
                        <div className="pcui-url">
                          <p>
                            {post?.[FIELDS.WALL_POST.POST_PAGE_METATAGS][0].url}
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                )}

                {post?.[FIELDS.WALL_POST.POST_IMAGE_URL]?.length > 0 &&
                  !post?.[FIELDS.WALL_POST.POST_VIDEO_URL]?.length > 0 && (
                    <div className="pcui-post-image-preview">
                      {post?.[FIELDS.WALL_POST.POST_IMAGE_URL]?.map(
                        (image, key) => {
                          return (
                            <div
                              key={key}
                              className="pcui-image-post"
                              onClick={() => {
                                this.redirectPostDetail(post.id);
                              }}
                            >
                              {// eslint-disable-next-line jsx-a11y/img-redundant-alt
                              }<img height="100%" alt="no-image" width="100%" src={image} />
                            </div>
                          );
                        }
                      )}
                    </div>
                  )}

                {post?.[FIELDS.WALL_POST.POST_VIDEO_URL]?.length > 0 && (
                  <div className="pcui-post-video-preview">
                    {post?.[FIELDS.WALL_POST.POST_VIDEO_URL]?.map(
                      (url, key) => {
                        return (
                          <div key={key} className="pcui-video-post">
                            <iframe
                              title="iframe"
                              className="iframeWindow"
                              src={
                                url.match(/youtu.be/g)
                                  ? url.replace(
                                      "youtu.be/",
                                      "www.youtube.com/embed/"
                                    ) + "?rel=0&modestbranding=1"
                                  : url.replace("/watch?v=", "/embed/") +
                                    "?rel=0&modestbranding=1"
                              }
                              frameBorder="0"
                              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                            ></iframe>
                          </div>
                        );
                      }
                    )}
                  </div>
                )}

                <div className="pcui-likes-comments">
                  <div className="likes-sec">
                    {post?.[FIELDS.WALL_POST.POST_REACT]?.length > 0 && (
                      <React.Fragment>
                        <div className="like-icon">
                          <span></span>
                        </div>
                        <span className="like-count">
                          {post?.[FIELDS.WALL_POST.POST_REACT]?.length}
                        </span>
                      </React.Fragment>
                    )}
                    <div className="like-display">
                      <ul>
                        {post?.user_react_name?.map((name, key) => {
                          return <li key={key}>{name}</li>;
                        })}
                      </ul>
                    </div>
                  </div>
                  <div className="comments-sec">
                    {sortComments?.length > 0 && (
                      <span className="comments-count">
                        {sortComments?.length} Comments
                        <div className="like-display cmnt-like-right">
                          <ul>
                            {filterName.map((name, key) => {
                              return <li key={key}>{name}</li>;
                            })}
                          </ul>
                        </div>
                      </span>
                    )}
                  </div>
                </div>
                          
                <div className="pcui-lk-cmnt">
                  <div className="pcui-react comments-style">
                    <div className="pcui-like-box">
                      <input
                        id={`postLike${key}`}
                        checked={post[FIELDS.WALL_POST.POST_REACT]?.includes(
                          this.context?.state?.currentUser
                        )}
                        onChange={() => {
                          this.context.state.currentUser ?
                          this.addReactToPost(post.id):
                          this.props.history.push("/")
                        }}
                        type="checkbox"
                      />
                      <label htmlFor={`postLike${key}`}></label>
                    </div>
                    <span
                      className="text-span"
                      onClick={() => {
                        this.context.state.currentUser ?
                        this.addReactToPost(post.id):
                        this.props.history.push("/")
                      }}
                    >
                      Like
                    </span>
                  </div>

                  <div
                    className="pcui-comments comments-style"
                    onClick={() => {
                      this.context.state.currentUser ?
                      this.moveToComment(key):
                      this.props.history.push("/")
                    }}
                  >
                    <div className="icon-div comment">
                      <span className="comment-icon"></span>
                    </div>
                    <span className="text-span">Comment</span>
                  </div>

                  {/* <div className="pcui-share comments-style">
                    <div className="icon-div share">
                      <span className="share-icon"></span>
                    </div>
                    <span className="text-span">Share</span>
                  </div> */}
                </div>
                

                <div className="pcui-cmnt-sec">
                {this.context.state.currentUser && (

                  <div className="pcui-cmnt-inpt">
                    <div
                      className="gnrl-img-dsply cmnt-u-img"
                      onClick={() =>
                        this.redirectProfile(this.context.state.currentUser)
                      }
                    >
                      <ImageComponent
                        image={
                          this.context.state.userDetail?.[
                            FIELDS.USER.USER_IMAGE
                          ]
                        }
                      />
                    </div>

                    <textarea
                      id={"cmnt_tglr" + key}
                      placeholder={wallCommentsConstats.cmntsPlaceholder}
                      onKeyDown={(e) => {
                        e.keyCode === 13 &&
                          !e.shiftKey &&
                          this.onSendCommentClick(e, post?.id, key);
                      }}
                      onChange={(e) => this.onChangeHandler(e)}
                      className="gnrl-textarea cmnt-textarea"
                      value={this.state["post_comment" + key] || ""}
                      name={"post_comment" + key}
                      type="text"
                    />

                    {/* <button onClick={()=>this.onSendCommentClick(post?.id,key)}>Send</button> */}
                  </div>
                )}

                  <div className="pcui-cmnts-list">
                    <ul>
                      {sortComments?.map((comment, key, comments) => {
                        let commentTime = new Date(
                          comment[
                            FIELDS.WALL_POST_COMMENTS.POST_COMMENT_CREATED_AT
                          ]
                        );
                        let date = Helper.getPostDate(commentTime);
                        return (
                          <li key={key} className={key > 1 && !this.state.isShowMoreComments ? "hideCommentByDefault" : "" }>
                            <div
                              className="gnrl-img-dsply cmnt-u-img"
                              onClick={() => {
                                this.redirectProfile(
                                  comment[
                                    FIELDS.WALL_POST_COMMENTS
                                      .POST_COMMENT_USER_DETAIL
                                  ]?.id
                                );
                              }}
                            >
                              <ImageComponent
                                image={
                                  comment?.[
                                    FIELDS.WALL_POST_COMMENTS
                                      .POST_COMMENT_USER_DETAIL
                                  ][FIELDS.USER.USER_IMAGE]
                                }
                              />
                            </div>
                            <div className="cmnt-view">
                              <div className="cmnt-header">
                                <p
                                  className="cmnt-u-name"
                                  onClick={() => {
                                    this.redirectProfile(
                                      comment[
                                        FIELDS.WALL_POST_COMMENTS
                                          .POST_COMMENT_USER_DETAIL
                                      ]?.id
                                    );
                                  }}
                                >
                                  {
                                    comment[
                                      FIELDS.WALL_POST_COMMENTS
                                        .POST_COMMENT_USER_DETAIL
                                    ][FIELDS.USER.USER_NAME]
                                  }{" "}
                                  <span>{date}</span>{" "}
                                  <span className="dropdown"></span>
                                </p>
                                {(this.context.state.currentUser ===
                                  comment[
                                    FIELDS.WALL_POST_COMMENTS
                                      .POST_COMMENT_USER_DETAIL
                                  ]?.id ||
                                  localStorage.getItem("admin") ===
                                    adminUserPass.id) && (
                                  <div
                                    className="pbm-post-menu"
                                    onClick={(e) => {
                                      this.openPostMenu(e);
                                    }}
                                    id="three-dots-1"
                                  >
                                    <div
                                      className="post-menu-dropdown"
                                      id={`commentMenu${key}`}
                                      onClick={(e) => e.stopPropagation()}
                                    >
                                      <ul>
                                        <li
                                          className="delete"
                                          onClick={(e) => {
                                            this.onDeleteCommentClick(
                                              post.id,
                                              comments,
                                              key
                                            );
                                            this.closeDropdown(e);
                                          }}
                                        >
                                          Delete
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                )}
                              </div>
                              <p className="cmnt-u-text">
                                {comment.post_comment_text}
                              </p>
                            </div>
                          </li>
                        );
                      })}
                      {
                        sortComments.length > 2 &&
                        <li>
          <div className="cmnt-view view-more-comment"><a href={() => false} onClick={this.showMoreComments} >{this.state.isShowMoreComments ? "Hide again" : "View more"}</a></div></li>
                      }
                    </ul>
                  </div>
                </div>
              </div>
          );
        })}

        {this.state.showModal && (
          <UserModal
            getPostById={this.props.getPostById}
            postId={this.state.postId}
            ondeletPostClick={this.ondeletPostClick}
            closeModal={this.closeModal}
            show="deletePost"
          />
        )}

        <ShowSnackbar show="LinkCopied" />
      </React.Fragment>
    );
  }
}

export default PostComponentUI;
