import React, { Component } from "react";
import PostBoxModal from "./PostBoxModal";
import ImageComponent from "./ImageComponent";
import { AuthContext } from "../Firebase/AuthProvider";
import { FIELDS } from "../Firebase/Constants";
import { postBoxConstants } from "../Helper/StaticContent";

class StoryPostComponent extends Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = {
      showPostBox: false,
    };
  }

  togglePostBox = () => {
    this.setState({
      showPostBox: !this.state.showPostBox,
    });
  };

  render() {
    let userProfileName = this.props.isProfiePage && this.props.userDetail[FIELDS.USER.USER_NAME] ? this.props.userDetail[FIELDS.USER.USER_NAME] : this.context.state.userDetail[FIELDS.USER.USER_NAME];
    return (
      <div className="post-box-component">
        <div className="pbc-textarea">
          <div
            className="pbc-user-image"
            onClick={() => {
              this.props.history.push(
                `/profile/${this.context.state.currentUser}`
              );
            }}
          >
            <ImageComponent
              className="image-generic"
              image={this.context.state.userDetail[FIELDS.USER.USER_IMAGE]}
            />
          </div>
          <div className="pbc-text" onClick={() => this.togglePostBox()}>
            <span className="placeholder-text">
              {
                this?.context?.state?.userDetail?.[FIELDS.USER.USER_NAME] ? this?.props?.channelID ? 
                "Write to the community..." : this?.props?.userDetail && this?.props?.userDetail?.id !== this.context?.state?.currentUser ? "Write to "+ userProfileName?.split(
                  " "
                )[0]+" ..." : postBoxConstants?.placeholderText + userProfileName?.split(
                  " "
                )[0]+ ' ?'
               : this.props?.isProfiePage ? `Write to ...` : "What did you learn today ?"}
            </span>
          </div>
        </div>
        <div className="pbc-attachments" onClick={() => this.togglePostBox()}>
          <div className="pbc-icons">
            <div className="attachment-icon">
              <span className="link_icon"></span>
            </div>
            <span className="pbc-icons-label">
              {postBoxConstants.iconsLabel.link}
            </span>
          </div>

          <div className="pbc-icons">
            <div className="attachment-icon">
              <span className="image_icon"></span>
            </div>
            <span className="pbc-icons-label">
              {postBoxConstants.iconsLabel.photo}
            </span>
          </div>
        </div>

        {this.state.showPostBox && (
          <PostBoxModal realTimePostUpdate={this.props.realTimePostUpdate} userDetail={this.props.userDetail} closeModal={this.togglePostBox} channelID={this.props.channelID} channelName={this.props.channelName} userProfileName={userProfileName} isProfiePage={this.props.isProfiePage} />
        )}
      </div>
    );
  }
}

export default StoryPostComponent;
