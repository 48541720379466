import React, { Fragment, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";

// import styles
import "../Assets/Style/navbar.css";

// import component
import Logo from "../Assets/Images/blackLogo.png";
import ChannelModal from "./channel/ChannelModal";
import CreateShowModal from "../components/CreateShowModal";
import { Link } from "react-router-dom";
import NotificationDropdown from "./NotificationDropdown/NotificationDropdown";
import { searchSpecificUser } from '../Firebase/Users';
import userIcon from '../Assets/Images/profile.png'
import Select from 'react-select';

const NavBar = ({
  userName,
  logout,
  userImage,
  openPasswordModal,
  history,
  channelList,
  channelInfo,
  userID,
  currentPath,
  notifications,
  openEditProfileModal,
  isHost
}) => {
  const [showChannelModal, setChannelModal] = useState(false);
  const [liveShowModal, setLiveShowModal] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [selectedOption] = useState(null)
  const [searchResults, setSearchResults] = useState([])
  const [searchValue, setSearchValue] = useState("")

    const handleChange = async selectedOption => {
      setSearchValue(selectedOption);
      if(selectedOption){
        const result = await searchSpecificUser(selectedOption.toLowerCase());
        let users = result.map(el=>{
          el.id = el.uid
          return el;
        })
        let newResult = users.map((item) => {
          return { value: item.name, label:  <div className="userImg dropdownWithImage"><Link to={"/profile/"+item.id}><img className="img-fluid" src={item.image ? item.image : userIcon} alt="noImage" />&nbsp;&nbsp;{item.name}</Link></div> }
        })
        setSearchResults(newResult)
      }
      else {
        setSearchResults([])
      }
    };

    const handleChangeDropdown = () => {
      console.log(`Option selected:`, selectedOption);
    }


  return (
    <Fragment>
      <div className="Navbar">
        <div className="logoContainer">
          <Link to="/"><img
              src={Logo}
              alt="header-logo"
              height="35" /></Link>
          <div className="navbar_links">
            <Link to="/tribes" className={currentPath.includes("tribes") ? "" : ""}>Explore Tribes</Link>
          </div>
          <div className="mobile-menu">
              <span className="mobile-icon" onClick={()=>{setShowMobileMenu(!showMobileMenu)}}>
                <div id="nav-icon4" className={`${!showMobileMenu || "open"}`}>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </span>
                <Select
                  className="search-bar-header"
                  value={selectedOption}
                  onInputChange={event => handleChange(event)}
                  inputValue={searchValue}
                  options={searchResults}
                  onChange={handleChangeDropdown}
                  placeholder="Search People"
                  noOptionsMessage={() => 'No people found.'}
                />
          </div>
        </div>
        {
          userName &&
        <div className="headerRightContent">
          {
            isHost &&
            <button
              className="create-live-event-btn"
              onClick={() => setLiveShowModal(true)}
            >
              <span className="create-live-icon"></span> Create live session
            </button>
          }
          <NotificationDropdown notifications={notifications} userId={userID} />

          <div className={userName && userName.length > 10 ? "CustomDropDown CustomDropDownWithLongText" : "CustomDropDown"} >
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <img
                  src={userImage}
                  alt="company-logo"
                  className="img-fluid"
                  width="40"
                />
                &nbsp;<span>{userName && userName.split(" ")[0]}</span>&nbsp;
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item>
                  <Link to={"/profile/" + userID}>Profile</Link>
                </Dropdown.Item>
                <Dropdown.Item onClick={(e) => openEditProfileModal()}>
                  Edit Profile
                </Dropdown.Item>
                <Dropdown.Item onClick={(e) => openPasswordModal()}>
                  Change Password
                </Dropdown.Item>
                <Dropdown.Item onClick={(e) => logout(e)}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      }
      </div>
      {showChannelModal && (
        <ChannelModal
          setChannelModal={setChannelModal}
          historyProps={history}
        />
      )}
      {liveShowModal && (
        <CreateShowModal
          setLiveShowModal={setLiveShowModal}
          historyProps={history}
        />
      )}

      
      <div className={`mobile-menu-container ${showMobileMenu && "show"}`}>
        <div className="inner">
          <div className="image">
            <a href="/">
              <img src={Logo} alt=""/>
            </a>
          </div>
          <ul className="menu-list">
            <li>
              <div className="svg-icon">
                <img src={require('../Assets/svg/house-outline.svg')} alt="" />
              </div>
              <div className="input-div">
                <input type="radio" name="nav-menu" id="home" />
                <label className={currentPath === "/" ? "active" : ""} htmlFor="home"><a href="/">Home</a></label>
              </div>
            </li>
            <li>
              <div className="svg-icon">
                <img src={require('../Assets/svg/user.svg')} alt="" />
              </div>
              <div className="input-div">
                <input type="radio" name="nav-menu" id="Profile" />
                <label className={currentPath.includes("profile") ? "active" : ""} htmlFor="Profile"><a href={"/profile/" + userID}>Profile</a></label>
              </div>
            </li>
            <li>
              <div className="svg-icon">
                <img src={require('../Assets/svg/interactive.svg')} alt="" />
              </div>
              <div className="input-div">
                <input type="radio" name="nav-menu" id="Chanels" />
                <label htmlFor="Chanels" className={currentPath.includes("tribes") ? "active" : ""}><a href="/tribes">Tribes</a></label>
              </div>
            </li>
            { isHost && (

            <li>
              <div className="svg-icon">
                <img src={require('../Assets/svg/live.svg')} alt="" />
              </div>
              <div className="input-div">
                <label className={liveShowModal ? "active" :""} htmlFor="Create_Live_show" onClick={() => {setLiveShowModal(true);setShowMobileMenu(false)}}>Create Live Session</label>
              </div>
            </li>
            )}

            <li>
              <div className="svg-icon">
                <img src={require('../Assets/svg/square.svg')} alt="" />
              </div>
              <div className="tabs">
                <div className="tab">
                  <input type="checkbox" id="chck1" />
                  <label className="tab-label" htmlFor="chck1">
                    {channelInfo && channelInfo.name
                      ? channelInfo.name
                      : "All tribes"
                    }
                  </label>
                  <div className="tab-content">
                    <ul>
                      {channelList.map((item, index) => {
                        return (
                          <li
                            
                            key={index}
                          >
                            <a href={"/channel/" + item.id}>
                              {item.data().name}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </li>
          </ul>

        </div>
      </div>
    </Fragment>
  );
};
export default NavBar;
