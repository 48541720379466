import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { setAllNotificationSeenTrue } from '../../Firebase/Notifications'
import Helper from '../../Helper/helper'
import './Notifications.scss'

const NotificationDropdown = ({notifications, userId}) => {

    const openNotification =async(userId)=>{
      await setAllNotificationSeenTrue(userId)
    }

    return (
        <div className="notifications-dropdown">
            <Dropdown onClick={()=>{openNotification(userId)}}>
              <Dropdown.Toggle>
                {notifications.filter(e=>!e.isSeen).length > 0 && (
                  <span className="counting"><span>{notifications.filter(e=>!e.isSeen).length}</span></span>
                )}
                <img src={`${require('../../Assets/svg/bell.svg')}`} alt="noImage" />
              </Dropdown.Toggle>
              <Dropdown.Menu className={`custom-menu ${notifications.length === 0 && 'normal'}`}>
                {notifications.length > 0 ?
                  <>
                  <div className="notif-heading">Notifications</div>
                  
                  {// eslint-disable-next-line array-callback-return
                  notifications.map((v,i)=>{
                    let notificationTime = new Date(v.date);
                    if(v.channelName){
                      return(
                        <Dropdown.Item key={i} href={`/post/${v.postId}`}>
                          <div className={`dropdown-notif`}>
                            <div className="image">
                              <img className="profile-image" src={v.img || `${require('../../Assets/Images/profile.png')}`} alt="" />
                              <img className="icon" src={require('../../Assets/svg/comment.svg')} alt="noImage"  />
                            </div>
                            <div className="noti">
                              <div className="para">
                                <strong>{Helper.transformText(v.hostName)}</strong> posted in a {v.channelName+"'s tribe."}.
                              </div>
                              <div className="date"><p>{Helper.getPostDate(notificationTime)}</p></div>
                              {!v.isSeen && (
                                <div className="circle"></div>
                              )}
                            </div>
                          </div>
                        </Dropdown.Item>
                      )
                    }
                      if(v.reactorName){
                        return(
                          <Dropdown.Item key={i} href={`/post/${v.postId}`}>
                            <div className={`dropdown-notif`}>
                              <div className="image">
                                <img className="profile-image" src={v.img || `${require('../../Assets/Images/profile.png')}`} alt="" />
                                <img className="icon" src={require('../../Assets/svg/like.svg')} alt="noImage"  />
                              </div>
                              <div className="noti">
                                <div className="para">
                                  <strong>{Helper.transformText(v.reactorName)}</strong> liked {v.hostId === userId ? <strong>your</strong> : <strong>{Helper.transformText(v.hostName)}'s</strong> } post.
                                </div>
                                <div className="date"><p>{Helper.getPostDate(notificationTime)}</p></div>
                                {!v.isSeen && (
                                  <div className="circle"></div>
                                )}
                              </div>
                            </div>
                          </Dropdown.Item>
                        )
                      }else if(v.commentorName){
                        return(
                          <Dropdown.Item key={i} href={`/post/${v.postId}`} >
                            <div className={`dropdown-notif`}>
                              <div className="image">
                                <img className="profile-image" src={v.img || `${require('../../Assets/Images/profile.png')}`} alt="" />
                                <img className="icon" src={require('../../Assets/svg/comment.svg')} alt="noImage"  />
                              </div>
                              <div className="noti">
                                <div className="para">
                                  <strong>{Helper.transformText(v.commentorName)}</strong> {v.tagged ? "mentioned you on" : "commented on"}  {v.hostId === userId ? <strong>your</strong> : <strong>{Helper.transformText(v.hostName)}'s</strong>} post.
                                </div>
                                <div className="date"><p>{Helper.getPostDate(notificationTime)}</p></div>
                                {!v.isSeen && (
                                  <div className="circle"></div>
                                )}
                              </div>
                            </div>
                          </Dropdown.Item>
                        )
                      }
                  })}
                  </>
                  :
                  <Dropdown.Item className="padding">No new notification</Dropdown.Item>
                }
              </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

export default NotificationDropdown

