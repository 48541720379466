import Swal from 'sweetalert2';

export const showSuccessMessage = (message) => {
    Swal.fire({
        title: 'Success!',
        text: message,
        icon: 'success',
        confirmButtonText: 'Ok',
        confirmButtonColor: '#FF0101',
      })
};

export const showErrorMessage = (message) => {
    Swal.fire({
        title: 'Error!',
        text: message,
        icon: 'error',
        confirmButtonText: 'Ok',
        confirmButtonColor: '#FF0101',
      })
};

export const calcDate = (date1,date2) => {
  var diff = Math.floor(date1.getTime() - date2.getTime());
  var day = 1000 * 60 * 60 * 24;

  var days = Math.floor(diff/day);
  var months = Math.floor(days/31);
  var years = Math.floor(months/12);

  var message = date2.toDateString();
  message += " was "
  message += days + " days " 
  message += months + " months "
  message += years + " years ago \n"

  return message
  }