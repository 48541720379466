import React, { Fragment } from "react";
import '../Assets/Style/sidebar.css'
import noteIcon from '../Assets/Images/note.svg'
import communityIcon from '../Assets/Images/community.svg'
import profileIcon from '../Assets/Images/profile.png'
import partnersIcon from '../Assets/Images/partners.svg'
import { Link } from "react-router-dom";

const SideBar = ({ currentPage, showHideTabs, selectedTab, currentUser, isPreview }) => {

  return (
    <Fragment>
      <nav className="nav">
        {(currentUser && !isPreview) && (
          <ul className="nav-menu-items">
            {
              currentPage === "channels" &&
              <li className={`navbarText ${["people","groupChat","community"].includes(selectedTab) && "active"}`}>
                  <Link to="#" onClick={() => showHideTabs("community")}>
                    <img
                      src={communityIcon}
                      alt="live"
                      className="img-fluid mb-2"
                      width="35"
                    />
                    <p className="sidebarText">Activity</p>
                  </Link>
              </li>
            }
            {
              currentPage === "channels" &&
              <li className={`navbarText ${selectedTab === "agenda" && "active"}`}>
                  <Link to="#" onClick={() => showHideTabs("agenda")}>
                    <img
                        src={noteIcon}
                        alt="live"
                        className="img-fluid mb-2"
                        width="35"
                      />
                      <p className="sidebarText">Sessions</p>
                  </Link>
              </li>
            }
            
            {
              currentPage === "channels" &&
              <Fragment>
                <li className={`navbarText ${selectedTab === "speakers" && "active"}`}>
                <Link to="#" onClick={() => showHideTabs("speakers")}>
                      <img
                        src={profileIcon}
                        alt="live"
                        className="img-fluid mb-2"
                        width="35"
                      />
                      <p className="sidebarText">Speakers</p>
                    </Link>
                </li>
                <li className={`navbarText ${selectedTab === "partners" && "active"}`}>
                <Link to="#" onClick={() => showHideTabs("partners")}>
                      <img
                        src={partnersIcon}
                        alt="live"
                        className="img-fluid mb-2"
                        width="35"
                      />
                      <p className="sidebarText">Partners</p>
                    </Link>
                </li>
              </Fragment>
          }
        </ul>
        )}
      </nav>
    </Fragment>
  );
};

export default SideBar;
