import { decamelize } from "humps";
import { FIELDS } from "./Constants";
import Helper from "../Helper/helper";
import moment from "moment";
const base = "https://us-central1-assembly-f-live.cloudfunctions.net/";
//const base = "http://localhost:5001/assembly-f-live/us-central1/";
//const base = "https://assemblyf.com:5001/assembly-f-live/us-central1/";

export const createUser = async (email, name, invited_user_name) => {
  const url = base + "createUser?" + jsonToUrlEncode({ email: email, name: name, invited_user_name: invited_user_name });
  return await fetch(url, { method: "GET" });
};

export const newsletterMail = async (data) => {
  const url = base + "newsletterMail?" + jsonToUrlEncode({ email: data.email, subject: data.subject, comment: data.comment });
  return await fetch(url, { method: "GET" });
};

export const approveUser = async (email, name) => {
  const url = base + "approveUser?" + jsonToUrlEncode({ email: email, name: name });
  return await fetch(url, { method: "GET" });
};

export const reserveSeat = async (email) => {
  const url = base + "reserveSeat?" + jsonToUrlEncode({ dest: email });
  return await fetch(url, { method: "GET" });
};

export const inviteUser = async (email, event,user_name) => {

  const url = base + "inviteUsers?" + jsonToUrlEncode({
    dest: email,
    path: event[FIELDS.EVENT.EVENT_ICS],
    zoom_link: event[FIELDS.EVENT.EVENT_ZOOM_LINK],
    event_name: event[FIELDS.EVENT.EVENT_NAME],
    zoom_id: event[FIELDS.EVENT.EVENT_ZOOM_ID],
    zoom_password: event[FIELDS.EVENT.EVENT_ZOOM_PASSWORD],
    when: moment(event[FIELDS.EVENT.EVENT_START_DATE]).format("ddd MMM Do YYYY HH:mm [GMT]ZZ")+" ("+/\((.*)\)/.exec(new Date().toString())[1]+")",
    subject: 'Seat Reserved: ' + event[FIELDS.EVENT.EVENT_NAME] + " @ " +
      moment(event[FIELDS.EVENT.EVENT_START_DATE]).toDate() + ' - ' +
      Helper.getLocalTimeFromUTC_24hr(event[FIELDS.EVENT.EVENT_END_TIME]),
    description: event[FIELDS.EVENT.EVENT_DETAIL_LONG],
    event_image: event[FIELDS.EVENT.EVENT_IMAGE],
    event_id: `${process.env.REACT_APP_PROJECT_DOMAIN}/event/${event.uid}`,
    organizer_name : event[FIELDS.EVENT.EVENT_HOST_DETAIL][FIELDS.USER.USER_NAME],
    organizer_id: `${process.env.REACT_APP_PROJECT_DOMAIN}/profile/${event[FIELDS.EVENT.EVENT_HOST_ID]}`,
    user_name: `${user_name ? `${user_name}` : ""}`
  })
  return await fetch(url, { method: "GET" });
};

export const sendInvitation = async (email, event) => {
  const url =
    base +
    "sendInvitation?" +
    jsonToUrlEncode({
      dest: email,
      event_link: `${process.env.REACT_APP_PROJECT_DOMAIN}/event/${event.uid}`,
      event_name: event[FIELDS.EVENT.EVENT_NAME],
      subject: "Invitation: " + event[FIELDS.EVENT.EVENT_NAME],
      host_name: event[FIELDS.EVENT.EVENT_HOST_DETAIL][FIELDS.USER.USER_NAME],
    });
  return await fetch(url, { method: "GET" });
};

export const sendEventCancelled = async (email, event) => {
  const url =
    base +
    "sendEventCancelled?" +
    jsonToUrlEncode({
      dest: email,
      path: event[FIELDS.EVENT.EVENT_ICS],
      zoom_link: event[FIELDS.EVENT.EVENT_ZOOM_LINK],
      event_name: event[FIELDS.EVENT.EVENT_NAME],
      zoom_id: event[FIELDS.EVENT.EVENT_ZOOM_ID],
      zoom_password: event[FIELDS.EVENT.EVENT_ZOOM_PASSWORD],
      when: moment(event[FIELDS.EVENT.EVENT_START_DATE]).format("ddd MMM Do YYYY HH:mm [GMT]ZZ") + " (" + /\((.*)\)/.exec(new Date().toString())[1] + ")",
      subject:
        "Event has been cancelled : " +
        event[FIELDS.EVENT.EVENT_NAME] +
        " @ " +
        moment(event[FIELDS.EVENT.EVENT_START_DATE]).toDate() +
        " - " +
        Helper.getLocalTimeFromUTC_24hr(event[FIELDS.EVENT.EVENT_END_TIME]),
      description: event[FIELDS.EVENT.EVENT_DETAIL_LONG],
    });
  return await fetch(url, { method: "GET" });
};

export const sendEditEventEmail = async (email, event) => {
  const url =
    base +
    "sendEditEventEmail?" +
    jsonToUrlEncode({
      dest: email,
      path: event[FIELDS.EVENT.EVENT_ICS],
      zoom_link: event[FIELDS.EVENT.EVENT_ZOOM_LINK],
      event_name: event[FIELDS.EVENT.EVENT_NAME],
      zoom_id: event[FIELDS.EVENT.EVENT_ZOOM_ID],
      zoom_password: event[FIELDS.EVENT.EVENT_ZOOM_PASSWORD],
      when: moment(event[FIELDS.EVENT.EVENT_START_DATE]).format("ddd MMM Do YYYY HH:mm [GMT]ZZ") + " (" + /\((.*)\)/.exec(new Date().toString())[1] + ")",
      subject:
        "Event detail update : " +
        event[FIELDS.EVENT.EVENT_NAME] +
        " @ " +
        moment(event[FIELDS.EVENT.EVENT_START_DATE]).toDate() +
        " - " +
        Helper.getLocalTimeFromUTC_24hr(event[FIELDS.EVENT.EVENT_END_TIME]),
      description: event[FIELDS.EVENT.EVENT_DETAIL_LONG],
    });
  return await fetch(url, { method: "GET" });
}

export const sendNotification = async (email,data)=>{
  const url = base + "sendNotification?" + jsonToUrlEncode({
    email:email,
    user:data.chat_user_name,
    event_id: `${process.env.REACT_APP_PROJECT_DOMAIN}/event/${data.chat_event_id}`,
  }) 
  return await fetch(url, { method: "GET" });
}


function jsonToUrlEncode(json) {
  return Object.keys(json)
    .map((k) => decamelize(encodeURIComponent(k)) + "=" + encodeURIComponent(json[k]))
    .join("&");
}

export const inviteMembersToChannel = async (emails, email, username, channelname, channelId) => {
  const url = base + "inviteToChannel?" + jsonToUrlEncode({ emails, username, channelname, useremail: email, channelId });
  return await fetch(url, { method: "GET" });
};

export const acceptRejectUser = async (userEmail, email, name) => {
  const url = base + "acceptRejectUser?" + jsonToUrlEncode({ userEmail, email, name });
  return await fetch(url, { method: "GET" });
};