import React, { Component } from 'react'
import Helper from '../Helper/helper';
import { FIELDS } from '../Firebase/Constants';

class EventComponent extends Component {
  render() {
    const hostname = window.location.hostname === "localhost" ? "assembly-dev-3c297.web.app" : window.location.hostname
    return (
        <div className="ev-v2-component">
            {this.props.events && this.props.events.map((el, key) => {
                const date = Helper.getDate(el[FIELDS.EVENT.EVENT_START_DATE])
                const startTime = Helper.tConvert(el[FIELDS.EVENT.EVENT_START_TIME]);
                const endTime = Helper.getLocalTimeFromUTC(el[FIELDS.EVENT.EVENT_END_TIME]);
                return (
                    <div className="event" key={key}>
                        <h3><a rel="noopener noreferrer" target="_blank" href={`https://${hostname}/${this.props.isApproved ?"event":"unapproved-event"}/${el.uid}`}>{el[FIELDS.EVENT.EVENT_NAME]}</a></h3>
                        <span>Hosted by : {el[FIELDS.EVENT.EVENT_HOST_DETAIL][FIELDS.USER.USER_NAME]}</span>
                        <span>Event Date : {date.day + " " + date.month + " " + date.year}</span>
                        <span>Time : {startTime + " - " + endTime}</span>
                        {this.props.isApproved ?(
                            <div className="event-btn-absolute">
                                {!el[FIELDS.EVENT.EVENT_IS_DELETE] && (
                                    <a href="# " className="invite-btn edit-btn" onClick={(e) => { this.props.onEditEventClick(e, el.uid) }}>Edit Event</a>
                                )}
                                {el[FIELDS.EVENT.EVENT_IS_DELETE] ? <a href="# " className="invite-btn delete-btn">Deleted</a> : <a href="# " className="invite-btn" onClick={(e) => { this.props.onDeleteEvent(e, el) }}>Delete</a>}
                                {!el[FIELDS.EVENT.EVENT_IS_DELETE] && (
                                    <a href="# " className="invite-btn invite-btn-col" onClick={(e) => { this.props.onInviteClick(e, el) }}>Invite</a>
                                )}
                            </div>
                        ):( 

                            <React.Fragment>
                                <span>Meeting link : {el[FIELDS.EVENT.EVENT_ZOOM_LINK]}</span>
                                <span>Meeting id : {el[FIELDS.EVENT.EVENT_ZOOM_ID]}</span>
                                <span>Meeting password : {el[FIELDS.EVENT.EVENT_ZOOM_PASSWORD]}</span>
                                <div className="event-btn-absolute">
                                    <a href="# " className="invite-btn edit-btn" onClick={(e) => { this.props.onApproveEventClick(e, el.uid) }}>Approve</a>
                                </div>
                            </React.Fragment>
                        )}
                        
                    </div>
                )
            })}
        </div>
    )
  }
}

export default EventComponent
