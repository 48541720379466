import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "./Assets/Style/style.css";
import CreateEvent from "./Admin/CreateEvent";

// import Auth Provider for sharing global state to the app
import { AuthProvider } from "./Firebase/AuthProvider";
import PrivateRoute from "./Helper/PrivateRoute";
import EventsList from "./Admin/EventsList";
import Users from "./Admin/Users";
import AdminLogin from "./Admin/AdminLogin";
import AdminRoute from "./Helper/AdminRoute";
import ResetPassword from "./containers/ResetPassword";
import PostDetail from "./containers/PostDetail";
import EventDetail from "./containers/EventDetail";
import Home from "./containers/Home";
import Profile from "./containers/Profile";
import Channel from "./containers/Channel";
import Channels from "./containers/Channels";
import CreateChannel from "./containers/CreateChannel";

import Messenger from "./components/Messenger";
import Unsubscribe from './containers/Unsubscribe';

class AppLayout extends Component {
  constructor(props){
    super(props)
    this.state={
      isTokenFound: false,
      waitForUserDetails: true
    }
  }

  setToken = (value)=>{
    this.setState({isTokenFound:value})
  }


  render() {
    return (
      <>
      <AuthProvider>
        <Router>
          <Switch>
            {this.state.waitForUserDetails &&<Route exact path="/" component={Home} />}
            <Route path="/resetpassword" component={ResetPassword} />
            <Route exact path="/admin/login" component={AdminLogin} />
            <AdminRoute path="/admin/events" component={EventsList} />
            <AdminRoute path="/admin/users" component={Users} />
            <AdminRoute path="/admin" component={CreateEvent} />
            <PrivateRoute path="/feeds" component={Home} />

            <Route path="/profile/:ID" component={Profile} />
            <Route path="/post/:ID" component={PostDetail} />
            <Route path="/event/:ID" component={EventDetail} />

            {/* latest routes */}
            <Route path="/tribes" component={Channels} />
            <Route path="/channel/:ID" component={Channel} />
            <PrivateRoute path="/chat" component={Messenger} />
            <PrivateRoute path="/unsub/:ID" component={Unsubscribe} />
            <Route path="/createChannel" component={CreateChannel} />
            <Route path="/:slug" component={Channel} />

            <Route
              path="*"
              component={() => {
                return "error 404";
              }}
            />
          </Switch>
        </Router>
      </AuthProvider>
      </>
    );
  }
}

export default AppLayout;
