import React, { Component }  from 'react'
import Header from '../components/header'
import Loader from '../components/Loader'
import RightSideBar from '../components/RightSideBar'
import SideBar from '../components/Sidebar'
import CreatChannel from '../components/createChannel'

class AddNewChannel extends Component {

    constructor(props) {
        super(props);
    }
      render() {return (
        <React.Fragment>
        <Loader/>

        <Header props={this.props} />
        <div className="DashboardMainContent">
            <SideBar currentPage="home" />
            <div className="channels_container">
                <div className="channels_form">
                    <h3>Create Tribe</h3>
                    <CreatChannel />
                    
                </div>
            </div>
            <RightSideBar />
        </div>
      </React.Fragment>
    )}
}

export default AddNewChannel
