import React, { Fragment, Component } from "react";

// Import components
//import ActivityBox from "../profile/activityBox";
import StoryPostComponent from "../../components/StoryPostComponent";
import { AuthContext } from "../../Firebase/AuthProvider";
// Import styles
import "../../Assets/Style/profile.scss";
import { addReaction } from "../../Firebase/Events";
import PostComponentUI from "../../components/channel/PostComponentUI";
import { withPost } from "../../Firebase/Schemas";
import { getUserDetail } from "../../Firebase/Users";
import { FIELDS, postQueryLimit } from "../../Firebase/Constants";
import { getChannelById, getChannelPostCount, getChannelPosts } from "../../Firebase/Channels";
import FacebookLoader from "../../components/FacebookLoader";
import Helper from "../../Helper/helper";
import MembersList from "../MembersList";

class ChannelPosts extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super();
    this.state = {
      posts: [],
      initialCall: false,
      postsLimit: postQueryLimit,
      totalPosts: 0,
      channelDetail: ""
    };
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  async componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    // if(!this.props.profile && !this.props.postDetail){
    //   this.props.history.push('/')
    // }
    const result = await getChannelPostCount(this.props.channelID);
    this.setState({
      totalPosts: result
    })
    this.realTimePostUpdate();
  }

  async componentDidUpdate(prevState) {
    if(prevState.channelInfo.name !== this.props.channelInfo.name) {
      const result = await getChannelPostCount(this.props.channelID);
      this.setState({
        totalPosts: result
      })
      this.realTimePostUpdate();
    }
    if (this.state.posts.length === 0 && !this.state.initialCall) {
      const result = await getChannelPostCount(this.props.channelID);
      this.setState({
        totalPosts: result
      })
      
      this.setState(
        {
          initialCall: true,
        },
        () => {
          this.state.posts.sort(
            (a, b) => b.post_created_at - a.post_created_at
          );
        }
      );
    }
  }

  // Update Post when something change like add/Update/Delete
  realTimePostUpdate = async (updatedPost, isBottom, isAdd) => {
    var post = "";
    this.context.showLoader(true)
    let limitPost = postQueryLimit;
    
    if (updatedPost) {
      
      updatedPost.sort((a, b) => b.post_created_at - a.post_created_at);
      let startAfter =
        updatedPost[updatedPost.length - 1]?.[FIELDS.WALL_POST.POST_CREATED_AT];
        post = await getChannelPosts(this.props.channelID, startAfter, limitPost, "desc");
      
    } else {
      
      if(isBottom) {
        this.context.showLoader(true)
        let prevLimit = this.state.postsLimit;
        limitPost = this.state.postsLimit + postQueryLimit
        this.setState({
          postsLimit: limitPost
        })
        if(this.props.channelID && this.state.totalPosts > prevLimit) {
          let startAfter =
            this.state.posts[this.state.posts.length - 1]?.[FIELDS.WALL_POST.POST_CREATED_AT];
            post = await getChannelPosts(this.props.channelID, startAfter, limitPost, "desc");
        }
        else {
          //this.context.showLoader(false)
        }
      }
      else {
        console.log("yess here", this.props.channelID)
        post = await getChannelPosts(this.props.channelID, '', limitPost, "desc");
      }
      
    }
    let newPostsList = isAdd ? [] : this.state.posts;
    if(post) {
      await post.onSnapshot(async (snapshot) => {
        await this.getNewPosts(snapshot, newPostsList);
      });
      
      // setTimeout(() => {
      //   this.context.showLoader(false);
      // },5000)
    }
    else {
      this.context.showLoader(false)
    }
    
  };

  getNewPosts = async (snapshot, newPostsList) => {
    await snapshot.docChanges().forEach(async (change) => {
      if (change.type === "added") {
        let data = change.doc.data();
        let post = withPost(data);
        post["id"] = change.doc.id;
        let userReactName = [];
        // for (const id of post.post_react) {
        //     let userName = await getUserDetail(id).then((data) => data?.name ? data.name : "");
        //     if(userName) {
        //       userReactName.push(userName)
        //     }
        // }
        // console.log("poostttt", post["post_message"])
        // await Promise.all(post.post_react.map(async (el) => {
        //   let userName = await getUserDetail(el).then((data) => data?.name ? data.name : "");
        //   console.log("userName", userName)
        //     if(userName) {
        //       userReactName.push(userName)
        //     }
        // }));
        
        newPostsList.push(post)
        if(!this.props.channelID && post.post_channel_id) {
          post["channelInfo"] = await this.getChannelInfo(post.post_channel_id);
        }
        
        post["user_react_name"] = userReactName;
        
      }
      //newPostsList = this.removeDuplicates(newPostsList, "id");
      // this.setState(
      //   {
      //     posts: newPostsList,
      //   },
      //   () => {
      //     // newPostsList.sort(
      //     //   (a, b) => b.post_created_at - a.post_created_at
      //     // );
      //   }
      // );

      if (change.type === "modified") {
        let updatedPost = [...this.state.posts];
        let data = change.doc.data();
        let post = await withPost(data);
        post["id"] = change.doc.id;
        let userReactName = [];
        for (const id of post.post_react) {
          if(data.name) {
            let userName = await getUserDetail(id).then((data) => data.name);
            userReactName.push(userName);
          }
        }
        post["user_react_name"] = userReactName;
        let index = updatedPost.findIndex(
          (post) => post.id === change.doc.id
        );

        if (index === -1) {
          updatedPost.push(post);
        } else {
          updatedPost[index] = post;
        }
        this.context.showLoader(false)
        this.setState(
          {
            posts: updatedPost,
          },
          () => {
            // this.state.posts.sort(
            //   (a, b) => b.post_created_at - a.post_created_at
            // );
          }
        );
        
      }

      if (change.type === "removed") {
        let updatedPost = [...this.state.posts];
        let data = change.doc.data();
        let post = await withPost(data);
        post["id"] = change.doc.id;

        let index = updatedPost.findIndex(
          (post) => post.id === change.doc.id
        );

        updatedPost.splice(index, 1);
        this.setState(
          {
            posts: updatedPost,
          },
          () => {
            // this.state.posts.sort(
            //   (a, b) => b.post_created_at - a.post_created_at
            // );
          }
        );
      }
    });
    //await Promise.all(snapshot.docChanges());
    this.context.showLoader(false)
    console.log("final posts", newPostsList)
    this.setState(
      {
        posts: newPostsList,
      },
      () => {
        // newPostsList.sort(
        //   (a, b) => b.post_created_at - a.post_created_at
        // );
      }
    );
  }

   removeDuplicates = (originalArray, prop) => {
    var newArray = [];
    var lookupObject  = {};

    for(var i in originalArray) {
       lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for(i in lookupObject) {
        newArray.push(lookupObject[i]);
    }
     return newArray;
}

  getChannelInfo = async (channelID) => {
    const result = await getChannelById(channelID);
    this.setState({ channelDetail: result });
    return result;
  };

  handleScroll = () => {
      //if(this.props.channelInfo) {
        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
        const body = document.body;
        const html = document.documentElement;
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
        const windowBottom = windowHeight + window.pageYOffset;
        if (windowBottom >= docHeight && this.context.state.showLoader === false) {
          this.realTimePostUpdate('', true)
        } 
    //}
  }

  updateReaction = (postId) => {
    let allPosts = [...this.state.posts];

    const userID = this.context.state.currentUser;
    let index = allPosts.findIndex((e) => e.id === postId);

    if (allPosts[index]["post_react"].includes(userID)) {
      allPosts[index]["post_react"].splice(
        allPosts[index]["post_react"]?.indexOf(userID),
        1
      );
    } else {
      allPosts[index]["post_react"].push(userID);
    }

    this.setState(
      {
        posts: allPosts,
      },
      async () => {
        await addReaction(postId, userID);
      }
    );
  };

  isJoin = () => {
    if(this.context.state.currentUser !== this.props.channelInfo.channel_owner) {
      if(this.props.channelInfo.members.length > 0) {
        return this.props.channelInfo.members.find((el) =>{
          return el.id === this.context.state.currentUser
        })
      }
      else return false;
    }
    else return true;
    
}

  render() {
    return (
      <Fragment>
        <div className={`profile-wrapper ${this.props.channelID && "double-grid"}`}>
          {/* {
            !this.props.isChannelPage &&
            <ChannelHeader setChannelModal={this.props.setChannelModal}  />
          } */}
          {this.props.channelID && (
            <div className="session-div">
              <div className="RightSideBarContainer" style={{marginTop: `0px`}}>
              
              {this.props.channelInfo.events.map((item, key) => {
                return new Date() < new Date(item.event_start_date_time) ? (
                  <div className="meeting-live-dive up-next">

                  <div className="SessionContent">
                    <div className="agenda-view">
                      <h1>{item.event_name}</h1>
                      <div className="inner">
                        <div className="speakers">
                          {
                            item.speakerList?.map((el,i) => {
                              if(i <= 4){
                              return (
                                <div className="img">
                                  <div className="host-image">
                                    <img src={el.image} alt="noImage" />
                                  </div>
                                  <div className="overflow-modal">
                                    <h4>{el.name}</h4>
                                  </div>
                                </div>
                              )
                              }
                            })
                          }
                        </div>

                        <div className="date">
                          <p>{Helper.getEventDate(item?.event_start_date_time, item?.event_end_time)}</p>
                        </div>
                      </div>
                    </div>
                  </div>
            </div>

                ) : ''
              })
              }

              <MembersList list={this.props.channelInfo.speakers} />



          </div>
            </div>
          )}

          <div className={window.location.pathname.includes('/channel/') ? "HomeContainer channelContainer" : "HomeContainer"} >
            <div className="ContentLeftWrap">
              {
                <StoryPostComponent channelName={this.props.channelInfo?.name} realTimePostUpdate={this.realTimePostUpdate} history={this.props.history} updateChannelInfo={this.props.updateChannelInfo} channelID={this.props.channelID}/> 
              }
              <PostComponentUI
                  isEvenPosts={true}
                  updateReaction={this.updateReaction}
                  history={this.props.history}
                  posts={this.state.posts}
                  channelID={this.props.channelID}
                  channelInfo={this.props.channelInfo}
                  checkIsAdmin={this.props.checkIsAdmin}
                  realTimePostUpdate={this.realTimePostUpdate}
              />
              {
                this.context.state.showLoader &&
                <FacebookLoader />
              }
            </div>
            {/* <div className="ContentRightWrap">
              <PostComponentUI
                isEvenPosts={false}
                updateReaction={this.updateReaction}
                history={this.props.history}
                posts={this.state.posts.filter((el, index) => {
                  return index % 2 !== 0;
                })}
                channelID={this.props.channelID}
              />
              {
                this.context.state.showLoader &&
                <FacebookLoader />
              }
            </div> */}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ChannelPosts;
