import React from 'react';
import './style.scss'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useEffect } from 'react';
import BannerTribe from "../../Assets/Images/banner-placeholder.jpeg";


const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3
    }
  };

function TribeStory({ channelsList, changeChannelPosts, channelId, fetchChannelList }) {
    useEffect(()=>{
        if(channelsList.length === 0) {
            fetchChannelList()
        }
    },[channelsList, fetchChannelList])
    return (
        <div className="tribe-story" id="tribe_story">
            <div className="inner">
                {channelsList.length > 0 ?
                    <Carousel responsive={responsive} draggable={true}>
                    {channelsList.map((channel,index)=>{
                        return (
                            <div key={index} className={channelId === channel.id ? "active-channel channel-layout" : "channel-layout"} title={channel.data().name } onClick={() => changeChannelPosts(channel.data(), channel.id)}>
                                <div className="cover-image">
                                    <img src={channel?.data().cover_image ? channel?.data().cover_image : BannerTribe} alt={channel?.name} />
                                </div>
                                <div className="channel-name">
                                    {channel.data().name}
                                </div>
                            </div>
                        )
                    })}
                    </Carousel>
                :
                    <React.Fragment>
                        <div className="channel-layout">
                            <div className="cover-image">
                            </div>
                            <div className="channel-name">
                            </div>
                        </div>
                        <div className="channel-layout">
                            <div className="cover-image">
                            </div>
                            <div className="channel-name">
                            </div>
                        </div>
                        <div className="channel-layout">
                            <div className="cover-image">
                            </div>
                            <div className="channel-name">
                            </div>
                        </div>
                        <div className="channel-layout">
                            <div className="cover-image">
                            </div>
                            <div className="channel-name">
                            </div>
                        </div>
                        <div className="channel-layout">
                            <div className="cover-image">
                            </div>
                            <div className="channel-name">
                            </div>
                        </div>
                        <div className="channel-layout">
                            <div className="cover-image">
                            </div>
                            <div className="channel-name">
                            </div>
                        </div>
                    </React.Fragment>
                }
                
            </div>
        </div>
    )
}

export default TribeStory
