import React, { Component } from "react";
import { FIELDS } from "../../Firebase/Constants";
import { AuthContext } from "../../Firebase/AuthProvider";
import { uploadEventImage } from "../../Firebase/StorageBucket";
import {
  getSpecificEventData,
  updateEvent,
  userEventGoingUser,
  createEvent
} from "../../Firebase/Events";
import UserModal from "../UserModal";
import { app } from "../../Firebase/firebase-config";
import {
  sendEditEventEmail,
} from "../../Firebase/Mailer";
import speakerIcon from '../../Assets/Images/userPlaceholder.jpeg'
import Helper from "../../Helper/helper";
import Select from 'react-select';
import { searchSpecificUser } from '../../Firebase/Users';
import userIcon from '../../Assets/Images/profile.png'

class CreateLiveEvent extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      formData: {
        event_name: "",
        event_host: "",
        event_host_id: "",
        event_start_date_time: "",
        event_start_time: "",
        event_end_time: "",
        event_detail_long: "",
        event_date_timestamp: ""
      },
      day: "",
      month: "",
      year: "",
      duration: 1,
      minutes: 0,
      errorText: false,
      showModal: false,
      editEvent: false,
      speakerList: [],
      selectedOption: null,
      searchValue: "",
      searchResults: []
    };
  }

  componentDidMount() {
    // get event detail if event id is available in url
    const urlParams = new URLSearchParams(window.location.search);
    const userID = urlParams.get("userId");
    const tableName = urlParams.get("approve");
    let eventID = urlParams.get("eventID");
    if (eventID) {
      this.context.showLoader(true);
      this.getEventDetail(tableName, eventID)
        .then((detail) => {
          if (detail) {
            detail = detail[0];
            let eventStartDate = new Date(
              detail[FIELDS.EVENT.EVENT_START_DATE]
            );
            let eventEndDate = new Date(detail[FIELDS.EVENT.EVENT_END_TIME]);

            const eventDay = eventStartDate.getDate();
            const eventMonth = eventStartDate.getMonth();
            const eventYear = eventStartDate.getFullYear();
            const eventToday =
              eventYear + "/" + (eventMonth + 1) + "/" + eventDay;

            let diff = eventEndDate.getTime() - eventStartDate.getTime();
            var eventDuration = Math.floor(diff / 1000 / 60 / 60);
            var eventMinutes = Math.floor(
              (diff % (1000 * 60 * 60)) / (1000 * 60)
            );

            this.setState(
              {
                formData: {
                  ...this.state.formData,
                  event_name: detail[FIELDS.EVENT.EVENT_NAME],
                  event_host: "",
                  event_host_id: detail[FIELDS.EVENT.EVENT_HOST_ID],
                  event_zoom_link: detail[FIELDS.EVENT.EVENT_ZOOM_LINK],
                  event_zoom_id: detail[FIELDS.EVENT.EVENT_ZOOM_ID],
                  event_zoom_password: detail[FIELDS.EVENT.EVENT_ZOOM_PASSWORD],
                  event_start_date_time: detail[FIELDS.EVENT.EVENT_START_DATE],
                  event_start_time: detail[FIELDS.EVENT.EVENT_START_TIME],
                  event_end_time: detail[FIELDS.EVENT.EVENT_END_TIME],
                  event_image: detail[FIELDS.EVENT.EVENT_IMAGE],
                  event_capacity: parseInt(detail[FIELDS.EVENT.EVENT_CAPACITY]),
                  event_detail_long: detail[FIELDS.EVENT.EVENT_DETAIL_LONG],
                  event_host_partner_image:
                    detail[FIELDS.EVENT.EVENT_HOST_PARTNER_IMAGE],
                  event_date_timestamp:
                    detail[FIELDS.EVENT.EVENT_DATE_TIMESTAMP],
                  event_is_approved: detail[FIELDS.EVENT.EVENT_IS_APPROVED],
                  event_going: detail[FIELDS.EVENT.EVENT_GOING],
                  event_testimonials: detail[FIELDS.EVENT.EVENT_TESTIMONIAL]
                    ? detail[FIELDS.EVENT.EVENT_TESTIMONIAL]
                    : ""
                },
                event_image_preview: detail[FIELDS.EVENT.EVENT_IMAGE],
                event_host_partner_image_preview:
                  detail[FIELDS.EVENT.EVENT_HOST_PARTNER_IMAGE],
                day: eventDay,
                month: eventMonth,
                year: eventYear,
                today: eventToday,
                duration: eventDuration,
                minutes: eventMinutes,
                editEvent: true,
                eventID: eventID,
                userID: userID,
                tableName: tableName,
              },
              () => {
                this.context.showLoader(false);
              }
            );
          }
        })
        .catch((err) => {
          if (err) this.context.showLoader(false);
        });
    } else {
      let duration = parseInt(this.state.duration);
      let minutes = parseInt(this.state.minutes);
      // set current date and time to the form
      let now = new Date();
      const day = now.getDate();
      const month = now.getMonth();
      const year = now.getFullYear();
      const time =
        ("0" + now.getHours()).slice(-2) +
        ":" +
        ("0" + now.getMinutes()).slice(-2);

      // get the UTC date
      let today = year + "/" + (month + 1) + "/" + day;
      let timePlusSecond = time + ":" + ("0" + now.getSeconds()).slice(-2);
      let date = new Date(today + " " + timePlusSecond);

      let timeTOMilisecond = duration * 3600 * 1000 + minutes * 60 * 1000;

      // event end time is plus 1 hour to event start time by default
      this.setState({
        formData: {
          ...this.state.formData,
          [FIELDS.EVENT.EVENT_START_DATE]: date.toUTCString(),
          [FIELDS.EVENT.EVENT_DATE_TIMESTAMP]: date.getTime(),
          [FIELDS.EVENT.EVENT_START_TIME]: time,
          [FIELDS.EVENT.EVENT_END_TIME]: new Date(
            date.setTime(date.getTime() + timeTOMilisecond)
          ).toUTCString(),
        },
        day: day,
        month: month,
        year: year,
        today: today,
      });
    }
  }

  // get specific event detail
  getEventDetail = async (tableName, id) => {
    let eventDetail = await getSpecificEventData(tableName, id);
    return eventDetail;
  };

  handleChange = async selectedOption => {
    this.setState({ searchValue: selectedOption });
    if(selectedOption){
      const result = await searchSpecificUser(selectedOption.toLowerCase());
      let users = result.map(el=>{
        el.id = el.uid
        return el;
      })
      let newResult = users.map((item) => {
        return { value: item.name, label:  <div className="userImg dropdownWithImage"><a href={() => false} onClick={() => this.handleChangeDropdown(item)}><img className="img-fluid" src={item.image ? item.image : userIcon} alt="noImage" />&nbsp;&nbsp;{item.name}</a></div> }
      })
      this.setState({ searchResults: newResult })
    }
    else {
      this.setState({ searchResults: [] })
    }
  };

  // on Input change handler
  onChangeHandler = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        event_host_id: this.context?.state && this.context?.state?.currentUser,
        [e.target.name]: e.target.value,
      },
    });
  };

  // Handle Image
  onHandleImage = (e) => {
    const image = e.target.files[0];
    const name = e.target.name;

    this.setState({
      [name]: image,
      [name + "_preview"]: URL.createObjectURL(image),
    });
  };

  compressImage = (e) => {
    const width = 600;
    //const fileName = e.target.files[0].name;
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;
      img.onload = () => {
        const elem = document.createElement("canvas");
        elem.width = width;
        const scaleFactor = width / img.width;
        elem.height = img.height * scaleFactor;
        const ctx = elem.getContext("2d");
        // img.width and img.height will contain the original dimensions
        ctx.drawImage(img, 0, 0, width, img.height * scaleFactor);
        ctx.canvas.toBlob(
          (blob) => {
            // const file = new File([blob], fileName, {
            //   type: "image/jpeg",
            //   lastModified: Date.now(),
            // });
          },
          "image/jpeg",
          1
        );
      };
    };
  };

  // Handle Remove Image
  onImageRemove = (e, name) => {
    e.preventDefault();
    if (name === "event_image_preview") {
      this.setState({
        [FIELDS.EVENT.EVENT_IMAGE]: "",
        [name]: false,
      });
    } else {
      this.setState({
        [FIELDS.EVENT.EVENT_HOST_PARTNER_IMAGE]: "",
        [name]: false,
        formData: {
          ...this.state.formData,
          event_host_partner_image: "",
        },
      });
    }
  };

  // Date Handler
  onDateChange = () => {
    let days = [];
    let selectedMonth = parseInt(this.state.month);
    let selectedYear = parseInt(this.state.year);
    let EndDay = 31;

    // Month
    let months = [];
    let monthsName = [
      "Jan",
      "Feb",
      "March",
      "April",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];

    // get the currrent year and add plus one for next year
    let now = new Date();
    let year = now.getFullYear();
    let years = [year, year + 1];

    // if selectedMonth is Feb then show 28 days or is leap year then show 29 days
    if (selectedMonth === 1) {
      var isleap =
        selectedYear % 4 === 0 &&
        (selectedYear % 100 !== 0 || selectedYear % 400 === 0);
      if (isleap) {
        EndDay = 29;
      } else {
        EndDay = 28;
      }
    }

    // if selected Month is April, June, September, November then show 30 days
    if (
      selectedMonth === 3 ||
      selectedMonth === 5 ||
      selectedMonth === 8 ||
      selectedMonth === 10
    ) {
      EndDay = 30;
    }

    for (var i = 1; i <= EndDay; i++) {
      days.push(i);
    }

    for (var j = 0; j <= 11; j++) {
      months.push({ name: monthsName[j], month: j });
    }

    // event duration
    let duration = [];

    for (var k = 0; k <= 5; k++) {
      duration.push(k);
    }

    // event Minutes
    let minutes = [];

    for (var l = 0; (i = l <= 55); l += 5) {
      minutes.push(l);
    }

    return { days, months, years, duration, minutes };
  };

  // on Date change Handler
  onSelectChangeDate = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    let Day = this.state.day;
    let Month = this.state.month;
    let Year = this.state.year;
    let duration = parseInt(this.state.duration);
    let minutes = parseInt(this.state.minutes);

    let Today = this.state.today;
    let selectedTime = this.state.formData.event_start_time;

    // on Day change handler
    if (e.target.value !== "Day" && name === "day") {
      this.setState({
        [name]: value,
      });
      Day = value;
    }

    // on Month change handler
    if (e.target.value !== "Month" && name === "month") {
      this.setState({
        [name]: value,
      });
      Month = value;
    }

    // on Month change handler
    if (e.target.value !== "Year" && name === "year") {
      this.setState({
        [name]: value,
      });
      Year = value;
    }

    // on Duration change Hours
    if (name === "duration") {
      this.setState({
        [name]: value,
      });
      duration = parseInt(value);
    }

    // on Duration change Minutes
    if (name === "minutes") {
      this.setState({
        [name]: value,
      });
      minutes = parseInt(value);
    }

    Today = Year + "/" + (parseInt(Month) + 1) + "/" + Day;
    let eventStartDateTime = new Date(Today + " " + selectedTime);

    let timeTOMilisecond = duration * 3600 * 1000 + minutes * 60 * 1000;

    this.setState({
      formData: {
        ...this.state.formData,
        [FIELDS.EVENT.EVENT_START_DATE]: eventStartDateTime.toUTCString(),
        [FIELDS.EVENT.EVENT_DATE_TIMESTAMP]: eventStartDateTime.getTime(),
        [FIELDS.EVENT.EVENT_END_TIME]: new Date(
          eventStartDateTime.setTime(
            eventStartDateTime.getTime() + timeTOMilisecond
          )
        ).toUTCString(),
      },
      today: Today,
    });
  };

  // on change Time handler
  onChangeTime = (e) => {
    let date = this.state.today;
    let eventStartDateTime = new Date(date + " " + e.target.value);
    let duration = parseInt(this.state.duration);
    let minutes = parseInt(this.state.minutes);

    let timeTOMilisecond = duration * 3600 * 1000 + minutes * 60 * 1000;

    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: e.target.value,
        [FIELDS.EVENT.EVENT_START_DATE]: eventStartDateTime.toUTCString(),
        [FIELDS.EVENT.EVENT_DATE_TIMESTAMP]: eventStartDateTime.getTime(),
        [FIELDS.EVENT.EVENT_END_TIME]: new Date(
          eventStartDateTime.setTime(
            eventStartDateTime.getTime() + timeTOMilisecond
          )
        ).toUTCString(),
      },
    });
  };

  // on Submit button
  onSubmitHandler = async (e) => {
    e.preventDefault();
    const { formData } = this.state;
    const validate = this.validate();

    // if error exit then show error and return
    if (validate) {
      this.setState({ errorText: validate });
      return;
    } else {

      this.setState({ errorText: false });
      this.context.showLoader(true);

      // Event Image
      const eventImageFile = this.state.event_image;
      let eventImageNewURL = "";
      // if eventImage exist then get the file path(store) in firebase storage and replace that image otherwise upload new image
      if (eventImageFile && formData.event_image) {
        // get the current event cover Image URL
        let eventImageURL = formData.event_image;
        // get the current firestore storage PATH of event cover image
        let eventImagePath = await this.getImageUrl(eventImageURL);
        // upload the new event cover image to the same PATH and get the UPDATED URL
        eventImageNewURL = await uploadEventImage(
          eventImageFile,
          eventImagePath
        );
        // change the previous image URL to the NEW ONE
        formData.event_image = eventImageNewURL;
      } else if (eventImageFile) {
        let now = new Date();
        let uniqueId = now.getTime();
        // IF event image is not exist then upload the new event IMAGE with unique ID
        eventImageNewURL = await uploadEventImage(
          eventImageFile,
          `images/events/${uniqueId}${eventImageFile.name}`
        );
        // ADD new event image URL to the formDATA
        formData.event_image = eventImageNewURL;
      }

      // event partner image
      const partnerImageFile = this.state.event_host_partner_image;
      let partnerImageNewUrl = "";
      if (partnerImageFile && formData.event_host_partner_image) {
        // get the current event partner image URL from event Detail
        let eventPartnerImageURL = formData.event_host_partner_image;
        // get the current firestore storage PATH of event partner image
        let eventPartnerImagePath = await this.getImageUrl(
          eventPartnerImageURL
        );
        // get the new URL of the uploaded event partner image
        partnerImageNewUrl = await uploadEventImage(
          partnerImageFile,
          eventPartnerImagePath
        );
        // Update the formData
        formData.event_host_partner_image = partnerImageNewUrl;
      } else if (partnerImageFile) {
        let now = new Date();
        let uniqueId = now.getTime();
        // IF event partner image is not exist then upload the new event partner IMAGE with unique ID
        partnerImageNewUrl = await uploadEventImage(
          partnerImageFile,
          `images/events/${uniqueId}${partnerImageFile.name}`
        );
        formData.event_host_partner_image = partnerImageNewUrl;
      }

      if (this.state.editEvent) {
        updateEvent(this.state.tableName, this.state.eventID, formData)
          .then((res) => {
            this.setState(
              {
                showModal: true,
                message: "Event detail updated",
              },
              async () => {
                this.context.showLoader(false);

                const userDetail = await userEventGoingUser(
                  this.state.formData
                );
                let userEmails = userDetail[
                  FIELDS.EVENT.EVENT_GOING_USER_DETAILS
                ].map((user) => user[FIELDS.USER.USER_EMAIL]);
                for (const email of userEmails) {
                  sendEditEventEmail(email, this.state.formData);
                }
              }
            );
          })
          .catch((err) => {
            if (err) {
              this.setState(
                {
                  showModal: true,
                  message: "Try sometime later",
                },
                () => {
                  this.context.showLoader(false);
                  this.props.history.push(
                    `/manage-shows?id=${this.state.userID}`
                  );
                }
              );
            }
          });
      } else {
        let oldEvents = [...this.props.eventsInfo]
        formData.speakerList = this.state.speakerList;
        let newEventInfo = formData;
        formData.event_speaker_list = this.state.speakerList;
        formData.event_channel_name = this.props.channelInfo.name;
        formData.event_channel_id = this.props.channelId;
        createEvent(formData)
          .then((res) => {
            this.setState(
              {
                showModal: false,
                formData: {
                  event_name: "",
                  event_host: "",
                  event_host_id: "",
                  event_zoom_link: "",
                  event_zoom_id: "",
                  event_zoom_password: "",
                  event_start_date_time: "",
                  event_end_time: "",
                  event_image: "",
                  event_capacity: 0,
                  event_detail_long: "",
                  event_host_partner_image: "",
                  event_date_timestamp: "",
                },
                event_host_partner_image_preview: false,
                event_image_preview: false,
                speakerList: []
              },
              () => {
                newEventInfo.eventId = res.id;
                oldEvents.push(newEventInfo);
                this.props.updateChannelInfo({ events: oldEvents }, "noMsg");
                this.context.showLoader(false);
              }
            );
          })
          .catch((err) => {
            if (err) {
              this.context.showLoader(false);
              this.setState({ errorText: "Something went wrong" });
            }
          });
        
        this.setState(
          {
            showModal: true,
            message: "Event has been created.",
            formData: {
              event_name: "",
              event_host: "",
              event_host_id: "",
              event_zoom_link: "",
              event_zoom_id: "",
              event_zoom_password: "",
              event_start_date_time: "",
              event_end_time: "",
              event_image: "",
              event_capacity: 0,
              event_detail_long: "",
              event_host_partner_image: "",
              event_date_timestamp: "",
            },
            event_host_partner_image_preview: false,
            event_image_preview: false,
            speakerList: []
          },
          () => {
            this.context.showLoader(false);
          }
        );
      }
    }
  };

  // get the image url of change
  getImageUrl = async (url) => {
    let storage = app.storage();
    let eventImageName = storage.refFromURL(url).getMetadata();
    const eventImagePath = await eventImageName.then(
      (metadata) => metadata.fullPath
    );
    return eventImagePath;
  };

  // validation on Create Live Event form
  validate = () => {
    const selectedYear = parseInt(this.state.year);
    const month = parseInt(this.state.month);
    const day = parseInt(this.state.day);

    const formData = this.state.formData;
    let error = "";

    // date after next day
    let dateAfterCurrentTime = new Date().getTime();

    if (formData.event_name === "") {
      error = "Please enter event name";
      return error;
    }
    if (formData.event_detail_long === "") {
      error = "Please enter event detail";
      return error;
    }
    if (
      (month === 3 || month === 5 || month === 8 || month === 10) &&
      day === 31
    ) {
      error = "Please select day";
      return error;
    }
    if (
      month === 1 &&
      selectedYear % 4 === 0 &&
      (selectedYear % 100 !== 0 || selectedYear % 400 === 0) &&
      day > 29
    ) {
      error = "Please select day";
      return error;
    }
    if (
      month === 1 &&
      !(
        selectedYear % 4 === 0 &&
        (selectedYear % 100 !== 0 || selectedYear % 400 === 0)
      ) &&
      day > 28
    ) {
      error = "Please select day";
      return error;
    }
    if (formData.event_date_timestamp < dateAfterCurrentTime) {
      error = "Event cannot be created with current date and time";
      return error;
    } else {
      error = false;
      return error;
    }
  };

  // close current Modal
  showModal = () => {
    this.setState(
      {
        showModal: !this.state.showModal,
      },
      () => {
        //this.props.close();
      }
    );
  };

  openPostMenu = (e) => {
    let menu = e.target.childNodes[0];
    if (menu.classList.contains("show-post-menu-dropdown")) {
      menu.classList.remove("show-post-menu-dropdown");
    } else {
      menu.classList.add("show-post-menu-dropdown");
    }
  };

  onAddSpeakerClick = (data) => {
    let oldSpeaker = [...this.state.speakerList];
    oldSpeaker.push(data);
    this.setState({ speakerList: oldSpeaker });
  }

  closeDropdown = (e) => {
    let dropDownMenu = e.target.parentNode.parentNode;
    dropDownMenu.classList.remove("show-post-menu-dropdown");
  };

  removeSpeaker = (name) => {
    let newSpeakers = [...this.state.speakerList]
    newSpeakers = newSpeakers.filter(el => {
      return el.name !== name;
    })
    this.setState({ speakerList: newSpeakers });
  }

  handleChangeDropdown = (userInfo) => {
    let data = {
      name: userInfo.name,
      title: userInfo.linkedin_profile,
      email: userInfo.email,
      link: userInfo.id,
      image: userInfo.image,
    };
    let oldSpeaker = [...this.state.speakerList];
    oldSpeaker.push(data);
    this.setState({ speakerList: oldSpeaker });
  }

  render() {
    let date = this.onDateChange();
    return (
      <div className="create-live-event-component">
        <div className="cle-inner">
          <form>
            {/* event name */}
            <div className="cle-input-group">
              <label htmlFor="cle_name">
                What's the name of your live show or workshop?
              </label>
              <input
                type="text"
                placeholder="Event Name"
                name={[FIELDS.EVENT.EVENT_NAME]}
                value={this.state.formData[FIELDS.EVENT.EVENT_NAME]}
                onChange={(e) => {
                  this.onChangeHandler(e);
                }}
                id="cle_name"
                className="cle-input"
              />
            </div>

            {/* event detail */}
            <div className="cle-input-group">
              <label htmlFor="cle_desc">
                Share details - host, speaker, why attend, etc
              </label>
              <textarea
                placeholder="Event detail"
                name={[FIELDS.EVENT.EVENT_DETAIL_LONG]}
                value={this.state.formData[FIELDS.EVENT.EVENT_DETAIL_LONG]}
                onChange={(e) => {
                  this.onChangeHandler(e);
                }}
                className="cle-textarea"
                id="cle_desc"
                cols="30"
                rows="10"
              ></textarea>
            </div>

            <div className="cle-input-group">
              <label htmlFor="cle_name">
                Meeting Link
              </label>
              <input
                type="text"
                placeholder="Meeting Link"
                name={[FIELDS.EVENT.EVENT_ZOOM_LINK]}
                value={this.state.formData[FIELDS.EVENT.EVENT_ZOOM_LINK]}
                onChange={(e) => {
                  this.onChangeHandler(e);
                }}
                id="cle_name"
                className="cle-input"
              />
            </div>
            {/* event meeting ID */}
            <div className="cle-input-group" style={{marginBottom:`25px`}}>
              <Select
                  className="search-bar-header"
                  value={this.state.selectedOption}
                  onInputChange={event => this.handleChange(event)}
                  inputValue={this.state.searchValue}
                  options={this.state.searchResults}
                  placeholder="Search Speaker"
                  noOptionsMessage={() => 'No speaker found.'}
              />
             </div>
            <div className={`ourSpeakers ${this.state.speakerList?.length === 0 && "remove-grid"}`}>
                {this.state.speakerList?.map((item, index) => {
                  return (
                    <div className="speaker" key={index}>
                      <div className="image-div">
                        <img
                          src={item.image?? speakerIcon}
                          alt="speaker"
                          className="img-fluid"
                        />
                      </div>

                      <div
                        className="pbm-post-menu float-right"
                        onClick={(e) => {
                          this.openPostMenu(e);
                        }}
                      >
                        <div
                          className="post-menu-dropdown"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <ul>
                            <li
                              className="delete"
                              onClick={(e) => {
                                this.removeSpeaker(item.name)
                                this.closeDropdown(e);
                              }}
                            >
                              Delete
                            </li>
                          </ul>
                        </div>
                      </div>
                      <p>{Helper.transformText(item.name)}</p>
                      <span>{item.title}</span>
                    </div>
                  );
                })}
          </div><br/><br/>
            {/* event date  */}
            <div className="cle-input-group">
              <label>What day is it happening?</label>
              <div className="cle-date-group">
                {/* Day */}
                <div className="select-option">
                  <select
                    name="day"
                    id="day"
                    value={this.state.day}
                    onChange={(e) => {
                      this.onSelectChangeDate(e);
                    }}
                  >
                    <option>Day</option>
                    {date.days.map((day) => {
                      return (
                        <option value={day} key={day}>
                          {day}
                        </option>
                      );
                    })}
                  </select>
                </div>

                {/* Month */}
                <div className="select-option">
                  <select
                    name="month"
                    id="month"
                    value={this.state.month}
                    onChange={(e) => {
                      this.onSelectChangeDate(e);
                    }}
                  >
                    <option>Month</option>
                    {date.months.map((el) => {
                      return (
                        <option value={el.month} key={el.month}>
                          {el.name}
                        </option>
                      );
                    })}
                  </select>
                </div>

                {/* Year */}
                <div className="select-option">
                  <select
                    name="year"
                    id="year"
                    value={this.state.year}
                    onChange={(e) => {
                      this.onSelectChangeDate(e);
                    }}
                  >
                    <option>Year</option>
                    {date.years.map((year) => {
                      return (
                        <option value={year} key={year}>
                          {year}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>

            {/* event start Time  */}
            <div className="cle-input-group">
              <label htmlFor="cle_time">What time does it start?</label>
              <div className="cle-date-group">
                <input
                  type="time"
                  id="cle_time"
                  onChange={(e) => {
                    this.onChangeTime(e);
                  }}
                  value={this.state.formData[FIELDS.EVENT.EVENT_START_TIME]}
                  name={[FIELDS.EVENT.EVENT_START_TIME]}
                  className="cle-input"
                />
                <span className="show-local-time">{`${
                  /\((.*)\)/.exec(new Date().toString())[1]
                }`}</span>
              </div>
            </div>

            {this.state.errorText && (
              <span className="text-red">{this.state.errorText}</span>
            )}

            {/* on Submit button */}
            <button
              onClick={(e) => {
                this.onSubmitHandler(e);
              }}
              className="cle-submit-btn"
            >
              {this.state.editEvent
                ? "UPDATE Event"
                : "Create Event"}
            </button>
          </form>
        </div>
        {this.state.showModal && (
          <UserModal
            show="showEditEventModal"
            message={this.state.message}
            closeModal={this.showModal}
          />
        )}
      </div>
    );
  }
}

export default CreateLiveEvent;
