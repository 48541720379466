import React, { Component } from "react";
import { AuthContext } from "../../Firebase/AuthProvider";
import DatePicker from "react-datepicker";
import { updateUserEducation } from "../../Firebase/Users";
import "react-datepicker/dist/react-datepicker.css";
import { getUserDetail } from "../../Firebase/Users";

class EducationModal extends Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    let currentDate = new Date()
    this.state = {
      instituteName: this.props.selectedExp.length !== 0 ? this.props.selectedExp.instituteName  : "",
      startDate: this.props.selectedExp.length !== 0 ? new Date(this.props.selectedExp.startDate ? this.props.selectedExp.startDate : "")  : '',
      endDate: this.props.selectedExp.length !== 0 ? new Date(this.props.selectedExp.endDate ? this.props.selectedExp.endDate : "")  : currentDate,
      description: this.props.selectedExp.length !== 0 ? this.props.selectedExp.description  : "",
    };
  }
  
  onChangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  validate = () => {
    let error = "";
    if (this.state.instituteName === "") {
      error = "Institute name cannot be blank";
      return error;
    }
    if (!this.state.startDate) {
      error = "Start date cannot be blank";
      return error;
    }
    if (!this.state.endDate) {
      error = "End date cannot be blank";
      return error;
    }
    return null;
  };

  onSubmitForm = async (e) => {
    e.preventDefault();
    const validate = this.validate();
    if (validate != null) {
      this.setState({ errorText: validate });
      return;
    } else {
      this.setState({ errorText: "" });
    }
    this.context.showLoader(true);
    let userUID = localStorage.getItem("userID")
    let userDetail = this.context.state.userDetail
    let userEducation = userDetail.user_education ? userDetail.user_education : []
    let data = {
      'instituteName': this.state.instituteName,
      'startDate': (this.state.startDate.getMonth()+1)+'/'+(this.state.startDate.getDay())+'/'+this.state.startDate.getFullYear(),
      'endDate': (this.state.endDate.getMonth()+1)+'/'+(this.state.endDate.getDay())+'/'+this.state.endDate.getFullYear(),
      'description': this.state.description
    }
    if(this.props.selectedIndex !== "") {
      userEducation[this.props.selectedIndex] = data
    }
    else {
      userEducation.push(data)
    }
    let resp = await updateUserEducation(userUID, userEducation)
    if(resp){
      let userData = await getUserDetail(userUID)
      this.context.setCurrentUser(userUID, userData);
      this.context.getData();
      this.props.updateCurrentUser(userData)
    }
    this.context.showLoader(false);

    
    // updateUserEducation(userUID, userEducation)
    //   .then(() => {
    //     this.setState(
    //       {
    //         instituteName: "",
    //         startDate: "",
    //         endDate: "",
    //         description: ""
    //       },
    //       () => {
    //         // showSuccessMessage("Profile has been updated.")
    //         this.context.showLoader(false);
    //         this.props.closeModal();
    //         // update user detail and events to refresh experience
    //         getUserDetail(userUID)
    //         .then((userData) => {
    //           this.context.setCurrentUser(userUID, userData);
    //           this.context.getData();
    //           this.props.updateCurrentUser(userData)
    //         })
    //         .catch((err) => {
    //           if (err) {
    //             this.context.showLoader(false);
    //           }
    //         });
    //       }
    //     );
    //   })
    //   .catch((err) => {
    //     if (err.code === "auth/requires-recent-login") {
    //       this.context.showLoader(false);
    //       this.setState({
    //         errorText: "Login again then retry",
    //       });
    //       return;
    //     }
    //     this.context.showLoader(false);
    //     this.props.closeModal();
    //   });
  };

  handleStartDateChange = (date) => {
    this.setState({ startDate: date })
  }

  handleEndDateChange = (date) => {
    this.setState({ endDate: date })
  }

  render() {
    return (
      <div className="user-modal" onClick={() => this.props.closeModal()}>
        <div className="user-modal-inner" onClick={(e) => e.stopPropagation()}>
          {this.props.show === "showProfileModal" && (
            <div className="change-pswrd-div gnrl-styling">
              <h3 className="login-header">
                Update Profile
              </h3>
              <form>
                <label>Institute Name</label>
                <input
                  type="text"
                  required
                  name="instituteName"
                  value={this.state.instituteName}
                  onChange={(e) => this.onChangeHandler(e)}
                  placeholder="Enter Institute Name"
                  className="email-input"
                />
                <label>Start Date</label>
                <DatePicker
                  selected={this.state.startDate}
                  onChange={this.handleStartDateChange}
                  showYearDropdown={true}
                  className="email-input"
                />
                <label>End Date</label>
                <DatePicker
                  selected={this.state.endDate}
                  onChange={this.handleEndDateChange}
                  showYearDropdown={true}
                  className="email-input"
                />
                <label>Description</label>
                <textarea
                  cols="30"
                  rows="2"
                  value={this.state.description}
                  onChange={(e) => this.setState({ description: e.target.value })}
                  placeholder="Enter description"
                  name="description"
                  className="email-input"
                ></textarea>
                {this.state.errorText && <span className="text-red">{this.state.errorText}</span>}
                <button
                  className="login-button-form"
                  onClick={(e) => {
                    this.onSubmitForm(e);
                  }}
                >
                  Update
                </button>
              </form>
            </div>
          )}
          

        </div>
      </div>
    );
  }
}

export default EducationModal;
