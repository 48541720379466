import React, { Component } from "react";
import { AuthContext } from "../Firebase/AuthProvider";
import "../Assets/Style/newLayout.scss";
import Header from "../components/header";
import LoginSignUpPage from "./LoginSignUpPage";
import UserModal from '../components/UserModal'
import TribeStory from "../components/TribesComponent/TribeStory";
import TribePage from "../components/tribePage";
import { getUserChannels } from "../Firebase/Channels";

class Home extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super();
    this.state = {
      posts: [],
      initialCall: false,
      showChannelModal: false,
      myChannels: [],
      latestEvents: [],
      showSignupModal: false,
      finalPosts: [],
      channelsList: [],
      channelId: "",
      showLoader: false,
      selectedChannel: ""
    };
  }

  setChannelModal = () => {
    this.setState({
      showChannelModal: !this.state.showChannelModal,
    });
  };

  componentDidMount = async () => {
    document.title = "Assembly F - Build your tribe!"
    if(localStorage.getItem("userID") ) {
      this.fetchChannelList();
    }
  }

  fetchChannelList = async () => {
    let myChannelsList = [];
    let channelList = await getUserChannels(localStorage.getItem("userID"));
   
    if(channelList.docs.length > 0) {
      channelList.docs.map(el => {
        myChannelsList.push(el)
      })
      if(myChannelsList.length > 0) {
            this.setState({ selectedChannel: myChannelsList[0].data(), channelId: myChannelsList[0].id, channelsList: myChannelsList })
      }
    }
    else {
      this.props.history.push('/tribes')
    }
  }



  moreProfileInfo =()=>{
    this.setState({showSignupModal:true})
  }

  closeModal = () => {
    this.setState({showSignupModal:false})
  }

  setNewPosts = (newPostsList) => {
    this.setState(
      {
        finalPosts: newPostsList
      }
    );
  }

  changeChannelPosts = (selectedChannel, id) => {
    this.setState({ channelId: id, selectedChannel });
  }

  emptyChannelList = () => {
    this.setState({ selectedChannel: "", channelId: "", channelsList: [] })
  }

  render() {
    return (
      <React.Fragment>
        {!this.context.state.currentUser ? (
          <LoginSignUpPage moreProfileInfo={this.moreProfileInfo} />
        ) : (
          <React.Fragment>
            <Header props={this.props} emptyChannelList={this.emptyChannelList} />
            <TribeStory channelId={this.state.channelId} fetchChannelList={this.fetchChannelList} channelsList={this.state.channelsList} changeChannelPosts={this.changeChannelPosts} />
            {
              this.state.selectedChannel &&
              <TribePage emptyChannelList={this.emptyChannelList} channelID={this.state.channelId} channelInfo={this.state.selectedChannel}  />
            }
          </React.Fragment>
        )}
        {this.state.showSignupModal && (
          <UserModal show="signup-popup" userInfo={this.context.state.userDetail} closeModal={this.closeModal}  />
        )}
      </React.Fragment>
    );
  }
}

export default Home;
