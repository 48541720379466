import React, { Component } from "react";
import CreatChannel from '../../components/createChannel'

class ChannelModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      displayModal: "flex"
    };
  }

  closeModal = () => {
    this.setState({
      displayModal: "none",
    });
  };

  render() {
    return (
      <div
        className="modal-box"
        style={{ display: this.state.displayModal, zIndex: "999" }}
        id="SignupModal"
        onClick={this.closeModal}
      >
        <div className="login-inner create_channels_form" onClick={(e) => e.stopPropagation()}>
          <h3>Create Tribe</h3>
          <CreatChannel />
        </div>
      </div>
    );
  }
}

export default ChannelModal;
