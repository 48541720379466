import React, { Fragment, Component } from "react";

// Import styles
import "../../../Assets/Style/profile.scss";
import { AuthContext } from "../../../Firebase/AuthProvider";
import { FIELDS } from "../../../Firebase/Constants";
import { defaultImageUrl } from "../../../Helper/StaticContent";
import Followers from "../../../Assets/Images/Followers.png";
import Elves from "../../../Assets/Images/Elves.png";
import AngelInvestor from "../../../Assets/Images/AngelInvestor.png";
import IntroducedBy from "../../../Assets/Images/Introducedby.png";
import OrganizationModal from '../OrganizationModal';
import Dropdown from "react-bootstrap/Dropdown";
// Imported Rating Component
// import ReactStars from "react-rating-stars-component";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";


const TraitsRow = styled(Row)`
  margin-left: 0px;
  line-height: 1;
`;

class ProfilePhoto extends Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      userType: ''
    };
  }
  
  toggleModal = (item, index)=>{
    this.setState({
      showModal: !this.state.showModal
    })
  }

  changeProfileData = (e) => {
    this.setState({ userType: e.target.text })
    this.props.onUserTypeClick(this.context.state.currentUser, e.target.text);
  }
  

  render() {
    document.title = this.props.userDetail?.name ? this.props.userDetail?.name + " | Assembly F" : "Assembly F"
    return (
      <Fragment>
        <div className="ProfilePhotoContainer">
          <div className="userImage">
              <img
                src={
                  this.props.profileImage || defaultImageUrl.userDefaultImage
                }
                alt="user-img"
              />
          </div>
          <div className="useName">
            <h1>
              {this.props.userDetail?.name ? this.props.userDetail.name : ""}
            </h1>
            <div className="org-icon-div">
              <span>
                {this.props.userDetail?.linkedin_profile
                  ? " "+this.props.userDetail.linkedin_profile
                  : this.props.userDetail ? "  Update Organization " : ""}
                  
              </span>
              {this.props.userDetail?.id === this.context.state.currentUser && (
              <div className="img-three-dots">
                <img
                  src={require('../../../Assets/Images/Group 3.png')}
                  alt="edit-img"
                  className="img-fluid"
                  onClick={() => this.toggleModal()}
                />
              </div>
              
              )}
            
            </div>
            
            
            {this.props.userDetail?.id !== this.context.state.currentUser && (
              <a
                href={() => false}
                onClick={(e) => {
                  this.context.state.currentUser ?
                  this.props.onFollowClick(
                    this.props.userDetail?.[FIELDS.USER.USER_ID],
                    this.props.userDetail?.[FIELDS.USER.USER_FOLLOWERS]
                  ):
                  this.props.history.push("/")

                }}
              >
                {this.props.userDetail?.[FIELDS.USER.USER_FOLLOWERS]?.includes(
                  this.context.state.currentUser
                )
                  ? "Unfollow"
                  : "Follow"}
              </a>
            )}
          </div>
        </div>
        <div className="profile_stars_and_followers">
          {/* <div className="label_followers">
            <ReactStars
              count={5}
              size={30}
              value="4.5"
              isHalf={true}
              emptyIcon={<i className="far fa-star"></i>}
              halfIcon={<i className="fa fa-star-half-alt"></i>}
              fullIcon={<i className="fa fa-star"></i>}
              activeColor="#ff0000"
              color="#eeeeee"
            />
            <span>Superfounder</span>
          </div> */}
          <TraitsRow >
              
              {this.props.userDetail?.id === this.context.state.currentUser ? (
                <div className="CustomDropDown" >
                <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                  <img
                    src={AngelInvestor}
                    alt="company-logo"
                    className="img-fluid"
                    width="20"
                  />
                  <span>{this.state.userType ? this.state.userType : this.props.userDetail?.user_skill}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={(e) => this.changeProfileData(e)}>
                    Founder
                  </Dropdown.Item>
                  <Dropdown.Item onClick={(e) => this.changeProfileData(e)}>
                    Product
                  </Dropdown.Item>
                  <Dropdown.Item onClick={(e) => this.changeProfileData(e)}>
                  Engineer
                  </Dropdown.Item>
                  <Dropdown.Item onClick={(e) => this.changeProfileData(e)}>
                  Growth
                  </Dropdown.Item>
                  <Dropdown.Item onClick={(e) => this.changeProfileData(e)}>
                  UX
                  </Dropdown.Item>
                  <Dropdown.Item onClick={(e) => this.changeProfileData(e)}>
                  Investor
                  </Dropdown.Item>
                  <Dropdown.Item onClick={(e) => this.changeProfileData(e)}>
                  Other
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              </div>
              ): 
              <div className="label_followers">
                <img
                  src={AngelInvestor}
                  alt="company-logo"
                  className="img-fluid icon-Angel-investor"
                  width="20"
                />
                <span>{this.props.userDetail?.user_skill}</span>
              </div>
              }
                  
            
            {/* Integration Check Needed */}
                {/* <div className="label_followers">
                  <img src={AngelInvestor} className="img_aibadge"/>
                  <span>Angel Investor</span>
                 
                </div> */}
              <div className="label_followers ">
                <img src={Followers} className="img_follow" alt="noImage" />
                <span>{this.props?.userDetail?.[FIELDS.USER.USER_FOLLOWERS]
                              ?.length} Followers</span>
              </div>
                <div className="label_followers">
                  <img src={Elves} className="img_elves" alt="noImage" />
                    <span>
                      {this.props.totalElves ? this.props.totalElves : 0} Ozi 
                      
                    </span>
                </div>
                <div className="label_followers">
                  {this.props.userDetail?.id !== this.context.state.currentUser && (
                    <a className="label" href={() => false} onClick={(e) =>{ this.context.state.currentUser ? this.props.giveElvesToUser(this.props.userDetail?.id) : this.props.history.push('/')}}>
                      give +5 Ozi
                    </a>
                  )}
                </div>
            </TraitsRow>
            {
              this.props.userDetail?.invited_user_name &&
              <Row className="mt-3 mb-3">
                <Col className="label_followers">
                  <img src={IntroducedBy} className="img_introducedby" alt="noImage"/>
                  {/* Name Integration Needed */}
                  {
                    this.props.userDetail?.user_type === "FromChannel" || this.props.userDetail?.pageFrom === "channel" ?
                    <a href={"/channel/"+this.props.userDetail?.invited_user_id}><span>Introduced by <b>&nbsp;{this.props.userDetail?.invited_user_name}</b></span>  </a>
                    :
                    <a href={"/profile/"+this.props.userDetail?.invited_user_id}><span>Introduced by <b>&nbsp;{this.props.userDetail?.invited_user_name}</b></span>  </a>
                  }
                 
                </Col>
              </Row>
            }
            
        </div>
        {this.props.userDetail?.id !== this.context.state.currentUser && (
          <div className="hint_followers">
            <p>hint: ozi are a show of appreciation! give +5 once a day.</p>
          </div>
        )}
        {this.state.showModal && (
            <OrganizationModal 
              show="showProfileModal"
              updateCurrentUser={this.props.updateCurrentUser} 
              openModal={this.toggleModal} 
              closeModal={this.toggleModal}  
            />
        )}
      </Fragment>
    );
  }
}

export default ProfilePhoto;
