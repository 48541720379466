import React, { Component } from "react";
import { app } from "../Firebase/firebase-config";
import { AuthContext } from "../Firebase/AuthProvider";
import { changeUserImage } from "../Firebase/StorageBucket";
import { updateUserImage, getUserDetail } from "../Firebase/Users";
import { FIELDS, defaultUserImages } from "../Firebase/Constants";
import ShareComponent from "./shareModal";
import { sendInvitation, inviteMembersToChannel } from "../Firebase/Mailer";
import { modalStaticContent } from "../Helper/StaticContent";
import smallLoader from '../Assets/Images/loader.gif'
import MoreProfile from './profile/moreProfile';
import LoginSignUpComp from "./LoginSignUpComp";
import SignUpComp from "./SignUpComp";

class UserModal extends Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = {
      newPassword: "",
      confirmPassword: "",
      errorText: "",
      email: "",
      emailsList: "",
      emailError: "",
      successMsg: "",
      modalType: "login"
    };
  }

  componentDidMount() {
    if(this.props.show === "login-form" && this.props.modalType === "signup"){
      this.setState({modalType:this.props.modalType})
    }
  }

  onChangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  validate = () => {
    let error = "";
    if (this.state.newPassword === "") {
      error = "password cannot be blank";
      return error;
    }
    if (this.state.newPassword.length < 8) {
      error = "Password should be at least 8 characters";
      return error;
    }
    if (this.state.confirmPassword === "") {
      error = "confirm password cannot be blank";
      return error;
    }
    if (this.state.newPassword !== this.state.confirmPassword) {
      error = "Password didn't matched";
      return error;
    }
    return null;
  };

  onChangePassword = (e) => {
    e.preventDefault();
    const validate = this.validate();
    if (validate != null) {
      this.setState({ errorText: validate });
      return;
    } else {
      this.setState({ errorText: "" });
    }
    this.context.showLoader(true);
    var user = app.auth().currentUser;
    user
      .updatePassword(this.state.newPassword)
      .then(() => {
        this.setState(
          {
            newPassword: "",
            confirmPassword: "",
          },
          () => {
            this.context.showLoader(false);
            this.props.closeModal();
            this.context.signOut();
          }
        );
      })
      .catch((err) => {
        if (err.code === "auth/requires-recent-login") {
          this.context.showLoader(false);
          this.setState({
            errorText: "Login again then retry",
          });
          return;
        }
        this.context.showLoader(false);
        this.props.closeModal();
      });
  };

  handleImageAsFile = (e) => {
    const image = e.target.files[0];
    if (image) {
      this.setState({ imageFile: image, imagePreview: URL.createObjectURL(image) });
    }
  };

  removeUserImage = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      imageFile: false,
      imagePreview: "",
    });
  };

  onUploadImage = async (e) => {
    e.preventDefault();
    // get the uploaded image
    let image = this.state.imageFile;
    // check if image is selected otherwise show error
    if (image) {
      // remove error
      this.setState({ errorText: "" });
      // Show loader
      this.context.showLoader(true);
      // set login user UID
      let userUID = this.context.state.userDetail.id;
      // get the current user image path from user detail
      let userImage = this.context.state.userDetail?.image ? this.context.state.userDetail?.image : "";
      // if userImage exist then get the file path store in firebase storage and replace that image otherwise upload new image
      let imageFileURL = "";
      if (userImage && !defaultUserImages.includes(this.context.state.userDetail.image) && userImage.indexOf("googleusercontent.com") === -1) {
        let storage = app.storage();
        let userImageName = storage.refFromURL(userImage).getMetadata();
        const userImagePath = await userImageName.then((data) => data.fullPath);
        imageFileURL = await changeUserImage(image, userImagePath);
      } else {
        imageFileURL = await changeUserImage(image, `images/users/${userUID}${image.name}`);
      }
      // update uploaded image url to database
      updateUserImage(userUID, imageFileURL)
        .then(() => {
          this.setState(
            {
              // unset all image state
              imageFile: false,
              imagePreview: "",
            },
            () => {
              // Hide loader and close modal
              this.props.closeModal();
              // update user detail and events to refresh image
              getUserDetail(userUID)
                .then((userData) => {
                  this.context.setCurrentUser(userUID, userData);
                  this.context.getData();
                })
                .catch((err) => {
                  if (err) {
                    this.context.showLoader(false);
                  }
                });
              this.context.showLoader(false);
            }
          );
        })
        .catch((err) => {
          this.setState(
            {
              // unset all image state
              imageFile: false,
              imagePreview: "",
            },
            () => {
              // Hide loader
              this.props.closeModal();
              this.context.showLoader(false);
            }
          );
        });
    } else {
      this.setState({
        errorText: "Please select image",
      });
    }
  };

  onYesClick = async () => {
    let UID = this.props.event[0]?.uid;
    let users = this.props.event[0]?.[FIELDS.EVENT.EVENT_GOING];
    let event = this.props.event[0];
    this.props.cancel(UID, users, event).then(() => {
      this.props.closeModal();
    });
  };

  onHandleSend = (events) => {
    let event = events[0];
    if (this.state.email !== "") {
      let emails = this.state.email;
      emails = Array.from(new Set(emails.split(","))).toString();
      emails = emails.replace(/,\s*$/, "");
      this.context.showLoader(true);
      sendInvitation(emails, event)
        .then((res) => {
          this.context.showLoader(false);
          this.props.closeModal(false);
        })
        .catch((err) => {
          this.setState(
            {
              errorText: "incorrect Email",
            },
            () => {
              this.context.showLoader(false);
            }
          );
        });
    } else {
      this.setState({
        errorText: "enter email",
      });
    }
  };

  inviteMoreMembers = () => {
    if(this.state.emailsList) {
      let emails = this.state.emailsList.replace(/\s/g,'').split(",");
      let isValidEmail = true;
      // eslint-disable-next-line no-useless-escape
      var regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      for (var i = 0; i < emails.length; i++) {
          if( emails[i] === "" || ! regex.test(emails[i])){
            isValidEmail = false;
          }
      }
      if(!isValidEmail) {
        this.setState({ emailError: "Invalid email address." })
      }
      else {
         inviteMembersToChannel(this.state.emailsList, this.context.state.userDetail.email, this.context.state.userDetail.name, this?.props?.channelInfo.name, this.props.channelId);
        this.setState({ successMsg: "Email has been sent.", emailError: "" })
        setTimeout(() => {
          this.props.closeModal();
        }, 1000);
      }
    }
    else {
      this.setState({ emailError: "Please write something." })
    }
  }

  changeState = (value)=>{
    this.setState({modalType:value})
  }

  render() {
    return (
      <div className="user-modal" onClick={() => this.props.closeModal()}>
        <div className="user-modal-inner" onClick={(e) => e.stopPropagation()}>
          {this.props.show === "showPasswordModal" && (
            <div className="change-pswrd-div">
              <h3 className="login-header">
                Hello from{" "}
                <span className="medium">
                  Assembly <span className="full">F</span>
                </span>
              </h3>
              <form>
                <input
                  type="password"
                  required
                  name="newPassword"
                  value={this.state.newPassword}
                  onChange={(e) => this.onChangeHandler(e)}
                  placeholder="Enter new password"
                  className="email-input"
                />
                <input
                  type="password"
                  required
                  name="confirmPassword"
                  value={this.state.confirmPassword}
                  onChange={(e) => this.onChangeHandler(e)}
                  placeholder="Re-type password"
                  className="email-input"
                />
                {this.state.errorText && <span className="text-red">{this.state.errorText}</span>}
                <button
                  className="login-button-form"
                  onClick={(e) => {
                    this.onChangePassword(e);
                  }}
                >
                  Change password
                </button>
              </form>
            </div>
          )}
          {this.props.show === "showImageModal" && (
            <div className="change-pswrd-div">
              <h3 className="login-header">
                Hello from{" "}
                <span className="medium">
                  Assembly <span className="full">F</span>
                </span>
              </h3>
              <form>
                <div className={`input-upload-image ${this.state.imagePreview && "image-preview"}`}>
                  <label className={this.state.imagePreview && "image-preview"} htmlFor="image-upload">
                    {this.state.imagePreview ? (
                      <React.Fragment>
                        <img src={this.state.imagePreview} alt="" />
                        <a
                          href="# "
                          onClick={(e) => {
                            this.removeUserImage(e);
                          }}
                        >
                          &#10010;
                        </a>
                      </React.Fragment>
                    ) : (
                      "Upload your image"
                    )}
                  </label>
                  <input className="input-image-file" accept="image/*" type="file" id="image-upload" name="upload-image" value="" onChange={this.handleImageAsFile} />
                </div>
                {this.state.errorText && <span className="text-red">{this.state.errorText}</span>}
                <button
                  className="login-button-form"
                  onClick={(e) => {
                    this.onUploadImage(e);
                  }}
                >
                  Upload
                </button>
              </form>
            </div>
          )}

          {this.props.show === "cancelReservation" && (
            <div className="cancel-reservation-modal change-pswrd-div">
              <h3 className="login-header">
                Hello from{" "}
                <span className="medium">
                  Assembly <span className="full">F</span>
                </span>
              </h3>
              <p className="message">
                Do you really want to <span>cancel</span> your reservation?
              </p>
              <div className="button">
                <button
                  onClick={() => {
                    this.onYesClick();
                  }}
                >
                  YES
                </button>
                <button
                  onClick={() => {
                    this.props.closeModal();
                  }}
                >
                  NO
                </button>
              </div>
            </div>
          )}

          {this.props.show === "deleteEvent" && (
            <div className="cancel-reservation-modal change-pswrd-div">
              <h3 className="login-header">
                Hello from{" "}
                <span className="medium">
                  Assembly <span className="full">F</span>
                </span>
              </h3>
              <p className="message">
                Do you really want to <span>Delete</span> this event?
              </p>
              <div className="button">
                <button
                  onClick={() => {
                    this.props.onDeleteEventClick(this.props.selectedEvent);
                  }}
                >
                  YES
                </button>
                <button
                  onClick={() => {
                    this.props.closeModal();
                  }}
                >
                  NO
                </button>
              </div>
            </div>
          )}

          {this.props.show === "inviteEmail" && (
            <div className="cancel-reservation-modal change-pswrd-div invite-email">
              <h3 className="login-header">
                Hello from{" "}
                <span className="medium">
                  Assembly <span className="full">F</span>
                </span>
              </h3>
              <p>Invite your friends to join this live session.</p>
              <form>
                <textarea
                  cols="30"
                  rows="10"
                  value={this.state.email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                  placeholder="Enter email by comma , separator"
                ></textarea>
                {this.state.errorText && <span className="text-red">{this.state.errorText}</span>}
                <button
                  className="invite-email-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    this.onHandleSend(this.props.event);
                  }}
                >
                  Send Invitation
                </button>
              </form>
            </div>
          )}

          {this.props.show === "showEditEventModal" && (
            <div className="cancel-reservation-modal change-pswrd-div">
              <h3 className="login-header">
                Hello from{" "}
                <span className="medium">
                  Assembly <span className="full">F</span>
                </span>
              </h3>
              <p className="message">{this.props.message}</p>
            </div>
          )}

          {this.props.show === "showReserevedMessage" && (
            <div className="splash-event">
              <h1 className="splash-event-name">{this.props.eventDetail && this.props.eventDetail[FIELDS.EVENT.EVENT_NAME]}</h1>
              <span className="splash-event-host">
                Hosted by{" "}
                <span className="text-red">
                  <a
                    href="# "
                    className="splash-host"
                    onClick={(e) => {
                      this.props.onProfileClick(e, this.props.eventDetail && this.props.eventDetail[FIELDS.EVENT.EVENT_HOST_DETAIL][FIELDS.USER.USER_ID]);
                    }}
                  >
                    {this.props.eventDetail && this.props.eventDetail[FIELDS.EVENT.EVENT_HOST_DETAIL][FIELDS.USER.USER_NAME]}
                  </a>
                </span>
              </span>
              <h1 className="splash-event-reserved">
                SEAT RESERVED<span className="text-red">.</span>
              </h1>
              <span className="forgot-password">Invite your friends to join this live session.</span>
              <ShareComponent eventDetail={this.props.eventDetail && this.props.eventDetail} />
            </div>
          )}

          {this.props.show === "showSignupModal" && (
            <h1 className="splash-text">
              REQUEST RECEIVED<span className="text-red">.</span>
              <br />
              <span className="text-red">LOOK FORWARD TO SEEING YOU IN OUR COMMUNITY.</span>
            </h1>
          )}

          {this.props.show === "showNewsletterEmailSend" && (
            <h1 className="splash-text">
              Thank you<span className="text-red">!</span>
              <br />
              <span className="text-red">We'll respond to your request shortly.</span>
            </h1>
          )}

          {this.props.show === "disableUserPermanently" && (
            <div className="cancel-reservation-modal change-pswrd-div">
              <h3 className="login-header">
                Hello from{" "}
                <span className="medium">
                  Assembly <span className="full">F</span>
                </span>
              </h3>
              <p className="message">
                Do you really want to <span>Disable</span> this user?
              </p>
              <div className="button">
                <button
                  onClick={() => {
                    this.props.onDisableUserClick()
                  }}
                >
                  YES
                </button>
                <button
                  onClick={() => {
                    this.props.closeModal();
                  }}
                >
                  NO
                </button>
              </div>
            </div>
          )}

          {this.props.show === "deletePost" && (
            <div className="general-post-modal">
              <h4 className="general-modal-heading">{modalStaticContent.deletePostHeading}</h4>
              <p className="general-modal-message">
                {modalStaticContent.deletePostMessage}
              </p>
              <div className="general-modal-buttons">
                <button onClick={()=>{this.props.closeModal()}} className="general-modal-btn general-modal-cancel-btn">{modalStaticContent.cancelBtnText}</button>
                <button onClick={()=>{this.props.ondeletPostClick(this.props.postId); }} className="general-modal-btn general-modal-delete-btn">{modalStaticContent.deleteBtnText}</button>
              </div>
            </div>
          )}

          {this.props.show === "joinChannel" && (
            <div className="general-post-modal">
              <div className="channel-modal-wrapper">

              <p className="gnrl-channel-join">Welcome to the<br/><span>{this?.props?.channelInfo.name}</span><br/>Community</p>
              
              <div className="menu">
                <p>Choose your level</p>
                <div className="radio-input">
                  <input type="radio" name="level" id="learner" onClick={()=>this.props.setUserType("Learner")} />
                  <label htmlFor="learner">Learner</label>
                </div>
                <div className="radio-input">
                  <input type="radio" name="level" id="expert" onClick={()=>this.props.setUserType("Expert")}/>
                  <label htmlFor="expert">Expert</label>
                </div>
                <div className="radio-input">
                  <input type="radio" name="level" id="master"  onClick={()=>this.props.setUserType("Master")} />
                  <label htmlFor="master">Master</label>
                </div>
              </div>

              <button onClick={()=>{this.props.openJoinChannelModal();this.props.closeModal()}} >Confirm to join</button>
              </div>

              {/* <p className="general-modal-message">
                {modalStaticContent.deletePostMessage}
              </p> */}
              {/* <div className="general-modal-buttons">
                <button onClick={()=>{this.props.closeModal()}} className="general-modal-btn general-modal-cancel-btn">{modalStaticContent.cancelBtnText}</button>
                <button onClick={()=>{this.props.ondeletPostClick(this.props.postId);this.props.closeModal(); this.props.getPostById && this.props.getPostById(this.props.postId) }} className="general-modal-btn general-modal-delete-btn">{modalStaticContent.deleteBtnText}</button>
              </div> */}
            </div>
          )}

          {this.props.show === "inviteChannel" && (
            <div className="general-post-modal">
              <div className="channel-modal-wrapper">
                <p className="gnrl-channel-join">Invite to<br/><span>{this?.props?.channelInfo.name}</span><br/></p>
                <div className="menu">
                  {/* <p>Please provide name & email</p> */}
                  {/* <div className="input-fields">
                    <label htmlFor="">Name</label>
                    <input type="text"  placeholder="Name"/>
                  </div> */}
                  <div className="input-fields">
                    <label htmlFor="">Email</label>
                    <textarea type="email" placeholder='Enter emails (comma separated)' onChange={(e) => this.setState({ emailsList: e.target.value })} />
                  </div>
                </div>
                {this.state.emailError && <span className="text-red">{this.state.emailError}</span>}
                {this.state.successMsg && <span className="text-green">{this.state.successMsg}</span>}
                <button onClick={()=>this.inviteMoreMembers()} >Send invitation</button>

              </div>
            </div>
          )}

          {this.props.show === "addWeekTopic" && (
            <div className="general-post-modal">
              <div className="channel-modal-wrapper">
                <p className="gnrl-channel-join">Add Topic to <br/><span>{this?.props?.channelInfo.name}</span><br/></p>
                <div className="menu">
                  <div className="input-fields">
                    <input type="text" name="channelTopic" value={this.props.channelTopic} onChange={(e) => this.props.onChangeHandlerInput(e)} placeholder="Write week's topic" />
                  </div>
                </div>
                <button onClick={()=>{this.props.addChannelTopic(this.props.channelTopic)}} >Add Topic</button>
              </div>
            </div>
          )}

          {this.props.show === "deleteExperience" && (
            <div className="general-post-modal">
              <h4 className="general-modal-heading">Delete experience</h4>
              <p className="general-modal-message">
                Are you sure you want to permanently remove this experience from your profile?
              </p>
              <div className="general-modal-buttons">
                <button onClick={()=>{this.props.closeModal()}} className="general-modal-btn general-modal-cancel-btn">{modalStaticContent.cancelBtnText}</button>
                <button onClick={()=>{this.props.updateUser(this.props.userIndex,"delete");this.props.closeModal() }} className="general-modal-btn general-modal-delete-btn">{modalStaticContent.deleteBtnText}</button>
              </div>
            </div>
          )}

          {this.props.show === "login-form" && this.state.modalType === "login" && (
            <div className="inside" style={{width: `${this.props.isModal && `100%`}` }}>
              <LoginSignUpComp changeState={this.changeState} isPopup={true} closeModal={this.props.closeModal} openPaymentModal={this.props.openPaymentModal} channelId={this.props.channelId} />
            </div>
          )}

          {this.props.show === "login-form" && this.state.modalType === "signup" && (
            <SignUpComp isPopup={true} changeState={this.changeState} openPaymentModal={this.props.openPaymentModal} closeModal={this.props.closeModal} channelId={this.props.channelId} channelInfo={this.props.channelInfo} />
          )}

          {this.props.show === "update-channel" && (
            <h1 className="splash-text confirmation-popup">
              Done
              <span className="text-red">Your change has been updated</span>
            </h1>
          )}

          {this.props.show === "payment-modal" && this.props.channelInfo?.priceType === "paid" && (
            <div className="payment-popup">
              <h1 className="heading-1">{this.props.channelInfo.name}</h1>
              <div className="middle">
                <h4 className="heading-4">Join our channel</h4>
                <h4 className="price">${this.props.channelInfo.amount}</h4>
                {
                  !this.props.isPaying ?
                  <button className="pay" onClick={() => this.props.checkoutToStripe(this.props.channelInfo.amount)}>Pay</button>
                  :
                  <div className="loadergif"><img src={smallLoader} alt="noImage" width="100" height="100" /></div>
                }
              </div>
              <div className="image">
                <img src={require("../Assets/Images/blackLogo.png")} alt="" />
              </div>
            </div>
          )}


          {this.props.show === "signup-popup" && (
            <div className="first-popup">
              <h3>Add your profile info</h3>
              <MoreProfile userInfo={this.props.userInfo} closeModal={this.props.closeModal} />
            </div>
          )}


        </div>
      </div>
    );
  }
}

export default UserModal;
