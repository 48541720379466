import React, { Fragment, useState, useContext } from "react";

// Import styles
import '../../../Assets/Style/profile.scss'
import { monthNames } from "../../../utils/constant"
import EducationModal from '../../../components/profile/EducationModal';
import { updateUserEducation } from "../../../Firebase/Users";
import { AuthContext } from "../../../Firebase/AuthProvider";
import { getUserDetail } from "../../../Firebase/Users";
import UserModal from "../../UserModal";

const Education = ({ userDetail, userID, updateCurrentUser }) => {

  const userContext = useContext(AuthContext)
  const [showModal, setShowModal] = useState(false)
  const [selectedExp, setSelectedExp] = useState([])
  const [selectedIndex, setSelectedIndex] = useState("")
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [userIndex, setUserIndex] = useState(false)

  const toggleModal = (item, index)=>{
    if(item) {
      setSelectedExp(item)
      setSelectedIndex(index)
    }
    else {
      setSelectedIndex("")
      setSelectedExp([])
    }
    setShowModal(!showModal)
  }

  const updateUser = (index, type) => {
    if(type === 'delete') {
    //   Swal.fire({
    //     title: 'Are you sure?',
    //     icon: 'warning',
    //     showCancelButton: true,
    //     confirmButtonText: 'Yes, delete it!',
    //     cancelButtonText: 'No, keep it',
    //     confirmButtonColor: 'red',
    //     cancelButtonColor: '#A9CD3D',
    // }).then(async (result) => {
        if (true) {
          let userDetail = userContext.state.userDetail
          const expArr = [...userDetail.user_education]
          
          let userUID = localStorage.getItem("userID")
          let finalArr = expArr.filter((item, indx) => indx !== index)
          updateUserEducation(userUID, finalArr)
            .then(() => {
              //showSuccessMessage("Profile has been updated.")
              userContext.showLoader(false);
              // update user detail and events to refresh experience
              getUserDetail(userUID)
              .then((userData) => {
                userContext.setCurrentUser(userUID, userData);
                userContext.getData();
                updateCurrentUser(userData)
              })
              .catch((err) => {
                if (err) {
                  this.context.showLoader(false);
                }
              });
            })
            .catch((err) => {
              if (err.code === "auth/requires-recent-login") {
                this.context.showLoader(false);
                this.setState({
                  errorText: "Login again then retry",
                });
                return;
              }
              this.context.showLoader(false);
              this.props.closeModal();
            });
        } else {
            return false;
        }
    }
    // );
    // }
  }

  const openPostMenu = (e) => {
    let allMenu = document.querySelectorAll(".post-menu-dropdown");
    let menu = e.target.childNodes[0];
    for (const a of allMenu) {
      if (!(a.getAttribute("id") === menu.getAttribute("id"))) {
        a.classList.remove("show-post-menu-dropdown");
      }
    }
    if (menu.classList.contains("show-post-menu-dropdown")) {
      menu.classList.remove("show-post-menu-dropdown");
    } else {
      menu.classList.add("show-post-menu-dropdown");
    }
  };

  return (
    <Fragment>
      {showDeleteModal && (
        <UserModal updateUser={updateUser} userIndex={userIndex} show="deleteExperience" closeModal={setShowDeleteModal} />
      )}
      <div className="ExperienceContainer">
        <div className="header">
          <p>Education</p>
          {
            userID === userDetail.id &&
            <img
              src={require('../../../Assets/svg/plus.svg')}
              alt="edit-img"
              className="img-fluid"
              onClick={() => toggleModal()}
            />
          }
        </div>
        {
            userDetail?.user_education ? (userDetail?.user_education.length === 0 && <p className="notFound">Not found.</p>) : ""
        }
        {userDetail.user_education && userDetail.user_education.sort((a,b)=> new Date(b.endDate).getTime() - new Date(a.endDate).getTime())?.map((item, index) => {
          let startMonth =  monthNames[item.startDate.split("/")[0] - 1]
          let endMonth =  monthNames[item.endDate.split("/")[0] - 1]
          let dateDiff = item.endDate.substring(item.endDate.lastIndexOf("/") + 1, item.endDate.length) - item.startDate.substring(item.startDate.lastIndexOf("/") + 1, item.startDate.length)
          
          //console.log('checcking...', dateDiff)
          return (
            <Fragment key={index}>
              <div className="Content">
                <div className="Title">
                  <div className="subTitle">
                    <h6>{item.instituteName}</h6>
                    <p>
                      {startMonth} {item.startDate.split("/")[2]} - {endMonth} {item.endDate.split("/")[2]} <br /> 
                      <span>{dateDiff+" years"}</span>
                    </p>
                  </div>
                  <div className="years">
                  {
                      userID === userDetail.id &&
                      <Fragment>
                          <div
                            className="pbm-post-menu"
                            onClick={(e) => {
                              openPostMenu(e);
                            }}
                            id='three-dots-1'
                          >
                            <div
                              className="post-menu-dropdown"
                              id={`postMenu${index}`}
                              onClick={(e) => e.stopPropagation()}
                            >
                              <ul>
                                <li
                                  className="link"
                                  onClick={() => toggleModal(item, index)}
                                >
                                  Edit
                                </li>
                                <li
                                  className="delete"
                                  // updateUser(index, 'delete')
                                  onClick={() => {setShowDeleteModal(true);setUserIndex(index)}}
                                >
                                  Delete
                                </li>
                              </ul>
                            </div>
                          </div>
                      </Fragment>
                      
                    }
                  </div>
                </div>
                <div className="text">
                  <p>
                    {
                      item.description
                    }
                  </p>
                </div>
              </div>
              {userDetail.user_education.length - 1 !== index ? <hr/> : ""}
            </Fragment>
          );
        })}
        
      </div>
      {showModal && (
            <EducationModal 
              show="showProfileModal"
              updateCurrentUser={updateCurrentUser} 
              openModal={toggleModal} 
              closeModal={toggleModal}  
              selectedExp={selectedExp}
              selectedIndex={selectedIndex}
            />
        )}
    </Fragment>
  );
};

export default Education;
