import { app } from "./firebase-config";
import { withUser } from "./Schemas";
import { FIELDS, queryLimit } from "./Constants";
import { createUser, newsletterMail, approveUser as sendEmail } from "./Mailer";
import firebase from 'firebase/app';
import { loginError } from "../Helper/StaticContent";
const db = app.firestore();


export const sendNewsletter = async (data) => {
  await newsletterMail(data);
  return "email sended";
};

export const updateUserKeywords = async () => {
  const result = await db.collection("users").where(FIELDS.USER.USER_APPROVE, "==", true).get();
  for (const doc of result.docs) {
    let data = doc.data();
    let name = data.name.toLowerCase().split(" ");
    if (!data[FIELDS.USER.USER_KEYWORD]) {
      await db
        .collection("users")
        .doc(doc.id)
        .set(
          {
            [FIELDS.USER.USER_KEYWORD]: name,
          },
          { merge: true }
        );
    } else {
      console.log(data)
    }
  }
};


export const updateUserSearchName = async () => {
  const result = await db.collection("users").where(FIELDS.USER.USER_APPROVE, "==", true).get();
  let count = 1
  for (const doc of result.docs) {
    let data = doc.data();
    let name =  getName(data.name)


    if (!data[FIELDS.USER.USER_SEARCH_NAME]) {
      console.log(name,count++)
      await db.collection("users").doc(doc.id).set({
        [FIELDS.USER.USER_SEARCH_NAME]: name,
      },{ merge: true });
    }else {
      console.log(data)
    }
  }
};

// split the user name into letters and save it to an arrays
export const getName = (name) =>{
  let KeywordNames = []
  let curName = ""
  
  name = name.toLowerCase()
  name.split("").forEach(el=>{
    curName += el
    KeywordNames.push(curName)
  })
  
  return KeywordNames
}



export const searchSpecificUser = async (name) => {
  let users = [];
  var docRef = await db.collection("users")
  .where(FIELDS.USER.USER_APPROVE,"==",true)
  .where(FIELDS.USER.USER_SEARCH_NAME, "array-contains", name).get();

  for (const doc of docRef.docs) {
    let user = withUser(doc.data());
    user["uid"] = doc.id;
    users.push(user);
  }
  return users;
};

// get sign-in user details by providing user UID
export const getUserDetail = async (uid) => {
  if(uid) {
    var user = await db.collection("users").doc(uid).get();
    user = user.data();
    if (user !== undefined) user[FIELDS.USER.USER_ID] = uid;
    return withUser(user);
  }
};

export const userExists = async (id, isObj) => {
  var user = await db.collection("users").doc(id).get();
  user = user.data();
  return !isObj ? user !== undefined : user;
};

export const getAllUsersCount = async (limit) => {
  db.collection('users').get().then(snap => {
    return snap.size // will return the collection size
  });
}

export const getAllUsers = async (limit) => {
  const users = [];
  let result = [];
  if(limit) {
    result = await db.collection("users").orderBy("loggedInTime", "desc").limit(limit).get();
  }
  else {
    //result = await db.collection("users").where(FIELDS.USER.USER_APPROVE, "==", true).get();
    result = await db.collection("users").get();
  }
  
  result.forEach((doc) => {
    const data = withUser(doc.data());
    data["id"] = doc.id;
    users.push(data);
  });
  return users;
};

export const registerUser = async (data) => {
  const result = await app.auth().createUserWithEmailAndPassword(data[FIELDS.USER.USER_EMAIL], data["password"]);
  //  console.log("result",result);
    await createUser(data[FIELDS.USER.USER_EMAIL],data[FIELDS.USER.USER_NAME], data[FIELDS.USER.USER_INVITED_USER_NAME]);
    return db.collection("users").doc(result.user.uid).set(withUser(data));
}

export const getUnApprovedUsers = async() => {
    const users = [];
    var user = db.collection("users").where(FIELDS.USER.USER_APPROVE, "==", false)
    user = await user.get();
    for (const doc of user.docs) {
        const data = await withUser(doc.data());
        data['uid'] = doc.id;
        users.push(data);
    }
    return users
}

export const approveUser = async(nextData) => {
    const users = [];
    var user = '';
    if(nextData !== "host"){
        if(nextData){
            var startAfter = nextData[nextData.length - 1][FIELDS.USER.USER_NAME]
            user = db.collection("users").where(FIELDS.USER.USER_APPROVE, "==", true).orderBy(FIELDS.USER.USER_NAME).startAfter(startAfter).limit(queryLimit)
        }else{
            user = db.collection("users").where(FIELDS.USER.USER_APPROVE, "==", true).orderBy(FIELDS.USER.USER_NAME).limit(queryLimit)
        }
    }else{
        user = db.collection("users")
        .where(FIELDS.USER.USER_APPROVE, "==", true)
        .where(FIELDS.USER.USER_IS_HOST, "==", true )
        .orderBy(FIELDS.USER.USER_NAME)
    }

    user = await user.get();
    for (const doc of user.docs) {
        const data = await withUser(doc.data());
        data['uid'] = doc.id;
        users.push(data);
    }

    return users
}

export const updateUser = async (uid,key,value,email,name) => {
    var user = await db.collection("users").doc(uid).update({
        [key]: value
    })
    if(email && name){
        sendEmail(email,name)
    }
    return user
}

export const getUserByEmail = async (email) =>{
  var getData = await db.collection("users").where("email", "==", email).get();
  return getData.docs;
}

export const getDisapproveUser = async (nextData) => {
  const users = [];
  var user = "";

  if (nextData) {
    var startAfter = nextData[nextData.length - 1][FIELDS.USER.USER_NAME];
    user = db
      .collection("users")
      .where(FIELDS.USER.USER_DISAPPROVE, "==", true)
      .where(FIELDS.USER.USER_APPROVE, "==", false)
      .orderBy(FIELDS.USER.USER_NAME)
      .startAfter(startAfter)
      .limit(queryLimit);
  } else {
    user = db.collection("users").where(FIELDS.USER.USER_DISAPPROVE, "==", true).where(FIELDS.USER.USER_APPROVE, "==", false).limit(queryLimit).orderBy(FIELDS.USER.USER_NAME);
  }

  user = await user.get();
  for (const doc of user.docs) {
    const data = await withUser(doc.data());
    data["uid"] = doc.id;
    users.push(data);
  }
  return users;
};

export const updateUserImage = async (uid, imageURL) => {
  var user = await db
    .collection("users")
    .doc(uid)
    .update({
      [FIELDS.USER.USER_IMAGE]: imageURL,
    });
  return user;
};

export const updateUserExperience = async (uid, data) => {
  await db
    .collection("users")
    .doc(uid)
    .update({
      [FIELDS.USER.USER_EXPERIENCE]: data,
    });
  return true;
};

export const updateUserEducation = async (uid, data) => {
  await db
    .collection("users")
    .doc(uid)
    .update({
      [FIELDS.USER.USER_EDUCATION]: data,
    });
  return true;
};

export const updateUserStory = async (uid, data) => {
  await db
    .collection("users")
    .doc(uid)
    .update({
      [FIELDS.USER.USER_STORY]: data
    });
  return true;
};

export const updateUserOrganization= async (uid, data) => {
  await db
    .collection("users")
    .doc(uid)
    .update({
      [FIELDS.USER.USER_LINKEDIN_PROFILE]: data
    });
  return true;
};

export const updateUserFollowing = async (uid, following) => {
  var user = await db
    .collection("users")
    .doc(uid)
    .update({
      [FIELDS.USER.USER_FOLLOWING]: following,
    });
  return user;
};

export const updateUserElves = async (uid, data) => {
  var user = await db
    .collection("users")
    .doc(uid)
    .update({
      [FIELDS.USER.USER_ELVES]: data
    });
  return user;
};

export const updateUserFollower = async (uid, follower) => {
  var user = await db
    .collection("users")
    .doc(uid)
    .update({
      [FIELDS.USER.USER_FOLLOWERS]: follower,
    });
  return user;
};

export const getFollowingUserDetail = async (data) => {
  let followingUserDetail = "";
  let followerUserDetail = "";

  // get Following user detail
  if (data) {
    if (data[FIELDS.USER.USER_FOLLOWING]?.length > 0) {
      followingUserDetail = await Promise.all(
        data[FIELDS.USER.USER_FOLLOWING].map(async (id) => {
          let detail = await getUserDetail(id);
          return detail;
        })
      );
    }

    // get Follower user detail
    if (data[FIELDS.USER.USER_FOLLOWERS]?.length > 0) {
      followerUserDetail = await Promise.all(
        data[FIELDS.USER.USER_FOLLOWERS].map(async (id) => {
          let detail = await getUserDetail(id);
          return detail;
        })
      );
    }
    data[FIELDS.USER.USER_FOLLOWING_DETAIL] = followingUserDetail;
    data[FIELDS.USER.USER_FOLLOWER_DETAIL] = followerUserDetail;
  }
  return withUser(data);
};

export const setDisabledUser = async (userID) =>{
  let userDetail = await db.collection("users").doc(userID).get()
  userDetail = withUser(userDetail.data())
  
  let followingUsersID =  userDetail?.user_following
  let followerUsersID = userDetail?.user_followers

  // remove id from others user following list  
  followerUsersID.map(async(id)=>{
    await db.collection("users").doc(id).update({
      "user_following":  firebase.firestore.FieldValue.arrayRemove(userID)
    })
  })

  // remove id from others user following me list 
  followingUsersID.map(async(id)=>{
    await db.collection("users").doc(id).update({
      "user_followers": firebase.firestore.FieldValue.arrayRemove(userID)
    })
  })

  // set user to inactive
  await db.collection("users").doc(userID).update({
    "user_is_approve":false
  })

  await db.collection("users").doc(userID).update({
    "user_disapprove":true
  })

  // remove following and follower list of that user
  await db.collection("users").doc(userID).update({
    "user_followers":[]
  })

  await db.collection("users").doc(userID).update({
    "user_following":[]
  })

  return userDetail
}


export const searchUserByName = async(name)=>{
  name = name.toLowerCase()
  let searchName = name.split("@")[1]

  let users = []
  let resp = await db.collection("users").where("user_search_name", "array-contains", searchName).get()
  for(const user of resp.docs){
    let data = user.data()
    data['id'] = user.id
    users.push(data)
  }
  return users
}

export const registerUserWithGoogle = async (data) => {
  const result = await db.collection("users").add(data);
  return result;
}

export const updateUserData = async (uid, data) => {
  var channel = await db
    .collection("users")
    .doc(uid)
    .update(data);
  return channel;
};


export const loginWithGoogleFunc = async () =>{
  let provider = new firebase.auth.GoogleAuthProvider();
  try {
    let signInWithPop = await app.auth().signInWithPopup(provider)
    /** @type {firebase.auth.OAuthCredential} */
    //const credential = signInWithPop.credential
    // This gives you a Google Access Token. You can use it to access the Google API.
    //const token = credential.accessToken;


    // The signed-in user info.
    const user = signInWithPop.user;
    const userDetail = await getUserByEmail(user.email)
    let userData;

    if(userDetail.length === 0){
      await registerUserWithGoogle({
          [FIELDS.USER.USER_NAME]: user.displayName,
          [FIELDS.USER.USER_EMAIL]: user.email,
          [FIELDS.USER.USER_LINKEDIN_PROFILE]: "",
          [FIELDS.USER.USER_IMAGE]: user.photoURL.replace("s96-c", "s400-c"),
          [FIELDS.USER.USER_ASK_EVENT]: "",
          [FIELDS.USER.USER_SEARCH_NAME] : getName(user.displayName),
          [FIELDS.USER.USER_KEYWORD] : user.displayName.toLowerCase().split(" "),
          [FIELDS.USER.USER_COUNTRY]: "",
          [FIELDS.USER.USER_INVITED_EMAIL]: "",
          [FIELDS.USER.USER_INVITED_USER_NAME]: "",
          [FIELDS.USER.USER_INVITED_USER_ID]: "",
          [FIELDS.USER.USER_USER_TYPE]: "",
          [FIELDS.USER.USER_DATE]: new Date(),
          [FIELDS.USER.USER_APPROVE]: true
      });
      const docs = await getUserByEmail(user.email);
      userData = await getUserDataIfExist(docs)
    }else{
      userData = await getUserDataIfExist(userDetail)
    }
    return userData;

  } catch (error) {
    // Handle Errors here.
    // var errorCode = error.code;
    // var errorMessage = error.message;
    // // The email of the user's account used.
    // var email = error.email;
    // // The firebase.auth.AuthCredential type that was used.
    // var credential = error.credential;
    // ...    
  }
}

export const getUserDataIfExist = async (docs) =>{
  let userData = []
  for (const user of docs) {
    let data = user.data();
    data['id'] = user.id
    userData.push(data)
  }
  return userData
}

export const loginFunc = async (email,password) =>{
  try {
    let resp = await app.auth().signInWithEmailAndPassword(email,password)
    let userDetail = await getUserDetail(resp.user.uid)
    
    if(!userDetail) throw new Error(loginError.userNotExist)
    
    if(userDetail.user_is_approve){
      await updateUser(resp.user.uid, "loggedInTime", Date.now(),"", "");
      return {id:resp.user.uid,data:resp}
    }else{
      throw new Error(loginError.accountNotApprovedError);
    }
  } catch (error) {
    console.log(error)
    return error
  }
}

export const forgotPasswordFunc = async (email,ActionCodeSettings) =>{
  try {
    let resp = await app.auth().sendPasswordResetEmail(email,ActionCodeSettings)
    return resp
  } catch (error) {
    console.log(error,"Forgot password error consoling")
    return error
  }
}

export const loginWithEmailAndPass = async (email, pass) =>{
  let resp;
  try {
    resp = await app.auth().signInWithEmailAndPassword(email, pass)
    return resp
  } catch (error) {
    return error
  }
}