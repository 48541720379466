import React, { Fragment, useEffect, useState } from "react";
import '../../Assets/Style/profile.scss'
import userIcon from '../../Assets/Images/profile.png'
import searchIcon from '../../Assets/Images/search.png'
import loaderGif from '../../Assets/Images/loader.gif'
import { getAllUsers, searchSpecificUser, getAllUsersCount } from "../../Firebase/Users";
import { Link } from 'react-router-dom';
import GroupChat from '../../components/channel/GroupChat'
import NetworkingRoom from '../../components/networkingRoom'
import Helper from "../../Helper/helper";

const ChatBox = (props) => {

  const [users, setUsers] = useState([])
  const [totalUsersCount, setTotalUsers] = useState(0)
  const [limit, setLimit] = useState(50)
  const [isLoading, setLoading] = useState(false)
  const [isPagination, setPagination] = useState(false)

  const fetchUsers = (async(limit) => {
    //const totalUsers = await getAllUsersCount();
    //setTotalUsers(totalUsers);
    const result = await getAllUsers(100)
    setUsers(result)
  })

  useEffect(()=>{
    props.membersList ? setUsers(props.membersList) : fetchUsers(limit);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const searchUsers = async (search) => {
    setLoading(true)
    const result = await searchSpecificUser(search.toLowerCase());
    let users = result.map(el=>{
      el.id = el.uid
      return el;
    })
    setLoading(false)
    search !== "" ? setUsers(users) : fetchUsers(limit)
  }
  

  const handleScroll = async (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
	  if(bottom  || e.target.scrollHeight - e.target.scrollTop === 729.5 || e.target.scrollHeight - e.target.scrollTop === 729.25) {
      let newOffset = limit + 50;
      if(totalUsersCount > newOffset) {
        setPagination(true)
        await fetchUsers(newOffset);
        setLimit(newOffset);
        setPagination(false)
      }
    }
  }
  
  return (
    <Fragment>
      
      <div className="RightSideBarContainer profile-bar">
      <NetworkingRoom userInfo={props.userInfo}  />
      {
        (props.selectedTab === "people" || props.selectedTab !== "groupChat") &&
        <div className="chatBoxContainer divWithScrollbar" onScroll={handleScroll}>
          <div className="Search">
            <input type="text" placeholder="search" onChange={(e) => searchUsers(e.target.value)} />
            <img
              src={searchIcon}
              className="img-fluid"
              width="12"
              alt="usrimage"
            />
          </div>
          <div className="chat-container">

          {!isLoading && users.map((item, key) => {
              return item.image && item.name !== "Muhammad Waleed Khan" && item.name !== "Elena Joe" && item.name !== "Abdullah Gilani" && item.name !== "Shahjahan Chaudhary " && (
                <div className="chatBox" key={key}>
                  <div className="userImg">
                    {
                        item.image ?
                        <Link to={"/profile/"+item.id}>
                          <img
                            src={item.image}
                            alt="user-img"
                            className="img-fluid"
                            width="40"
                          />
                        </Link>
                        :
                        <Link to={"/profile/"+item.id}>
                          <img
                            src={userIcon}
                            alt="user-img"
                            className="img-fluid mr-2"
                            width="40"
                          />
                        </Link>
                    }
                  </div>
                  <div className="Message">
                    <Link to={"/profile/"+item.id}> <p>{Helper.transformText(item.name)}</p></Link>
                    <span>{item.linkedin_profile}</span>
                  </div>
                </div>
              );
            })}
            {
              isPagination && <div className="loaderImg"><img src={loaderGif} width="50" height="50" alt="noImage" /></div>
            }
            {
              isLoading && <p>Searching...</p>
            }
            {
              users.length === 0 && <p style={{fontWeight:300}} >no user found</p>
            }
        </div>
        </div>

        }
        {
          props.selectedTab === "groupChat" &&
          <div className="chat-component">
            <GroupChat eventDetail={[]} history={props.history}  />
          </div>
        }
       </div>
    </Fragment>
  );
};

export default ChatBox;
