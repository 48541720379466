import React, { Component, Fragment } from "react";
import { AuthContext } from "../Firebase/AuthProvider";
import { getFollowingUserDetail } from "../Firebase/Users";
import { FIELDS } from "../Firebase/Constants";
import FollowersList from "../components/FollowerList";
import FollowingInfo from "../components/FollowingList";
// Import styles
import "../Assets/Style/profile.scss";

class FollowingList extends Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = {
      userDetailLength: 0,
      userFollowingDetail: [],
    };
  }

  componentDidMount() {
    if (this.state.userFollowingDetail.length <= 0) {
      if (this.props.profile) {
        this.getUserFollowerDetail(this.props.userDetail).then((data) => {
          this.setState({
            userFollowingDetail: data[FIELDS.USER.USER_FOLLOWING_DETAIL],
          });
        });
      } else {
        this.getUserFollowerDetail(this.context.state.userDetail).then(
          (data) => {
            this.setState({
              userFollowingDetail: data[FIELDS.USER.USER_FOLLOWING_DETAIL],
            });
          }
        );
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let userDetail = prevProps.profile
      ? prevProps.userDetail
      : this.context.state.userDetail;
    if (
      userDetail[FIELDS.USER.USER_FOLLOWING]?.length !==
      prevState.userDetailLength
    ) {
      this.setState({
        userDetailLength: userDetail[FIELDS.USER.USER_FOLLOWING]?.length,
      });
      this.getUserFollowerDetail(userDetail).then((data) => {
        this.setState({
          userFollowingDetail: data[FIELDS.USER.USER_FOLLOWING_DETAIL],
        });
      });
    }
    return;
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  getUserFollowerDetail = async (data) => {
    const detail = await getFollowingUserDetail(data);
    return detail;
  };

  render() {
    return (
      <Fragment>
         {(this.props?.userDetail?.[FIELDS.USER.USER_FOLLOWING]?.length > 0 ||
          this.props?.userDetail?.[FIELDS.USER.USER_FOLLOWERS]?.length > 0) && (
          <div className="FollowerSection">
            {this.props?.userDetail?.[FIELDS.USER.USER_FOLLOWING]?.length >
              0 && (
              <div className="FollowerWrapper">
                <FollowingInfo 
                  profile={this.props.profile}
                  userDetail={this.props.userDetail}
                />
              </div>
            )}
            {this.props?.userDetail?.[FIELDS.USER.USER_FOLLOWERS]?.length > 0 && (
              <FollowersList
                profile={this.props.profile}
                userDetail={this.props.userDetail}
              />
            )}

          </div>
        )}
      </Fragment>
    );
  }
}

export default FollowingList;
