import React, { Component } from "react";
import { AuthContext } from "../../../Firebase/AuthProvider";
import { updateUserOrganization } from "../../../Firebase/Users";
import "react-datepicker/dist/react-datepicker.css";
import { getUserDetail } from "../../../Firebase/Users";

class MyStoryModal extends Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = {
      userStory: "",
    };
  }
  
  onChangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  validate = () => {
    let error = "";
    if (this.state.instituteName === "") {
      error = "Organization cannot be blank";
      return error;
    }
    return null;
  };

  onSubmitForm = async (e) => {
    e.preventDefault();
    const validate = this.validate();
    if (validate != null) {
      this.setState({ errorText: validate });
      return;
    } else {
      this.setState({ errorText: "" });
    }
    this.context.showLoader(true);
    let userUID = localStorage.getItem("userID")
    let resp = await updateUserOrganization(userUID, this.state.userStory)
    if(resp){
      let userData = await getUserDetail(userUID)
      this.context.setCurrentUser(userUID, userData);
      this.context.getData();
      this.props.updateCurrentUser(userData)
    }else{
      this.context.showLoader(false);
    }
    
    
    // updateUserOrganization(userUID, this.state.userStory)
    //   .then(() => {
    //     this.setState(
    //       {
    //         userStory: ""
    //       },
    //       () => {
    //         // showSuccessMessage("Profile has been updated.")
    //         this.context.showLoader(false);
    //         this.props.closeModal();
    //         // update user detail and events to refresh experience
    //         getUserDetail(userUID)
    //         .then((userData) => {
    //           this.context.setCurrentUser(userUID, userData);
    //           this.context.getData();
    //           this.props.updateCurrentUser(userData)
    //         })
    //         .catch((err) => {
    //           if (err) {
    //             this.context.showLoader(false);
    //           }
    //         });
    //       }
    //     );
    //   })
    //   .catch((err) => {
    //     if (err.code === "auth/requires-recent-login") {
    //       this.context.showLoader(false);
    //       this.setState({
    //         errorText: "Login again then retry",
    //       });
    //       return;
    //     }
    //     this.context.showLoader(false);
    //     this.props.closeModal();
      // });
  };

  handleStartDateChange = (date) => {
    this.setState({ startDate: date })
  }

  handleEndDateChange = (date) => {
    this.setState({ endDate: date })
  }

  render() {
    return (
      <div className="user-modal" onClick={() => this.props.closeModal()}>
        <div className="user-modal-inner" onClick={(e) => e.stopPropagation()}>
          {this.props.show === "showProfileModal" && (
            <div className="change-pswrd-div gnrl-styling">
              <h3 className="login-header">
                Update Profile
              </h3>
              <form>
                <label>My Organization</label>
                <input type="text" placeholder="Enter your organization" value={this.state.description} name="linkedin_profile" className="email-input" onChange={(e) => this.setState({ userStory: e.target.value })} />
                {this.state.errorText && <span className="text-red">{this.state.errorText}</span>}
                <button
                  className="login-button-form"
                  onClick={(e) => {
                    this.onSubmitForm(e);
                  }}
                >
                  Update
                </button>
              </form>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default MyStoryModal;
