import React, { Component }  from 'react'
import { AuthContext } from '../../Firebase/AuthProvider'
import { FIELDS } from "../../Firebase/Constants";
import { channelError, channelFormText } from "../../Helper/StaticContent";
import { createChannel } from "../../Firebase/Channels";
import { uploadUserImage } from "../../Firebase/StorageBucket";
import { updateUser } from '../../Firebase/Users';

class AddNewChannel extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            description: "",
            link: "",
            imageFile: "",
            channelCode: 0,
            displayModal: "flex",
            onChannelClick: "",
            imagePreview: "",
            errorText: "",
            channel_status: "Inactive"
        };
    }

    onchangeHandler = (e) => {
        this.setState({
          [e.target.name]: e.target.value,
        });
      };
    
      handleImageAsFile = (e) => {
        const image = e.target.files[0];
        if (image) {
          this.setState({
            imageFile: image,
            imagePreview: URL.createObjectURL(image),
          });
        }
      };
    
      validate = () => {
        let error = "";
        if (this.state.name === "") {
          error = channelError.nameError;
          return error;
        }
        return null;
      };
    
      moveToChannel(channelID) {
        setTimeout(() => {
          window.location.href = "/channel/" + channelID;
        }, 1500);
      }
    
      onChannelClick = async (e) => {
        e.preventDefault();
        this.context.showLoader(true);
        const validate = this.validate();
        if (validate != null) {
          this.setState({ errorText: validate });
          this.context.showLoader(false);
          return;
        } else {
          this.setState({ errorText: "" });
        }
        const imageFile = this.state.imageFile;
        let imageUrl = "";
        if (imageFile) {
          imageUrl = await uploadUserImage(imageFile);
        }
        createChannel({
          [FIELDS.CHANNEL.CHANNEL_NAME]: this.state.name,
          [FIELDS.CHANNEL.CHANNEL_DESCRIPTION]: this.state.description,
          [FIELDS.CHANNEL.CHANNEL_LINK]: this.state.link,
          [FIELDS.CHANNEL.CHANNEL_COVER_IMAGE]: imageUrl,
          [FIELDS.CHANNEL.CHANNEL_OWNER]: this.context.state.currentUser,
          [FIELDS.CHANNEL.CHANNEL_CODE_NUMBER]: parseInt(this.state.channelCode),
          [FIELDS.CHANNEL.CHANNEL_STATUS]: this.state.channel_status
        })
          .then((res) => {
            let channelId = res.id;
            let userChannels = this.context.state.userDetail?.user_channels ? this.context.state.userDetail?.user_channels : []
            userChannels.push(channelId);
            updateUser(this.context.state.currentUser, "user_channels", userChannels,"", "");
            this.setState(
              {
                name: "",
                description: "",
                link: "",
                imageFile: "",
              },
              () => {
                this.context.showLoader(false);
              }
            );
            // showSuccessMessage("Channel has been created successfully!");
            this.moveToChannel(channelId);
          })
          .catch((err) => {
            if (err.message) {
              this.setState({ errorText: err.message }, () => {
                this.context.showLoader(false);
              });
            }
          });
      };
    
      closeModal = () => {
        this.props.setChannelModal(false);
        this.setState({
          displayModal: "none",
        });
      };
    
      removeUserImage = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
          imageFile: false,
          imagePreview: "",
        });
      };
      render() {return (
        <React.Fragment>
        <form>
                <input type="text" required placeholder="Tribe Name" name="name"
                    value={this.state.name}
                    onChange={this.onchangeHandler} />
            {this.state.errorText && (
                <span className="text-red">{this.state.errorText}</span>
                )}
                <textarea rows="7" placeholder="Description" required
                    name="description"
                    value={this.state.description}
                    onChange={this.onchangeHandler}>
                </textarea>
                <input type="text" placeholder="Enter Tribe code" name="channelCode"
                    value={this.state.channelCode}
                    onChange={this.onchangeHandler} 
                />
            <div
                className={`input-upload-image ${
                    this.state.imagePreview && "image-preview"
                }`}
                >
                <label
                    className={this.state.imagePreview && "image-preview"}
                    htmlFor="image-upload"
                >
                    {this.state.imagePreview ? (
                    <React.Fragment>
                        <img src={this.state.imagePreview} alt="" />
                        <a
                        href="# "
                        onClick={(e) => {
                            this.removeUserImage(e);
                        }}
                        >
                        &#10010;
                        </a>
                    </React.Fragment>
                    ) : (
                    `${channelFormText.imageFile}`
                    )}
                </label>
                <input
                    className="input-image-file"
                    accept="image/*"
                    type="file"
                    id="image-upload"
                    name="upload-image"
                    value=""
                    onChange={this.handleImageAsFile}
                />
                </div>
                
            <div className="btn-div">
                <button onClick={(e) => {
                    this.onChannelClick(e);
                }}>Create</button>
            </div>
        </form>
      </React.Fragment>
    )}
}

export default AddNewChannel
