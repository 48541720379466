import React, { Fragment, Component } from "react";

// import style sheet
import "./channelform.scss";

import { AuthContext } from "../../Firebase/AuthProvider";
import { uploadUserImage } from "../../Firebase/StorageBucket";

class AddSpeaker extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      name: props.editChannelInfo ? props.editChannelInfo.name : "",
      title: props.editChannelInfo ? props.editChannelInfo.title : "",
      email: props.editChannelInfo ? props.editChannelInfo.email : "",
      link: props.editChannelInfo ? props.editChannelInfo.link : "",
      imageFile: props.editChannelInfo ? props.editChannelInfo.imageFile : "",
      imagePreview: "",
      errorText: "",
    };
  }

  validate = () => {
    let error = "";
    if (this.state.name === "") {
      error = "Speaker name is required.";
      return error;
    }
    if (this.state.link === "") {
      error = "Link fields is required.";
      return error;
    }
    return null;
  };

  handleImageAsFile = (e) => {
    const image = e.target.files[0];
    if (image) {
      this.setState({
        imageFile: image,
        imagePreview: URL.createObjectURL(image),
      });
    }
  };

  onchangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChannelClick = async (e) => {
    e.preventDefault();
    this.context.showLoader(true);
    const validate = this.validate();
    if (validate != null) {
      this.setState({ errorText: validate });
      this.context.showLoader(false);
      return;
    } else {
      this.setState({ errorText: "" });
    }
    const imageFile = this.state.imageFile;
    let imageUrl = "";
    if (imageFile) {
      imageUrl = await uploadUserImage(imageFile);
    }
    let data = {
      name: this.state.name,
      title: this.state.title,
      email: this.state.email,
      link: this.state.link,
      image: imageUrl,
    };
    let newSpeakersArr = [...this.props.speakersList]
    if(this.props.editChannelInfo) {
      newSpeakersArr[this.props.selectedIndex] = data;
    }
    else {
      newSpeakersArr.push(data)
    }
    this.props.updateChannelInfo({ speakers: newSpeakersArr });
    this.setState({
      name: "",
      title: "",
      email: "",
      link: "",
      imageFile: "",
      imagePreview: "",
      errorText: "",
    })
  };

  render() {
    return (
      <Fragment>
        <form>
          <div className="channel-input-group">
            <label>Name of the speaker</label>
            <input
              type="text"
              name="name"
              onChange={this.onchangeHandler}
              value={this.state.name}
              placeholder="Speaker Name"
            />
          </div>
          <div className="channel-input-group">
            <label>Title</label>
            <input
              type="text"
              name="title"
              value={this.state.title}
              placeholder="40 characters e.g World's Leading Fintech"
              onChange={this.onchangeHandler}
            />
          </div>
          <div className="channel-input-group">
            <label>Speaker Email</label>
            <input
              type="text"
              name="email"
              onChange={this.onchangeHandler}
              value={this.state.email}
              placeholder="Speaker Email for confirmation"
            />
          </div>
          <div className="channel-input-group">
            <label>Link (Website/etc)</label>
            <input
              type="text"
              name="link"
              onChange={this.onchangeHandler}
              value={this.state.link}
              placeholder="Enter link"
            />
          </div>
          <div className="channel-upload-group">
            <label>Upload speaker logo</label>
            <label className="image-label">
              Select Image
              <input
                type="file"
                accept="image/*"
                id="image-upload"
                name="upload-image"
                value=""
                onChange={this.handleImageAsFile}
              />
            </label>
            {this.state.errorText && (
              <span className="error-display">{this.state.errorText}</span>
            )}
          </div>

          <button
            className="form-submit"
            onClick={(e) => {
              this.onChannelClick(e);
            }}
          >
            {this.props.editChannelInfo ? "Update" : "Add"} Speaker
          </button>
        </form>
      </Fragment>
    );
  }
}

export default AddSpeaker;
