import React, { Component } from 'react'
import "../Assets/Style/layout.css"
import {
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  TwitterShareButton
} from "react-share";

import {
  FacebookIcon,
  LinkedinIcon,
  WhatsappIcon,
  TwitterIcon
} from "react-share";
import { FIELDS } from '../Firebase/Constants';

class ShareComponent extends Component {

  render() {
      const hostname = window.location.hostname === "localhost" ? "assembly-dev-3c297.web.app" : window.location.hostname
      const eventGuid = `https://${hostname}/event/${this.props.eventDetail && this.props.eventDetail.uid}`;
    return (
      <div className={`share-component ${this.props.show && "show-share-component"}`} id="share">
        <div className="share-icon-list">
            <TwitterShareButton className="share-icon"
              disabled={this.props.disabled}
              url={eventGuid}
              title={this.props.eventDetail && this.props.eventDetail[FIELDS.EVENT.EVENT_NAME]}
            >
              <TwitterIcon borderRadius="50%" size="40"></TwitterIcon>
            </TwitterShareButton>

            <LinkedinShareButton className="share-icon"
                disabled={this.props.disabled}
                url={eventGuid}
                title={this.props.eventDetail && this.props.eventDetail[FIELDS.EVENT.EVENT_NAME]}
                summary={this.props.eventDetail && this.props.eventDetail[FIELDS.EVENT.EVENT_DETAIL_LONG]}
            >
                <LinkedinIcon  borderRadius="50%" size="40"></LinkedinIcon>
            </LinkedinShareButton>
            <WhatsappShareButton className="share-icon"
                disabled={this.props.disabled}
                url={eventGuid}
                title={this.props.eventDetail && this.props.eventDetail[FIELDS.EVENT.EVENT_NAME]}
                separator=","
            >
                <WhatsappIcon    borderRadius="50%" size="40"></WhatsappIcon>
            </WhatsappShareButton>
            <FacebookShareButton className="share-icon"
                url={eventGuid}
                disabled={this.props.disabled}
            > 
                <FacebookIcon    borderRadius="50%" size="40"></FacebookIcon>
            </FacebookShareButton>

        </div>
      </div>
    )
  }
}

export default ShareComponent
