import React, { Fragment, useState } from "react";

// import components
import UpComingSession from "./UpComingSession";
import LearningJourney from "./LearningJourney";
// import styles
import '../../Assets/Style/agenda.css'
import { notifyAllAboutTribeEvent } from '../../Firebase/Events'

const OurAgenda = ({ eventsList, removeEvents, channelInfo, currentUser }) => {
  const [showLearningJourney] = useState(false);
  const [isNotifyAll, setNotifyAll] = useState(false);

  const notifyAllMembers = async (eventId) => {
    setNotifyAll(true);
    await notifyAllAboutTribeEvent(eventId);
  }

  return (
    <Fragment>
      <div className="AgendaWrapper">
        <UpComingSession eventsList={eventsList} isNotifyAll={isNotifyAll} notifyAllMembers={notifyAllMembers} removeEvents={removeEvents} channelInfo={channelInfo} currentUser={currentUser} />
        {showLearningJourney && <LearningJourney />}
      </div>
    </Fragment>
  );
};

export default OurAgenda;
