import React, { Component } from "react";
import { channelError } from "../../Helper/StaticContent";
import { AuthContext } from "../../Firebase/AuthProvider";

class JoinChannelModal extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      channel_code: "",
      displayModal: "flex",
      errorText: "",
    };
  }

  onchangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  validate = () => {
    let error = "";
    if (this.state.channel_code === "") {
      error = channelError.codeError;
      return error;
    }
    return null;
  };

  onChannelClick = async (e) => {
    e.preventDefault();
    this.context.showLoader(true);
    const validate = this.validate();
    if (validate != null) {
      this.setState({ errorText: validate });
      this.context.showLoader(false);
      return;
    } else {
      this.setState({ errorText: "" });
    }
    if(parseInt(this.state.channel_code) !== this.props.requiredCode) {
      this.setState({ errorText: "Invalid code." });
    }
    else {
      this.setState({ errorText: "" });
      let newMembersArr = [...this.props.memberList]
      newMembersArr.push(this.context.state.userDetail)
      this.props.updateChannelInfo({ members: newMembersArr }, "Congratulations! You have successfully joined the channel.");
    }
  };

  closeModal = () => {
    this.props.setChannelModal();
    this.setState({
      displayModal: "none",
    });
  };

  render() {
    return (
      <div
        className="modal-box"
        style={{ display: this.state.displayModal, zIndex: "999" }}
        id="SignupModal"
        onClick={this.closeModal}
      >
        <div className="login-inner" onClick={(e) => e.stopPropagation()}>
          <h3 className="login-header">Enter Tribe Code</h3>
          <form>
            <input
              type="number"
              required
              name="channel_code"
              value={this.state.channel_code}
              onChange={this.onchangeHandler}
              placeholder="Enter Tribe Code"
              className="email-input"
            />
            {this.state.errorText && (
              <span className="error-display">{this.state.errorText}</span>
            )}
            <button
              className="login-button-form channel-create-btn"
              onClick={(e) => {
                this.onChannelClick(e);
              }}
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default JoinChannelModal;
