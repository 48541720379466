import React, { useEffect, useState } from 'react'
import { Fragment } from 'react'
import UserImg from '../../Assets/Images/profile.png'
import Play from '../../Assets/Images/Assembly.png'
import { app } from "../../Firebase/firebase-config";
const db = app.firestore();


const NetworkingRoom = ({ userInfo, channelId }) => {

    const [liveVideos, setLiveVideos] = useState([])
    const [membersList, setMembersList] = useState(0)
    const [showAllVideos, setShowAllVideos] = useState(false)
    useEffect(() => {
        fetchLiveVideos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchLiveVideos = () => {
        if (!channelId) {
            db.collection("videos")
                .where("isMeetingActive", "==", true)
                .orderBy('datetime', 'desc')
                .limit(5)
                .get()
                .then(async (snapshot) => {
                    let videoList = [];
                    let videoLength = snapshot.docs.length;
                    if (videoLength > 0) {
                        // eslint-disable-next-line array-callback-return
                        snapshot.docs.map(el => {
                            let videoInfo = el.data();
                            let joinedMembers = videoInfo.membersList.length + 1;
                            //setMembersList(joinedMembers)
                            if (joinedMembers > 1) {
                                videoInfo.membersList = videoInfo.membersList.slice(0, 7);
                            }
                            videoList.push(videoInfo);
                        })
                    }
                    setLiveVideos(videoList);
                });
        }
        else {
            db.collection("videos")
                .where('channelId', "==", channelId)
                .orderBy('datetime', 'desc')
                .get()
                .then(async (snapshot) => {
                    let videoLength = snapshot.docs.length;
                    if (videoLength > 0 && snapshot.docs[0].data().isMeetingActive === true) {
                        const videoInfo = snapshot.docs[0].data();
                        let joinedMembers = videoInfo.membersList.length + 1;
                        setMembersList(joinedMembers)

                        if (joinedMembers > 1) {
                            videoInfo.membersList = videoInfo.membersList.slice(0, 7);
                        }
                        setLiveVideos([videoInfo]);
                    }
                });
        }
    }
    const goToVideoSection = (meetingId) => {
        window.open("https://live.assemblyf.com:2053/?meeting_id=" + meetingId + "&meeting_name=" + userInfo.name.split(" ")[0] + "&user_id=" + userInfo.id + "", "_blank")
    }

    return (
        <Fragment>
            {liveVideos.map((item, key) => {
                return (
                    <Fragment key={key}>
                        <div className={(key > 1 && showAllVideos === false) ? "RightSideBarContainer hideOthersMeeting" : "RightSideBarContainer"}>
                            <div className="meeting-live-dive">
                                <span>Live</span>
                                <h1 className="meeting-name">{item.meetingId}</h1>

                                <div className="image-container">
                                    <div className="image-box">
                                        <img src={item.hostInfo.image ? item.hostInfo.image : UserImg} alt="noImage" />
                                    </div>
                                    {item.membersList.map((member) => {
                                        return (
                                            <div className="image-box">
                                                <img src={member.image ? member.image : UserImg} alt="noImage" />
                                            </div>
                                        )
                                    })}
                                    {
                                        membersList > 1 && (membersList - item.membersList.length - 1) > 0 &&
                                        <div className="image-box more-members-count">
                                            <span>+{membersList - item.membersList.length - 1}</span>
                                        </div>
                                    }
                                </div>
                                <div className="join-btn">
                                    <a href={() => false} onClick={() => goToVideoSection(item.meetingId)}>Join Now <img src={Play} alt="noImage" /></a>
                                </div>





                            </div>
                        </div>
                    </Fragment>
                )
            })}
            {
                liveVideos.length > 2 && <div className="showMorebtn"><span onClick={() => setShowAllVideos(true)}>Show More</span></div>
            }
        </Fragment>
    )
}

export default NetworkingRoom;