import React, { Fragment } from "react";
import fbContentLoader from '../Assets/Images/fb-content-loader.gif'
import "../Assets/Style/facebookLoader.css";

const FacebookLoader = ({ numberOfLoader }) => {
  return (
    <Fragment>
      {
        numberOfLoader === 1 ?
        <img
            src={fbContentLoader}
            alt="user-img"
            className="img-fluid fb-content-loader"
          />
        :
        <Fragment>
          <img
            src={fbContentLoader}
            alt="user-img"
            className="img-fluid fb-content-loader"
          />
          <img
              src={fbContentLoader}
              alt="user-img"
              className="img-fluid fb-content-loader"
          />
        </Fragment>
      }
        
        </Fragment>
  );
};

export default FacebookLoader;
