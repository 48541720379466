import React, { Fragment, Component } from "react";

// Import styles
import '../../Assets/Style/profile.scss'
// Import components
import ProfilePhoto from "./profilePhoto";
import Experience from "./experience";
import MyStory from "./myStory";
import Education from "./education";
import Followers from "../NewFollowingList";
import {AuthContext} from '../../Firebase/AuthProvider'
import StoryPostComponent from '../../components/StoryPostComponent'
import { addReaction } from '../../Firebase/Events'
import { app } from "../../Firebase/firebase-config";
import { withPost } from '../../Firebase/Schemas'
import { getUserDetail } from '../../Firebase/Users'
import { FIELDS, postQueryLimit } from '../../Firebase/Constants'
import PostComponentUI from '../../components/PostComponentUI'
import {  getEventsByHost, getUserAttendingEvents, updateSpecificEvent } from '../../Firebase/Events'
import FacebookLoader from "../../components/FacebookLoader";
import ChannelModal from "../../components/channel/ChannelModal";
import ChannelHeader from "../../components/channelHeader/homePage";
import SkeletonLoader from "../Loaders/SkeletonLoader";
import { getChannelById } from "../../Firebase/Channels";

const db = app.firestore();


class Profile extends Component {
  static contextType = AuthContext
  constructor(props){
      super(props)
      this.state={
          showSignUpModal:false,
          showLoginModal:false,
          showSplashModal:false,
          events:[],
          userGoingEvents:[],
          unApprovedEvents:[],
          pastApprovedEvents:[],
          userID:"",
          users:'',
          eventDetail:'',
          posts:[],
          initialCall: false,
          showModal: false,
          postsLimit: postQueryLimit,
          totalPosts: 0,
          totalElves: 0,
          showChannelModal: false,
          isReactionClick: false,
          isCommentAdded:false
      }
  }

  componentDidUpdate(prevProps){
     // Parameter User ID
     let userID = this.props.props.match.params.ID
     if(userID !== prevProps.props.match.params.ID) {
      this.getUserTotalPosts(userID);
      this.realTimePostUpdate()
     }
     if(this.state.userID !== userID){
       this.setState({
         userID
       },()=>{
         this.getUserEvents()
       })
     } 
     
    if(this.state.posts.length === 0 && !this.state.initialCall){
      this.realTimePostUpdate()
      this.setState({
        initialCall:true,
      },()=>{
        this.state.posts.sort((a,b)=>b.post_created_at - a.post_created_at)
      })
    }
  }

  setChannelModal = () => {
    this.setState({
      showChannelModal: !this.state.showChannelModal,
    });
  };

  getChannelInfo = async (channelID) => {
    const result = await getChannelById(channelID);
    return result;
  };

  // Update Post when something change like add/Update/Delete
  realTimePostUpdate = async (updatedPost, isBottom) => {
    var post = ''
    let limitPost = postQueryLimit;
    if(updatedPost){
      updatedPost.sort((a,b)=>b.post_created_at - a.post_created_at)
      var startAfter = updatedPost[updatedPost.length - 1]?.[FIELDS.WALL_POST.POST_CREATED_AT]
      post = db.collection("wall_posts").where("post_user_profile_id","array-contains-any",[this.props.props.match.params.ID, this.context.state.currentUser]).where("post_is_deleted", "==", false).orderBy("post_created_at","desc").startAfter(startAfter).limit(postQueryLimit)
    }else{
      if(isBottom) {
        this.context.showLoader(true)
        let prevLimit = this.state.postsLimit;
        limitPost = this.state.postsLimit + postQueryLimit
        this.setState({
          postsLimit: limitPost
        })
        if(this.state.totalPosts > prevLimit) {
          
          let startAfter =
            this.state.posts[this.state.posts.length - 1]?.[FIELDS.WALL_POST.POST_CREATED_AT];
            if(startAfter) {
              post = db.collection("wall_posts").where("post_user_profile_id","array-contains-any",[this.props.props.match.params.ID, this.context.state.currentUser]).where("post_is_deleted", "==", false).orderBy("post_created_at","desc").startAfter(startAfter).limit(postQueryLimit)
            }
            else {
              post = db.collection("wall_posts").where("post_user_profile_id","array-contains-any",[this.props.props.match.params.ID, this.context.state.currentUser]).where("post_is_deleted", "==", false).orderBy("post_created_at","desc").startAfter(0).limit(postQueryLimit)
            }
        }
        else {
          this.context.showLoader(false)
        }
      }
      else {
        post = db
        .collection("wall_posts")
        .where("post_is_deleted", "==", false)
        .where("post_user_profile_id","array-contains-any",[this.props.props.match.params.ID, this.context.state.currentUser])
        .orderBy("post_created_at","desc")
        .limit(postQueryLimit);
      }
    }
    let newPostsList = this.state.posts;
    if(post) {
      post.onSnapshot((snapshot) => {
        snapshot.docChanges().forEach(async(change) => {
          //let data = change.doc.data()
          //let post = withPost(data)
          //let postHostId = post?.post_user_id
          // let commentorUserId = post?.post_comments?.length > 0 && post?.post_comments[post?.post_comments?.length - 1]?.name
          // if(postHostId === this.context.state.currentUser){
          //   let postReactorId = post?.post_react?.length > 0 && post?.post_react[post?.post_react?.length - 1]
          //   let data =  await getUserDetail(postReactorId)
          // }

          
          if(change.type === "added"){
            let data = change.doc.data()
            let post =  withPost(data)
            post['id'] = change.doc.id
            let userReactName = []
            for (const id of post.post_react){
              let userName = await getUserDetail(id).then(data=>data?.name)
              userReactName.push(userName)
            }
            if(post.post_channel_id) {
              post["channelInfo"] = await this.getChannelInfo(post.post_channel_id);
            }
            post["user_react_name"] = userReactName
            if(isBottom) {
              newPostsList.push(post)
              this.setState(
                {
                  posts: newPostsList,
                },
                () => {
                  newPostsList.sort(
                    (a, b) => b.post_created_at - a.post_created_at
                  );
                }
              );
            }
            else {
              this.setState({
                posts:[...this.state.posts,post]
              },()=>{
                this.state.posts.sort((a,b)=>b.post_created_at - a.post_created_at)
              })
            }
          }
          
          if(change.type === "modified"){
            let updatedPost = [...this.state.posts]
            let data = change.doc.data()
            let post = await withPost(data)
            post['id'] = change.doc.id
            let userReactName = []
            for (const id of post.post_react){
              let userName = await getUserDetail(id).then(data=>data?.name)
              userReactName.push(userName)
            }
            post["user_react_name"] = userReactName
            let index = updatedPost.findIndex(post=> post.id === change.doc.id)
  
            if (index === -1) {
              updatedPost.push(post)
            }else{
              updatedPost[index] = post;
            }
  
            this.setState({
              posts:updatedPost
            },()=>{
              this.state.posts.sort((a,b)=>b.post_created_at - a.post_created_at)
            }) 
          }
  
          if(change.type === "removed"){
            let updatedPost = [...this.state.posts]
            let data = change.doc.data()
            let post = await withPost(data)
            post['id'] = change.doc.id
            
            let index = updatedPost.findIndex(post=> post.id === change.doc.id)
            
            updatedPost.splice(index,1)
            this.setState({
              posts:updatedPost
            },()=>{
              this.state.posts.sort((a,b)=>b.post_created_at - a.post_created_at)
            }) 
          }
        });
      })
    }
    setTimeout(() => {
      this.context.showLoader(false)
    }, 500)
  };

  updateReaction =  (postId) =>{
    let allPosts = [...this.state.posts]

    const userID = this.context.state.currentUser;
    let index = allPosts.findIndex(e=>e.id === postId)
    
    if(allPosts[index]["post_react"].includes(userID)){
      allPosts[index]["post_react"].splice(allPosts[index]["post_react"]?.indexOf(userID),1)
    }else{
      allPosts[index]["post_react"].push(userID)
      this.setState({isReactionClick:true})
    }
    
    this.setState({
      posts:allPosts,
    },async()=>{
      await addReaction(postId,userID)
      this.setState({isReactionClick:false})
    })
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    // Current user ID
    let currentUserID = localStorage.getItem("userID")
    this.setState({userID:currentUserID})
    this.getUserEvents(); 
    let userID = this.props.props.match.params.ID
    this.getUserTotalPosts(userID);
  }

  getUserTotalPosts (userID) {
    db.collection('wall_posts')
      .where("post_user_profile_id", "array-contains-any", [userID])
      .get().then(
      (snapshot) => {
        this.setState({
          totalPosts: snapshot.docs.length
        })
      }
    );
  }


  componentWillUnmount() {
      window.removeEventListener("scroll", this.handleScroll);
      // fix Warning: Can't perform a React state update on an unmounted component
      this.setState = (state,callback)=>{
          return;
      };
  }

  onLoginClick =(event_id)=>{
    this.setState({
        showLoginModal:!this.state.showLoginModal,
        event_id:false
    })
    if(event_id){
        this.setState({event_id})
    }
  }

  onSignupClick =()=>{
    this.setState({
      showSignUpModal:!this.state.showSignUpModal,
      event_id:false
    })
  }

  closeModal=(name,value)=>{
      this.setState({
          [name]:value
      })
  }

  getEventNameHost = (event)=>{
      this.setState({
         eventDetail:event
      })
  }


  getUserEvents = ()=>{
    let userID = this.props.props.match.params.ID
    // Current user ID
    let currentUserID = localStorage.getItem("userID")

    if(userID !== currentUserID){
      this.getEventsByUser(userID)
      this.getUserDetailByID(userID)
      this.getUserGoingEvents(userID)
      this.getApprovedPastEvents(userID)
    }

    if(!userID || userID === currentUserID){
      this.getEventsByUser(currentUserID)
      this.getUserDetailByID(currentUserID)
      this.getUserGoingEvents(userID)
      this.getApprovedPastEvents(userID)
    }
    this.getCurrentUserDetail(userID)
  }

  getUnapprovedEvents = async(userID)=>{
    this.context.showLoader(true)
    getEventsByHost("unapproved_events",userID,"showUnapprovedEvents").then(data=>{
      this.setState({
        unApprovedEvents:data
      },()=>{
        this.context.showLoader(false)
      })
    }).catch(err=>{
      if(err){
        this.context.showLoader(false)
      }
    })
  }

  getApprovedPastEvents = async (userID) =>{
    this.context.showLoader(true)
    getEventsByHost("events",userID,"showPastApprovedEvents").then(data=>{
      this.setState({
        pastApprovedEvents:data.reverse()
      },()=>{
        this.context.showLoader(false)
      })
    }).catch(err=>{
      console.log(err)
      if(err){
        this.context.showLoader(false)
      }
    })
  }

  getUserGoingEvents = async(id)=>{
    this.context.showLoader(true)
    let userAttendingEvents = await getUserAttendingEvents('events',id)
    this.setState({
      userGoingEvents : userAttendingEvents
    },()=>{
      this.context.showLoader(false)
    })
  }

  getEventsByUser = async(userID) =>{
    window.scrollTo({
      top: 0,
      left: 0,
    });
    this.context.showLoader(true)
    getEventsByHost("events",userID,"showEventsHostedByUser").then(data=>{
      this.setState({
        events:data
      },()=>{
        this.context.showLoader(false)
      })
    }).catch(err=>{
      this.context.showLoader(false)
    })
  }

  getUserDetailByID = async (id) =>{
    this.context.showLoader(true)
    getUserDetail(id).then(data=>{
      if(!data.user_is_approve){
        this.props.props.history.push('/')
        return
      }else{
        this.setState({
          users:data
        },()=>{
          this.state.users.user_experience.sort((a,b)=>{
            let date = new Date(a.endDate).getTime()
            let bdate = new Date(b.endDate).getTime()
            return bdate - date
          })
          this.context.showLoader(false)
        })
      }
    }).catch(err=>{
      this.context.showLoader(false)
    })
  }

  getCurrentUserDetail = async (id) =>{
    this.context.showLoader(true)
    getUserDetail(id).then(data=>{
      this.setState({
        totalElves:data.user_total_elves
      },()=>{
        this.context.showLoader(false)
      })
    }).catch(err=>{
      this.context.showLoader(false)
    })
  }

  onFollowClick = async (followingID,followerList) =>{
    this.context.showLoader(true)
    if(this.context.state.currentUser){
      let followers = [...followerList]
      let updatedUserDate = await this.context.followUser(followingID,followers)
      await this.context.getData()
      this.getUserDetailByID(updatedUserDate.id).then(data=>{
        if(data){
          this.context.showLoader(false)
        }
      }).catch(err=>{
        if(err){
          this.context.showLoader(false)
        }
      })
    }else{
      this.context.showLoader(false)
      this.onLoginClick();
    }
    
  }

  giveElvesToUser = async (userId) => {
    //this.context.showLoader(true)
    if(this.context.state.currentUser){
      await this.context.giveElves(userId)
      await this.context.getData()
        this.getCurrentUserDetail(userId).then(data=>{
          if(data){
            // this.context.showLoader(false)
          }
        }).catch(err=>{
          if(err){
            // this.context.showLoader(false)
          }
        })
      }else{
        // this.context.showLoader(false)
        this.onLoginClick();
      }
  }

  onBioUpdateClick = async(tableName,id,value) =>{
    this.context.showLoader(true)
    updateSpecificEvent(tableName,id,[FIELDS.USER.USER_BIO],value).then(()=>{
      this.getUserDetailByID(id)
    }).catch(err=>{
      if(err){
        this.context.showLoader(false)
      }
    })
  }

  onUserTypeClick = async(id,value) =>{
    this.context.showLoader(true)
    updateSpecificEvent('users',id,'user_skill',value).then(()=>{
      this.getUserDetailByID(id)
    }).catch(err=>{
      if(err){
        this.context.showLoader(false)
      }
    })
  }

  updateCurrentUser = (data) => {
    this.setState({
      users: data,
    },()=>{
      this.state.users.user_experience.sort((a,b)=>b.present - a.present)

    })
  }

  handleScroll = () => {
      const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
      const body = document.body;
      const html = document.documentElement;
      const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
      const windowBottom = windowHeight + window.pageYOffset;
      if (windowBottom >= (docHeight-0.75)) {
        this.realTimePostUpdate('', true)
      } 
  }
  
  render() {

    return (
      <Fragment>
        <div className="ProfileContainer">
            <div className="ContentLeftWrap">
              
              {(this.context.state.showLoader && !this.context.state.dummyLoader) ? 
                <SkeletonLoader mb="10" profile={true} height="m" />
              :
                <ProfilePhoto
                  onBioUpdateClick={this.props.props.onBioUpdateClick}
                  onFollowClick={this.onFollowClick} 
                  profile={this.state}
                  profileImage={this.state.users['image']}
                  userDetail={this.state.users}
                  giveElvesToUser={this.giveElvesToUser}
                  totalElves={this.state.totalElves}
                  userID={localStorage.getItem("userID")}
                  openModal={this.props.openModal}
                  updateCurrentUser={this.updateCurrentUser}
                  onUserTypeClick={this.onUserTypeClick}
                  history={this.props.props.history}
                />
              }

              {(this.context.state.showLoader && !this.context.state.dummyLoader) ?
                <SkeletonLoader mb="10" general={true} />
              :
                <MyStory
                  userDetail={this.state.users}
                  userID={localStorage.getItem("userID")}
                  openModal={this.props.openModal}
                  updateCurrentUser={this.updateCurrentUser}
                />
              }


              {(this.context.state.showLoader && !this.context.state.dummyLoader) ?
                <SkeletonLoader mb="10" general={true} />
              :
                <Experience
                userDetail={this.state.users}
                userID={localStorage.getItem("userID")}
                openModal={this.props.openModal}
                updateCurrentUser={this.updateCurrentUser}
              />
              }

              {(this.context.state.showLoader && !this.context.state.dummyLoader) ?
                <SkeletonLoader mb="10" general={true} />
              :
                <Education 
                userDetail={this.state.users}
                userID={localStorage.getItem("userID")}
                updateCurrentUser={this.updateCurrentUser}
              />
              }   
                {this.context.state.currentUser && (
                  <Followers profile={localStorage.getItem("userID")} userDetail={this.state.users}/>
                )}
               {
                 localStorage.getItem("userID") === "5Y3cWzNEe5X67tDMsCZIpXmFCFs1" && <ChannelHeader setChannelModal={this.setChannelModal}  />
               }
            </div>
            <div className="ContentRightWrap">
            {this.context.state.currentUser && (
              <StoryPostComponent realTimePostUpdate={this.realTimePostUpdate} userDetail={this.state.users} history={this.props.props.history} isProfiePage={true}/>
            )}
              
              <PostComponentUI updateReaction={this.updateReaction} history={this.props.props.history} posts={this.state.posts} isProfiePage={true} userDetail={this.state.users} currentUser={this.context.state.currentUser}  />
              {
                this.context.state.showLoader &&
                <FacebookLoader />
              }
            </div>
        </div>
        {this.state.showChannelModal && (
          <ChannelModal
            setChannelModal={this.setChannelModal}
            historyProps=""
          />
        )}
      </Fragment>
    );
  }
};

export default Profile;
