import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import '../Assets/Style/LoginSignupComp.scss';
import { AuthContext } from '../Firebase/AuthProvider';
import { forgotPasswordFunc, loginFunc, loginWithGoogleFunc } from '../Firebase/Users';
import { loginError, loginFormText } from '../Helper/StaticContent'
import UserModal from './UserModal';

function LoginSignUpComp({moreProfileInfo, openPaymentModal, closeModal, isPopup, channelId, changeState }) {
    const history = useHistory()
    const context = useContext(AuthContext)
    const [showModal, setShowModal] = useState(false)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState('')
    const [isForgotPassword, setIsForgotPassword] = useState(false)
    const [errorText, setErrorText] = useState(false)


    const loginWithGoogle = async () => {
        let details = await loginWithGoogleFunc()
        if(details[0]?.email){
            context.setCurrentUser(details[0]?.id,details[0]); 
            history.push({
                pathname:  "/"
            });
            moreProfileInfo && moreProfileInfo();
        }
    }

    const onLoginClick = async e =>{
        e.preventDefault();
        // You have to pass a continue URL via ActionCodeSettings to redirect the user back to the app:
        var ActionCodeSettings = {
            // After password reset, the user will be give the ability to go back
            // to this page.
            url: `${window.location.href}`,
            handleCodeInApp: false
        };
        context.showLoader(true)
        if(isForgotPassword){
            if(email === "" || email.trim() === ""){
                setErrorText('Email address required')
                return
            }
            let resp = await forgotPasswordFunc(email,ActionCodeSettings)
            if(!resp){
                setErrorText(loginError.resetPasswordError);
                setIsForgotPassword(false)
                context.showLoader(false)
            }else{
                setErrorText(resp.message)
                context.showLoader(false)
            }
        }else{
            if(email === "" || email.trim === ""){
                setErrorText("Email address is required")
                return
            }
            let resp = await loginFunc(email,password)
            if(isPopup) closeModal();
            if(resp?.id && resp.data){
                //context.setCurrentUser(resp.id,resp.data);
                if(channelId) {
                    openPaymentModal('change');
                }
                history.push("/");
                context.showLoader(false);
            }else{
                // if error code 400 - 499 then add custome error text
                setErrorText(resp.message)
                context.showLoader(false)
            }
        }
    }
    return (
        <React.Fragment>
        <div id="login-signup-comp" className={isPopup && "popup"}>
            <div className="inner">
                {!isPopup && (
                    <h1 className="heading">Find your tribe.</h1>
                )}
                <button className="google" onClick={()=>loginWithGoogle()}><span className="google-logo"><img src={require("../Assets/svg/g_icon.svg")} alt="noImage" /></span>Continue with Google</button>
                <span className="underline"><span>OR</span></span>
                <form>
                    <div className="form-div">
                    <input 
                            placeholder="Your email"
                            type="email"
                            name="email"
                            onChange={e=>setEmail(e.target.value)}
                            onKeyDown={e => (e.key === 'Enter' && e.keyCode === 13) && onLoginClick(e)}
                            value={email}
                            autoComplete="off"
                            className="email"
                        />
                    </div>
                    {!isForgotPassword && (
                        <div className="form-div">
                        <input
                            type="password"
                            name="password"
                            onChange={e=>setPassword(e.target.value)}
                            value={password}
                           onKeyDown={e => (e.key === 'Enter' && e.keyCode === 13) && onLoginClick(e)}
                            placeholder={loginFormText.password}
                            autoComplete="off"
                        />
                        </div>
                    )}
                    <a href={() => false} onClick={e=>{setIsForgotPassword(!isForgotPassword);setEmail('');setPassword('');setErrorText(false)}}>{!isForgotPassword ?"Forgot Password?":"Login" } </a>
                    {errorText && (
                        <span className="error-display">{errorText}</span>
                    )}
                    <div className="form-div">
                        <button className="apply-btn login-new-btn" onClick={(e) => {onLoginClick(e)}}>
                            {isForgotPassword
                            ? loginFormText.sendLinkBtnText
                            : loginFormText.loginBtnText}
                        </button>
                    </div>
                </form>

                <p className="account">Create an account? <span onClick={()=>{changeState ? changeState("signup") : setShowModal(true)}}>Sign up</span></p>
            </div>
        </div>
        {(!isPopup && showModal) && (
            <UserModal modalType="signup" show="login-form" closeModal={setShowModal} />
        )}
        </React.Fragment>
    )
}

export default LoginSignUpComp
