import React, { Fragment, Component } from "react";

// import style sheet
import "./channelform.scss";

import { AuthContext } from "../../Firebase/AuthProvider";

class EditChannelCode extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      code: props.channel_code_number
    };
  }

  validate = () => {
    let error = "";
    if (this.state.code === "") {
      error = "Tribe code is required.";
      return error;
    }
    return null;
  };

  onchangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChannelClick = async (e) => {
    e.preventDefault();
    
    this.context.showLoader(true);
    let data = {
      channel_code_number: this.state.code
    };
    this.props.updateChannelInfo(data);
  };

  render() {
    return (
      <Fragment>
        <form>
          <div className="channel-input-group">
          <label>Tribe Code</label>
          <input type="text" name="code"
              onChange={this.onchangeHandler}
              value={this.state.code} placeholder="Enter Tribe Code" />
        </div>
          <button
            className="form-submit"
            onClick={(e) => {
              this.onChannelClick(e);
            }}
          >
            Edit Tribe Code
          </button>
       </form>
      </Fragment>
    );
  }
}

export default EditChannelCode;
