import React,{useContext} from 'react'
import {AuthContext} from '../Firebase/AuthProvider'


const Loader = () => {
    const context = useContext(AuthContext)
    return (
        <div className={`loader ${context.state.showLoader && "show-loader"}`}>
            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </div>
    )
}

export default Loader