import React, { Fragment } from "react";
import '../Assets/Style/sidebar.css'

const RightSideBar = () => {
  return (
    <Fragment>
      <nav className="nav">
      </nav>
    </Fragment>
  );
};

export default RightSideBar;
