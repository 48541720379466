import React, { Fragment, useContext } from "react";

// import styles
import '../../Assets/Style/speakers.css'
import speakerIcon from '../../Assets/Images/userPlaceholder.jpeg'
import { AuthContext } from "../../Firebase/AuthProvider";
import SearchDropdown from "../SearchDropdown";
import Helper from "../../Helper/helper";

const Speaker = ({ speakers, removeSpeaker, editSpeaker, channelInfo, editChannelInfo, selectedIndex, updateChannelInfo, stepId, isPreview  }) => {
  const context = useContext(AuthContext)

  const openPostMenu = (e) => {
    let menu = e.target.childNodes[0];
    if (menu.classList.contains("show-post-menu-dropdown")) {
      menu.classList.remove("show-post-menu-dropdown");
    } else {
      menu.classList.add("show-post-menu-dropdown");
    }
  };

  const closeDropdown = (e) => {
    let dropDownMenu = e.target.parentNode.parentNode;
    dropDownMenu.classList.remove("show-post-menu-dropdown");
  };

  
  const onAddSpeakerClick = async (data)=>{
    let newSpeakersArr = [...channelInfo?.speakers]
    if(editChannelInfo) {
      newSpeakersArr[selectedIndex] = data;
    }
    else {
      newSpeakersArr.push(data)
    }
    await updateChannelInfo({ speakers: newSpeakersArr });
  }

  const checkIsAdmin = (adminList) => {
    let IsAdmin = false;
    if(adminList &&  context?.state?.userDetail) {
      let foundAdmin = adminList.find(el => {
        return el.email ===  context?.state?.userDetail.email
      })
      if(foundAdmin) {
        IsAdmin = true
      }
    }
    return IsAdmin;
  }

  return (
    <Fragment>
      <div className="SpeakerWrapper" style={{height:`${stepId === 1 && "unset"}`}} >
        <div className="SpeakerContainer">
          <div className="title">
            {context.state.currentUser ? 
              <h2 className="font-changed">Speakers</h2>
            :
            <h2 className={!context.state.currentUser && "font-changed"}>Speakers</h2>
            }
          </div>
          {checkIsAdmin(channelInfo?.adminList) || (!isPreview && ((context?.state?.userDetail?.email === 'shah@assemblyf.com') || (context?.state?.currentUser === channelInfo?.channel_owner))) ? (
            <SearchDropdown title="Add Speakers" onAddSpeakerClick={onAddSpeakerClick} />
          ) : ''}



          <div className={`ourSpeakers ${speakers?.length === 0 && "remove-grid"}`}>
                {speakers?.map((item, index) => {
                  return (
                    <div className="speaker" key={index}>
                      <div className="image-div">
                        <a href={"/profile/"+item.link}>
                          <img
                            src={item.image?? speakerIcon}
                            alt="speaker"
                            // className="img-fluid"
                          />
                        </a>
                      </div>
                      {((context?.state?.userDetail?.email === 'shah@assemblyf.com') || (context?.state?.currentUser === channelInfo?.channel_owner)) && (

                      <div
                        className="pbm-post-menu float-right"
                        onClick={(e) => {
                          openPostMenu(e);
                        }}
                      >
                        <div
                          className="post-menu-dropdown"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <ul>
                          <li
                              className="link"
                              onClick={(e) => {
                                editSpeaker(item, index);
                                closeDropdown(e);
                              }}
                            >
                              edit
                            </li>
                            <li
                              className="delete"
                              onClick={(e) => {
                                removeSpeaker(item.name)
                                closeDropdown(e);
                              }}
                            >
                              Delete
                            </li>
                          </ul>
                        </div>
                      </div>
                      )}
                      
                      <p><a href={"/profile/"+item.link}>{Helper.transformText(item.name)}</a></p>
                      <span>{item.title}</span>
                    </div>
                  );
                })}
                {
                  speakers?.length === 0 &&
                  <h5>no speaker found.</h5>
                }
                
          </div>
          </div>
      </div>
    </Fragment>
  );
};

export default Speaker;
