import React, { Component } from 'react'
import {AuthContext} from "../Firebase/AuthProvider"
import { FIELDS } from '../Firebase/Constants'
import "../Assets/Style/admin-style.css"
import {  sendEventCancelled, sendInvitation } from '../Firebase/Mailer'
import Header from '../components/header'
import Loader from '../components/Loader'
import EventComponent from './EventComponent'
import { getEvents, updateSpecificEvent, getSpecificEventData, createEvent, userEventGoingUser } from '../Firebase/Events'

class EventsList extends Component {

  static contextType = AuthContext
  constructor(props){
      super(props)
      this.state={
          showInviteModal :false,
          selectedEvent:null,
          email:'',
          showEvent:'approvedEvents',
          unapprovedEvents:[]
      }
  }

  componentDidMount(){
      this.getUnapprovedEvents()
  }

  getUnapprovedEvents = async()=>{
      const data = await getEvents("unapproved_events",true);
      this.setState({
          unapprovedEvents:data
      })
  }

  onInviteClick = (e,el)=>{
      this.setState({
          showInviteModal:!this.state.showInviteModal,
          selectedEvent:el,
          email:''
      })
  }

  onShowModal =()=>{
      this.setState({
          showInviteModal:!this.state.showInviteModal
      })
  }

  onHandleSend = () => {
      this.context.showLoader(true)
      if(this.state.selectedEvent !== null){
          sendInvitation(this.state.email,this.state.selectedEvent).then(res => {
              this.context.showLoader(false)
              this.setState({
                  showInviteModal:false
              })
          })
      }
  }

  onDeleteEvent = async (e, el) => {
    e.preventDefault();
    this.context.deleteEvent("events", el.uid);

    const userDetail = await userEventGoingUser(el);
    let userEmails = userDetail[FIELDS.EVENT.EVENT_GOING_USER_DETAILS].map((user) => user[FIELDS.USER.USER_EMAIL]);
    for (const email of userEmails) {
      sendEventCancelled(email, el);
    }
  };

  onEditEventClick = (e, id) => {
    e.preventDefault();
    this.props.history.push(`/admin?id=${id}`);
  };

  onFilterChange = (e) => {
    this.setState({
      showEvent: e.target.id,
    });
  };

  onApproveEventClick = async (e, id) => {
    this.context.showLoader(true);
    e.preventDefault();
    await updateSpecificEvent("unapproved_events", id, [FIELDS.EVENT.EVENT_IS_APPROVED], true);
    const data = await getSpecificEventData("unapproved_events", id);
    await createEvent("events", data[0]);
    await this.getUnapprovedEvents();
    await this.context.getData();
    this.context.showLoader(false);
  };

  render() {
    // const hostname = window.location.hostname === "localhost" ? "assembly-dev-3c297.web.app" : window.location.hostname
    return (
      <React.Fragment>
        <Loader />
        <Header admin="true" history={this.props.history} />
        <div className="event-list-component">
          <div className="user-list-toggle">
            <span className="user-list-toggle-heading">Filter</span>
            <form>
              <div className="user-toggle-group">
                <label htmlFor="inActiveUser">Approved Events</label>
                <input type="radio" checked={this.state.showEvent === "approvedEvents"} onChange={(e) => this.onFilterChange(e)} id="approvedEvents" name="events-approved" />
              </div>
              <div className="user-toggle-group">
                <label htmlFor="activeUser">Unapproved Events</label>
                <input type="radio" id="unapprovedEvents" onChange={(e) => this.onFilterChange(e)} name="events-approved" />
              </div>
            </form>
          </div>

          <div className="user-container">
            {this.state.showEvent === "approvedEvents" && (
              <div className="event-ask-users">
                <span>Approved Events</span>
                <EventComponent
                  isApproved={true}
                  onEditEventClick={this.onEditEventClick}
                  onDeleteEvent={this.onDeleteEvent}
                  onInviteClick={this.onInviteClick}
                  events={this.context.state.events}
                />
              </div>
            )}
            {this.state.showEvent === "unapprovedEvents" && (
              <div className="event-ask-users">
                <span>Unapproved Events</span>
                <EventComponent onApproveEventClick={this.onApproveEventClick} events={this.state.unapprovedEvents} />
              </div>
            )}
          </div>

          {this.state.showInviteModal && (
            <div className="invite-Modal" onClick={this.onShowModal}>
              <div
                className="invite-inner"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <h3>Send Invitation by Email</h3>
                <form>
                  <label htmlFor="email"></label>
                  <textarea
                    name="email"
                    id=""
                    cols="30"
                    rows="5"
                    value={this.state.email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                    placeholder="enter email by comma , separated "
                  ></textarea>
                </form>
                <button onClick={this.onHandleSend}>SEND</button>
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default EventsList;
