import React, { Fragment } from "react";
import '../../Assets/Style/sidebar.css'
import chatIcon from '../../Assets/Images/chat.png'
import peopleIcon from '../../Assets/Images/people.png'
import { Link } from "react-router-dom";

const RightSideBar = ({ showHideTabs, noIcon, currentUser }) => {
  return (
    <Fragment>
      <nav className="nav">
        {currentUser && (
          <>
          {!noIcon && (

          <ul className="nav-menu-items">
          <li className="navbarText">
                  <Link onClick={() => showHideTabs("people")}>
                    <img
                      src={peopleIcon}
                      alt="live"
                      className="img-fluid mb-2"
                      width="35"
                    />
                    <p className="sidebarText">People</p>
                  </Link>
              </li>
              <li className="navbarText">
                <Link onClick={() => showHideTabs("groupChat")}>
                    <img
                      src={chatIcon}
                      alt="live"
                      className="img-fluid mb-2"
                      width="35"
                    />
                    <p className="sidebarText">Chat</p>
                  </Link>
              </li>
          </ul>
          )}
          </>
        )}

      </nav>
    </Fragment>
  );
};

export default RightSideBar;
