export const EVENT_SCEHMA = '/Event';
export const EVENT_LIST_SCHEMA = 'EVENT_LIST';
export const POST_COMMENTS_LIST_SCHEMA = 'POST_COMMENTS_LIST';
export const USER_SCHEMA = '/USER';
export const USER_LIST_SCHEMA = 'USER_LIST';
export const TESTIMONIAL_SCHEMA = '/TESTIMONIAL';
export const CHAT_SCHEMA = '/CHAT';
export const WALL_POST_SCHEMA = "/WALL_POST";
export const WALL_POST_COMMENTS_SCHEMA = "/WALL_POST_COMMENTS";
export const CHANNEL_SCHEMA = "/CHANNEL";
export const UNSUBSCRIBE_TRIBES_SCHEMA = "/UNSUBSCRIBE_TRIBES";

export const defaultUserImages = [
    `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/images%2Fusers%2FdefaultImages%2Fdefault-user-image-1.jpg?alt=media&token=85109e44-0876-4128-aefd-2dcfc0667511`,
    `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/images%2Fusers%2FdefaultImages%2Fdefault-user-image-2.jpg?alt=media&token=1c3e76bf-1f1f-42cc-8687-ca6e979fd52d`,
    `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/images%2Fusers%2FdefaultImages%2Fdefault-user-image-3.jpg?alt=media&token=55425c4d-dea9-4e7d-a140-abfb87c0dc2a`
]

export const queryLimit = 20;

export const postQueryLimit = 10;

export const FIELDS = {
    EVENT : {
        EVENT_NAME : "event_name",
        EVENT_ZOOM_LINK : "event_zoom_link",
        EVENT_ZOOM_ID : "event_zoom_id",
        EVENT_ZOOM_PASSWORD : "event_zoom_password",
        EVENT_START_DATE : "event_start_date_time",
        EVENT_START_TIME : "event_start_time",
        EVENT_END_TIME : "event_end_time",
        EVENT_IMAGE : "event_image",
        EVENT_CAPACITY : "event_capacity",
        EVENT_DETAIL_SHORT : "event_detail_short",
        EVENT_DETAIL_LONG : "event_detail_long",
        EVENT_HOST : "event_host",
        EVENT_HOST_ID : "event_host_id",
        EVENT_HOST_DETAIL : "event_host_detail",
        EVENT_GOING: "event_going",
        EVENT_ICS:"event_ics",
        EVENT_IS_DELETE: "event_is_delete",
        EVENT_HOST_PARTNER_IMAGE:"event_host_partner_image",
        EVENT_GOING_USER_DETAILS: "event_going_user_details",
        EVENT_DATE_TIMESTAMP: "event_date_timestamp",
        EVENT_IS_APPROVED : "event_is_approved",
        EVENT_SPEAKER_LIST : "event_speaker_list",
        EVENT_CHANNEL_NAME: "event_channel_name",
        EVENT_CHANNEL_ID: "event_channel_id",
    },
    USER : {
        USER_ID : "id",
        USER_EMAIL : "email",
        USER_IMAGE : "image",
        USER_NAME : "name",
        USER_COUNTRY: "country",
        USER_LINKEDIN_PROFILE : "linkedin_profile",
        USER_INVITED_EMAIL : "invited_email",
        USER_INVITED_USER_NAME : "invited_user_name",
        USER_INVITED_USER_ID : "invited_user_id",
        USER_USER_TYPE: "user_type",
        USER_USER_SKILL: "user_skill",
        USER_APPROVE : "user_is_approve",
        USER_ASK_EVENT:"user_ask_event",
        USER_FOLLOWING: "user_following",
        USER_FOLLOWERS: "user_followers",
        USER_ELVES: "user_total_elves",
        USER_FOLLOWER_DETAIL:"user_follower_detail",
        USER_FOLLOWING_DETAIL:"user_following_detail",
        USER_DISAPPROVE :"user_disapprove",
        USER_IS_HOST: "user_is_host",
        USER_PHONE: "user_phone",
        USER_BIO : 'user_bio',
        HOST_TESTIMONIAL : "host_testimonials",
        USER_KEYWORD : "user_keywords",
        USER_SEARCH_NAME : "user_search_name",
        USER_EXPERIENCE: "user_experience",
        USER_EDUCATION: "user_education",
        USER_STORY: "user_story",
        USER_IS_OLD: "user_is_old",
        USER_DATE: "user_date",
        USER_CHANNELS: "user_channels",
        USER_PAYMENTS: "payments",
    },
    TESTIMONIAL: {
        ID : 'id',
        USER_ID : "user_id",
        USER_DETAIL: "user_detail",
        CREATED_AT : "created_at",
        HOST_ID : "host_id",
        EVENT_ID : "event_id",
        RATING: "rating",
        TESTIMONIAL: "testimonial",
        IS_DELETED : "is_deleted"
    },
    CHAT :{
        CHAT_CREATED_AT : 'chat_created_at',
        CHAT_EVENT_ID : 'chat_event_id',
        CHAT_MESSAGE : 'chat_message',
        CHAT_USER_ID : 'chat_user_id',
        CHAT_TO_USER_ID : 'chat_to_user_id',
        CHAT_USER_IMAGE : 'chat_user_image',
        CHAT_USER_NAME : 'chat_user_name' ,
        CHAT_CHANNEL_ID : 'chat_channel_id',
        CHAT_CHANNEL_NAME : 'chat_channel_name',
        CHAT_CHANNEL_IMAGE : 'chat_channel_image',
    },
    WALL_POST :{
        POST_CREATED_AT : "post_created_at",
        POST_USER_ID : "post_user_id",
        POST_USER_DETAIL : "post_user_detail",
        POST_MESSAGE : "post_message",
        POST_SCORE : "post_score",
        POST_IMAGE_URL : "post_image_url",
        POST_VIDEO_URL : "post_video_url",
        POST_TAG_USERS : "post_tag_users",
        POST_IS_DELETED : "post_is_deleted",
        POST_REACT : "post_react",
        POST_PAGE_METATAGS : "post_page_metatags",
        POST_COMMENTS : "post_comments",
        POST_CHANNEL_ID : "post_channel_id",
        POST_CHANNEL_NAME : "post_channel_name",
        POST_USER_PROFILE_ID: "post_user_profile_id",
        POST_USER_PROFILE_NAME: "post_user_profile_name",
        POST_USER_PROFILEID: "post_user_profileId",
    },
    WALL_POST_COMMENTS : {
        POST_COMMENT_CREATED_AT : "post_comment_created_at",
        POST_COMMENT_USER_DETAIL : "post_comment_user_detail",
        POST_COMMENT_TEXT : "post_comment_text",
        POST_COMMENT_ISDELETED : "post_comment_is_deleted",
        POST_COMMENT_UNIQUE_ID : 'post_comment_uinque_id'
    },
    CHANNEL: {
        CHANNEL_NAME: "name",
        CHANNEL_DESCRIPTION: "description",
        CHANNEL_EVENTS: "events",
        CHANNEL_LINK: "link",
        CHANNEL_PARTNERS: "partners",
        CHANNEL_SPEAKERS: "speakers",
        CHANNEL_COVER_IMAGE: "cover_image",
        CHANNEL_OWNER: "channel_owner",
        CHANNEL_POSTS: "channel_posts",
        CHANNEL_CODE_NUMBER: "channel_code_number",
        CHANNEL_MEMBERS: "members",
        CHANNEL_TOPICS: "channel_topics",
        CHANNEL_STATUS: "channel_status"
    },
    VIDEOS: {
        DATE_TIME : "datetime",
        HOST_ID: "hostId",
        HOST_INFO : "hostInfo",
        IS_MEETING_ACTIVE : "isMeetingActive",
        MEETING_ID : "meetingId",
        MEMBERS_LIST : "membersList",
    },
    UNSUBSCRIBE_TRIBES: {
        USER_ID : "userId",
        TRIBE_LIST : "tribeList",
    }
}

const TYPE_STRING = {"type": "string", "default": "" };
const TYPE_BOOLEAN = {"type": "boolean", "default": false };
const TYPE_ARRAY = {"type" : "array", "default" : []};
const TYPE_NUMBER = {"type":"number","default":""};

export const EVENT = {
    [FIELDS.EVENT.EVENT_NAME]: TYPE_STRING,
    [FIELDS.EVENT.EVENT_ZOOM_LINK]: TYPE_STRING,
    [FIELDS.EVENT.EVENT_ZOOM_ID]: TYPE_STRING,
    [FIELDS.EVENT.EVENT_ZOOM_PASSWORD]: TYPE_STRING,
    [FIELDS.EVENT.EVENT_START_DATE]: TYPE_STRING,
    [FIELDS.EVENT.EVENT_START_TIME]: TYPE_STRING,
    [FIELDS.EVENT.EVENT_END_TIME]: TYPE_STRING,
    [FIELDS.EVENT.EVENT_IMAGE]: TYPE_STRING,
    [FIELDS.EVENT.EVENT_CAPACITY]: TYPE_STRING,
    [FIELDS.EVENT.EVENT_DETAIL_SHORT]: TYPE_STRING,
    [FIELDS.EVENT.EVENT_DETAIL_LONG]: TYPE_STRING,
    [FIELDS.EVENT.EVENT_HOST] : TYPE_STRING,
    [FIELDS.EVENT.EVENT_HOST_DETAIL] : {"$ref": USER_SCHEMA},
    [FIELDS.EVENT.EVENT_HOST_ID] : TYPE_STRING,
    [FIELDS.EVENT.EVENT_ICS] : TYPE_STRING,
    [FIELDS.EVENT.EVENT_IS_DELETE]: TYPE_BOOLEAN,
    [FIELDS.EVENT.EVENT_GOING] : TYPE_ARRAY,
    [FIELDS.EVENT.EVENT_HOST_PARTNER_IMAGE] :TYPE_STRING,
    [FIELDS.EVENT.EVENT_GOING_USER_DETAILS] : TYPE_ARRAY,
    [FIELDS.EVENT.EVENT_DATE_TIMESTAMP]: TYPE_NUMBER,
    [FIELDS.EVENT.EVENT_IS_APPROVED] : TYPE_BOOLEAN,
    [FIELDS.EVENT.EVENT_END_TIME]: TYPE_STRING,
    [FIELDS.EVENT.EVENT_SPEAKER_LIST]: TYPE_ARRAY,
    [FIELDS.EVENT.EVENT_CHANNEL_NAME]: TYPE_STRING,
    [FIELDS.EVENT.EVENT_CHANNEL_ID]: TYPE_STRING,
}

export const USER = {
    [FIELDS.USER.USER_ID] : TYPE_STRING,
    [FIELDS.USER.USER_EMAIL] : TYPE_STRING,
    [FIELDS.USER.USER_IMAGE] : TYPE_STRING,
    [FIELDS.USER.USER_NAME] : TYPE_STRING,
    [FIELDS.USER.USER_LINKEDIN_PROFILE] : TYPE_STRING,
    [FIELDS.USER.USER_COUNTRY] : TYPE_STRING,
    [FIELDS.USER.USER_APPROVE] : TYPE_BOOLEAN,
    [FIELDS.USER.USER_ASK_EVENT]:{type:"string",default:false},
    [FIELDS.USER.USER_FOLLOWING]: TYPE_ARRAY,
    [FIELDS.USER.USER_FOLLOWERS]: TYPE_ARRAY,
    [FIELDS.USER.USER_FOLLOWER_DETAIL]: TYPE_ARRAY,
    [FIELDS.USER.USER_ELVES]: TYPE_NUMBER,
    [FIELDS.USER.USER_FOLLOWING_DETAIL]: TYPE_ARRAY,
    [FIELDS.USER.USER_DISAPPROVE] : TYPE_BOOLEAN,
    [FIELDS.USER.USER_IS_HOST] : TYPE_BOOLEAN,
    [FIELDS.USER.USER_PHONE] : TYPE_STRING,
    [FIELDS.USER.USER_BIO] :TYPE_STRING,
    [FIELDS.USER.HOST_TESTIMONIAL] : TYPE_ARRAY,
    [FIELDS.USER.USER_KEYWORD] : TYPE_ARRAY,
    [FIELDS.USER.USER_SEARCH_NAME] : TYPE_ARRAY,
    [FIELDS.USER.USER_EXPERIENCE] : TYPE_ARRAY,
    [FIELDS.USER.USER_EDUCATION] : TYPE_ARRAY,
    [FIELDS.USER.USER_STORY] : TYPE_STRING,
    [FIELDS.USER.USER_IS_OLD] : TYPE_BOOLEAN,
    [FIELDS.USER.USER_INVITED_EMAIL] : TYPE_STRING,
    [FIELDS.USER.USER_INVITED_USER_NAME] : TYPE_STRING,
    [FIELDS.USER.USER_INVITED_USER_ID] : TYPE_STRING,
    [FIELDS.USER.USER_USER_TYPE] : TYPE_STRING,
    [FIELDS.USER.USER_USER_SKILL] : TYPE_STRING,
    [FIELDS.USER.USER_DATE] : TYPE_STRING,
    [FIELDS.USER.USER_CHANNELS] : TYPE_STRING,
    [FIELDS.USER.USER_PAYMENTS] : TYPE_ARRAY
}

export const TESTIMONIAL = {
    [FIELDS.TESTIMONIAL.ID] : TYPE_STRING,
    [FIELDS.TESTIMONIAL.CREATED_AT] : TYPE_NUMBER,
    [FIELDS.TESTIMONIAL.USER_ID]: TYPE_STRING,
    [FIELDS.TESTIMONIAL.USER_DETAIL] :{"$ref": USER_SCHEMA},
    [FIELDS.TESTIMONIAL.HOST_ID] : TYPE_STRING,
    [FIELDS.TESTIMONIAL.EVENT_ID] : TYPE_STRING,
    [FIELDS.TESTIMONIAL.TESTIMONIAL] : TYPE_STRING,
    [FIELDS.TESTIMONIAL.RATING] :  TYPE_NUMBER,
    [FIELDS.TESTIMONIAL.IS_DELETED] : TYPE_BOOLEAN
}

export const CHAT = {
    [FIELDS.CHAT.CHAT_CREATED_AT]: TYPE_NUMBER,
    [FIELDS.CHAT.CHAT_EVENT_ID] : TYPE_STRING,
    [FIELDS.CHAT.CHAT_USER_ID] : TYPE_STRING,
    [FIELDS.CHAT.CHAT_USER_IMAGE] : TYPE_STRING,
    [FIELDS.CHAT.CHAT_TO_USER_ID] : TYPE_STRING,
    [FIELDS.CHAT.CHAT_USER_NAME] : TYPE_STRING,
    [FIELDS.CHAT.CHAT_MESSAGE] : TYPE_STRING,
    [FIELDS.CHAT.CHAT_CHANNEL_ID] : TYPE_STRING,
    [FIELDS.CHAT.CHAT_CHANNEL_NAME] : TYPE_STRING,
    [FIELDS.CHAT.CHAT_CHANNEL_IMAGE] : TYPE_STRING,
}

export const WALL_POST = {
    [FIELDS.WALL_POST.POST_CREATED_AT] : TYPE_NUMBER,
    [FIELDS.WALL_POST.POST_USER_ID] : TYPE_STRING,
    [FIELDS.WALL_POST.POST_USER_DETAIL] : {"$ref": USER_SCHEMA},
    [FIELDS.WALL_POST.POST_MESSAGE] : TYPE_STRING,
    [FIELDS.WALL_POST.POST_SCORE] : TYPE_NUMBER,
    [FIELDS.WALL_POST.POST_TAG_USERS] : TYPE_ARRAY,
    [FIELDS.WALL_POST.POST_VIDEO_URL] : TYPE_ARRAY,
    [FIELDS.WALL_POST.POST_IMAGE_URL] : TYPE_ARRAY,
    [FIELDS.WALL_POST.POST_IS_DELETED] : TYPE_BOOLEAN,
    [FIELDS.WALL_POST.POST_REACT] : TYPE_ARRAY,
    [FIELDS.WALL_POST.POST_PAGE_METATAGS] : TYPE_ARRAY,
    [FIELDS.WALL_POST.POST_CHANNEL_ID] : TYPE_STRING,
    [FIELDS.WALL_POST.POST_CHANNEL_NAME] : TYPE_STRING,
    [FIELDS.WALL_POST.POST_COMMENTS] : TYPE_ARRAY,
    [FIELDS.WALL_POST.POST_USER_PROFILE_ID] : TYPE_ARRAY,
    [FIELDS.WALL_POST.POST_USER_PROFILE_NAME] : TYPE_STRING,
    [FIELDS.WALL_POST.POST_USER_PROFILEID] : TYPE_STRING
}

export const WALL_POST_COMMENTS = {
    [FIELDS.WALL_POST_COMMENTS.POST_COMMENT_CREATED_AT] : TYPE_NUMBER,
    [FIELDS.WALL_POST_COMMENTS.POST_COMMENT_USER_DETAIL] : {"$ref": USER_SCHEMA},
    [FIELDS.WALL_POST_COMMENTS.POST_COMMENT_TEXT] : TYPE_STRING,
    [FIELDS.WALL_POST_COMMENTS.POST_COMMENT_ISDELETED] : TYPE_BOOLEAN,
}

export const CHANNEL = {
    [FIELDS.CHANNEL.CHANNEL_NAME]: TYPE_STRING,
    [FIELDS.CHANNEL.CHANNEL_DESCRIPTION] : TYPE_STRING,
    [FIELDS.CHANNEL.CHANNEL_EVENTS] : TYPE_ARRAY,
    [FIELDS.CHANNEL.CHANNEL_LINK] : TYPE_STRING,
    [FIELDS.CHANNEL.CHANNEL_SPEAKERS] : TYPE_ARRAY,
    [FIELDS.CHANNEL.CHANNEL_PARTNERS] : TYPE_ARRAY,
    [FIELDS.CHANNEL.CHANNEL_POSTS] : TYPE_ARRAY,
    [FIELDS.CHANNEL.CHANNEL_COVER_IMAGE] : TYPE_STRING,
    [FIELDS.CHANNEL.CHANNEL_OWNER] : {"$ref": USER_SCHEMA},
    [FIELDS.CHANNEL.CHANNEL_CODE_NUMBER]: TYPE_NUMBER,
    [FIELDS.CHANNEL.CHANNEL_MEMBERS] : TYPE_ARRAY,
    [FIELDS.CHANNEL.CHANNEL_TOPICS] : TYPE_ARRAY,
    [FIELDS.CHANNEL.CHANNEL_STATUS] : TYPE_STRING
}

export const VIDEOS = {
    [FIELDS.VIDEOS.DATE_TIME] : TYPE_STRING,
    [FIELDS.VIDEOS.HOST_ID] : TYPE_STRING,
    [FIELDS.VIDEOS.HOST_INFO] : TYPE_STRING,
    [FIELDS.VIDEOS.IS_MEETING_ACTIVE] : TYPE_STRING,
    [FIELDS.VIDEOS.MEETING_ID] : TYPE_STRING,
    [FIELDS.VIDEOS.MEMBERS_LIST] : TYPE_ARRAY
}

export const UNSUBSCRIBE_TRIBES = {
    [FIELDS.UNSUBSCRIBE_TRIBES.USER_ID] : TYPE_STRING,
    [FIELDS.UNSUBSCRIBE_TRIBES.TRIBE_LIST] : TYPE_ARRAY
}

export const newUsersList = [
    'Fatima Moin',
    'Haseeb Siddiqui',
    'Ailis McCaul',
    'Reginald Parker',
    'Huma Hamid',
    'Jehan Ara',
    'Kristin Anderssen',
    'Samar Hasan',
    'Sennen Desouza',
    'Shahid Qamar',
    'Shahjahan Chaudhary',
    'Shehryar Hydri',
    'Stela Suils Cuesta',
    'Syed Naseh',
    'Yishel Khan',
    'Abdullah Gillani',
    'Muhammad Waleed Khan',
    'Adeel Qureshi',
    'Isfandiyar Shaheen',
    'Irtaza Syed',
    'Syed Muhammad Hasan Naqvi',
    'Asim Fayaz',
    'Zohaib Jawed',
    'Fatima Moin',
    'Haseeb Siddiqui',
    'Yash B',
    'Saad Humayun',
    'Safeer Moosvi',
    'Kalsoom Lakhani',
    'Syed Hashmi',
    'Yousuf Mukhtar',
    'Osama Javed',
    'Adeel Hasan',
    'Angus Crombie',
    'Shahid Qamar',
    'Umair Azhar',
    'Sennen Desouza',
    'Arhum Ishtiaq',
    'Yasir Asif',
    'Jaudat Ali',
    'Muhammad Younas',
    'Haris Salman',
    'Zaki Mahomed',
    'Muhammad Umer Farooq',
    'Mihai Sava',
    'John Hancock',
    'Haseeb Siddiqui',
    'H Tariq',
    'Fawad Khaliq',
    'Sidra Riaz',
    'Ali Nawab',
    'Syed Hannan',
    'Mian Taimoor Ahmad',
    'Hamna Asher',
    'Yusuf Jan',
    'Sameed Ahmed Khan',
    'Omar Ali',
    'JEHAN ARA'
]

export const countryList = [ 
    {"name": "Afghanistan", "code": "AF"}, 
    {"name": "land Islands", "code": "AX"}, 
    {"name": "Albania", "code": "AL"}, 
    {"name": "Algeria", "code": "DZ"}, 
    {"name": "American Samoa", "code": "AS"}, 
    {"name": "AndorrA", "code": "AD"}, 
    {"name": "Angola", "code": "AO"}, 
    {"name": "Anguilla", "code": "AI"}, 
    {"name": "Antarctica", "code": "AQ"}, 
    {"name": "Antigua and Barbuda", "code": "AG"}, 
    {"name": "Argentina", "code": "AR"}, 
    {"name": "Armenia", "code": "AM"}, 
    {"name": "Aruba", "code": "AW"}, 
    {"name": "Australia", "code": "AU"}, 
    {"name": "Austria", "code": "AT"}, 
    {"name": "Azerbaijan", "code": "AZ"}, 
    {"name": "Bahamas", "code": "BS"}, 
    {"name": "Bahrain", "code": "BH"}, 
    {"name": "Bangladesh", "code": "BD"}, 
    {"name": "Barbados", "code": "BB"}, 
    {"name": "Belarus", "code": "BY"}, 
    {"name": "Belgium", "code": "BE"}, 
    {"name": "Belize", "code": "BZ"}, 
    {"name": "Benin", "code": "BJ"}, 
    {"name": "Bermuda", "code": "BM"}, 
    {"name": "Bhutan", "code": "BT"}, 
    {"name": "Bolivia", "code": "BO"}, 
    {"name": "Bosnia and Herzegovina", "code": "BA"}, 
    {"name": "Botswana", "code": "BW"}, 
    {"name": "Bouvet Island", "code": "BV"}, 
    {"name": "Brazil", "code": "BR"}, 
    {"name": "British Indian Ocean Territory", "code": "IO"}, 
    {"name": "Brunei Darussalam", "code": "BN"}, 
    {"name": "Bulgaria", "code": "BG"}, 
    {"name": "Burkina Faso", "code": "BF"}, 
    {"name": "Burundi", "code": "BI"}, 
    {"name": "Cambodia", "code": "KH"}, 
    {"name": "Cameroon", "code": "CM"}, 
    {"name": "Canada", "code": "CA"}, 
    {"name": "Cape Verde", "code": "CV"}, 
    {"name": "Cayman Islands", "code": "KY"}, 
    {"name": "Central African Republic", "code": "CF"}, 
    {"name": "Chad", "code": "TD"}, 
    {"name": "Chile", "code": "CL"}, 
    {"name": "China", "code": "CN"}, 
    {"name": "Christmas Island", "code": "CX"}, 
    {"name": "Cocos (Keeling) Islands", "code": "CC"}, 
    {"name": "Colombia", "code": "CO"}, 
    {"name": "Comoros", "code": "KM"}, 
    {"name": "Congo", "code": "CG"}, 
    {"name": "Congo, The Democratic Republic of the", "code": "CD"}, 
    {"name": "Cook Islands", "code": "CK"}, 
    {"name": "Costa Rica", "code": "CR"}, 
    {"name": "Cote D'Ivoire", "code": "CI"}, 
    {"name": "Croatia", "code": "HR"}, 
    {"name": "Cuba", "code": "CU"}, 
    {"name": "Cyprus", "code": "CY"}, 
    {"name": "Czech Republic", "code": "CZ"}, 
    {"name": "Denmark", "code": "DK"}, 
    {"name": "Djibouti", "code": "DJ"}, 
    {"name": "Dominica", "code": "DM"}, 
    {"name": "Dominican Republic", "code": "DO"}, 
    {"name": "Ecuador", "code": "EC"}, 
    {"name": "Egypt", "code": "EG"}, 
    {"name": "El Salvador", "code": "SV"}, 
    {"name": "Equatorial Guinea", "code": "GQ"}, 
    {"name": "Eritrea", "code": "ER"}, 
    {"name": "Estonia", "code": "EE"}, 
    {"name": "Ethiopia", "code": "ET"}, 
    {"name": "Falkland Islands (Malvinas)", "code": "FK"}, 
    {"name": "Faroe Islands", "code": "FO"}, 
    {"name": "Fiji", "code": "FJ"}, 
    {"name": "Finland", "code": "FI"}, 
    {"name": "France", "code": "FR"}, 
    {"name": "French Guiana", "code": "GF"}, 
    {"name": "French Polynesia", "code": "PF"}, 
    {"name": "French Southern Territories", "code": "TF"}, 
    {"name": "Gabon", "code": "GA"}, 
    {"name": "Gambia", "code": "GM"}, 
    {"name": "Georgia", "code": "GE"}, 
    {"name": "Germany", "code": "DE"}, 
    {"name": "Ghana", "code": "GH"}, 
    {"name": "Gibraltar", "code": "GI"}, 
    {"name": "Greece", "code": "GR"}, 
    {"name": "Greenland", "code": "GL"}, 
    {"name": "Grenada", "code": "GD"}, 
    {"name": "Guadeloupe", "code": "GP"}, 
    {"name": "Guam", "code": "GU"}, 
    {"name": "Guatemala", "code": "GT"}, 
    {"name": "Guernsey", "code": "GG"}, 
    {"name": "Guinea", "code": "GN"}, 
    {"name": "Guinea-Bissau", "code": "GW"}, 
    {"name": "Guyana", "code": "GY"}, 
    {"name": "Haiti", "code": "HT"}, 
    {"name": "Heard Island and Mcdonald Islands", "code": "HM"}, 
    {"name": "Holy See (Vatican City State)", "code": "VA"}, 
    {"name": "Honduras", "code": "HN"}, 
    {"name": "Hong Kong", "code": "HK"}, 
    {"name": "Hungary", "code": "HU"}, 
    {"name": "Iceland", "code": "IS"}, 
    {"name": "India", "code": "IN"}, 
    {"name": "Indonesia", "code": "ID"}, 
    {"name": "Iran, Islamic Republic Of", "code": "IR"}, 
    {"name": "Iraq", "code": "IQ"}, 
    {"name": "Ireland", "code": "IE"}, 
    {"name": "Isle of Man", "code": "IM"}, 
    {"name": "Israel", "code": "IL"}, 
    {"name": "Italy", "code": "IT"}, 
    {"name": "Jamaica", "code": "JM"}, 
    {"name": "Japan", "code": "JP"}, 
    {"name": "Jersey", "code": "JE"}, 
    {"name": "Jordan", "code": "JO"}, 
    {"name": "Kazakhstan", "code": "KZ"}, 
    {"name": "Kenya", "code": "KE"}, 
    {"name": "Kiribati", "code": "KI"}, 
    {"name": "Korea, Democratic People'S Republic of", "code": "KP"}, 
    {"name": "Korea, Republic of", "code": "KR"}, 
    {"name": "Kuwait", "code": "KW"}, 
    {"name": "Kyrgyzstan", "code": "KG"}, 
    {"name": "Lao People'S Democratic Republic", "code": "LA"}, 
    {"name": "Latvia", "code": "LV"}, 
    {"name": "Lebanon", "code": "LB"}, 
    {"name": "Lesotho", "code": "LS"}, 
    {"name": "Liberia", "code": "LR"}, 
    {"name": "Libyan Arab Jamahiriya", "code": "LY"}, 
    {"name": "Liechtenstein", "code": "LI"}, 
    {"name": "Lithuania", "code": "LT"}, 
    {"name": "Luxembourg", "code": "LU"}, 
    {"name": "Macao", "code": "MO"}, 
    {"name": "Macedonia, The Former Yugoslav Republic of", "code": "MK"}, 
    {"name": "Madagascar", "code": "MG"}, 
    {"name": "Malawi", "code": "MW"}, 
    {"name": "Malaysia", "code": "MY"}, 
    {"name": "Maldives", "code": "MV"}, 
    {"name": "Mali", "code": "ML"}, 
    {"name": "Malta", "code": "MT"}, 
    {"name": "Marshall Islands", "code": "MH"}, 
    {"name": "Martinique", "code": "MQ"}, 
    {"name": "Mauritania", "code": "MR"}, 
    {"name": "Mauritius", "code": "MU"}, 
    {"name": "Mayotte", "code": "YT"}, 
    {"name": "Mexico", "code": "MX"}, 
    {"name": "Micronesia, Federated States of", "code": "FM"}, 
    {"name": "Moldova, Republic of", "code": "MD"}, 
    {"name": "Monaco", "code": "MC"}, 
    {"name": "Mongolia", "code": "MN"}, 
    {"name": "Montenegro", "code": "ME"},
    {"name": "Montserrat", "code": "MS"},
    {"name": "Morocco", "code": "MA"}, 
    {"name": "Mozambique", "code": "MZ"}, 
    {"name": "Myanmar", "code": "MM"}, 
    {"name": "Namibia", "code": "NA"}, 
    {"name": "Nauru", "code": "NR"}, 
    {"name": "Nepal", "code": "NP"}, 
    {"name": "Netherlands", "code": "NL"}, 
    {"name": "Netherlands Antilles", "code": "AN"}, 
    {"name": "New Caledonia", "code": "NC"}, 
    {"name": "New Zealand", "code": "NZ"}, 
    {"name": "Nicaragua", "code": "NI"}, 
    {"name": "Niger", "code": "NE"}, 
    {"name": "Nigeria", "code": "NG"}, 
    {"name": "Niue", "code": "NU"}, 
    {"name": "Norfolk Island", "code": "NF"}, 
    {"name": "Northern Mariana Islands", "code": "MP"}, 
    {"name": "Norway", "code": "NO"}, 
    {"name": "Oman", "code": "OM"}, 
    {"name": "Pakistan", "code": "PK"}, 
    {"name": "Palau", "code": "PW"}, 
    {"name": "Palestinian Territory, Occupied", "code": "PS"}, 
    {"name": "Panama", "code": "PA"}, 
    {"name": "Papua New Guinea", "code": "PG"}, 
    {"name": "Paraguay", "code": "PY"}, 
    {"name": "Peru", "code": "PE"}, 
    {"name": "Philippines", "code": "PH"}, 
    {"name": "Pitcairn", "code": "PN"}, 
    {"name": "Poland", "code": "PL"}, 
    {"name": "Portugal", "code": "PT"}, 
    {"name": "Puerto Rico", "code": "PR"}, 
    {"name": "Qatar", "code": "QA"}, 
    {"name": "Reunion", "code": "RE"}, 
    {"name": "Romania", "code": "RO"}, 
    {"name": "Russian Federation", "code": "RU"}, 
    {"name": "RWANDA", "code": "RW"}, 
    {"name": "Saint Helena", "code": "SH"}, 
    {"name": "Saint Kitts and Nevis", "code": "KN"}, 
    {"name": "Saint Lucia", "code": "LC"}, 
    {"name": "Saint Pierre and Miquelon", "code": "PM"}, 
    {"name": "Saint Vincent and the Grenadines", "code": "VC"}, 
    {"name": "Samoa", "code": "WS"}, 
    {"name": "San Marino", "code": "SM"}, 
    {"name": "Sao Tome and Principe", "code": "ST"}, 
    {"name": "Saudi Arabia", "code": "SA"}, 
    {"name": "Senegal", "code": "SN"}, 
    {"name": "Serbia", "code": "RS"}, 
    {"name": "Seychelles", "code": "SC"}, 
    {"name": "Sierra Leone", "code": "SL"}, 
    {"name": "Singapore", "code": "SG"}, 
    {"name": "Slovakia", "code": "SK"}, 
    {"name": "Slovenia", "code": "SI"}, 
    {"name": "Solomon Islands", "code": "SB"}, 
    {"name": "Somalia", "code": "SO"}, 
    {"name": "South Africa", "code": "ZA"}, 
    {"name": "South Georgia and the South Sandwich Islands", "code": "GS"}, 
    {"name": "Spain", "code": "ES"}, 
    {"name": "Sri Lanka", "code": "LK"}, 
    {"name": "Sudan", "code": "SD"}, 
    {"name": "Suriname", "code": "SR"}, 
    {"name": "Svalbard and Jan Mayen", "code": "SJ"}, 
    {"name": "Swaziland", "code": "SZ"}, 
    {"name": "Sweden", "code": "SE"}, 
    {"name": "Switzerland", "code": "CH"}, 
    {"name": "Syrian Arab Republic", "code": "SY"}, 
    {"name": "Taiwan, Province of China", "code": "TW"}, 
    {"name": "Tajikistan", "code": "TJ"}, 
    {"name": "Tanzania, United Republic of", "code": "TZ"}, 
    {"name": "Thailand", "code": "TH"}, 
    {"name": "Timor-Leste", "code": "TL"}, 
    {"name": "Togo", "code": "TG"}, 
    {"name": "Tokelau", "code": "TK"}, 
    {"name": "Tonga", "code": "TO"}, 
    {"name": "Trinidad and Tobago", "code": "TT"}, 
    {"name": "Tunisia", "code": "TN"}, 
    {"name": "Turkey", "code": "TR"}, 
    {"name": "Turkmenistan", "code": "TM"}, 
    {"name": "Turks and Caicos Islands", "code": "TC"}, 
    {"name": "Tuvalu", "code": "TV"}, 
    {"name": "Uganda", "code": "UG"}, 
    {"name": "Ukraine", "code": "UA"}, 
    {"name": "United Arab Emirates", "code": "AE"}, 
    {"name": "United Kingdom", "code": "GB"}, 
    {"name": "United States", "code": "US"}, 
    {"name": "United States Minor Outlying Islands", "code": "UM"}, 
    {"name": "Uruguay", "code": "UY"}, 
    {"name": "Uzbekistan", "code": "UZ"}, 
    {"name": "Vanuatu", "code": "VU"}, 
    {"name": "Venezuela", "code": "VE"}, 
    {"name": "Viet Nam", "code": "VN"}, 
    {"name": "Virgin Islands, British", "code": "VG"}, 
    {"name": "Virgin Islands, U.S.", "code": "VI"}, 
    {"name": "Wallis and Futuna", "code": "WF"}, 
    {"name": "Western Sahara", "code": "EH"}, 
    {"name": "Yemen", "code": "YE"}, 
    {"name": "Zambia", "code": "ZM"}, 
    {"name": "Zimbabwe", "code": "ZW"} 
]

export const CHANNEL_COVER_IMAGE = "https://media.sproutsocial.com/uploads/2018/04/Facebook-Cover-Photo-Size.png"