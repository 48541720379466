import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { AuthContext } from '../Firebase/AuthProvider'
import { getAllChannelsListWithPagination, updateChannel } from '../Firebase/Channels'
import { CHANNEL_COVER_IMAGE } from '../Firebase/Constants'
import { adminEmail } from '../Helper/StaticContent'
import Loader from './Loader'
import { getUserDetail } from "../Firebase/Users";

function ChannelListComp() {
    const context = useContext(AuthContext)
    const [channelsList, setChannelsList] = useState([])
    const [showLoaderBtn, setHideLoadBtn] = useState(true)

    useEffect(() => {
        (async function(){
           await getChannels()
        }())
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const openPostMenu = (e) => {
      let allMenu = document.querySelectorAll(".post-menu-dropdown");
      let menu = e.target.childNodes[0];
      for (const a of allMenu) {
        if (!(a.getAttribute("id") === menu.getAttribute("id"))) {
          a.classList.remove("show-post-menu-dropdown");
        }
      }
      if (menu.classList.contains("show-post-menu-dropdown")) {
        menu.classList.remove("show-post-menu-dropdown");
      } else {
        menu.classList.add("show-post-menu-dropdown");
      }
    };

    const closeDropdown = async (e, channelId, status) => {
      await updateChannel(channelId, {'channel_status': status});
      let dropDownMenu = e.target.parentNode.parentNode;
      dropDownMenu.classList.remove("show-post-menu-dropdown");
    };

    /**
    * fetch all channels from the DB with pagination
    * by default it will load first 5 channles which
    * can be set by passing numbers of item and its required startAfter params
    * to which is the last value of the array
    *  getAllChannelsListWithPagination(lastvalue,7)
    */
    const getChannels = async()=>{
        const userData = await getUserDetail(context.state.currentUser);
        if(userData) {
            const isAdmin = userData.email === "shah@assemblyf.com";
            const _lists = await getAllChannelsListWithPagination(userData.user_channels, isAdmin)
            if(_lists.length === 0) {
            setHideLoadBtn(false);
            }
            setChannelsList([...channelsList,..._lists])
        }
    }

    const getChannelUrl = (channelId, slug) => {
        let url = `/channel/${channelId}`;
        if(slug) {
          url = '/'+slug;
        }
        return url;
    }
    return (
        <React.Fragment>
        {channelsList.length === 0 ? <Loader /> :
        <div id="channels_container" className="channels_container main-page">
            <h5><span>Find tribes</span> that match you.</h5>
            <div className="channel-grid">
                <div className="channels_inner">
                    {channelsList.map((channelInfo,index)=>{
                        return(
                            <div className="channel-card-new" key={index}>
                                {
                                    context.state.userDetail?.email === adminEmail &&
                                    <div
                                        key={index}
                                        className="pbm-post-menu sub-menu-channel"
                                        onClick={(e) => {
                                        openPostMenu(e);
                                        }}
                                    >
                                        <div
                                        className="post-menu-dropdown"
                                        id={`postMenu${channelInfo.id}`}
                                        onClick={(e) => e.stopPropagation()}
                                        >
                                        <ul>
                                            <li
                                            className="show"
                                            onClick={(e) => {
                                                closeDropdown(e, channelInfo.id, 'active');
                                            }}
                                            >
                                            Show
                                            </li>
                                            <li
                                                className="visibility"
                                                onClick={(e) => {
                                                closeDropdown(e, channelInfo.id, 'Inactive');
                                                }}
                                            >
                                                Hide
                                            </li>
                                            <li
                                            className="delete"
                                            onClick={(e) => {
                                                closeDropdown(e, channelInfo.id, 'deleted');
                                            }}
                                            >
                                            Delete
                                            </li>
                                        </ul>
                                        </div>
                                    </div>
                                }
                                <div className="channel-list">
                                    <div className="channel-image">
                                        <Link to={`/channel/${channelInfo.id}`}>
                                        {channelInfo?.cover_image ?
                                            <img src={channelInfo.cover_image} alt="noImage" />
                                            :
                                            <img src={CHANNEL_COVER_IMAGE} alt="noImage" />
                                        }
                                        </Link>
                                    </div>

                                    <div className="channel-description">
                                        <h4 className="channel-name"><Link to={getChannelUrl(channelInfo.id, channelInfo?.slug)}>{channelInfo?.name}</Link></h4>
                                        <p className="channel-desc">{channelInfo?.description}</p>
                                        <ul className="channle-authors">
                                            <li>{channelInfo.members.length} members</li>
                                            {
                                            channelInfo.priceType === "paid" &&
                                            <li>${channelInfo.amount}/month</li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                   {
			showLoaderBtn &&
			<div className="loadMoreBtn"></div>
                   }
            </div>
</div>
        </div>
        }
        </React.Fragment>
    )
}

export default React.memo(ChannelListComp)
