import React, { Component } from 'react'
import { LazyLoadImage} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { defaultImageUrl } from '../Helper/StaticContent';


class ImageComponent extends Component {
  render() {
    return (
      <LazyLoadImage
      onClick={()=>{this.props.onClick && this.props.onClick()}}
      className={this.props.className}
      height="100%"
      width="100%" 
      effect="blur"
      src={this.props.image || defaultImageUrl.userDefaultImage } />
    )
  }
}

export default ImageComponent
