import React, { Component } from 'react'
import {AuthContext} from '../Firebase/AuthProvider'
import { getFollowingUserDetail } from '../Firebase/Users'
import { FIELDS } from '../Firebase/Constants'
import ImageComponent from './ImageComponent'
import Helper from '../Helper/helper'
import { Link } from "react-router-dom";

class FollowingList extends Component {
    static contextType = AuthContext
    constructor(props){
        super(props)
        this.state={
            userDetailLength:0,
            userFollowingDetail:[]
        }
    }

    componentDidMount(){
        if(this.state.userFollowingDetail.length <= 0){
            if(this.props.profile){
                this.getUserFollowerDetail(this.props.userDetail).then(data=>{
                    this.setState({
                        userFollowingDetail:data[FIELDS.USER.USER_FOLLOWING_DETAIL]
                    })
                })
            }else{
                this.getUserFollowerDetail(this.context.state.userDetail).then(data=>{
                    this.setState({
                        userFollowingDetail:data[FIELDS.USER.USER_FOLLOWING_DETAIL]
                    })
                })
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let userDetail = prevProps.profile ? prevProps.userDetail : this.context.state.userDetail 
        if(userDetail[FIELDS.USER.USER_FOLLOWING]?.length !== prevState.userDetailLength){
            this.setState({
                userDetailLength:userDetail[FIELDS.USER.USER_FOLLOWING]?.length,
            })
            this.getUserFollowerDetail(userDetail).then(data=>{
                this.setState({
                    userFollowingDetail:data[FIELDS.USER.USER_FOLLOWING_DETAIL]
                })
            })
        }
        return 
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state,callback)=>{
            return;
        };
    }

    getUserFollowerDetail = async(data)=>{
        const detail = await getFollowingUserDetail(data)
        return detail
    }

    render() {
        return (
            <div className="follower-list">
                {this.props.profile ?(
                    // <p>{this.props?.userDetail?.[FIELDS.USER.USER_NAME].split(" ")[0]} is following ({this.props?.userDetail?.[FIELDS.USER.USER_FOLLOWING]?.length})</p>
                    <p>Following ({this.props?.userDetail?.[FIELDS.USER.USER_FOLLOWING]?.length})</p>
                ):(
                    // <p>You're following ({this.context.state.userDetail[FIELDS.USER.USER_FOLLOWING]?.length})</p>
                    <p>Following ({this.context.state.userDetail[FIELDS.USER.USER_FOLLOWING]?.length})</p>
                )}
                <ul className="follower-ul">
                    {this.state.userFollowingDetail?.length > 0 && this.state.userFollowingDetail.slice(0, 8).map((user,key)=>{
                        return user && (
                            <li key={key}><span><Link to={`/profile/${user[FIELDS.USER.USER_ID]}`}><ImageComponent image={user[FIELDS.USER.USER_IMAGE]} /></Link></span>
                            <span className="name-follower">{Helper.transformText(user[FIELDS.USER.USER_NAME].split(" ")[0])}</span>
                            </li>
                            )
                    })}
                </ul>
            </div>
        )
    }
}

export default FollowingList
