import React, { useContext, useState } from 'react'
import { AuthContext } from '../Firebase/AuthProvider';
import { updateChannel } from '../Firebase/Channels';
import { countryList, FIELDS } from '../Firebase/Constants';
import { uploadUserImage } from '../Firebase/StorageBucket';
import { getName, getUserByEmail, getUserDetail, loginWithEmailAndPass, registerUser, updateUser } from '../Firebase/Users';
import { loginError, signUpError, signUpFormText } from '../Helper/StaticContent';
import '../Assets/Style/NewLogin.scss'

function SignUpComp(props) {
    const context = useContext(AuthContext)
    const [formData, setFormData] = useState({})
    const [isLoaderShow, setisLoaderShow] = useState(false)
    const [errorText, setErrorText] = useState('')
    const [imagePreview, setImagePreview] = useState(false)


    const validate = () => {
        let error = '';
        if(formData && Object.keys(formData).length === 0 && formData.constructor === Object){
            error = "Please fill the fields"
            return error;
        }
        if (!formData.firstName || formData.firstName === '') {
            error = "First name is required."
            return error;
        }
        if (!formData.lastName || formData.lastName === '') {
            error = "Last name is required."
            return error;
        }
        if (!formData.linkedInProfile || formData.linkedInProfile === '') {
            error = signUpError.organisationError
            return error;
        }
        if (!formData.email || formData.email === '') {
            error = signUpError.emailError
            return error;
        }
        if (!formData.country || formData.country === '') {
            error = "Country is required."
            return error;
        }
        if (!formData.password || formData.password === '') {
            error = signUpError.passwordError
            return error;
        }
        if (formData.password !== formData.confirmPassword) {
            error = signUpError.confirmPasswordError
            return error;
        }
        return null;
    }

    const onSignUpClick = async () =>{
        context.showLoader(true)
        const err = validate()
        if(err != null){
            setErrorText(err)
            context.showLoader(false)
            console.log(err)
            return;
        }else{
            setisLoaderShow(true)
            setErrorText('')
            let imageUrl = imagePreview && await uploadUserImage(imagePreview)

            try {
                let channelOwnser = props?.channelInfo && props?.channelInfo?.channel_owner &&  await getUserDetail(props?.channelInfo?.channel_owner);
                await registerUser({
                    [FIELDS.USER.USER_NAME]: formData?.firstName+' '+formData?.lastName,
                    [FIELDS.USER.USER_EMAIL]: formData?.email.trim(),
                    [FIELDS.USER.USER_LINKEDIN_PROFILE]: formData?.linkedInProfile,
                    [FIELDS.USER.USER_IMAGE]: imageUrl,
                    [FIELDS.USER.USER_ASK_EVENT]: "",
                    [FIELDS.USER.USER_SEARCH_NAME] : getName(formData?.firstName+' '+formData?.lastName),
                    [FIELDS.USER.USER_KEYWORD] : (formData?.firstName+formData?.lastName).toLowerCase().split(""),
                    [FIELDS.USER.USER_COUNTRY]: formData?.country,
                    password: formData?.password,
                    [FIELDS.USER.USER_INVITED_EMAIL]: channelOwnser ? channelOwnser.email : "",
                    [FIELDS.USER.USER_INVITED_USER_NAME]: channelOwnser ? props.channelInfo.name : "",
                    [FIELDS.USER.USER_INVITED_USER_ID]: channelOwnser ? props.channelId : "",
                    [FIELDS.USER.USER_USER_TYPE]: channelOwnser ? "FromChannel" : "",
                    [FIELDS.USER.USER_DATE]: new Date(),
                    [FIELDS.USER.USER_APPROVE]: true,
                    [FIELDS.USER.USER_CHANNELS]: props.channelInfo?.priceType === "paid" ? [] : channelOwnser ? [props.channelId] : []
                });


                if(props?.channelId) {
                    // add user to the channel memeber
                    if(props?.channelInfo?.priceType !== "paid") {
                      const newUser = await getUserByEmail(formData?.email);
                      if(newUser){
                        let userDetail =  newUser[0].data();
                        userDetail.id = newUser[0].id;
                        let channelMembers = props?.channelInfo?.members
                        channelMembers.push(userDetail);
                        let data = { members: channelMembers}
                        await updateChannel(props?.channelId,data)
                      }
                    }
                }

                let users = await loginWithEmailAndPass(formData.email,formData.password)
                let userDetail = await getUserDetail(users.user.uid)
                
                if(!userDetail?.id){
                    setisLoaderShow(false)
                    setErrorText(loginError.userNotExist)
                    context.showLoader(false)
                    return
                }else{
                    context.showLoader(false)
                    setisLoaderShow(false)
                    setErrorText('')
                    await updateUser(users.user.uid, "loggedInTime", Date.now(),"", "")
                    context.setCurrentUser(users.user.uid,userDetail);
                    props?.closeModal && props.closeModal();
                    if(props?.isPopup) {
                        setTimeout(() => {
                            props?.openPaymentModal && props.openPaymentModal('change');
                        }, 900);
                    }  
                } 
            } catch (error) {
                setErrorText(error.message)
                setisLoaderShow(false)
                context.showLoader(false)
            }
        }
    }

    return (
        <div className="new-login-screen">
        <h3>Signup</h3>
        <div className="form-div">
          <div className="form-inner">
            <input className={`${errorText?.includes("First name") && "error-border"}`} type="text" name="firstName" value={formData?.firstName || ""} onChange={e=>setFormData({...formData,[e.target.name]:e.target.value})} placeholder="Your first name" />
            <input className={`${errorText?.includes("Last name") && "error-border"}`} type="text" name="lastName" value={formData?.lastName || ""} onChange={e=>setFormData({...formData,[e.target.name]:e.target.value})} placeholder="Your last name" />
            <input className={`${errorText?.includes("Organisation") && "error-border"}`} type="text" name="linkedInProfile" value={formData?.linkedInProfile || ""} onChange={e=>setFormData({...formData,[e.target.name]:e.target.value})} placeholder="Your company or institution" />
            <input className={`${errorText?.includes("email") && "error-border"}`} type="text" name="email" value={formData?.email} onChange={e=>setFormData({...formData,[e.target.name]:e.target.value})} placeholder="Your email" />
            <select
            className={`${errorText?.includes("Country") && "error-border"}`}
            name="country"
            id="country"
            value={formData?.country || ""}
            onChange={e=>setFormData({...formData,[e.target.name]:e.target.value})}
            >
            <option value="">
                Choose Country
            </option>
            {countryList.map((country) => {
                return (
                <option value={country.name} key={country.code}>
                    {country.name}
                </option>
                );
            })}
            </select>
            <input className={`${errorText?.includes("password") && "error-border"}`} type="password" autoComplete="off" name="password" value={formData?.password || ""} onChange={e=>setFormData({...formData,[e.target.name]:e.target.value})} placeholder="Your password" />
            <input className={`${errorText?.includes("Password") && "error-border"}`} type="password" autoComplete="off" required name="confirmPassword" value={formData?.confirmPassword || ""} onChange={e=>setFormData({...formData,[e.target.name]:e.target.value})} placeholder={signUpFormText.confirmPassword} />
            <div className={`input-upload-image ${imagePreview && "image-preview"}`}>
                <label className={imagePreview && "image-preview"} htmlFor="image-upload">{imagePreview ? (
                    <React.Fragment>
                        <img src={URL.createObjectURL(imagePreview)} alt="" />
                        <a href="# " onClick={()=>setImagePreview(false)}>&#10010;</a>
                    </React.Fragment>
                ) : `${signUpFormText.image}`}
                <input className="input-image-file"
                    accept="image/*"
                    type="file"
                    id="image-upload"
                    name="upload-image"
                    value=""
                    onChange={(e)=>setImagePreview(e.target.files[0])}
                />
                </label>
            </div>

            {errorText && (
                <span className="error-display">{errorText}</span>
            )}
            {isLoaderShow ? 
                <div className="loadergif"><img src={require('../Assets/Images/loader.gif')} alt="noImage" width="100" height="100" /></div> 
                : <button onClick={(e) => {onSignUpClick(e) }}>Signup</button>
            } 
            <p className="acc-btn">Already have an account? <span onClick={() => {props.changeState ? props.changeState("login") : props.closeModal() }}>Login here</span></p>
          </div>
        </div>
      </div>
    )
}

export default SignUpComp
