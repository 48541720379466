import React, { Component } from 'react'
import "../Assets/Style/login.css"
import { AuthContext } from '../Firebase/AuthProvider'
import Header from "../components/header";
import Sidebar from "../components/Sidebar";
import RightSidebar from "../components/RightSideBar";
import ChatBox from "../components/chatBox";
import { getEventById } from '../Firebase/Events';
import Helper from '../Helper/helper';

class EventDetail extends Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = {
      event: ""
    };
  }

  async componentDidMount() {
    let eventId = this.props.match.params.ID;
    const result = await getEventById(eventId);
    this.setState({ event: result })
  }

  render() {
    return (
      <React.Fragment>
        <Header props={this.props} />
        <div className="DashboardMainContent">
        <Sidebar currentPage="home" />
        <div className="profile-wrapper">
           <div className="ProfileContainer post-detail">
              <div className="ContentLeftWrap">
              <div className={`meeting-live-dive up-next`}>
{
  this.state.event &&
  <div className="SessionContent">
  <div className="agenda-view ads">
    <h1>{this.state.event.event_name}</h1>
    <h3 className="channel-name">{this.state.event.event_channel_name}</h3>
    <div className="inner">
      <div className="speakers">
        {
          this.state.event.event_speaker_list?.map((el,i) => {
            if(i <= 4){
            return (
              <div className="img">
                <div className="host-image">
                  <img src={el.image} alt="noImage" />
                </div>
                <div className="overflow-modal">
                  <h4>{el.name}</h4>
                </div>
              </div>
            )
            }
          })
        }
      </div>

      <div className="date">
        <p>{Helper.getEventDate(this.state.event?.event_start_date_time, this.state.event?.event_end_time)}</p>
      </div>
    </div>
  </div>
</div>
}

</div>
		      
	      </div>
           </div>
        </div>
        <ChatBox props={this.props} userInfo={this.context.state.userDetail} />
        <RightSidebar />
        </div>
      </React.Fragment>
    );
  }
}

export default EventDetail;
