import React, { useState } from 'react'
import { searchSpecificUser } from '../../Firebase/Users';
import './index.css'

const SearchDropdown = ({onAddSpeakerClick,width, title}) => {
    const [users, setUsers] = useState([])
    const [name, setName] = useState("")

    const searchUsers = async (search) => {
        if(search.length > 0){
          const result = await searchSpecificUser(search.toLowerCase());
          console.log(result)
          let users = result.map(el=>{
            el.id = el.uid
            return el;
          })
          search !== "" && setUsers(users)
        }else{
          setUsers([])
        }
    }


    const addSpeakers = async(index) =>{
        let _users = [...users]

        let data = {
          name: _users[index].name,
          title: _users[index].linkedin_profile,
          email: _users[index].email,
          link: _users[index].id,
          image: _users[index].image,
        };
        if(onAddSpeakerClick){
            await onAddSpeakerClick(data)
        }
        setUsers([])
        setName("")
    }

    return (
        <div className="add-speaker-container" style={{width: width? `${width}%` :""}}>
            <p>{title}</p>
            <div className="inner">
            
            <div className="search-bar">
                <input type="text" value={name} placeholder="search" onChange={(e) => {setName(e.target.value);searchUsers(e.target.value)}} />
                <img
                src={require('../../Assets/Images/search.png')}
                className="img-fluid"
                width="12"
                alt="usrimage"
                />
            </div>

            {
               users.length > 0 && <div className="search-list">
                    <ul>
                    {users?.map((user,key)=>{
                        return user.image && (
                        <li key={key} className="search-item" onClick={()=>{addSpeakers(key)}}>
                            <div className="left">
                            <div className="image">
                                <img src={user.image} alt="" />
                            </div>
                            </div>
                            <div className="right">
                            <div className="inner">
                                <span>{user.name}</span>
                                <span>{user.linkedin_profile}</span>
                            </div>
                            </div>
                        </li>
                        )
                    })}
                    </ul>
                </div>
            }
            </div>
        </div>
    )
}

export default SearchDropdown
