import React, { Component } from 'react'
import ImageComponent from '../components/ImageComponent'
import logo from '../Assets/Images/logo-f (1).png'
import {AuthContext} from "../Firebase/AuthProvider"

class AdminLogin extends Component {
    static contextType = AuthContext    
    constructor(props) {
        super(props);
        this.state={
            email:'',
            password:'',
            adminEmail:"admin@assemblyf.com",
            adminPassword:"shah@ttadmin%",
            errorText:""
        }
        
    }
    
    onChangeHandler = (e)=>{
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    onLoginClick = (e)=>{
        e.preventDefault();
        let error = this.validation()
        if(error !== true){
            this.setState({
                errorText:error
            })
        }else(
            this.setState({
                errorText:false
            },()=>{
                this.context.setAdminLogin(true)
                localStorage.setItem("admin",this.state.email)
                this.props.history.push('/admin')
            })  
        )
    }


    validation =()=>{
        if(this.state.email.length === 0 ){
            return "Email cannot be blank"
        }
        if(this.state.password.length === 0 ){
            return "Please enter password"
        }
        if(this.state.email.length === 0 && this.state.password.length === 0){
            return "Enter email & password"
        }
        if(this.state.email === this.state.adminEmail && this.state.password === this.state.adminPassword){
            return true
        }
        if(this.state.email !== this.state.adminEmail && this.state.password !== this.state.adminPassword){
            return "you are not authorized"
        }
        if(this.state.email !== this.state.adminEmail || this.state.password !== this.state.adminPassword){
            return "you are not authorized"
        }
        return false
    }

  render() {
    return (
     <div className="admin-login-component">
        <div className="admin-login-inner">
            <a href="# " onClick={e=>{e.preventDefault();this.props.history.push("/")}}><ImageComponent image={logo} /></a> 
            <form>
                <div className="login-input-group">
                    <input type="email" className="login-input" value={this.state.email} name="email" onChange={e=>{this.onChangeHandler(e)}} placeholder="Enter your email"/>
                </div>
                <div className="login-input-group">
                    <input type="password" className="login-input"  value={this.state.password} name="password" onChange={e=>{this.onChangeHandler(e)}} placeholder="Password" />
                </div>
                <span className="error-text">{this.state.errorText}</span>
                <button className="login-button" onClick={(e)=>{this.onLoginClick(e)}}>LOGIN</button>
            </form>
        </div>
     </div>
    )
  }
}

export default AdminLogin
