import React, { Component } from 'react'
import '../Assets/Style/style.css'
import {AuthContext} from '../Firebase/AuthProvider'
import { FIELDS } from '../Firebase/Constants'
import UserModal from './UserModal'
import { defaultImageUrl } from '../Helper/StaticContent'
import NavBar from './Navbar';
import { getMyChannels } from "../Firebase/Channels";
import { app } from '../Firebase/firebase-config'
import { getUserDetail } from '../Firebase/Users'
const db = app.firestore()

class Header extends Component {
  static contextType = AuthContext
  constructor(props){
    super(props)
    this.state={
      showDropdown:false,
      showModal:false,
      channelList: [],
      scrollActive:0,
      initialCall: false,
      notifications:[]
    }
  }

  componentDidMount() {
    this.getChannelList();
    window.addEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate(){
    if(this.state.notifications.length === 0 && !this.state.initialCall){
      console.log("notification calling")
      this.realTimeNotifications(this.context?.state?.currentUser)
      this.setState({initialCall:true})
    }

    // this.realTimeNotifications(this.context?.state?.currentUser)
  }


  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  getChannelList = async () => {
    setTimeout(async() => {
      const result = await getMyChannels(this.context.state.currentUser);
      this.setState({ channelList: result });
    },500)
  };


  onSignOutClick=(e)=>{
    e.preventDefault();
    if(this.props.admin){
      this.context.signOut("admin");
      this.props.history.push("/")
    }else{
      if(this.props.emptyChannelList) {
        this.props.emptyChannelList();
      }
      this.context.signOut();
      //this.props.props.history.push("/")
    }
  }

  home=()=>{
    this.props.history.push("/")
  }

  redirectPage = e =>{
    e.preventDefault();
    if(e.target.text === "Create Event"){
      this.props.history.push("/admin")
    }
    if(e.target.text === "Event List"){
      this.props.history.push("/admin/events")
    }
    if(e.target.text === "User List"){
      this.props.history.push("/admin/users")
    }
  }

  showDropdown = ()=>{
    this.setState({
      showDropdown: !this.state.showDropdown
    })
  }

  showModal =()=>{
    this.setState({
      showModal:!this.state.showModal,
      showDropdown:false
    })
  }

  openImageModal =()=>{
    this.showModal();
    this.setState({show:"showImageModal"})
  }

  openPasswordModal =()=>{
    this.showModal();
    this.setState({show:"showPasswordModal"})
  }

  handleScroll = (event) => {
    let scrollPosition = window.scrollY;
    this.setState({scrollActive:scrollPosition})
  }


  realTimeNotifications = async(userId)=>{
    const REACTNOTIFICATION = "ReactNotifications"
    const COMMENTNOTIFICATION = "CommentsNotifications"
    const TRIBEPOSTNOTIFICATIONS = "TribePostNotifications"
    if(userId && userId){
  
      let reactNotifications =  db.collection("users").doc(userId).collection(REACTNOTIFICATION).limit(10).orderBy("date", "desc")
      let commentNotifications =  db.collection("users").doc(userId).collection(COMMENTNOTIFICATION).limit(10).orderBy("date", "desc")
      let tribePostNotifications =  db.collection("users").doc(userId).collection(TRIBEPOSTNOTIFICATIONS).limit(10).orderBy("date", "desc")
      
      let notificationsArray = []
  
      reactNotifications.onSnapshot(snapshot=>{
        snapshot.docChanges().forEach(async(change) => {
          if(change.type === "added"){
            let data = change.doc.data()
            if(data.reactorId !== this.context?.state?.currentUser){
              let userDetail = await getUserDetail(data.reactorId)
              data['img'] = userDetail?.image
              data['docId'] = change.doc.id
              notificationsArray.push(data)
            }
            this.setState({notifications:notificationsArray},()=>{
              this.state.notifications.sort((a,b)=>b.date - a.date)
            })
          }  

          if(change.type === "modified"){
            let data = change.doc.data()
            let userDetail = await getUserDetail(data.commentorId)
            data['img'] = userDetail?.image
            data['docId'] = change.doc.id
            let indexOfModify = notificationsArray.findIndex(e=>e.docId === change.doc.id)
            notificationsArray[indexOfModify] = data
            this.setState({notifications:notificationsArray},()=>{
              this.state.notifications.sort((a,b)=>b.date - a.date)
            })
          }

        })
      })
  
      commentNotifications.onSnapshot(snapshot=>{
        snapshot.docChanges().forEach(async(change) => {
          if(change.type === "added"){
            let data = change.doc.data()
            if(data.commentorId !== this.context?.state?.currentUser){
              let userDetail = await getUserDetail(data.commentorId)
              data['img'] = userDetail?.image
              data['docId'] = change.doc.id
              notificationsArray.push(data)
            }
            this.setState({notifications:notificationsArray},()=>{
              this.state.notifications.sort((a,b)=>b.date - a.date)
            })
          }
          
          if(change.type === "modified"){
            let data = change.doc.data()
            let userDetail = await getUserDetail(data.commentorId)
            data['img'] = userDetail?.image
            data['docId'] = change.doc.id
            let indexOfModify = notificationsArray.findIndex(e=>e.docId === change.doc.id)
            notificationsArray[indexOfModify] = data
            this.setState({notifications:notificationsArray},()=>{
              this.state.notifications.sort((a,b)=>b.date - a.date)
            })
          }
          
        })
      })


      tribePostNotifications.onSnapshot(snapshot=>{
        snapshot.docChanges().forEach(async(change) => {
          if(change.type === "added"){
            let data = change.doc.data()
            let userDetail = await getUserDetail(data.hostId)
            data['img'] = userDetail?.image
            data['docId'] = change.doc.id
            data['type'] = 'tribePost'
            notificationsArray.push(data)
            this.setState({notifications:notificationsArray},()=>{
              this.state.notifications.sort((a,b)=>b.date - a.date)
            })
          }  
        })
      })
  
    }
  }

  openEditProfileModal =()=>{
    this.showModal();
    this.setState({show:"signup-popup"})
  }

  render() {
    return (
      <React.Fragment>
      {/* <div className={`header-component gnrl-grey-bg ${this.props.detailPage && "limit-width"} ${this.props.about && "about-padding"}`}> */}
      <div className={`topNavBar ${this.state.scrollActive > 70 && "box-shadow"} ${!this.context.state.currentUser && "no-user" } `}>
        <div className="left-header">
          {!this.context.state.currentUser && (

          <div className="header-logo">
            {!this.props.home && (
              <img src={require("../Assets/Images/blackLogo.png")} onClick={()=>this.props.props.history.push('/')} alt="" />
            )}
          </div>
          )}

          {this.props.admin && (
            <React.Fragment>
              <a href="# " className={`admin-create-event ${this.props.history.location.pathname === "/admin" && "selected"}`} onClick={e => this.redirectPage(e)}>Create Event</a>
              <a href="# " className={`admin-create-event ${this.props.history.location.pathname === "/admin/events" && "selected"}`} onClick={e => this.redirectPage(e)}>Event List</a>
              <a href="# " className={`admin-create-event ${this.props.history.location.pathname === "/admin/users" && "selected"}`} onClick={e => this.redirectPage(e)}>User List</a>
            </React.Fragment>
          )}
        </div>
        <div className="right-header">
          {(this.props.previousEvent && !this.context?.state?.currentUser) && (
            <React.Fragment>
              <button className="li-login-btn header-btn" onClick={()=>{this.props.onLoginClick()}}>Login</button>
              <button className="li-login-btn header-btn" onClick={()=>{this.props.onSignupClick()}}>Register</button>
            </React.Fragment>
          )}

          {!this.context.state.currentUser && !this.props.isLoginPage && (
            <React.Fragment>
              <button className={`${!this.props.isLoggedOutChannel && "simple"} li-login-btn header-btn`} onClick={()=>{this.props.showForm ? this.props.showForm()  : this.props.props.history.push('/')}}>Signup</button>
              <button className={`${!this.props.isLoggedOutChannel && "simple"} li-login-btn header-btn`} onClick={()=>{this.props.showForm ? this.props.showForm()  :this.props.props.history.push('/')}}>Login</button>

            </React.Fragment>
          )}


          {this.props.admin ? (
            <button className="button-sign-out" onClick={e => this.onSignOutClick(e)} >Log out</button>
          ) :
            <React.Fragment>
              {this.context.state.currentUser && (
                // <div className="header-profile-view">
                //   <div className="profile-inner-box">

                //     <div className="profile-image" onClick={()=>{this.props.history.push(`/profile/${this.context.state.currentUser}`)}}>
                //       <img src={this.context.state.userDetail[FIELDS.USER.USER_IMAGE] || defaultImageUrl.userDefaultImage} alt=""/>
                //     </div>
                //     <div className="profile-detail">
                //       <span onClick={()=>this.showDropdown()}>{this.context.state.userDetail[FIELDS.USER.USER_NAME]} <p>&#9660;</p></span>
                //     </div>
                //   </div>
                //   {this.state.showDropdown && (
                //     <div className="profile-dropdown">
                //       <ul className="profile-dropdown-list">
                //         <li>
                //           <button onClick={e=>{this.showModal();this.setState({show:"showImageModal"})}}><span><img src={userIcon} alt=""/></span> <p className="paragraph-generic-class">upload photo</p></button>
                //         </li>
                //         <li>
                //           <button onClick={e=>{this.showModal();this.setState({show:"showPasswordModal"})}}><span><img src={lockIcon} alt=""/></span> <p className="paragraph-generic-class">change password</p></button>
                //         </li>
                //         <li>
                //           <button onClick={e => this.onSignOutClick(e)}><span><img src={logoutIcon} alt=""/></span> <p className="paragraph-generic-class">logout</p></button>
                //         </li>
                //       </ul>
                //     </div>
                //   )}
                // </div>
                <NavBar 
                  notifications={this.state.notifications}
                  showImageModal={this.openImageModal}
                  openPasswordModal={this.openPasswordModal}
                  logout={this.onSignOutClick}
                  userImage={this.context.state.userDetail ? this.context.state.userDetail[FIELDS.USER.USER_IMAGE] || defaultImageUrl.userDefaultImage : ""}
                  history={this.props.history}
                  channelList={this.state.channelList}
                  channelInfo={this.props.channelInfo}
                  userID={this.context.state.userDetail?.id}
                  userName={this.context.state.userDetail?.name}
                  currentPath={window.location.pathname}
                  isHost={this.context.state?.userDetail?.user_is_host}
                  openEditProfileModal={this.openEditProfileModal}
                />

              )}
            </React.Fragment>
          }
        </div>
      </div>
      {this.state.showModal && (
        <UserModal show={this.state.show} closeModal={this.showModal} userInfo={this.context.state.userDetail}  />
      )}
      </React.Fragment>
    )
  }
}

export default Header

