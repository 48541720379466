import React, { Component } from 'react'
import { app } from "./firebase-config";
import { getEvents, updateEventGoingSeat, updateTimeStampEvent, updateSpecificEvent, getIcsByEventId, shallowIcs, updateIcsByEventId, uploadIcs } from './Events';
import { getUserDetail, userExists, updateUserFollowing, updateUserFollower, updateUserElves } from './Users';
import { inviteUser } from './Mailer';
import { FIELDS } from './Constants';

export const AuthContext = React.createContext();

const analytics = app.analytics();

export class AuthProvider extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentUser: '',
            events: [],
            userDetail: '',
            updateStateCalled: false,
            showLoader: false,
            updateUser: false,
            eventLoading: false,
            adminLogin:false,
            showSnackbar:false,
            dummyLoader:false
        }
    }


    componentDidMount() {
        app.auth().onAuthStateChanged(async user => {
            if (user) {
                if (await userExists(user.uid) === true) {
                    getUserDetail(user.uid).then(data => {
                        if (data.user_is_approve) {
                            this.setState({
                                currentUser: user.uid,
                                userDetail: data
                            }, () => {
                                localStorage.setItem("userID", user.uid)
                            })
                        } else {
                        }
                    })
                } else {
                    if(user.hasOwnProperty('displayName')) {
                        getUserDetail(localStorage.getItem("userID")).then(data => {
                            this.setState({
                                currentUser: localStorage.getItem("userID"),
                                userDetail: data
                            })
                        })
                    }
                    
                }
            } else {
                this.setState({
                    currentUser: '',
                    userDetail: ''
                })
            }
        })

        if (localStorage.getItem("userID")) {
            this.setState({
                currentUser: localStorage.getItem("userID")
            })
        }

        if (this.state.events.length === 0) {
            this.getData()
        }

        if (localStorage.getItem("admin")) {
            this.setState({
                adminLogin: localStorage.getItem("admin")
            })
        }
        
        updateTimeStampEvent();
    }

    setCurrentUser = (uid, data) => {
        this.setState({
            currentUser: uid,
            userDetail: data
        }, () => {
            localStorage.setItem("userID", uid)
        })
    }

    showSnackbar = (value)=>{
        this.setState({
            showSnackbar:value
        })
    }

    componentDidUpdate(prevProps, prevState, ) {
        // if(prevState.events.length == 0){
        //     this.getData()
        // }
    }


    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.updateStateCalled) {
            this.getData();
            this.setState({ updateStateCalled: false })
        }
        return true
    }


    updateState = (value) => {
        this.setState({ updateStateCalled: value })
    }

    signOut = (admin) => {
        if(admin){
            localStorage.removeItem("admin");
            this.setState({ adminLogin: false, })
        }else{
            app.auth().signOut();
            localStorage.removeItem("userID");
            this.setState({ currentUser: undefined, })
        }
    }
    
    showLoader = (value) => {
        if (this.state.eventLoading === true) {
            this.setState({
                showLoader: this.state.eventLoading
            })
        } else {
            this.setState({
                showLoader: value
            })
        }
    }

    // Get Events from firestore and save into state
    getData = () => {
        // this.setState({ eventLoading: true })
        // this.showLoader(true)
        return;
        // getEvents("events").then(data => {
        //     this.setState({ events: data })
        //     this.setState({ eventLoading: false }, () => {
        //         this.showLoader(false)
        //     })

        // })
    }


    deleteEvent = async(tableName,id) => {
        this.showLoader(true)
        await updateSpecificEvent(tableName,id,[FIELDS.EVENT.EVENT_IS_DELETE],true);
        
        // get the ICS data by event ID
        let icsData = await getIcsByEventId(id);
        // get the ical of ICS DATA 
        let ics = await shallowIcs(icsData)
        // Delete the events in ICS file 
        ics.method("CANCEL")

        // Update ICS file in invites table 
        await updateIcsByEventId(id, ics.toJSON());
        // get the updated ICS file URL from firestore storage
        let icsURL = await uploadIcs(ics.toString(),id);

        if(icsURL){
            await updateSpecificEvent(tableName,id,[FIELDS.EVENT.EVENT_ICS],icsURL);
            const data = await getEvents(tableName);
            this.setState({ 
                events: data,
                eventLoading:false
            },()=>{
                this.showLoader(false)
            })
        }
    }

    reserveSeat = async (uid, users, event) => {
        this.showLoader(true)
        let userId = this.state.currentUser;
        let userList = [...users]
        if (!userList.includes(userId)) {
            //  reserveSeat(this.state.userDetail.email)
            inviteUser(this.state.userDetail.email,event,this.state.userDetail.name)
            userList.push(userId)
            await updateEventGoingSeat(uid, userList)
            analytics.logEvent("seat Reserved",{"user":this.state.userDetail.email,"event":event.event_name})
            
            // auto following host when reserving a seat to event
            let hostFollowersDetail = await getUserDetail(event[FIELDS.EVENT.EVENT_HOST_ID]).then(data=>data[FIELDS.USER.USER_FOLLOWERS]);
            let hostId = event[FIELDS.EVENT.EVENT_HOST_ID];
            if(!hostFollowersDetail.includes(userId) && userId !== hostId){
                let updatedHostDetail = await this.followUser(hostId,hostFollowersDetail)
                await updateSpecificEvent("events",uid,[FIELDS.EVENT.EVENT_HOST_DETAIL],updatedHostDetail)
            }

            const data = await getEvents("events");
            this.setState({ events: data })
            this.setState({ eventLoading: false }, () => {
                this.showLoader(false)
            })

        } else {
            userList.splice(userList.indexOf(userId), 1)
            await updateEventGoingSeat(uid, userList);
            const data = await getEvents("events");
            this.setState({ events: data })
            this.setState({ eventLoading: false }, () => {
                this.showLoader(false)
            })
        }
    }

    setAdminLogin =(value)=>{
        this.setState({
            adminLogin:value
        })
    }

    featuredHostByEvent = () =>{
        // get the id of current sign-in user
        let currentUserId = this.state.currentUser
        // get event whose event attendee is greater than 5
        // eslint-disable-next-line array-callback-return
        let host = this.state.events.filter((user)=>{
            if(user[FIELDS.EVENT.EVENT_GOING].length > 3){
                return user[FIELDS.EVENT.EVENT_HOST_DETAIL]
            }
        })

        // get only host detail from event whose attendee is greater than 5
        let featuredHost = host.map(user=>user[FIELDS.EVENT.EVENT_HOST_DETAIL])

        // than remove same host from hostdetail
        let removeDuplicateHost = featuredHost.filter((v,i,a)=>a.findIndex(t=>(t.email === v.email && t.id !== currentUserId ))===i)

        // Shuffle the list and return sortedHost 
        return shuffle(removeDuplicateHost)


        function shuffle(a) {
            for (let i = a.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [a[i], a[j]] = [a[j], a[i]];
            }
            return a;
        }
    }

    followUser = async(followerId,follower)=>{
        // sign-in user ID
        const userId = this.state.currentUser
        // following list of sign-in user
        let followingList = [...this.state?.userDetail?.user_following]
        // follower list of that user who is beign follow by sign-in user
        let followerList = [...follower];


        let updatedHost = "";
        // if current/sign-in user is not following that user than add follow user id to following array in sign-in user
        if(!followingList.includes(followerId)){
            // this.showLoader(true)
            followingList.push(followerId)
            followerList.push(userId)
            // update current/sign-in user following list 
            await updateUserFollowing(userId,followingList)
            // update follower list of that follow user who is beign follow by sign-in user
            await updateUserFollower(followerId,followerList)
            // get detail of user who is beign followed by current/sign-in user
            updatedHost = await getUserDetail(followerId)
            
            // get current/sign-in user updated detail and set it to state 
            getUserDetail(userId).then(data=>{
                this.setState({
                    userDetail:data
                },()=>{
                    // this.showLoader(false)
                })
            })

            // return followed user detail 
            return updatedHost
        }else{
            // this.showLoader(true)
            // if user is follow that user then remove id from current/sign-in user following-list 
            followingList.splice(followingList.indexOf(followerId),1)
            // if current/sign-in user un-follow that user then remove id from follower list of that user who is beign un-follow by user
            followerList.splice(followerList.indexOf(userId),1)
            // update current user following list
            await updateUserFollowing(userId,followingList)
            // update follow user follower list
            await updateUserFollower(followerId,followerList)

            // get detail of follow user 
            updatedHost = await getUserDetail(followerId) 

            // get detail of current user
            getUserDetail(userId).then(data=>{
                this.setState({
                    userDetail:data
                },()=>{
                    // this.showLoader(false)
                })
            })

            // return detail of follow user
            return updatedHost
        }
    }

    giveElves = async(userId)=>{
        this.setState({dummyLoader:true})
        let userInfo = await getUserDetail(userId)
        let data = userInfo.user_total_elves ? userInfo?.user_total_elves + 5 : 0  + 5;
        const result = await updateUserElves(userId, data)
        // this.setState({dummyLoader:false})
        return result;
    }

    render() {
        return (
          <AuthContext.Provider
            value={{
              state: this.state,
              deleteEvent: this.deleteEvent,
              updateState: this.updateState,
              showLoader: this.showLoader,
              reserveSeat: this.reserveSeat,
              setCurrentUser: this.setCurrentUser,
              signOut: this.signOut,
              setAdminLogin: this.setAdminLogin,
              getData: this.getData,
              featuredHostByEvent: this.featuredHostByEvent,
              followUser:this.followUser,
              showSnackbar: this.showSnackbar,
              giveElves: this.giveElves
            }}
          >
            {this.props.children}
          </AuthContext.Provider>
        );
    }
}
