import React, { Fragment, useEffect, useState, useContext } from "react";
import '../../../Assets/Style/profile.scss'
import userIcon from '../../../Assets/Images/profile.png'
import searchIcon from '../../../Assets/Images/search.png'
import { getAllUsers, searchSpecificUser } from "../../../Firebase/Users";
import { Link } from 'react-router-dom';
import GroupChat from '../../../components/channel/GroupChat'
import NetworkingRoom from '../../../components/networkingRoom'
import Helper from "../../../Helper/helper";
import { AuthContext } from "../../../Firebase/AuthProvider";

const ChatBox = (props) => {

  const [users, setUsers] = useState([])
  const context = useContext(AuthContext)


  useEffect(()=>{
    props?.membersList ? setUsers(props.membersList): fetchUsers();
  }, [props])

  const fetchUsers = async() => {
    const result = await getAllUsers(100)
    setUsers(result)
  }

  const searchUsers = async (search) => {
    const result = await searchSpecificUser(search.toLowerCase());
    let users = result.map(el=>{
      el.id = el.uid
      return el;
    })
    if(props?.membersList){
      search !== "" ? setUsers(users) : setUsers(props?.membersList)
    }else{
      search !== "" ? setUsers(users) : fetchUsers()
    }
  }

  return (
    <Fragment>
      <div className="RightSideBarContainer channel-bar" style={{background: !props.currentUser && "#ffffff", marginTop: !props.currentUser && "0"}}>
        {props.currentUser && (
          <>
          <NetworkingRoom userInfo={props.userInfo} channelId={props.channelInfoId}  />
          <div className="tabs-selector">
            <button onClick={()=>{props.showHideTabs('people')}} className={props?.selectedTab === "people" && "active"}>
              {/* <img src={peopleIcon} alt=""/> */}
              <span>Members</span>
            </button>
            <button onClick={()=>{props.showHideTabs('groupChat')}} className={props?.selectedTab === "groupChat" && "active"}>
              {/* <img src={chatIcon} alt=""/> */}
              <span>Group Chat</span>
            </button>
          </div>
        {
            (props.selectedTab === "people" || props.selectedTab !== "groupChat") &&
          <div className="chatBoxContainer divWithScrollbar">
            <div className="Search">
              <input type="text" placeholder="search" onChange={(e) => searchUsers(e.target.value)} />
              <img
                src={searchIcon}
                className="img-fluid"
                width="12"
                alt="usrimage"
              />
            </div>
            <div className="chat-container">

            {users.map((item, key) => {
                return item && (
                  <div className="chatBox" key={key}>
                    <div className="userImg">
                    {
                          item.image ?
                          <a href={"/profile/"+(item.id ? item.id : item.channel_owner)}>
                          <img
                            src={item.image}
                            alt="user-img"
                            className="img-fluid"
                            width="40"
                          />
                          </a>
                          :
                          <a href={"/profile/"+item.id}>
                            <img
                              src={userIcon}
                              alt="user-img"
                              className="img-fluid mr-2"
                              width="40"
                            />
                          </a>
                      }
                    </div>
                    <div className="Message">
                      <Link to={"/profile/"+item.id}><p>{Helper.transformText(item.name)}</p></Link>
                      <span>{item.linkedin_profile}</span>
                      {
                        (context.state.userDetail.email && context.state.userDetail.email === 'shah@assemblyf.com') || (context.state.currentUser === props.channelInfo?.channel_owner) ?
                        <a className="removeMember" href={() => false} onClick={() => props.removeMember(item.id)}><img src={require("../../../Assets/svg/close.svg")} alt="noImage" /> </a> : ""
                      }
                    </div>
                   
                  </div>
                );
              })}
            </div>
              
          </div>
          }
          {
            props.selectedTab === "groupChat" &&
            <div className="chat-component">
              <GroupChat eventDetail={[]} history={props.history} 
                channelInfoId={props.channelInfoId}  
                ownerId={props.ownerId}
                channelInfo={props.channelInfo}
              />
            </div>
          }
          </>
        )}

       </div>
    </Fragment>
  );
};

export default React.memo(ChatBox);
