import React, { Component } from "react";
import { liveShowError } from "../Helper/StaticContent";
import { AuthContext } from "../Firebase/AuthProvider";

class CreateShowModal extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      meetingName: "",
      displayModal: "flex",
      errorText: "",
    };
  }

  onchangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  validate = () => {
    let error = "";
    if (!this.state.meetingName) {
      error = liveShowError.nameError;
      return error;
    }
    return null;
  };

  onChannelClick = async (e) => {
    e.preventDefault();
    const validate = this.validate();
    if (validate) {
      this.setState({ errorText: validate });
    }
    else {
      this.closeModal();
      let meetingID = this.state.meetingName.replace(/\s/g, '')
      let username = this.context.state.userDetail.name.split(" ")[0]
      window.open("https://live.assemblyf.com:2053/?meeting_id="+meetingID+"&meeting_name="+username+"&user_id="+this.context.state.userDetail.id+"&c="+this.props.channelID , "_blank");
    }
  };

  closeModal = () => {
    this.props.setLiveShowModal();
    this.setState({
      displayModal: "none",
    });
  };

  render() {
    return (
      <div
        className="modal-box"
        style={{ display: this.state.displayModal, zIndex: "999" }}
        id="SignupModal"
        onClick={this.closeModal}
      >
        <div className="login-inner" onClick={(e) => e.stopPropagation()}>
          <h3 className="login-header">Create Live Session</h3>
          <form>
            <input
              type="text"
              required
              name="meetingName"
              value={this.state.meetingName}
              onChange={this.onchangeHandler}
              placeholder="Enter Event Name"
              className="email-input"
            />
            {this.state.errorText && (
              <span className="error-display">{this.state.errorText}</span>
            )}
            <button
              className="login-button-form channel-create-btn"
              onClick={(e) => {
                this.onChannelClick(e);
              }}
            >
              Go Live!
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default CreateShowModal;
