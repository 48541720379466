import React, { Component } from 'react'
import {AuthContext} from '../Firebase/AuthProvider'
import '../Assets/Style/newLayout.scss'
import Header from '../components/header';
import Sidebar from '../components/Sidebar';
import Profile from '../components/profile';
import ChatBox from '../components/chatBox';
import RightSidebar from '../components/RightSideBar';
import UserModal from '../components/UserModal';

class ProfileContainer extends Component {
  static contextType = AuthContext

  constructor(props){
    super()
    this.state={
      posts:[],
      initialCall: false,
      showModal: false
    }
  }

  componentDidMount() {
  }

  toggleModal=()=>{
    this.setState({showModal:!this.state.showModal})
  }


  render() {
    return (
      <div className={!this.context.state.currentUser && "logged-out-profile new-design"}>
        {/* <Loader /> */}
        <Header props={this.props} showForm={this.toggleModal} />
        <div className={`DashboardMainContent ProfileTopContainer ${!this.context.state.currentUser && "without-chat-grid"}`} >
          <Sidebar currentPage="profile" />
          <Profile props={this.props} openModal={this.toggleModal} />
          {this.context.state.currentUser && (
            <>
            <ChatBox props={this.props} userInfo={this.context.state.userDetail} />
            </>
          )}
          <RightSidebar />
         
        </div>
        
        {this.state.showModal && (
          <UserModal closeModal={this.toggleModal} show="login-form" userInfo={this.context.state.userDetail}  />
        )}
        
      </div>
    );
  }
}

export default ProfileContainer
