import React, { Fragment } from "react";

// import styles
import '../../Assets/Style/agenda.css'

// import constants
import { LearningJourneyList } from "../../utils/constant";

const LearningJourney = () => {
  return (
    <Fragment>
      <div className="AgendaContentWrapper">
        <div className="title">
          <h2>Learning Journey</h2>
        </div>
        <div className="AgendaContainer">
          <div className="UpcomingSessions">
            {LearningJourneyList.map((item, index) => {
              return (
                <div className="Session" key={index}>
                  <span>{item.title}</span>
                </div>
              );
            })}
          </div>
          <div className="SessionContent">
            <img
              src="../../static/assets/images/thumbnail.jpg"
              className="img-fluid"
              alt="img"
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default LearningJourney;
