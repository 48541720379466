import React, { useState } from "react";

export default function SearchComponent(props) {
  const [user, setUser] = useState("");
  const [cancelSearch, setCancelSearch] = useState(false)


  const onChangeHandler = (e) => {
    let name = (e.target.value).toLowerCase()
    setUser(name);
  };

  return (
    <div className="search-component">
      <input
        type="text"
        className="input-fiels"
        placeholder="search by name"
        value={user}
        onChange={(e) => {
          onChangeHandler(e);
        }}
      />
      <button
        className="search-btn"
        onClick={() => {
          cancelSearch ? props.onCancelSearchClick() : props.onSearchClick(user); 
          setCancelSearch(!cancelSearch)
          cancelSearch && setUser('')
        }}
      >
        {cancelSearch ?"Cancel":"Search"}
      </button>
    </div>
  );
}
