import React, { Component } from "react";
import { AuthContext } from "../../../Firebase/AuthProvider";
import "react-datepicker/dist/react-datepicker.css";
import { uploadUserImage } from '../../../Firebase/StorageBucket';
import { updateUserData } from '../../../Firebase/Users';
import smallLoader from '../../../Assets/Images/loader.gif'

class MoreProfile extends Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = {
      firstname: props.userInfo.name.split(" ")[0],
      lastname: props.userInfo.name.split(" ")[1],
      company: props.userInfo.linkedin_profile ? props.userInfo.linkedin_profile : "",
      userStory: props.userInfo.user_story ? props.userInfo.user_story : "",
      imagePreview: "",
      imageFile: "",
      messageSucc: "",
      isLoaderShow: false
    };
  }
  
  onChangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmitForm = async (e) => {
    e.preventDefault();
    this.setState({ isLoaderShow: true })
    const imageFile = this.state.imageFile;
    let data = {
      name: this.state.firstname+' '+this.state.lastname,
      linkedin_profile: this.state.company,
      user_story: this.state.userStory
    };
    if (imageFile) {
      data.image = await uploadUserImage(imageFile);
    }
    updateUserData(this.context.state.currentUser, data);
    this.setState({ messageSucc: "Your profile has been updated.", isLoaderShow: false })
    setTimeout(()=>{
      this.props.closeModal();
    }, 1000)
  };

  handleImageAsFile = (e) => {
    const image = e.target.files[0];
    if (image) {
      this.setState({ imageFile: image, imagePreview: URL.createObjectURL(image) });
    }
  };
  
  render() {
    return (
      <form>
        <input placeholder="First name" type="text" name="firstname" value={this.state.firstname} onChange={(e) => this.onChangeHandler(e)} />
        <input placeholder="Last name" type="text" name="lastname" value={this.state.lastname} onChange={(e) => this.onChangeHandler(e)}/>
        <input placeholder="Your company or institution" type="text" name="company" value={this.state.company} onChange={(e) => this.onChangeHandler(e)} />
        <textarea placeholder="Tell us about yourself" name="userStory" value={this.state.userStory} onChange={(e) => this.onChangeHandler(e)}></textarea>
        <div className="image-uploader">
          <div className="input">
            <label htmlFor="image">{this.state.imagePreview ? "Choose different?" :"Upload profile photo"}</label>
            <input type="file" onChange={(e)=>{this.handleImageAsFile(e)}} id="image" />
          </div>
          {this.state.imagePreview && (
            <div className="display">
              <img src={this.state.imagePreview} alt="" />
            </div>
          )}
        </div>
        {
          this.state.messageSucc &&
          <span className="succMsg">{this.state.messageSucc}</span>
        }
        {
            this.state.isLoaderShow ? <div className="loadergif"><img src={smallLoader} alt="noImage" width="100" height="100" /></div> :  <button onClick={(e) => {
              this.onSubmitForm(e);
            }}>Save</button>
        }
       
      </form>
    );
  }
}

export default MoreProfile;